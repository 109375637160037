// src/features/insurance-billing/hooks/useInsuranceBills.ts
import { useCallback, useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { BillingStatus } from "../pages/InsurancePageLayout";
import { BillingTypeEnum, DateRangesByStatus, PaginatedGetInsuranceTransaction, SearchTransaction } from "../types/insuranceBills.types";
import { GetInsuranceTransactionDto } from "../../../../../api-client";
import { useDebounce } from "./useDebounce";

interface UseInsuranceBillsProps {
  getAllBGInsuranceBill: (
    billStatus: BillingStatus,
    searchQuery: SearchTransaction
  ) => Promise<PaginatedGetInsuranceTransaction>;
  getAllInsuranceBill: (
    billStatus: BillingStatus,
    searchQuery: SearchTransaction
  ) => Promise<PaginatedGetInsuranceTransaction>;
  billStatus: BillingStatus;
  lastUpdate?: any;
  billType?: BillingTypeEnum;
}

export function useInsuranceBills({
  getAllBGInsuranceBill,
  getAllInsuranceBill,
  billStatus,
  lastUpdate,
  billType,
}: UseInsuranceBillsProps) {
  const [paginatedData, setPaginatedData] = useState<PaginatedGetInsuranceTransaction>();
  const [allBill, setAllBill] = useState<GetInsuranceTransactionDto[]>([]);

  // Date filters: track date ranges per bill status
  const [dateRanges, setDateRanges] = useState<DateRangesByStatus>({});
  const currentRange = dateRanges[billStatus] || {
    startDate: null,
    endDate: null,
  };

  // Pagination states
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(15);

    // -------------------------
  // Debounced searching
  // -------------------------
  // This is the value typed by the user into a search box.
  const [searchValue, setSearchValue] = useState<string | null>(null);
  // We'll wait ~400ms after the user stops typing to issue the request.
  const debouncedSearchValue = useDebounce(searchValue, 400);

  const fetchAllBill = useCallback(async () => {
    try {
      const searchQuery: SearchTransaction = {
        page,
        limit,
        startDate: currentRange.startDate?.format("YYYY-MM-DD"),
        endDate: currentRange.endDate?.format("YYYY-MM-DD"),
        searchStr: debouncedSearchValue || undefined,
      };

      let data: PaginatedGetInsuranceTransaction;
      if (billType && billType === BillingTypeEnum.BG) {
        data = await getAllBGInsuranceBill(billStatus, searchQuery);
      } else {
        data = await getAllInsuranceBill(billStatus, searchQuery);
      }
      setPaginatedData(data);
      setAllBill(data.data);
    } catch (error) {
      console.error("Error fetching all bills:", error);
    }

  }, [billStatus, currentRange.endDate, currentRange.startDate, getAllInsuranceBill, limit, page, debouncedSearchValue, getAllBGInsuranceBill, billType]);

  useEffect(() => {
    fetchAllBill();
  }, [fetchAllBill, lastUpdate]);

  // Handlers for date range changes
  const handleStartDateChange = (newDate: Dayjs | null) => {
    setDateRanges((prev) => ({
      ...prev,
      [billStatus]: {
        ...prev[billStatus],
        startDate: newDate,
      },
    }));
    setPage(1);
  };

  const handleEndDateChange = (newDate: Dayjs | null) => {
    setDateRanges((prev) => ({
      ...prev,
      [billStatus]: {
        ...prev[billStatus],
        endDate: newDate,
      },
    }));
    setPage(1);
  };

  return {
      allBill,
      fetchAllBill,
    paginatedData,
    page,
    limit,
    setPage,
    setLimit,
    dateRanges,
    currentRange,
    handleStartDateChange,
    handleEndDateChange,
    searchValue,
    setSearchValue,
  };
}
