import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import TreatmentPeriodCard from "./TreatmentPeriodCard";
import CurrentTherapyStatusCard from "./CurrentTherapyStatusCard";
import SpecialFeatureCard from "./SpecialFeatureCard";
import ChangeOfTreatmentCard from "./ChangeOfTreatmentCard";
import TreatmentDiscontinuationCard from "./TreatmentDiscontinuationCard";
import ContinueTreatmentCard from "./ContinueTreatmentCard";
import PrognosticAssessmentCard from "./PrognosticAssessmentCard";
import TherapyChangeSuggestionsCard from "./TherapyChangeSuggestionsCard";
import PrintingAndShippingProtocolCard from "./PrintingAndShippingProtocolCard";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  Paper,
} from "@mui/material";
import {
  DoctorReportDto,
  DoctorReportStausDto,
  DoctorReportStausDtoStatusEnum,
  DoctorReportTimeSlotDto,
} from "../../../../api-client";
import useStore from "../../../../helpers/useStore";
import { AppointmentContext } from "../../../../stores/Appointment/appointment.provider";
import { DocumentationContext } from "../../../../stores/Documentation/Documentation.provider";
import Form from "../../../PatientDataEntryForm/Form";
import { observer } from "mobx-react";
import { PatientsContext } from "../../../../stores/Patients/patients.provider";

interface DoctorReportFormProps {
  editMode: boolean;
  onUpdateReportStatus?: (doctorReportStatus: DoctorReportStausDto) => void;
  doctorReport:DoctorReportDto;
  setDoctorReport:(doctorReport:DoctorReportDto)=>void;
  rxId: string | null;
  handleRowClick?: (report: DoctorReportDto) => void;
  handleCloseDialog:(value?:any) => void;
  onSave?:()=>void;
  routingParams?: any;
} 

export interface DoctorReportFormRef {
  handleSubmitDoctorReport: () => void;
}

const DoctorReportForm = observer(forwardRef<DoctorReportFormRef, DoctorReportFormProps>(
  ({ editMode, onUpdateReportStatus, rxId,doctorReport,setDoctorReport,handleRowClick,handleCloseDialog,onSave,routingParams }, ref) => {
    const DocumentationStore = useStore(DocumentationContext);
    const PatientStore=useStore(PatientsContext);
    // const [doctorReport, setDoctorReport] = useState<DoctorReportDto>({
    //   id:  "",
    //   rxId: rxId ? rxId: "",
    // });
  
    const {
      getDoctorReportByRxId,
      createDoctorReport,
      updateDoctorReport,
      getDoctorReportFirstAndLastTimeSlot,
      updateDoctorReportStatus,
    } = DocumentationStore;

    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const fetchData=()=>{
      if(rxId){
      setLoading(true);
      getDoctorReportByRxId(rxId)
        .then((doctorReport: DoctorReportDto) => {
          setDoctorReport(doctorReport);
        })
        .catch((error) => {
          console.log(`Error fetching doctor report for rxId ${rxId}`, error);
        })
        .finally(() => setLoading(false));}
    }

    useEffect(() => {
      if (rxId) {
        fetchData()
       
      }
    }, [rxId]);
    useImperativeHandle(ref, () => ({
      async handleSubmitDoctorReport() {
        setLoading(true);
        let doctorReportObj = { ...doctorReport };
    
        try {
          if (doctorReportObj.id) {
            // Update doctor report
            const updatedReport = await updateDoctorReport(doctorReportObj.id, doctorReportObj);
            setDoctorReport(updatedReport);
          } else {
            // Create new doctor report
            let createDoctorReportObj = { ...doctorReportObj, id: undefined };
            const newReport = await createDoctorReport(createDoctorReportObj);
            setDoctorReport(newReport);
            if(handleRowClick){
            handleRowClick(newReport)}
          }
        } catch (error) {
          console.error("Error in submitting doctor report:", error);
          throw error; // Re-throw to let the caller handle it
        } finally {
          setLoading(false);
        }
      },
    }));
    

    // useImperativeHandle(ref, () => ({
    //   handleSubmitDoctorReport() {
    //     setLoading(true);
    //     let doctorReportObj = { ...doctorReport };

    //     if (doctorReportObj)
    //       if (doctorReportObj.id) {
    //         // Update doctor report
    //         updateDoctorReport(doctorReportObj.id, doctorReportObj)
    //           .then((data) => {
    //             setDoctorReport(data);
    //           })
    //           .catch((error) => {
    //             console.log(error);
    //           })
    //           .finally(() => setLoading(false));
    //       } else {
    //         let createDoctorReportObj = { ...doctorReportObj, id: undefined };
    //         createDoctorReport(createDoctorReportObj)
    //           .then((doctorReport) => {
    //             setDoctorReport(doctorReport);

    //           })
    //           .catch((error) => {
    //             console.log(error);
    //           })
    //           .finally(() => setLoading(false));
    //       }
    //       // fetchData()
    //   },
    // }));



    const handleCompleteDoctorReport = () => {
      setLoading(true);
      updateDoctorReportStatus(doctorReport.id, { status: DoctorReportStausDtoStatusEnum.Complete })
        .then((data: DoctorReportStausDto) => {
          onUpdateReportStatus && onUpdateReportStatus(data);
          if(handleCloseDialog){
            handleCloseDialog(true)}
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    };

    const FormContent=(
      <Form>
      {loading && 
        <div>
          <CircularProgress size={60} sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }} />
          Loading...
        </div>
    }
    {!loading && (
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Grid sx={{ marginBottom: 2 }}>
        <TreatmentPeriodCard
          doctorReport={doctorReport}
          setDoctorReport={setDoctorReport}
          editMode={editMode}
        /></Grid>
         <Grid sx={{ marginBottom: 2 }}>
        <CurrentTherapyStatusCard
          editMode={editMode}
          doctorReport={doctorReport}
          setDoctorReport={setDoctorReport}
        /></Grid>
        <Grid sx={{ marginBottom: 2 }}>
        <SpecialFeatureCard
          editMode={editMode}
          doctorReport={doctorReport}
          setDoctorReport={setDoctorReport}
        /></Grid>
        <Grid sx={{ marginBottom: 2 }}>
        <ChangeOfTreatmentCard
          editMode={editMode}
          doctorReport={doctorReport}
          setDoctorReport={setDoctorReport}
        /></Grid>
        </Grid>
        <Grid item xs={6}>
        <Grid sx={{ marginBottom: 2 }}>
        <TreatmentDiscontinuationCard
          editMode={editMode}
          doctorReport={doctorReport}
          setDoctorReport={setDoctorReport}
        /></Grid>
        <Grid sx={{ marginBottom: 2 }}>
        <ContinueTreatmentCard
          editMode={editMode}
          doctorReport={doctorReport}
          setDoctorReport={setDoctorReport}
        /></Grid>
        <Grid sx={{ marginBottom: 2 }}>
        <PrognosticAssessmentCard
          editMode={editMode}
          doctorReport={doctorReport}
          setDoctorReport={setDoctorReport}
        /></Grid>
        <Grid sx={{ marginBottom: 2 }}>
        <TherapyChangeSuggestionsCard
          editMode={editMode}
          doctorReport={doctorReport}
          setDoctorReport={setDoctorReport}
        /></Grid>
        <Grid sx={{ marginBottom: 2 }}>
        <PrintingAndShippingProtocolCard
          editMode={editMode}
          doctorReport={doctorReport}
          setDoctorReport={setDoctorReport}
        /></Grid>
        </Grid>
      </Grid>
    )}
    {editMode && doctorReport.id && (
      <div style={{ textAlign: "center", marginBottom: "16px" }}>
        <Button variant="contained" color="primary" onClick={() =>setOpenDialog(true)
        }>
          Vollständig
        </Button>
      </div>
    )}

    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>Sind Sie sicher, dass Sie das Formular ausfüllen möchten?</DialogTitle>
      <DialogContent>
        Sie werden das Formular später nicht mehr aktualisieren können.
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="secondary">
          Nein
        </Button>
        <Button
          onClick={() => {
            handleCompleteDoctorReport();
            setOpenDialog(false);
            
          }}
          color="primary"
        >
          Ja
        </Button>
      </DialogActions>
    </Dialog></Form>)

return (
  editMode ? (
    <Dialog open={editMode} onClose={handleCloseDialog} fullWidth maxWidth="lg">
      <DialogTitle>
        {editMode ? "Arzt Report" : "View Arzt Report"}
      </DialogTitle>
      <DialogContent>  
        {FormContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} variant="text">
          Abbrechen
        </Button>
        <Button onClick={onSave} variant="contained" disabled={!editMode}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    FormContent // Render FormContent if not in editReportMode
  )
);


  }
));

export default DoctorReportForm;
