import React, { FC } from "react";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";

interface PrintingBackdropProps {
  isPrinting: boolean;
  message?: string;
}

export const PrintingBackdrop: FC<PrintingBackdropProps> = ({ isPrinting, message }) => {
  return (
    <Backdrop
      open={isPrinting}
      style={{ zIndex: 2000, color: "#fff" }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <CircularProgress color="inherit" />
        <Typography
          variant="h6"
          sx={{ mt: 2 }}
        >
          {message ?? "Bitte warten, Druckvorgang läuft..."}
        </Typography>
      </Box>
    </Backdrop>
  );
};
