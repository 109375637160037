import React, { forwardRef } from "react";

interface A4PrintComponentProps {
  billNumber: string;
  rxNumber: string;
  careProviderIk: string;
}

const A4PrintComponent = forwardRef<HTMLDivElement, A4PrintComponentProps>((props, ref) => {
  const { billNumber, rxNumber, careProviderIk } = props;

  const billLine: string = "206.5mm";
  const ikLine: string = "221.5mm";

  return (
    <div
      ref={ref}
      style={{
        width: "210mm",
        height: "297mm",
        position: "relative",
        fontFamily: "Courier New, monospace",
        fontSize: "22pt",
        backgroundColor: "transparent",
        letterSpacing: "2.6mm",
      }}
    >
      {/* Bill Number Position */}
      <div
        style={{
          position: "absolute",
          left: "10mm", // Fixed horizontal position
          top: billLine, // Fixed vertical position
        }}
      >
        {billNumber}
      </div>

      {/* IK and bill id line */}
      <div
        style={{
          position: "absolute",
          left: "10mm",
          top: ikLine,
        }}
      >
        <div>{careProviderIk}</div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "82mm",
          top: ikLine,
        }}
      >
        <div>{rxNumber}</div>
      </div>
    </div>
  );
});

export default A4PrintComponent;
