import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Button,
  Container,
  Grid,
  Typography,
  Divider,
  Checkbox,
  FormControl,
  FormControlLabel,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  Radio,
  styled,
  Tooltip,
  IconButton,
} from "@mui/material";
import { attentionColors, mobiliTheme } from "../../../../../themes/mobiliTheme";
import { Add, Check, CheckBox, Delete, PersonSearch } from "@mui/icons-material";
import Controls from "../../../../../atoms/Controls";
import TextArea from "../../../../../atoms/TextArea";
import { observer, useObserver } from "mobx-react";
import { initialValues } from "../../../../pages/Patient/initialValues";
import useStore from "../../../../../helpers/useStore";
import { MasterTablesContext } from "../../../../../stores/MasterTables/MasterTables.provider";
import { DoctorContext } from "../../../../../stores/Doctor/doctor.provider";
import { RxStoreContext } from "../../../../../stores/Rx/rx.provider";
import { NoteDto, RxDto, RxDtoProcessingStatusEnum } from "../../../../../api-client";
import { v4 as uuidv4 } from "uuid";
import notificationStore from "../../../../../stores/Notification/notificationStore";
import { UserContext } from "../../../../../stores/User/User.provider";
import DoctorDialog from "../../../../PatientDataEntryForm/Doctor";
import { PatientsContext } from "../../../../../stores/Patients/patients.provider";
import ValidationCardRxInfoPage from "../../../../../molecules/ValidationCardRxInfoPage";
import FlatCard from "../../../../../atoms/FlatCard";
import classes from "./Zahnarzt.module.css";
import ValidationCard, { formType } from "../../../../../molecules/ValidationCard";
import WarningDialog from "../../../../../atoms/WarningDialog";
import debounce from "lodash.debounce";
import ValidationTooltip from "../../../../../atoms/validationToolTip";
import { getStatusColor } from "../statusColor";
import { NotesContext } from "../../../../../stores/Notes/notes.provider";
import Notes from "../../../../ScheduleOverview/Notes/Notes";
import { validateFrequency, validateTherapy } from "../../../../../molecules/Editable";
import { rxuseForm } from "../../../../PatientDataEntryForm/Form";

interface DiagnoseGroup {
  a: string;
  b: string;
  c: string;
  d: string;
}

interface ZahnarztProps {
  rxFormData: any;
  patientValues: any;
  handleData: any;
  updateFormData?: any;
  masterTablesData: any;
  readOnlyView?: boolean;
  newRx: boolean | undefined;
  openRx?: boolean;
  onCloseRx?: any;
  updateRxProcessingStatus?: (event: any) => void;
}

const ScalableDiv = styled("div")(({ theme }) => ({
  transformOrigin: "top left",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.6)",
  },
  [theme.breakpoints.between("sm", "xl")]: {
    transform: "scale(0.65)",
  },
  [theme.breakpoints.up("xl")]: {
    transform: "scale(0.85)",
  },
}));

const ScalableComponentSimple = ({ children }: any) => {
  return <ScalableDiv>{children}</ScalableDiv>;
};

const Zahnarzt: React.FC<ZahnarztProps> = observer(
  ({
    rxFormData,
    patientValues,
    handleData,
    updateFormData,
    masterTablesData,
    readOnlyView,
    newRx,
    onCloseRx,
    openRx,
    updateRxProcessingStatus,
  }) => {
    const masterTablesStore = useStore(MasterTablesContext);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [unsavedChangesWarning, setShowunsavedChangesWarning] = useState(false);
    const UserStore = useStore(UserContext);
    const patientStore = useStore(PatientsContext);
    const NotesStore = useStore(NotesContext);
    const { getRxNotes, updateNoteById, postNotes, fetchNotes, setFetchNotes } = NotesStore;

    const { getLiveValidation } = patientStore;
    const rxStore = useStore(RxStoreContext);
    const { savedSeriesForPatient, updateprocessingStatus } = rxStore;

    const { user } = UserStore;
    const { getICD, getTarifGroupIds, getTherapyId } = masterTablesStore;
    const [selectedDiagnoseGroup, setSelectedDiagnoseGroup] = useState<DiagnoseGroup | null>(null);
    const RxStore = useStore(RxStoreContext);
    const { saveRxs } = RxStore;
    const [loading, setLoading] = useState(false);
    const [rxNotes, setRxNotes] = useState<NoteDto[]>([]);

    const tarifGroupsMasterData = getTarifGroupIds();
    //For payer
    const insurer = masterTablesData
      .filter((obj: any) => "insurer" in obj)
      .map((obj: any) => obj.insurer);
    const [selectedPayer, setSelectedPayer] = useState(null);
    const [selectedChipCard, setSelectedChipCard] = useState<string | null>(null);
    const diagnoseGroups = masterTablesData
      .filter((obj: any) => "diagnosisGroups" in obj)
      .map((obj: any) => obj.diagnosisGroups);

    // const diagnoseGroups = masterTablesData
    // .filter((obj: any) => obj.RxType?.id === "Zahnarzt") // Filter objects where RxType.id is "Zahnarzt"
    // .map((obj: any) => obj.diagnosisGroups); // Map to extract the diagnosisGroups

    const therapyOptions = masterTablesData
      .filter((obj: any) => "therapy" in obj)
      .map((obj: any) => obj.therapy);
    const rxType = masterTablesData
      .filter((obj: any) => "rxType" in obj) // Filter objects that have the insuranceStatus property
      .map((obj: any) => obj.rxType);
    //  therapyOptions= therapyOptions[0].filter(
    //       (therapyObj: any) =>

    //         therapyObj.duration
    //     )

    //For Doctor
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedBSNR, setSelectedBSNR] = useState<string | null>(null);
    const [selectedLANR, setSelectedLANR] = useState<string | null>(null);
    const DoctorStore = useStore(DoctorContext);
    const { doctorOptions } = DoctorStore;
    const [isSelectActive, setIsSelectActive] = useState(false);
    const [isAddEditDialogOpen, setAddEditDialogOpen] = useState(false);

    const account = masterTablesData
      .filter((obj: any) => "account" in obj)
      .map((obj: any) => obj.account);

    const handlePersonSearchClick = () => {
      setIsSelectActive(!isSelectActive); // Activate the Select dropdown
    };
    const handleDoctorChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
      setUnsavedChanges(true);
      const selectedValue: any = event.target.value;
      setSelectedDoctor(selectedValue);
      // Autopopulate bsnr based on doctor selection
      const selectedDoctor: any = doctorOptions.find(
        (doctor: any) => doctor?.contactInfo.lastName === selectedValue
      );

      if (selectedDoctor) {
        setFormData((prevFormValues: any) => ({
          ...prevFormValues,
          doctorNumber: selectedDoctor.number,
          practiceNumber: selectedDoctor.clinicNumber,
          doctorData: selectedDoctor,
        }));
        setSelectedBSNR(selectedDoctor.clinicNumber);
        setSelectedLANR(selectedDoctor.number);
      }

      // Filter doctor options based on selectedValue
      const filteredOptions: any = doctorOptions.filter(
        (doctor: any) => doctor?.contactInfo?.lastName === selectedValue
      );
      if (filteredOptions?.length > 0) {
        // If filteredOptions has a value, set it as the fetchedData
        // setFetchedData(filteredOptions[0]);
        // Update receiptForm with selected doctor
        // if (!isEditing) {
        setFormData({
          ...formData,
          doctorId: filteredOptions[0].id,
          doctorNumber: filteredOptions[0].number,
          practiceNumber: filteredOptions[0].clinicNumber,
          doctorData: filteredOptions[0],
        });
        // } else {
        //   setFormValues((prevFormValues: any) => ({
        //     ...prevFormValues,
        //     rxs: prevFormValues.rxs.map((rx: any) => ({
        //       ...rx,
        //       doctorId: filteredOptions[0].id,
        //       practiceNumber: filteredOptions[0].clinicNumber,
        //       doctorNumber: filteredOptions[0].number,
        //     })),
        //   }));
        // }
      } else {
        // setFetchedData(null);
        setFormData({
          ...formData,
          doctorId: "",
          doctorNumber: "",
          practiceNumber: "",
        });
      }
    };

    const initialrxValues = {
      Receiptpayer: patientValues?.payer,
      firstName: "",
      lastName: "",
      address: "",
      dob: "",
      processingStatus: RxDtoProcessingStatusEnum.InBehandlung,
      status: "",
      signatureStatus: false,
      insuranceStatus: "",
      chipcard: "",
      tarifGroupId: "",
      insureeNumber: "",
      rxtype: "Kasse",
      accountId: "",

      isPerpetual: false,

      doctorId: "",
      practiceNumber: "",
      doctorNumber: "",
      date: "",
      isCopaymentExempted: false,
      isAccident: false,
      isBVG: false,
      icdCodeEntries: [
        { id: "", userText: "" },
        { id: "", userText: "" },
      ],
      diagnosisText: "",
      diagnosegroup: null,
      a: "",
      b: "",
      c: "",
      d: "",
      hasIndividualSymptoms: false,
      therapyRxs: [
        {
          id: uuidv4(),
          amount: "",
          frequency: { id: "", text: "" },
          therapy: { id: "", HMP4: "", price: "", abbreviation: "", description: "" },
          isDoubled: false,
          doubledTreatmentIds: [],
          rxSeries: { label: "" },
        },
        {
          id: uuidv4(),
          amount: "",
          frequency: { id: "", text: "" },
          therapy: { id: "", HMP4: "", price: "", abbreviation: "", description: "" },
          isDoubled: false,
          doubledTreatmentIds: [],
          rxSeries: { label: "" },
        },
        {
          id: uuidv4(),
          amount: "",
          frequency: { id: "", text: "" },
          therapy: { id: "", HMP4: "", price: "", abbreviation: "", description: "" },
          isDoubled: false,
          doubledTreatmentIds: [],
          rxSeries: { label: "" },
        },
      ],
      isHomeVisit: rxFormData.isHomeVisit,
      therapyReport: false,
      urgent: false,
      symptoms: "",
      therapyFrequency: "",
      amount: "",
      additionalNotes: "",
      doctorData: {} as any,
      duplicate: false,
    };

    const [formData, setFormData] = useState(initialrxValues);
    const [icdCodeEntries, setIcdCodeEntries] = useState(() => {
      // Ensure `initialrxValues.icdCodeEntries` is an array
      return (initialrxValues.icdCodeEntries || []).map((entry) => ({
        id: entry.id ? entry.id : "",
        userText: entry.userText ? entry.userText.split("") : Array(5).fill(""),
      }));
    });

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getRxNotes(rxFormData.id);

          setRxNotes(response);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();
    }, [rxFormData.id, fetchNotes]);
    const updateNote = async (index: number, updatedCard: NoteDto) => {
      updatedCard.rxId = rxFormData.id;
      if (updatedCard.id) {
        await updateNoteById(updatedCard.id, user?.id as string, updatedCard);
      } else {
        await postNotes(user?.id as string, updatedCard);
      }
      const updatedNotes = [...rxNotes];
      updatedNotes[index] = updatedCard;
      setRxNotes(updatedNotes);
      // setFetchNotes(!fetchNotes);
    };

    const handleUnsavedChangesWarning = () => {
      handleGenericSubmit();
    };
    const handleCloseRxDialog = () => {
      if (unsavedChanges) {
        // If there are unsaved changes, set a state to open the warning dialog
        setShowunsavedChangesWarning(true);
      } else {
        onCloseRx();
      }
    };
    const handleCloseUnsavedChangesWarning = () => {
      onCloseRx();
      // If user cancels and chooses not to continue, close the warning dialog
      setShowunsavedChangesWarning(false);
    };
    const [lines, setLines] = useState(["", ""]); // Initialize with two empty strings

    // useEffect(() => {
    //   if (rxFormData) {
    //     // Populate the form data with values from rxFormData
    //     setFormData(rxFormData);
    //   } else {
    //     // Use empty values for form data
    //    setFormData(initialrxValues)
    //   }
    // }, [rxFormData]);
    // Initialize with two input boxes

    // Expose the submitForm function to the parent component

    const formattedRxData = (rxFormData: any, initialTherapyRxs: any) => {
      const code = (rxFormData.diagnosisGroup?.code || rxFormData.diagnosegroup)?.toUpperCase(); // Example: "PS1"

      // Check if the code is "PS1"
      if (code) {
        // Determine the additional character based on flags
        let additionalChar;
        if (rxFormData.a) {
          additionalChar = "a";
        } else if (rxFormData.b) {
          additionalChar = "b";
        } else if (rxFormData.c) {
          additionalChar = "c";
        } else if (rxFormData.d) {
          additionalChar = "d";
        }

        // Combine the characters and set input symptoms
        const newInputSymptoms = [...code];
        if (additionalChar) {
          newInputSymptoms.push(additionalChar);
        }

        // Update the inputSymptoms state
        setInputSymptoms(newInputSymptoms);
      }
      if (rxFormData.icdCodeEntries.length > 0) {
        // Process the existing entries
        const processedEntries = rxFormData.icdCodeEntries.map((entry: any) => {
          // Remove periods and split userText into an array of characters
          const cleanedUserText = entry.userText.replace(/\./g, ""); // Remove periods
          const splitUserText = cleanedUserText.split(""); // Split into characters

          // Ensure the array has exactly 5 elements, padding with empty strings if necessary
          const paddedUserText = [
            ...splitUserText,
            ...Array(5).fill("").slice(splitUserText.length, 5),
          ];

          return {
            id: entry.id || "",
            userText: paddedUserText,
          };
        });

        // Ensure there are at least two entries
        const defaultEntries =
          processedEntries.length < 2
            ? [...processedEntries, { userText: Array(5).fill("") }]
            : processedEntries;

        setIcdCodeEntries(defaultEntries);
      } else {
        // If there are no entries, set two empty entries
        setIcdCodeEntries([
          { id: "", userText: Array(5).fill("") },
          { id: "", userText: Array(5).fill("") },
        ]);
      }

      if (rxFormData.diagnosisText) {
        // Split the diagnosisText on newline characters
        const splitLines = rxFormData.diagnosisText.split("\n");

        // Ensure the array has exactly two elements
        // If less, pad with empty strings; if more, trim to two elements
        const formattedLines = [
          splitLines[0] || "", // First line
          splitLines[1] || "", // Second line (if it exists, otherwise empty string)
        ];

        setLines(formattedLines);
      }

      const updatedFormData = {
        ...rxFormData,
        firstName: patientValues.firstName || "",
        lastName: patientValues.lastName || "",
        dob: patientValues.dob || "",

        insureeNumber: rxFormData.insureeNumber || patientValues.insuranceNo,
        insuranceStatus: readOnlyView?rxFormData.insuranceStatus :(rxFormData.insuranceStatus || patientValues.status),
        // Receiptpayer: rxFormData.payer.insurer || "",
        Receiptpayer:
          insurer[0]?.find((option: any) => option.id === rxFormData.Receiptpayer)?.name ||
          rxFormData.payer?.name ||
          rxFormData.Receiptpayer,
        chipcard: rxFormData.chipcard || rxFormData.payer?.ik,
        doctorId:
          doctorOptions?.find((option: any) => option.id === rxFormData.doctorId)?.contactInfo
            .lastName || rxFormData.doctor?.contactInfo?.lastName,
        diagnosegroup:
          diagnoseGroups[0]?.find((option: any) => option.id === rxFormData.diagnosegroup)?.code ||
          rxFormData.diagnosisGroup?.code,
        rxtype:
          rxType[0]?.find((option: any) => option.id === rxFormData.rxtype)?.text ||
          rxFormData.rxtype?.text ||
          "Kasse",
        doctorData:
          (doctorOptions as any).find(
            (option: any) =>
              option.contactInfo.lastName === rxFormData.doctorId ||
              option.id === rxFormData.doctorId
          ) || rxFormData.doctor,
        accountId:
          account[0]?.find((option: any) => option.id === rxFormData.accountId)?.name ||
          rxFormData.account?.name,
        status: rxFormData.status || "",
        signatureStatus: rxFormData.signatureStatus || "",
        therapyRxs: initialTherapyRxs,
        // rxtype:rxFormData.rxtype?.id || "",
        // accountId:rxFormData.account?.id || "",
        // chipcard: rxFormData.payer?.id || "",
        // doctorId: rxFormData.doctor?.contactInfo?.lastName || "",
        bsnr: rxFormData.practiceNumber || "",
        // diagnosegroup: rxFormData.diagnosisGroup?.code || "",
      };
      return updatedFormData;
    };

    useEffect(() => {
      const fetchData = async () => {
        if (rxFormData && rxFormData.rxNumber) {
          // rxFormData = patientValues?.rxs.find((rx: any) => rx.id === rxFormData.id);

          // let rxs: [] = [];
          let icdCodeEntries;

          if (rxFormData.icdCodeEntries && rxFormData.icdCodeEntries.length > 0) {
            if (rxFormData.icdCodeEntries.length === 1) {
              icdCodeEntries = [...rxFormData.icdCodeEntries, { userText: "" }];
            } else {
              icdCodeEntries = rxFormData.icdCodeEntries;
            }
          } else {
            icdCodeEntries = [{ userText: "" }, { userText: "" }];
          }
          rxFormData.therapyRxs = rxFormData?.therapyRxs?.flatMap((therapy: any) => {
            return {
              id: therapy.id,
              date: rxFormData.date,
              therapy: therapy.therapy.id
                ? therapy.therapy
                : {
                    id: therapy?.therapy,
                    abbreviation: therapy?.therapy,
                    description:
                      therapyOptions[0]?.find(
                        (therapyObj: any) => therapyObj.id === therapy?.therapy
                      ).description || "",
                  },
              amount: therapy.amount,
              frequency: therapy.frequency
                ? therapy.frequency.id
                  ? therapy.frequency
                  : { id: therapy?.frequency, text: therapy?.frequency }
                : null,
              isDoubled: therapy.isDoubled,
              doubledTreatmentIds: therapy.doubledTreatmentIds,
              rxSeries: therapy.rxSeries,
            };
          });

          const initialTherapyRxs = Array.from({ length: 3 }, (_, index) => {
            if (index < rxFormData.therapyRxs.length) {
              // If index is within the length of formData.therapyRxs, use existing data
              return rxFormData.therapyRxs[index];
            } else {
              // Otherwise, add an empty row
              return {
                id: uuidv4(),
                amount: "",
                frequency: { id: "", text: "", prefferedValue: "" },
                therapy: { id: "", HMP4: "", price: "", abbreviation: "", description: "" },
                isDoubled: false,
                doubledTreatmentIds: [],
                // rxSeries:{}
              };
            }
          });
          // Update form data based on fetched data

          const updatedFormData = formattedRxData(rxFormData, initialTherapyRxs);
          // Update form data state
          setFormData(updatedFormData);

          // Optionally, return the updated form data
          return updatedFormData;
        }
        // Make API request to fetch patient data based on patient ID or any other identifier
        else {
          // If no selectedRow, check if therapytableData exists
          const therapyRxs: any =
            rxFormData.therapyRxs && rxFormData.therapyRxs.length
              ? Array.from({ length: 3 }, (_, index) => ({
                  ...rxFormData.therapyRxs[index],
                  id: rxFormData.therapyRxs[index]?.id || uuidv4(),
                  amount: rxFormData.therapyRxs[index]?.amount || "",
                  frequency: rxFormData.therapyRxs[index]?.frequency
                    ? rxFormData.therapyRxs[index].frequency
                    : { id: "", text: "" },

                  therapy: rxFormData.therapyRxs[index]?.therapy
                    ? rxFormData.therapyRxs[index].therapy
                    : { id: "", abbreviation: "" },
                }))
              : initialrxValues.therapyRxs;

          const updatedData = formattedRxData(rxFormData, therapyRxs);

          // Set form values using therapytableData or default to initialrxValues
          setFormData(updatedData);
        }
      };
      fetchData();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rxFormData, patientValues?.rxs]);
    // Update symptoms based on diagnosis group and the fourth character
    // Update symptoms based on diagnosis group and the selected boolean flags
    useEffect(() => {
      if (formData.diagnosegroup) {
        const diagnoseGroupIsId = diagnoseGroups[0].some(
          (group: any) => group.id === formData.diagnosegroup
        );
        // Find the matching diagnose group based on the selected code
        const selectedDiagnoseGroup = diagnoseGroupIsId
          ? diagnoseGroups[0].find((group: any) => group.id === formData.diagnosegroup)
          : diagnoseGroups[0].find((group: any) => group.code === formData.diagnosegroup);

        if (selectedDiagnoseGroup) {
          // Generate symptoms based on selected checkboxes
          let selectedSymptoms = "";
          if (formData.a) selectedSymptoms += selectedDiagnoseGroup.a + " ";
          if (formData.b) selectedSymptoms += selectedDiagnoseGroup.b + " ";
          if (formData.c) selectedSymptoms += selectedDiagnoseGroup.c + " ";
          if (formData.d) selectedSymptoms += selectedDiagnoseGroup.d + " ";
          // If diagnose group length is 4 and a, b, c, d are all false, concatenate all subgroup text
          if (
            selectedDiagnoseGroup.code?.length === 4 &&
            !formData.a &&
            !formData.b &&
            !formData.c &&
            !formData.d
          ) {
            selectedSymptoms +=
              (selectedDiagnoseGroup.a ? selectedDiagnoseGroup.a + " " : "") +
              (selectedDiagnoseGroup.b ? selectedDiagnoseGroup.b + " " : "") +
              (selectedDiagnoseGroup.c ? selectedDiagnoseGroup.c + " " : "") +
              (selectedDiagnoseGroup.d ? selectedDiagnoseGroup.d + " " : "");
          }

          setSelectedDiagnoseGroup(selectedDiagnoseGroup);
          setFormData((prevValues: any) => ({
            ...prevValues,
            a: selectedDiagnoseGroup.a ? prevValues.a : false,
            b: selectedDiagnoseGroup.b ? prevValues.b : false,
            c: selectedDiagnoseGroup.c ? prevValues.c : false,
            d: selectedDiagnoseGroup.d ? prevValues.d : false,
            symptoms: selectedSymptoms.trim(),
          }));
        }
      }
    }, [formData.diagnosegroup, formData.a, formData.b, formData.c, formData.d]);

    const handleICDCodeEntrySubmit = async (icdCode: any, index: number) => {
      try {
        // Access userText from the current icdCodeEntry
        const userTextArray: any = formData.icdCodeEntries[index].userText;
        let formattedUserText = userTextArray;

        // Insert period if the 4th character is present
        if (userTextArray.length >= 4 && !formattedUserText.includes(".")) {
          formattedUserText = `${formattedUserText.slice(0, 3)}.${formattedUserText.slice(3)}`;
        }

        // Log the formatted userText

        // Fetch ICD object using the formatted string
        const icdObject: any = await getICD(formattedUserText);
        if (icdObject) {
          // Update the icdCodeEntries state with the formatted userText
          setFormData((prevValues: any) => {
            const updatedICDCodeEntries = prevValues.icdCodeEntries.map(
              (icdEntry: any, i: number) => {
                return i === index
                  ? { id: icdCode?.id || "", userText: formattedUserText }
                  : icdEntry;
              }
            );

            // Update concatenated diagnosisText
            const concatenatedDiagnosisText = prevValues.diagnosisText
              ? `${prevValues.diagnosisText}\n${icdObject.text}` // Append the new icdObject.text to the existing diagnosisText with a new line
              : icdObject.text;
            return {
              ...prevValues,
              icdCodeEntries: updatedICDCodeEntries,
              diagnosisText: concatenatedDiagnosisText.trim(), // Set diagnosisText to the concatenated value
            };
          });
          setLines((prevLines) => {
            const newLines = [...prevLines];
            newLines[index] = icdObject.text; // Update the specific line
            return newLines;
          });
        }
      } catch (error) {
        console.error("Error fetching ICD object:", error);
      }
    };

    const handleChange = (event: any, index?: any) => {
      const { name, value } = event.target;
      const updatedTherapyRxs: any = [...formData.therapyRxs];
      updatedTherapyRxs[index][name.split(".")[1]] = value;
      setFormData({ ...formData, therapyRxs: updatedTherapyRxs });
    };
    const handleOpenAddEditDialog = (lanr: string | null, bsnr: string | null) => {
      setSelectedLANR(lanr);
      setSelectedBSNR(bsnr);
      setAddEditDialogOpen(true);
    };

    const handleCloseAddEditDialog = (data: any) => {
      // Handle doctor data, LANR, and BSNR passed back from DoctorDialog
      setAddEditDialogOpen(false);

      // Here you can perform further actions with the received data
      if (data && "number" in data) {
        const { number, clinicNumber, contactInfo, id } = data;

        // Update states or perform other operations based on the received data
        setSelectedLANR(number);
        setSelectedBSNR(clinicNumber);
        setSelectedDoctor(contactInfo?.lastName);
        setFormData((prevFormValues: any) => ({
          ...prevFormValues,
          doctorNumber: number,
          practiceNumber: clinicNumber,
          doctorId: id,
          doctorData: data,
        }));

        // For example, you can update doctor form values if needed
        // setDoctorFormValues(doctorData);
      }
    };

    const [diagnosisText, setDiagnosisText] = useState("");

    const processDiagnosis = () => {
      const diagnosisGroup = formData.a + formData.b + formData.c + formData.d;

      if (diagnosisGroup.length === 4) {
        setDiagnosisText(diagnosisGroup); // Full group, no need to check further
      } else if (diagnosisGroup.length === 3) {
        const char = formData.d;
        if (["a", "b", "c", "d"].includes(char)) {
          setDiagnosisText(diagnosisGroup); // 3-character group + valid 4th char
        } else {
          alert("Invalid 4th character. Must be a, b, c, or d.");
        }
      } else {
        alert("Invalid Diagnosis Group length. Ensure correct input.");
      }
    };
    const [isHausbesuchSelected, setHausbesuchSelected] = useState<boolean | null>(
      formData.isHomeVisit
    );
    const handleCheckboxChange = (event: any) => {
      const selectedValue = event.target.value; // "yes" or "no"

      if (selectedValue === "yes") {
        setHausbesuchSelected(true);
        // Set isHomeVisit to true if "yes" is selected
        handleInputChange({
          target: {
            name: "isHomeVisit",
            value: true,
          } as unknown as EventTarget & HTMLInputElement, // Cast to EventTarget & HTMLInputElement
        } as React.ChangeEvent<HTMLInputElement>);
      } else if (selectedValue === "no") {
        setHausbesuchSelected(false);
        // Set isHomeVisit to false if "no" is selected
        handleInputChange({
          target: {
            name: "isHomeVisit",
            value: false,
          } as unknown as EventTarget & HTMLInputElement, // Cast to EventTarget & HTMLInputElement
        } as React.ChangeEvent<HTMLInputElement>);
      } else {
        setHausbesuchSelected(null); // Set null if neither is selected
        // Set isHomeVisit to null if neither checkbox is selected
        handleInputChange({
          target: {
            name: "isHomeVisit",
            value: null,
          } as unknown as EventTarget & HTMLInputElement, // Cast to EventTarget & HTMLInputElement
        } as React.ChangeEvent<HTMLInputElement>);
      }
    };

    const handleInputDiagnosisChange = (e: any, key: any) => {
      setFormData({ ...formData, [key]: e.target.value });
    };

    const [inputSymptoms, setInputSymptoms] = useState(["", "", "", ""]);
    const inputRefs: any = useRef([]);

    // Ensure inputRefs.current is correctly populated
    useEffect(() => {
      inputRefs.current = {};
    }, [formData.icdCodeEntries.length]);

    // const updateRxProcessingStatus = async (event: any) => {
    //   const response = await updateprocessingStatus(rxFormData.id, event?.target.value);
    // };
    const handleDeleteTherapy = (therapyId: string) => {
      // Filter out the therapy that matches the given ID
      const updatedTherapies = formData.therapyRxs.filter(
        (therapy) => therapy.id !== therapyId
      );
    
      // Update formData state with new therapy list
      setFormData({
        ...formData,
        therapyRxs: updatedTherapies,
      });
    };
    const handleInputChange = (
      event: any,
      index?: any,
      idValue?: string,
      compartmentIndex?: string
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name, value, checked, type, id } = event.target;
      setUnsavedChanges(true);

      const inputValue = type === "checkbox" ? checked : value;

      if (name === "date" && formData.isPerpetual && value) {
        setFormData((prevValues: any) => ({
          ...prevValues,
          unvirtualize: true,
        }));
      }

      const updatedTherapyRxs = [...formData.therapyRxs];

      if (name === "therapy") {
        // const selectedTherapy = therapyOptions[0]?.find(
        //   (therapyObj: any) => therapyObj.lineItem.concat("(", therapyObj.abbreviation, ")") === value
        // );
        const selectedTherapy = therapyOptions[0]?.find(
          (therapyObj: any) => therapyObj.abbreviation === value
        );

        if (selectedTherapy) {
          updatedTherapyRxs[index] = {
            ...updatedTherapyRxs[index],
            therapy: {
              ...updatedTherapyRxs[index].therapy,
              id: selectedTherapy.id,
              abbreviation: selectedTherapy.abbreviation,
              description: selectedTherapy.description || "",
              price: selectedTherapy.price || "",
            },
            frequency: selectedTherapy.frequency,
          };

          setFormData((prevformValues: any) => ({
            ...prevformValues,
            therapyRxs: updatedTherapyRxs,
          }));
        }
      }

      if (name === "amount") {
        updatedTherapyRxs[index] = {
          ...updatedTherapyRxs[index],
          amount: value,
        };

        setFormData((prevformValues) => ({
          ...prevformValues,
          therapyRxs: updatedTherapyRxs,
        }));
      }
      if (name === "frequency") {
        updatedTherapyRxs[index] = {
          ...updatedTherapyRxs[index],
          frequency: {
            id: value,
            text: value,
          },
        };

        setFormData((prevformValues) => ({
          ...prevformValues,
          therapyRxs: updatedTherapyRxs,
        }));
      }

      if (name === "rxSeries") {
        // Update therapyRxs based on rowIndex and therapyId
        const updatedTherapyRxs = formData.therapyRxs.map((therapy: any, index: number) => {
          if (index === index && therapy.id === idValue) {
            return {
              ...therapy,
              rxSeries: { id: id, label: value },
            };
          }
          return therapy;
        });

        // Update the formData state with the updated therapyRxs
        setFormData((prevFormData) => ({
          ...prevFormData,
          therapyRxs: updatedTherapyRxs,
        }));
      }

      if (name.startsWith("icdCodeEntries")) {
        const newValue = value.toUpperCase().slice(0, 1);

        // Update the specific compartment in the ICD code entry
        setFormData((prevValues) => {
          const updatedICDCodeEntries = [...prevValues.icdCodeEntries];
          const updatedUserText = [...updatedICDCodeEntries[index].userText];
          updatedUserText[compartmentIndex as any] = newValue;
          updatedICDCodeEntries[index].userText = updatedUserText.join("");

          return { ...prevValues, icdCodeEntries: updatedICDCodeEntries };
        });

        // Automatically move focus to the next compartment
        if (newValue && (compartmentIndex as any) < 4) {
          inputRefs.current[`${index}-${(compartmentIndex as any) + 1}`]?.focus();
        }
      }

      if (name === "rxtype") {
        setFormData((prevValues) => ({
          ...prevValues,
          diagnosegroup: null, // Clear diagnosegroup when rxtype changes
        }));
        filterDiagnoseGroups(value);
      }

      // if (name === "doctorNumber") {
      //   // Autopopulate doctor and bsnr based on LANR selection
      //   const selectedDoctor: any = doctorOptions.find(
      //     (doctor: any) => doctor.number === Number(value)
      //   );
      //   // setFetchedData(selectedDoctor);
      //   console.log("UZZHHHH", selectedDoctor);
      //   if (selectedDoctor) {
      //     setFormData((prevFormValues: any) => ({
      //       ...prevFormValues,
      //       doctorId: selectedDoctor.id,
      //       practiceNumber: selectedDoctor.clinicNumber,
      //       doctorData:selectedDoctor
      //     }));
      //     setSelectedDoctor(selectedDoctor.contactInfo.lastName);
      //     setSelectedBSNR(selectedDoctor.clinicNumber);
      //   }
      // }
      if (name === "hasIndividualSymptoms") {
        // When the checkbox is selected, clear symptom codes and text
        // if (inputValue ) {
        setFormData((prevValues: any) => ({
          ...prevValues,
          a: false, // Clear symptom code
          b: false,
          c: false,
          d: false,
          symptoms: "",
          hasIndividualSymptoms: inputValue, // Clear symptoms text
        }));
        // }
      } else if (
        (name === "a" || name === "b" || name === "c" || name === "d") &&
        formData?.hasIndividualSymptoms
      ) {
        // If the checkbox is selected, and symptom code is changed, uncheck the checkbox
        setFormData((prevValues: any) => ({
          ...prevValues,
          hasIndividualSymptoms: false,
          [name]: inputValue,
        }));
      } else {
        // Update other form fields based on their names
        setFormData((prevValues: any) => ({
          ...prevValues,
          [name]: id ? id : inputValue,
        }));
      }
      if (name === "inputSymptoms") {
        const newInputDiagnose = [...inputSymptoms];
        newInputDiagnose[index] = value.slice(0, 1); // Only accept one character per input

        // Update the inputDiagnose state
        setInputSymptoms(newInputDiagnose);

        // Move focus to the next input if the current input is not empty and is not the last one
        if (index < 3 && value.length > 0) {
          inputRefs.current[index + 1].focus();
        }

        // Construct the new diagnose group
        const newDiagnoseGroup = newInputDiagnose.join("");

        // Extract the diagnose group and subgroup
        const diagnosegroup = newDiagnoseGroup.slice(0, 3);

        const subgroup = newDiagnoseGroup.length === 4 ? newDiagnoseGroup[3] : "";

        // Determine boolean flags based on the fourth character
        const isA = subgroup === "a";
        const isB = subgroup === "b";
        const isC = subgroup === "c";
        const isD = subgroup === "d";

        setFormData((prevValues: any) => ({
          ...prevValues,
          diagnosegroup: isA || isB || isC || isD ? diagnosegroup : newDiagnoseGroup, // Set the full diagnose group if no A, B, C, or D
          a: isA,
          b: isB,
          c: isC,
          d: isD,
        }));

        const valueToCheck =
          isA || isB || isC || isD ? diagnosegroup.toUpperCase() : newDiagnoseGroup.toUpperCase();

        const options = diagnoseGroups[0].filter((option: any) => option.RxType?.id === "Zahnarzt");
        // Extract valid codes from options
        const validCodes = options.map((option: any) => option.code.toUpperCase());

        // Check if newDiagnoseGroup is a valid code
        if (newDiagnoseGroup.length === 4 && !validCodes.includes(valueToCheck.toUpperCase())) {
          notificationStore.showMessage("Ungültige Diagnosegruppe", "error");
        }
      }
      if (name.startsWith("diagnosisText")) {
        // Update the specific line in the diagnosis text array
        setLines((prevLines) => {
          const newLines = [...prevLines];
          newLines[index] = value;

          // Update the formData with the joined lines
          setFormData((prevValues) => ({
            ...prevValues,
            diagnosisText: newLines.join("\n"),
          }));

          return newLines;
        });
      }
      if (name.startsWith("symptoms")) {
        // Update the specific line in the diagnosis text array

        // Update the formData with the joined lines
        setFormData((prevValues) => ({
          ...prevValues,
          symptoms: value,
        }));
      }

      // if (name === "doctorNumber") {
      //   // Autopopulate doctor and bsnr based on LANR selection
      //   const selectedDoctor: any = doctorOptions.find(
      //     (doctor: any) => doctor.number === Number(value)
      //   );
      //   setFetchedData(selectedDoctor);
      //   console.log("UZZHHHH", selectedDoctor);
      //   if (selectedDoctor) {
      //     setFormData((prevFormValues: any) => ({
      //       ...prevFormValues,
      //       doctorId: selectedDoctor.id,
      //       practiceNumber: selectedDoctor.clinicNumber,
      //     }));
      //     setSelectedDoctor(selectedDoctor.contactInfo.lastName);
      //     setSelectedBSNR(selectedDoctor.clinicNumber);
      //   }
      // }
      if (insurer[0]) {
        if (name === "Receiptpayer") {
          // Autopopulate doctor and bsnr based on LANR selection

          const selectedPayer: any = insurer[0].find(
            (option: any) => option.id === id || option.ik === value
          );
          if (selectedPayer) {
            setFormData((prevFormValues: any) => ({
              ...prevFormValues,
              chipcard: selectedPayer?.ik,
              tarifGroupId: selectedPayer?.tarifGroup?.id,
            }));
            setSelectedChipCard(selectedPayer.ik);
          }
        }

        // if (name === "chipcard") {
        //   const selectedPayer: any = insurer[0].find((option: any) => option.ik === value);
        //   if (selectedPayer) {
        //     setFormData((prevFormValues: any) => ({
        //       ...prevFormValues,
        //       Receiptpayer: selectedPayer.id,
        //       tarifGroupId: selectedPayer?.tarifGroup?.id,
        //     }));

        //     setSelectedPayer(selectedPayer.name);
        //   }
        // }
      }

      setFormData((prevFormData) => {
        const newFormData = { ...prevFormData, [name]: value };
        debounceValidation(newFormData);
        return newFormData;
      });
    };
    const handleChipCardDataEntry = (event: React.KeyboardEvent | React.FocusEvent) => {
      rxvalidate();
      const chipCardValue = (event.currentTarget as HTMLInputElement).value;
      if (
        (event as React.KeyboardEvent).key === "Enter" ||
        event.type === "blur" ||
        event.type === "mouseleave"
      ) {
        // Check if the chipcard number is valid
        const selectedPayer = insurer[0]?.find((option: any) => option.ik === chipCardValue);
        if (selectedPayer) {
          // If valid, autofill payer info
          setFormData((prevFormValues: any) => ({
            ...prevFormValues,
            Receiptpayer: selectedPayer?.id,
            tarifGroupId: selectedPayer?.tarifGroup?.id,
          }));
          setSelectedPayer(selectedPayer.name);
          notificationStore.showMessage(
            `${selectedPayer?.id} ist ein IK für ${selectedPayer?.name}`,
            "success"
          );
        } else if (chipCardValue) {
          // Show error if chipcard is invalid
          notificationStore.showMessage(
            "Bitte geben Sie eine gültige Chipkartennummer ein.",
            "warning"
          );
          // Pass all form values with updated chipcard value for validation
        }
      }
    };
    useEffect(() => {
      rxvalidate();
    }, [formData]);
    const rxvalidate = (fieldValues = formData) => {
      let temp: any = { ...rxerrors };
      // Validate chipcard (if chipcard exists in fieldValues)
      if ("chipcard" in fieldValues) {
        const chipCardValue = fieldValues.chipcard;
        if (chipCardValue && !insurer[0]?.some((option: any) => option.ik === chipCardValue)) {
          temp.chipcard = "Ungültige Chipkartennummer";
        } else {
          temp.chipcard = ""; // Clear error if valid
        }
      }
      if ("accountId" in fieldValues) temp.accountId = fieldValues.accountId ? "" : "Pflichtfeld";
      if ("tarifGroupId" in fieldValues)
        temp.tarifGroupId = fieldValues.tarifGroupId ? "" : "Pflichtfeld";

      rxsetErrors({
        ...temp,
      });

      if (fieldValues == formData) return Object.values(temp).every((x) => x == "");
    };
    const { rxerrors, rxsetErrors } = rxuseForm(formData, true, rxvalidate);
    const [validationData, setValidationData] = useState<any>({});
    const debounceValidation = useCallback(
      debounce(async (currentFormData) => {
        const data = await getLiveValidation(currentFormData);
        setValidationData(data);
      }, 100),
      []
    );
    const handleICDInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      index: number,
      compartmentIndex: number
    ) => {
      const { value } = event.target;
      const newValue = value.toUpperCase().slice(0, 1);
      setUnsavedChanges(true);

      setIcdCodeEntries((prevCompartments) => {
        // Make a copy of the previous state
        const updatedCompartments = [...prevCompartments];

        // Check if the index exists
        if (index >= updatedCompartments.length) {
          console.error(`Index ${index} is out of bounds.`);
          return updatedCompartments;
        }

        // Ensure `userText` is an array
        const compartment = updatedCompartments[index];
        if (!compartment.userText || compartment.userText.length !== 5) {
          compartment.userText = Array(5).fill("");
        }

        // Update the value
        compartment.userText[compartmentIndex] = newValue;

        // Convert to a single string
        let userText = compartment.userText.join("");

        // Insert period if necessary
        if (userText.length > 3 && !userText.includes(".")) {
          userText = `${userText.slice(0, 3)}.${userText.slice(3)}`;
        }

        // Update form data
        setFormData((prevValues) => {
          const updatedICDCodeEntries = [...prevValues.icdCodeEntries];
          updatedICDCodeEntries[index] = { ...updatedICDCodeEntries[index], userText };
          return { ...prevValues, icdCodeEntries: updatedICDCodeEntries };
        });

        return updatedCompartments;
      });

      // Move focus to the next input if necessary
      if (newValue && compartmentIndex < 4) {
        inputRefs.current[`${index}-${compartmentIndex + 1}`]?.focus();
      }
    };

    const handleGenericSubmit = async (event?: any) => {
      if (event) {
        setLoading(false);
        event.preventDefault(); // Prevent default form submission behavior
      }
      try {
        const hasEmptyCharacters = inputSymptoms.some((char) => char === "");
        const hasNonEmptyCharacters = inputSymptoms.some((char) => char !== "");

        if (hasNonEmptyCharacters && hasEmptyCharacters) {
          notificationStore.showMessage(
            "Bitte überprüfen Sie Ihre Diagnosegruppe. Alle vier Felder müssen ausgefüllt sein.",
            "error"
          );
          return;
        }
        if (rxvalidate()) {
          setLoading(true);
          const updatedRxValues: any = {
            ...formData,
            id: patientValues?.rxs.find((rx: any) => rx.id === rxFormData.id)?.id || "",
            rxNumber: patientValues?.rxs.find((rx: any) => rx.id === rxFormData.id)?.rxNumber || "",
            doctor: formData.doctorData,
            accountId:
              account[0].find((option: any) => option.name === formData.accountId)?.id ||
              formData.accountId,
            rxtype:
              rxType[0].find((option: any) => option.text === formData.rxtype)?.id ||
              formData.rxtype ||
              "Kasse",
            diagnosegroup:
              diagnoseGroups[0].find((option: any) => option.code === formData.diagnosegroup)?.id ||
              formData.diagnosegroup,
            doctorId:
              (doctorOptions as any).find(
                (option: any) => option.contactInfo.lastName === formData.doctorId
              )?.id || formData.doctorId,

            Receiptpayer:
              insurer[0].find((option: any) => option.id === formData.chipcard)?.id ||
              formData.Receiptpayer,
            // therapyRxs:
            //   formData.therapyRxs
            //     ?.filter((therapy: any) => therapy.therapy.id !== "") // Filter out entries without therapy.therapyId or therapy.id
            //     .map((therapy: any) => ({

            //       frequency: therapy.frequency || "",

            //     })) || [],

            therapyRxs: (formData.therapyRxs || []) // Ensure formData.therapyRxs is an array or provide an empty array
              .filter((therapy) => therapy.therapy.id !== "") // Filter out entries without therapy.therapyId or therapy.id
              .map((therapy: any) => {
                if (
                  !(therapy.frequency && therapy.frequency.id) ||
                  !(therapy.therapy && therapy.therapy.id) ||
                  !therapy.amount
                ) {
                  throw new Error(
                    "Bitte fügen Sie die Behandlung hinzu, um das Rezept zu speichern/bearbeiten (Therapie, Menge, Häufigkeit sind Pflichtfelder) und deaktivieren Sie auch die Zelle nach der Bearbeitung!"
                  );
                }
                const validTherapy = validateTherapy(
                  therapy.therapy.abbreviation,
                  masterTablesData
                );
                const validFrequency = validateFrequency(therapy.frequency.id, masterTablesData);
                if (!validTherapy || !validFrequency) {
                  throw new Error("Bitte geben Sie eine gültige Therapie und Häufigkeit ein.");
                }
                const insurer = masterTablesData
                  .filter((obj: any) => "insurer" in obj)
                  .map((obj: any) => obj.insurer); // Extract the insurer array

                const payer =
                  insurer[0].find((option: any) => option.name === formData.Receiptpayer)?.id ||
                  formData.Receiptpayer;
                const tarifTherapy = getTherapyId(
                  formData.tarifGroupId,
                  therapy.therapy.abbreviation
                );

                return {
                  ...therapy,
                  therapy: { id: tarifTherapy, abbreviation: therapy.therapy.abbreviation },
                };
              }),
          };
          // // Handle form submission logic here
          // const updatedFormData = {

          //   patient:{
          //     ...patientValues,
          //   firstName: formData.patient?.firstName || "",
          //   lastName: formData.patient?.lastName || "",
          //   dob: formData.patient?.dob || ""},
          //   rxs: updatedRxValues
          // };
          const updatedFormData = {
            ...patientValues,
            rxs: newRx
              ? [updatedRxValues]
              : patientValues?.rxs.map((rx: any) =>
                  rx.id === rxFormData.id ? updatedRxValues : rx
                ),
          };

          setUnsavedChanges(false);
          if (newRx) {
            updateFormData(updatedRxValues);
            setFormData(updatedRxValues);
            //This for newRx setting rx form
            // mainFormSubmit()
          } else {
            const response = await saveRxs(patientValues.id, [
              updatedRxValues,
            ] as unknown as RxDto[]); // Use updatedFormData instead of formData
            handleData(updatedFormData);
            setFormData(updatedRxValues);
            //  await searchPatient({ id: patientValues.id });
            handleCloseRxDialog();
          }
        } else {
          notificationStore.showMessage(
            "Bitte füllen Sie alle erforderlichen Felder aus",
            "warning"
          );
        }
      } catch (error: any) {
        console.error("Error saving Rx:", error);
        notificationStore.showMessage(
          error.message || "Fehler beim Speichern des Rezepts",
          "error",
          error
        );
      } finally {
        // Set loading back to false when the API call is complete
        setLoading(false);
      }
    };

    const [therapyRxs, setTherapyRxs] = useState(formData.therapyRxs); // State to manage therapyRxs array
    const componentRef = useRef(null);
    const handleDuplicateRow = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement>,
      idValue?: string
    ) => {
      const { value } = event.target;
      console.log("handleDuplicateRow called", { index, value }, event, idValue);
      setUnsavedChanges(true);

      const updatedTherapyRxs = formData.therapyRxs.map((therapy: any, index: number) => {
        console.log("szjasjsajjsajsakakkyyifdd", idValue, therapy.id, therapy.id === idValue);
        // Check if the current therapy matches the ID we're updating
        if (therapy.id === idValue) {
          // Validate the amount
          if (!therapy.amount || (therapy.amount as unknown as number) % 2 !== 0) {
            notificationStore.showMessage(
              "Bitte fügen Sie einen gültigen Betrag hinzu.",
              "warning"
            );
            // Return the therapy as is to avoid changes
            return therapy;
          }

          // Temporarily update the therapy object
          const updatedTherapy = {
            ...therapy,
            isDoubled: value as any,
            doubledTreatmentIds: (therapy.doubledTreatmentIds &&
            therapy.doubledTreatmentIds.length > 0
              ? therapy.doubledTreatmentIds
              : [uuidv4()]) as any,
            amount: therapy.amount,
          };

          // Check for duplicate therapies
          const isDuplicate = formData.therapyRxs.some((othertherapy, otherIndex) => {
            if (otherIndex !== index) {
              // Compare relevant properties
              return updatedTherapy.therapy.abbreviation === othertherapy.therapy.abbreviation;
            }
            return false;
          });

          if (isDuplicate) {
            notificationStore.showMessage(
              "Die Behandlung ist bereits beendet, so dass kein Duplikat zulässig ist.",
              "warning"
            );
            // Return the therapy as is to avoid changes
            return therapy;
          }

          // Return the updated therapy if all conditions are met
          return updatedTherapy;
        }

        // Return the therapy as is if the ID doesn't match
        return therapy;
      });
      console.log("ajjksajjaskjakjaaaaaay", updatedTherapyRxs);

      // Update the formData state with the updated therapyRxs
      setFormData((prevFormData) => ({
        ...prevFormData,
        therapyRxs: updatedTherapyRxs,
      }));

      // setFormData((prevFormData) => {
      //   const updatedTherapyRxs = [...prevFormData.therapyRxs];
      //   const therapy = updatedTherapyRxs[index];

      //   if (therapy) {
      //     // Validate the amount
      //     if (!therapy.amount || (therapy.amount as unknown as number) % 2 !== 0) {
      //       notificationStore.showMessage(
      //         "Bitte fügen Sie einen gültigen Betrag hinzu.",
      //         "warning"
      //       );
      //       return prevFormData; // Return the previous state without changes
      //     }
      //     // Check for duplicate therapies (you need to define your comparison logic)
      //     const isDuplicate = updatedTherapyRxs.some((othertherapy, otherIndex) => {
      //       if (otherIndex !== index) {
      //         // Compare relevant properties
      //         return therapy.therapy.abbreviation === othertherapy.therapy.abbreviation;
      //       }
      //       return false;
      //     });
      //     if (isDuplicate) {
      //       // Show an alert for duplicate therapies
      //       notificationStore.showMessage(
      //         "Die Behandlung ist bereit<s beendet, so dass kein Duplikat zulässig ist.",
      //         "warning"
      //       );
      //       return prevFormData;
      //     }

      //     therapy.isDoubled = value as any;
      //     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      //     (therapy.doubledTreatmentIds = (
      //       therapy.doubledTreatmentIds && therapy.doubledTreatmentIds.length > 0
      //         ? therapy.doubledTreatmentIds
      //         : [uuidv4()]
      //     ) as any),
      //       (therapy.amount = therapy.amount);
      //   }

      //   return {
      //     ...prevFormData,
      //     therapyRxs: updatedTherapyRxs,
      //   };
      // });
    };

    const [filteredDiagnoseGroups, setFilteredDiagnoseGroups] = useState([]);

    const filterDiagnoseGroups = (rxtype: any) => {
      const filtered = diagnoseGroups[0].filter((option: any) => option.RxType?.id === rxtype);
      setFilteredDiagnoseGroups(filtered);
    };

    useEffect(() => {
      filterDiagnoseGroups(formData.rxtype);
    }, [formData.rxtype]);

    console.log("formData", formData, readOnlyView);
    const FormContent = (
      <Grid
        container
        direction={"row"}
        spacing={1}
        xs={12}
        sx={{ paddingTop: "0px", paddingLeft: "2px", border: "0px dashed blue" }}
      >
        {!readOnlyView ? (
          <Grid
            item
            xs={12}
            md={3}
            sx={{ border: "0px dashed green" }}
          >
            <FlatCard title={`Rezept ${rxFormData?.rxNumber || ""}`}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Controls.Select
                    name="rxtype"
                    label="Rezept-typ"
                    value={formData?.rxtype}
                    onChange={handleInputChange}
                    options={rxType[0]?.map((option: any) => ({
                      label: option?.text.toString(),
                      value: option?.text.toString(),
                      id: option?.id.toString(),
                    }))}
                    disabled={readOnlyView}
                    style={{
                      width: "100%",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "10px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Controls.Select
                    name="accountId"
                    label="Mandant"
                    value={formData?.accountId}
                    onChange={handleInputChange}
                    options={user?.location?.accounts?.map((option: any) => ({
                      label: option?.name?.toString(),
                      value: option?.name?.toString(),
                      id: option?.id?.toString(),
                    }))}
                    disabled={readOnlyView}
                    style={{
                      width: "100%",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "10px",
                    }}
                    required
                    error={rxerrors.accountId}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Controls.Select
                    name="status"
                    label="Rezeptverbleib"
                    value={formData?.status}
                    onChange={handleInputChange}
                    options={[
                      {
                        label: "noch nicht erstellt",
                        value: "noch nicht erstellt",
                      },
                      { label: "beim Patient", value: "beim Patient" },
                      { label: "beim Therapeut", value: "beim Therapeut" },
                      { label: "vorhanden", value: "vorhanden" },
                    ]}
                    disabled={readOnlyView}
                    style={{
                      width: "100%",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "10px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Controls.Select
                    name="tarifGroupId"
                    label="Tarif Group"
                    id={1}
                    value={formData?.tarifGroupId}
                    onChange={handleInputChange}
                    options={
                      tarifGroupsMasterData
                        ? tarifGroupsMasterData.map((value: any) => ({
                            label: value.toString(),
                            value: value.toString(),
                          }))
                        : []
                    }
                    disabled={readOnlyView}
                    style={{
                      width: "100%",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "10px",
                    }}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Controls.Checkbox
                    name="isPerpetual"
                    label="Dauerrezept"
                    value={formData?.isPerpetual}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </FlatCard>

            <FlatCard title="Arzt">
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Controls.Select
                    name="doctor"
                    label="Arzt aus der Liste wählen"
                    id={1}
                    value={selectedDoctor || formData.doctorId}
                    onChange={handleDoctorChange}
                    options={doctorOptions?.map((doctor: any) => ({
                      label: doctor.contactInfo.lastName.toString(),
                      value: doctor.contactInfo.lastName.toString(),
                      id: doctor.id.toString(),
                    }))}
                    disabled={readOnlyView}
                    style={{
                      width: "100%",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "10px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Button
                    onClick={() => handleOpenAddEditDialog(selectedLANR, selectedBSNR)}
                    disabled={readOnlyView}
                  >
                    Arzt hinzufügen / bearbeiten
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <ValidationTooltip
                    validity={validationData?.signatureValidation?.validity}
                    message={validationData?.signatureValidation?.validity}
                    placement="left"
                    sx={{ zIndex: 1300 }}
                  >
                    <Controls.RadioGroup
                      label={
                        <Typography sx={{ textAlign: "left" }}>
                          Arztunterschrift vorhanden
                        </Typography>
                      }
                      name="signatureStatus"
                      value={formData?.signatureStatus}
                      options={[
                        { id: "nicht kontrolliert", label: "nicht kontrolliert" },
                        { id: "vorhanden", label: "vorhanden" },
                        { id: "fehlend", label: "fehlend" },
                      ]}
                      disabled={readOnlyView}
                      onChange={handleInputChange}
                      sx={{
                        width: "100%",
                        paddingLeft: "1rem",
                        textAlign: "left",
                        fontSize: "0.7rem",
                      }}
                    />
                  </ValidationTooltip>
                </Grid>
              </Grid>

              {isAddEditDialogOpen && (
                <DoctorDialog
                  openAddEditDialog={isAddEditDialogOpen}
                  handleCloseAddEditDialog={handleCloseAddEditDialog}
                  doctorOptions={doctorOptions}
                  selectedLANR={formData.doctorNumber}
                  selectedBSNR={formData.practiceNumber}
                />
              )}
            </FlatCard>
            <FlatCard
              title=" Heilmittel nach Maßgabe des Kataloges"
              sx={{ width: "500px", marginTop: "70px" }}
            >
              <Table
                sx={{
                  borderCollapse: "collapse",
                  width: "100%",
                  border: "1px solid #ccc",
                  background: "white",
                  paddingTop: "10px",
                }}
              >
                <TableBody sx={{ maxHeight: "50px", overflowY: "scroll" }}>
                  <TableCell>Actions</TableCell>
                  <TableCell>Heilmittel</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Menge</TableCell>
                  <TableCell>Series</TableCell>
                  {formData?.therapyRxs.map((therapy, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      // style={{ border: "1px solid red", height: "1px" }}
                    >
                            <TableCell sx={{ padding: "0px", width: "50px", height: "1px" }}>
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteTherapy(therapy.id)}
                            disabled={readOnlyView} // Prevent deletion in read-only mode
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          width: "50px",
                          height: "1px",
                        }}
                      >
                        <Controls.Select
                          disabled={readOnlyView}
                          name="therapy"
                          label=""
                          value={therapy.therapy.abbreviation}
                          onChange={(event: any) => handleInputChange(event, rowIndex)}
                          // options={therapyOptions[0]
                          //   ?.filter((therapyObj: any) => therapyObj.duration)
                          //   ?.map((therapy: any) => ({
                          //     label: therapy?.abbreviation?.toString(),
                          //     value: therapy?.abbreviation?.toString(),
                          //   }))}
                          options={therapyOptions[0]
                            ?.filter((therapyObj: any) => therapyObj.duration)
                            ?.reduce((acc: any[], therapy: any) => {
                              const abbreviation = therapy?.abbreviation?.toString();
                              if (
                                abbreviation &&
                                !acc.some((option) => option.value === abbreviation)
                              ) {
                                acc.push({
                                  label: abbreviation,
                                  value: abbreviation,
                                });
                              }
                              return acc;
                            }, [])}
                          style={{
                            width: "100px",
                            marginTop: "0px",
                            marginBottom: "0px",
                            fontSize: "10px",
                          }}
                        />
                      </TableCell>
                      {/* <TableCell
                            sx={{
                              padding: "0px",
                              direction: "column",
                              height: "1px",
                            }}
                          >
                            <Typography sx={{ fontSize: "10px" }}>
                              {therapy.therapy.description}
                            </Typography>
                          </TableCell> */}
                      <TableCell
                        sx={{
                          padding: "0px",
                          width: "10px",
                          height: "1px",
                        }}
                      >
                        <Controls.Checkbox
                          name="doubled"
                          value={therapy.isDoubled}
                          label={
                            <Typography sx={{ fontSize: "10px" }}>Doppelbehandlung</Typography>
                          }
                          disabled={!therapy.therapy.abbreviation || readOnlyView}
                          onChange={(event: any) => {
                            handleDuplicateRow(rowIndex, event, therapy.id);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          width: "50px",
                          height: "1px",
                          borderLeft: "1px solid #ccc",
                        }}
                      >
                        <Controls.Input
                          name="amount"
                          disabled={readOnlyView}
                          label=""
                          value={therapy.amount}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleInputChange(event, rowIndex, therapy.id);
                          }} // Pass rowIndex to handleInputChange
                          style={{ width: "100%", margin: "0px" }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0px",
                          width: "80px",
                          height: "1px",
                          borderLeft: "1px solid #ccc",
                        }}
                      >
                        <Controls.Select
                          name="rxSeries"
                          // label="Rezeptverbleib"
                          id={1}
                          style={{ width: "100%", margin: "0px" }}
                          value={therapy.rxSeries?.label}
                          onChange={(event: any) => handleInputChange(event, rowIndex, therapy.id)}
                          options={savedSeriesForPatient.map((option: any) => ({
                            label: option.label,
                            value: option.label,
                            id: option.id,
                          }))}
                          onBlur={true}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </FlatCard>

            {/* <Controls.Input
              name="practiceNumber"
              label="BSNR"
              value={selectedBSNR || formData?.practiceNumber}
              onChange={handleInputChange}
            /> */}
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            md={4}
            sx={{ border: "0px dashed green" }}
          >
            <FlatCard
              title="Allgemein"
              sx={{ marginBottom: "2px" }}
            >
              <Grid
                container
                spacing={0}
                justifyContent="space-between"
              >
                {/* First Row */}
                <Grid
                  item
                  xs={5}
                >
                  <Typography
                    textAlign="left"
                    sx={{ fontSize: "0.8em" }}
                  >
                    Rezept-Typ:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                >
                  <Controls.Chip
                    label={formData.rxtype || "-"}
                    sx={{ width: "100%", fontSize: "0.7rem" }}
                  />
                </Grid>

                {/* Second Row */}
                <Grid
                  item
                  xs={5}
                >
                  <Typography
                    textAlign="left"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Account:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                >
                  <Controls.Chip
                    label={formData.accountId || "-"}
                    sx={{ width: "100%", fontSize: "0.7rem", padding: "0px" }}
                  />
                </Grid>

                {/* Third Row */}
                <Grid
                  item
                  xs={5}
                >
                  <Typography
                    textAlign="left"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Rx Location:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                >
                  <Controls.Chip
                    label={
                      formData.isHomeVisit === true
                        ? "Haus/Heim"
                        : formData.isHomeVisit === false
                        ? "Praxis"
                        : "-"
                    }
                    sx={{ width: "100%", fontSize: "0.7rem" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Controls.Select
                    name="processingStatus"
                    label="Status"
                    onChange={updateRxProcessingStatus}
                    value={formData?.processingStatus || RxDtoProcessingStatusEnum.InBehandlung}
                    options={Object.values(RxDtoProcessingStatusEnum).map((value) => ({
                      value: value,
                      label: value,
                    }))}
                    noTriggerChange={true}
                  />
                </Grid>
              </Grid>
            </FlatCard>
            <FlatCard
              title="Notizen"
              sx={{
                paddingTop: "5px",
                marginBottom: "8px",
                textAlign: "left",
              }}
            >
              <Notes
                notes={rxNotes}
                setNotes={updateNote}
                maxVisibleNotes={3}
              />
            </FlatCard>

            <FlatCard
              title="Arzt"
              sx={{ paddingTop: "5px", marginBottom: "8px", textAlign: "left" }}
            >
              <Typography
                variant="body2"
                sx={{ fontSize: "0.8rem" }}
              >
                {`${formData?.doctorData?.contactInfo?.salutation || "Dr."} ${
                  formData?.doctorData?.contactInfo?.firstNames || ""
                } ${formData?.doctorData?.contactInfo?.lastName || ""}`}
                <br />
                {`Address:${formData?.doctorData?.contactInfo?.addressLine1 || ""}`}

                {`${formData?.doctorData?.contactInfo?.addressLine2 || ""}`}
                <br />
                {`Tel: ${formData?.doctorData?.doctor?.contactInfo?.phone1 || ""}`}
                <br />
                {`LANR: ${formData?.doctorData?.doctor?.number || ""}`}
                <br />
                {`BSNR: ${formData?.doctorData?.doctor?.clinicNumber || ""}`}
              </Typography>
            </FlatCard>
            {rxFormData && (
              <FlatCard
                title="Validierung"
                sx={{ marginBottom: "8px" }}
              >
                <ValidationCard
                  patientData={patientValues}
                  selectedRx={formData}
                  form={formType.SHORT}
                />
              </FlatCard>
            )}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", border: "0px solid red", borderColor: "transparent" }}
        >
          {" "}
          {/* transparent border color is a hack because i couldnt figure out any way to add top padding*/}
          <ScalableComponentSimple occupiedHeight={readOnlyView ? 0.8 : 1}>
            <Container
              sx={{ border: "0px solid yellow" }}
              className={classes.container}
              ref={componentRef}
            >
              {/* Small box */}
              <form
                onSubmit={handleGenericSubmit}
                className={classes.form}
              >
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    sx={{ border: "0px solid green" }}
                  >
                    <Grid
                      container
                      spacing={1}
                      sx={{ flexGrow: 1 }}
                    >
                      {/* Checkboxes */}
                      {/* Checkbox on the left */}
                      <Grid
                        item
                        xs={1}
                      >
                        <Grid
                          item
                          xs={3}
                        >
                          <div style={{ position: "relative" }}>
                            <Controls.Checkbox
                              style={{
                                position: "absolute",
                                zIndex: 2,
                                left: -10,
                                top: -20,
                                "&:not(.Mui-checked)": {
                                  // Define the styles for the unchecked state here
                                  color: "rgba(128, 128, 128, 0.2)",
                                },
                                "& .MuiSvgIcon-root": { fontSize: 50 }, // Adjust the size of the checkbox icon
                              }}
                              disabled={readOnlyView}
                              name="isCopaymentExempted"
                              value={formData?.isCopaymentExempted}
                              onChange={handleInputChange}
                            />
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 3,
                                top: 10,
                                left: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                                pointerEvents: "none", // Make the label not clickable
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "9px",
                                  color: attentionColors.immediateAttention,
                                  fontWeight: "bold",
                                }}
                              >
                                Zuzah- lungs- frei
                              </span>
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                        >
                          <div style={{ position: "relative" }}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              style={{
                                position: "absolute",
                                zIndex: 2,
                                left: -10,
                                top: 10,
                                "&:not(.Mui-checked)": {
                                  // Define the styles for the unchecked state here
                                  color: "rgba(128, 128, 128, 0.2)",
                                },

                                "& .MuiSvgIcon-root": { fontSize: 50 }, // Adjust the size of the checkbox icon
                              }}
                              name="isCopaymentExempted"
                              value={!formData?.isCopaymentExempted}
                              onChange={handleInputChange}
                            />
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 3,
                                top: 40,
                                left: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                                pointerEvents: "none", // Make the label not clickable
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "9px",
                                  color: attentionColors.immediateAttention,
                                  fontWeight: "bold",
                                }}
                              >
                                Zuzah- lungs- pflicht
                              </span>
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={3}
                        >
                          <div style={{ position: "relative" }}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              style={{
                                position: "absolute",
                                zIndex: 2,
                                left: -10,
                                top: 40,
                                "&:not(.Mui-checked)": {
                                  // Define the styles for the unchecked state here
                                  color: "rgba(128, 128, 128, 0.2)",
                                },
                                "& .MuiSvgIcon-root": { fontSize: 50 }, // Adjust the size of the checkbox icon
                              }}
                              name="isAccident"
                              value={formData?.isAccident}
                              onChange={handleInputChange}
                            />
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 3,
                                top: 70,
                                left: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                                pointerEvents: "none", // Make the label not clickable
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "9px",
                                  color: attentionColors.immediateAttention,
                                  fontWeight: "bold",
                                }}
                              >
                                Unfall- folgen
                              </span>
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                        >
                          <div style={{ position: "relative" }}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              style={{
                                position: "absolute",
                                zIndex: 2,
                                left: -10,
                                top: 70,
                                "&:not(.Mui-checked)": {
                                  // Define the styles for the unchecked state here
                                  color: "rgba(128, 128, 128, 0.2)",
                                },
                                "& .MuiSvgIcon-root": { fontSize: 50 }, // Adjust the size of the checkbox icon
                              }}
                              name="isBVG"
                              value={formData?.isBVG}
                              onChange={handleInputChange}
                            />
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 3,
                                top: 100,
                                left: 20,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                                pointerEvents: "none", // Make the label not clickable
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "9px",
                                  color: attentionColors.immediateAttention,
                                  fontWeight: "bold",
                                }}
                              >
                                BVG
                              </span>
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      {/* Form content */}
                      <Grid
                        item
                        xs={6}
                      >
                        <div className={classes.box}>
                          {/* <Grid container spacing={2}> */}

                          <Grid
                            item
                            xs={12}
                          >
                            <Typography className={classes.typography}>
                              Krankenkasse bzw. Kostenträger
                            </Typography>
                            <Controls.Input
                              disabled
                              name="Receiptpayer"
                              label=""
                              value={selectedPayer || formData.Receiptpayer}
                              onChange={handleInputChange}
                              style={{
                                width: "100%",
                              }}
                            />
                            {/* <Controls.Select
                              disabled={readOnlyView}
                              name="Receiptpayer"
                              label=""
                              id={1}
                              value={selectedPayer || formData.Receiptpayer}
                              onChange={handleInputChange}
                              options={insurer[0]?.map((option: any) => ({
                                label: option.ik.toString(),
                                value: option.name.toString(),
                                id: option.id.toString(),
                              }))}
                              style={{
                                width: "100%",
                                marginTop: "0px",
                                marginBottom: "0px",
                                fontSize: "10px",
                              }}
                            /> */}

                            <Divider
                              sx={{
                                flexGrow: 1,
                                background: attentionColors.immediateAttention,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <Typography className={classes.typography}>
                              Name, Vorname des Versicherten
                            </Typography>
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                              >
                                <Controls.Input
                                  disabled={readOnlyView}
                                  name=""
                                  label=""
                                  value={formData?.lastName}
                                  onChange={handleInputChange}
                                  style={{ width: "100%", margin: "0px" }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                              >
                                <Controls.Input
                                  disabled={readOnlyView}
                                  name=""
                                  label=""
                                  value={formData?.firstName}
                                  onChange={handleInputChange}
                                  style={{ width: "100%", margin: "0px" }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={5}
                              >
                                <Typography className={classes.typography}>geb . am</Typography>
                                <Controls.DatePicker
                                  name=""
                                  label=""
                                  value={formData.dob}
                                  onChange={handleInputChange}
                                  disabled
                                  variant="standard"
                                  style={{
                                    width: "100%",
                                    padding: "0px",
                                    margin: "0px",
                                    fontSize: "10px",
                                    "& .MuiInputBase-input": {
                                      padding: 0,
                                      margin: 0,
                                      paddingRight: "0px",
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Divider
                              sx={{
                                flexGrow: 1,
                                background: attentionColors.immediateAttention,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                              >
                                <Typography className={classes.typography}>
                                  Kostenträgerkennung
                                </Typography>
                                <div style={{ height: "30px" }}>
                                  <Controls.Input
                                    disabled={readOnlyView}
                                    name="chipcard"
                                    label=""
                                    value={selectedChipCard || formData?.chipcard}
                                    onChange={handleInputChange}
                                    onKeyDown={handleChipCardDataEntry}
                                    onBlur={handleChipCardDataEntry}
                                    style={{
                                      width: "100%",
                                    }}
                                    error={rxerrors.chipcard}
                                  />
                                  {/* <Controls.Select
                                    disabled={readOnlyView}
                                    name="chipcard"
                                    label=""
                                    id={1}
                                    value={selectedChipCard || formData?.chipcard}
                                    onChange={handleInputChange}
                                    options={insurer[0]?.map((insurer: any) => ({
                                      label: insurer.name.toString(),
                                      value: insurer.ik.toString(),
                                    }))}
                                    style={{
                                      width: "100%",
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      fontSize: "10px",
                                    }}
                                  /> */}
                                </div>
                              </Grid>

                              <Grid
                                item
                                xs={4}
                              >
                                <Typography className={classes.typography}>
                                  Versicherten-Nr.
                                </Typography>
                                <Controls.Input
                                  disabled={readOnlyView}
                                  name="insureeNumber"
                                  label=""
                                  value={formData?.insureeNumber}
                                  onChange={handleInputChange}
                                  style={{ width: "100%", margin: "0px" }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={4}
                              >
                                <Typography className={classes.typography}>Status</Typography>
                                <Controls.Select
                                  disabled={readOnlyView}
                                  name="insuranceStatus"
                                  label=""
                                  value={formData?.insuranceStatus}
                                  onChange={handleInputChange}
                                  options={[
                                    { label: "10000", value: "10000" },
                                    { label: "30000", value: "30000" },
                                    { label: "50000", value: "50000" },
                                  ]}
                                  style={{
                                    width: "100%",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    fontSize: "10px",
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Divider
                              sx={{
                                flexGrow: 1,
                                background: attentionColors.immediateAttention,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={4}
                              >
                                <Typography className={classes.typography}>
                                  Betriebsstätten-Nr.
                                </Typography>
                                <Controls.Input
                                  disabled={readOnlyView}
                                  name="practiceNumber"
                                  label=""
                                  value={selectedBSNR || formData?.practiceNumber}
                                  onChange={handleInputChange}
                                  style={{ width: "100%", margin: "0px" }}
                                />
                              </Grid>

                              <Grid
                                item
                                xs={4}
                              >
                                <Typography className={classes.typography}>Arzt-Nr.</Typography>
                                <Controls.Input
                                  disabled={readOnlyView}
                                  name="doctorNumber"
                                  label=""
                                  value={selectedLANR || formData?.doctorNumber}
                                  onChange={handleInputChange}
                                  style={{ width: "100%", margin: "0px" }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={4}
                              >
                                <Typography className={classes.typography}>Datum</Typography>
                                <ValidationTooltip
                                  validity={validationData?.initialDateVAlidation?.validity}
                                  message={validationData?.initialDateVAlidation?.validity}
                                  placement="right"
                                  sx={{ zIndex: 1300 }}
                                >
                                  <Controls.DatePicker
                                    disabled={readOnlyView}
                                    name="date"
                                    label=""
                                    value={formData?.date}
                                    onChange={handleInputChange}
                                    variant="standard"
                                    style={{
                                      width: "100%",
                                      padding: "0px",
                                      margin: "0px",
                                      fontSize: "10px",
                                      "& .MuiInputBase-input": {
                                        padding: 0,
                                        margin: 0,
                                        paddingRight: "0px",
                                      },
                                    }}
                                    formatDate="dd.MM.yy"
                                  />
                                </ValidationTooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      {/* Checkboxes on the right */}
                      <Grid
                        item
                        xs={5}
                        sx={{ paddingLeft: "95px !important" }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            whiteSpace: "normal",
                            fontWeight: "bold",
                            color: attentionColors.immediateAttention,
                          }}
                        >
                          Zahnärztliche Heilmittelverordnung
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={1}
                    >
                      <Grid
                        item
                        xs={6}
                        sx={{ paddingTop: "0px !important" }}
                      >
                        <Typography className={classes.typography}>
                          {" "}
                          Verordnung nach Maßgabe des Kataloges
                        </Typography>
                        <div className={classes.subbox}>
                          {/* <Grid container spacing={2}> */}

                          <Controls.Checkbox
                            disabled={readOnlyView}
                            name="urgent"
                            label={
                              <Typography className={classes.typography}>
                                Dringlicher Behandlungsbedarf innerhalb von 14 Tagen
                              </Typography>
                            }
                            value={formData?.urgent}
                            onChange={handleInputChange}
                          />

                          <Grid
                            container
                            spacing={1}
                            alignItems="center"
                          >
                            {/* Row for "Hausbesuch" and "Therapiebericht" */}
                            <Grid
                              item
                              xs={6}
                              md={5}
                            >
                              <Typography
                                variant="body1"
                                className={classes.typography}
                              >
                                Hausbesuch
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              md={5}
                            >
                              <Typography
                                variant="body1"
                                className={classes.typography}
                              >
                                Therapiebericht
                              </Typography>
                            </Grid>

                            {/* Row for Checkboxes */}
                            {/* <Grid item xs={6} md={6}> */}
                            <FormControlLabel
                              label=""
                              control={
                                <>
                                  <Checkbox
                                    checked={formData.isHomeVisit === true}
                                    onChange={handleCheckboxChange}
                                    disabled={readOnlyView}
                                    name="visitTypeCheckboxJa"
                                    value="yes"
                                  />
                                  <Typography variant="body1">ja</Typography>
                                  <Checkbox
                                    checked={formData.isHomeVisit === false}
                                    onChange={handleCheckboxChange}
                                    disabled={readOnlyView}
                                    name="visitTypeCheckboxNein"
                                    value="no"
                                  />
                                  <Typography variant="body1">nein</Typography>
                                </>
                              }
                            />
                            {/* </Grid> */}

                            {/* <Grid item xs={6} md={6}> */}
                            <FormControlLabel
                              label=""
                              control={
                                <>
                                  <Checkbox
                                    checked={formData.therapyReport === true}
                                    onChange={handleInputChange}
                                    disabled={readOnlyView}
                                    name="therapyReport"
                                    value="yes"
                                  />
                                  <Typography className={classes.typography}>Ja</Typography>
                                </>
                              }
                            />
                            {/* </Grid> */}
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      spacing={1}
                    >
                      {/* First Column: ICD Code and Diagnose Group */}
                      <Grid
                        item
                        xs={3}
                      >
                        <Typography className={classes.typography}>Indikationsschlüssel</Typography>
                        <div className="boxContainer">
                          {inputSymptoms?.map((value, index) => (
                            <input
                              key={index}
                              type="text"
                              value={value}
                              onChange={(event) => handleInputChange(event, index)}
                              className={classes.compartment}
                              maxLength={1}
                              name="inputSymptoms"
                              disabled={readOnlyView}
                              ref={(el) => (inputRefs.current[index] = el)}
                              onKeyDown={(e) => {
                                if (e.key === "ArrowRight") {
                                  // Move focus to the next input on ArrowRight
                                  if (index < inputSymptoms.length - 1) {
                                    const nextInput = inputRefs.current[index + 1];
                                    if (nextInput) {
                                      nextInput.focus();
                                    }
                                  }
                                } else if (e.key === "ArrowLeft") {
                                  // Move focus to the previous input on ArrowLeft
                                  if (index > 0) {
                                    const prevInput = inputRefs.current[index - 1];
                                    if (prevInput) {
                                      prevInput.focus();
                                    }
                                  }
                                } else if (e.key === "Backspace") {
                                  if (!e.currentTarget.value) {
                                    // If the current input is empty and backspace is pressed
                                    if (index > 0) {
                                      const prevInput = inputRefs.current[index - 1];
                                      if (prevInput) {
                                        prevInput.focus();
                                        // Optionally clear the previous input's value
                                        handleInputChange(
                                          {
                                            target: {
                                              name: "inputSymptoms",
                                              value: "",
                                            },
                                          } as React.ChangeEvent<HTMLInputElement>,
                                          index - 1
                                        );
                                      }
                                    }
                                    e.preventDefault(); // Prevent the default backspace behavior
                                  }
                                }
                              }}
                            />
                          ))}
                        </div>

                        <Box sx={{ paddingTop: "25px" }}>
                          <Typography className={classes.typography}>ICD-10 Code</Typography>
                          {icdCodeEntries.map((icdEntry, index) => (
                            <div
                              key={`icd-${index}`}
                              style={{ display: "flex", alignItems: "center" }}
                              className="boxContainer"
                            >
                              {Array.from({ length: 5 }).map((_, compartmentIndex) => (
                                <React.Fragment key={compartmentIndex}>
                                  <input
                                    name={`icdCodeEntries-${index}-${compartmentIndex}`}
                                    type="text"
                                    className={classes.compartment}
                                    value={
                                      compartmentIndex === 3 &&
                                      icdEntry.userText[compartmentIndex] === "."
                                        ? ""
                                        : icdEntry.userText[compartmentIndex] || ""
                                    }
                                    onChange={(event) =>
                                      handleICDInputChange(event, index, compartmentIndex)
                                    }
                                    disabled={readOnlyView}
                                    maxLength={1}
                                    style={{ marginRight: compartmentIndex === 2 ? "0px" : "2px" }}
                                    ref={(input) =>
                                      (inputRefs.current[`${index}-${compartmentIndex}`] = input)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleICDCodeEntrySubmit(icdEntry, index);
                                      } else if (e.key === "Backspace") {
                                        if (!e.currentTarget.value) {
                                          // If the current input is empty and backspace is pressed
                                          if (compartmentIndex > 0) {
                                            const prevCompartmentIndex = compartmentIndex - 1;
                                            const prevInput =
                                              inputRefs.current[`${index}-${prevCompartmentIndex}`];

                                            // Clear the current cell data
                                            handleICDInputChange(
                                              {
                                                target: {
                                                  name: `icdCodeEntries-${index}-${compartmentIndex}`,
                                                  value: "",
                                                },
                                              } as React.ChangeEvent<HTMLInputElement>,
                                              index,
                                              compartmentIndex
                                            );

                                            // Focus on the previous input
                                            if (prevInput) {
                                              prevInput.focus();

                                              // Clear the previous input's value if necessary
                                              handleICDInputChange(
                                                {
                                                  target: {
                                                    name: `icdCodeEntries-${index}-${prevCompartmentIndex}`,
                                                    value: "",
                                                  },
                                                } as React.ChangeEvent<HTMLInputElement>,
                                                index,
                                                prevCompartmentIndex
                                              );
                                            }
                                          }
                                          e.preventDefault(); // Prevent the default backspace behavior
                                        }
                                      } else if (e.key === "ArrowRight") {
                                        // Move focus to the next cell on ArrowRight
                                        if (compartmentIndex < 4) {
                                          const nextCompartmentIndex = compartmentIndex + 1;
                                          const nextInput =
                                            inputRefs.current[`${index}-${nextCompartmentIndex}`];
                                          if (nextInput) {
                                            nextInput.focus();
                                          }
                                        }
                                      } else if (e.key === "ArrowLeft") {
                                        // Move focus to the previous cell on ArrowLeft
                                        if (compartmentIndex > 0) {
                                          const prevCompartmentIndex = compartmentIndex - 1;
                                          const prevInput =
                                            inputRefs.current[`${index}-${prevCompartmentIndex}`];
                                          if (prevInput) {
                                            prevInput.focus();
                                          }
                                        }
                                      }
                                    }}
                                  />
                                  {compartmentIndex === 2 && (
                                    <span style={{ fontSize: "25px", marginRight: "2px" }}>.</span>
                                  )}
                                </React.Fragment>
                              ))}
                            </div>
                          ))}
                        </Box>
                      </Grid>

                      {/* Second Column: Symptoms and Diagnosis Text */}
                      <Grid
                        item
                        xs={9}
                      >
                        <Grid
                          container
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: attentionColors.immediateAttention,
                              }}
                            >
                              Diagnose mit Leitsymptomatik, ggf. wesentliche Befunde, ggf.
                              Spezifizierung
                            </Typography>

                            <Tooltip
                              title={formData.symptoms || ""}
                              arrow
                            >
                              <div
                                style={{
                                  display: "inline-block",
                                  position: "relative",
                                  height: "70px",
                                  border: "1px solid #c62828",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "100%", // Ensure it uses full width of container
                                }}
                              >
                                <Controls.TextArea
                                  name="symptoms"
                                  disabled={readOnlyView}
                                  rows={4}
                                  placeholder=""
                                  value={formData.symptoms || ""}
                                  onChange={handleInputChange}
                                  // style={{
                                  //   flex: 1,
                                  //   height: "15px",
                                  //   border: "1px solid #c62828",
                                  //   overflow: "hidden",
                                  //   textOverflow: "ellipsis",
                                  //   width: "100%", // Ensure it uses full width of container
                                  // }}
                                />
                              </div>
                            </Tooltip>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                border: "1px solid lightgray",
                                backgroundColor: "white",
                              }}
                            >
                              {lines.map((line: any, index: any) => (
                                <Tooltip
                                  title={line || ""}
                                  arrow
                                >
                                  <Controls.TextArea
                                    key={index}
                                    name={`diagnosisText-${index}`}
                                    disabled={readOnlyView}
                                    rows={2}
                                    placeholder=""
                                    value={line}
                                    onChange={(e: any) => handleInputChange(e, index)}
                                    style={{
                                      marginBottom: "2px",
                                      flex: 1,
                                      height: "15px",
                                      border: "1px solid #c62828",
                                    }}
                                    multiline
                                  />
                                </Tooltip>
                              ))}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={1}
                      sx={{ paddingTop: "1px", borderBottom: "1px solid black" }}
                    >
                      <Typography className={classes.typography}>
                        Heilmittel nach Maßgabe des Kataloges
                      </Typography>

                      <Table
                        sx={{
                          borderCollapse: "collapse",
                          width: "100%",
                          border: "1px solid red",
                          background: "white",
                          fontSize: "10px", // Adjust font size for compactness
                        }}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell
                              sx={{
                                borderRight: "1px solid black",
                                fontSize: "10px",
                                whiteSpace: "nowrap",
                              }}
                              className={classes.typography}
                            >
                              Heilmittel
                            </TableCell>
                            <TableCell
                              sx={{
                                borderRight: "1px solid black",
                                fontSize: "10px",
                                whiteSpace: "nowrap",
                              }}
                              className={classes.typography}
                            >
                              Menge
                            </TableCell>
                            <TableCell
                              sx={{
                                borderLeft: "1px solid black",
                                fontSize: "10px",
                                whiteSpace: "nowrap",
                              }}
                              className={classes.typography}
                            >
                              Behandlungstage pro Woche
                            </TableCell>
                          </TableRow>
                          {formData?.therapyRxs.map((therapy: any, rowIndex: any) => (
                            <TableRow key={rowIndex}>
                              <TableCell
                                sx={{
                                  padding: "2px",
                                  whiteSpace: "nowrap",
                                  borderRight: "1px solid black",
                                }}
                              >
                                <Controls.Select
                                  name="therapy"
                                  label=""
                                  value={therapy.therapy.abbreviation}
                                  onChange={(event: any) => handleInputChange(event, rowIndex)}
                                  options={therapyOptions[0]
                                    ?.filter(
                                      (therapyObj: any) =>
                                        therapyObj.duration && therapyObj.lineItem
                                    )
                                    ?.map((option: any) => ({
                                      // label: option.lineItem.concat("(", option.abbreviation, ")")?.toString(),
                                      // value: option.lineItem.concat("(", option.abbreviation, ")")?.toString(),
                                      label: option.lineItem?.toString(),
                                      value: option.abbreviation?.toString(),
                                    }))}
                                  style={{
                                    fontSize: "10px",
                                    width: "100px", // Minimum width to ensure usability
                                    padding: "0px !important",
                                  }}
                                  disabled={readOnlyView}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "2px",
                                  whiteSpace: "nowrap",
                                  borderRight: "1px solid black",
                                }}
                              >
                                <Controls.Input
                                  name="amount"
                                  label=""
                                  value={therapy.amount}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleInputChange(event, rowIndex, therapy.id)
                                  }
                                  disabled={readOnlyView}
                                  sx={{
                                    fontSize: "10px",
                                    minWidth: "60px",
                                    padding: "0px !important",
                                  }} // Minimum width to ensure usability
                                />
                              </TableCell>
                              <TableCell sx={{ padding: "2px", whiteSpace: "nowrap" }}>
                                <Controls.Input
                                  name="frequency"
                                  label=""
                                  value={therapy.frequency?.id}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleInputChange(event, rowIndex, therapy.id)
                                  }
                                  disabled={readOnlyView}
                                  sx={{ fontSize: "10px", minWidth: "60px" }} // Minimum width to ensure usability
                                />
                              </TableCell>
                              <TableCell sx={{ padding: "2px", whiteSpace: "nowrap" }}>
                                <Controls.Checkbox
                                  name="doubled"
                                  value={therapy.isDoubled}
                                  label={
                                    <Typography sx={{ fontSize: "8px" }}>
                                      Doppelbehandlung
                                    </Typography>
                                  }
                                  disabled={!therapy.therapy.abbreviation || readOnlyView}
                                  onChange={(event: any) =>
                                    handleDuplicateRow(rowIndex, event, therapy.id)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>

                    {/* <Grid item xs={12}>
                      <Typography className={classes.typography}>
                        Heilmittel nach Maßgabe des Kataloges
                      </Typography>
                      <Grid item xs={12} sx={{ maxHeight: "140px", overflowY: "auto" }}>
                        <Table
                          sx={{
                            borderCollapse: "collapse",
                            width: "100%",
                            border: "1px solid red",
                            background: "white",
                          }}
                        >
                          <TableBody sx={{ maxHeight: "50px", overflowY: "scroll" }}>
                            {formData?.therapyRxs.map((therapy, rowIndex) => (
                              <TableRow
                                key={rowIndex}
                                // style={{ border: "1px solid red", height: "1px" }}
                              >
                                <TableCell
                                  sx={{
                                    padding: "0px",
                                    width: "50px",
                                    height: "1px",
                                  }}
                                >
                                  <Controls.Select
                                    disabled={readOnlyView}
                                    name="therapy"
                                    label=""
                                    value={therapy.therapy.abbreviation}
                                    onChange={(event: any) => handleInputChange(event, rowIndex)}
                                    // options={therapyOptions[0]
                                    //   ?.filter((therapyObj: any) => therapyObj.duration)
                                    //   ?.map((therapy: any) => ({
                                    //     label: therapy?.abbreviation?.toString(),
                                    //     value: therapy?.abbreviation?.toString(),
                                    //   }))}
                                    options={therapyOptions[0]
                                      ?.filter((therapyObj: any) => therapyObj.duration)
                                      ?.reduce((acc: any[], therapy: any) => {
                                        const abbreviation = therapy?.abbreviation?.toString();
                                        if (
                                          abbreviation &&
                                          !acc.some((option) => option.value === abbreviation)
                                        ) {
                                          acc.push({
                                            label: abbreviation,
                                            value: abbreviation,
                                          });
                                        }
                                        return acc;
                                      }, [])}
                                    style={{
                                      width: "110px",
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      fontSize: "10px",
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0px",
                                    direction: "column",
                                    height: "1px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "10px" }}>
                                    {therapy.therapy.description}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0px",
                                    width: "30px",
                                    height: "1px",
                                  }}
                                >
                                  <Controls.Checkbox
                                    name="doubled"
                                    value={therapy.isDoubled}
                                    label={
                                      <Typography sx={{ fontSize: "10px" }}>
                                        Doppelbehandlung
                                      </Typography>
                                    }
                                    disabled={!therapy.therapy.abbreviation || readOnlyView}
                                    onChange={(event: any) => {
                                      handleDuplicateRow(rowIndex, event, therapy.id);
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0px",
                                    width: "30px",
                                    height: "1px",
                                    borderLeft: "1px solid red",
                                  }}
                                >
                                  <Controls.Input
                                    name="amount"
                                    disabled={readOnlyView}
                                    label=""
                                    value={therapy.amount}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      handleInputChange(event, rowIndex, therapy.id);
                                    }} // Pass rowIndex to handleInputChange
                                    style={{ width: "100%", margin: "0px" }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid> */}

                    <Grid
                      container
                      spacing={1}
                      sx={{ paddingTop: "5px", position: "relative" }}
                    >
                      {/* First Column */}
                      <Grid
                        item
                        xs={12}
                      >
                        <div
                          className={classes.gridBox}
                          style={{ position: "relative" }}
                        >
                          <Typography className={classes.typography}>
                            Weitere Hinweise (ggf. Angaben/Begründung zum langfristigen
                            Heilmittelbedarf, Angaben zur Biankoverdnung, etc.)
                          </Typography>

                          <Controls.TextArea
                            disabled={readOnlyView}
                            name="additionalNotes"
                            rows={3}
                            placeholder=""
                            value={formData?.additionalNotes}
                            onChange={handleInputChange}
                            style={{ flex: 1, height: "60px", width: "100%" }}
                          />

                          {/* Doctor Info positioned in the top right corner */}
                          <div
                            style={{ position: "absolute", top: 25, right: 0, width: "40%" }}
                            className={classes.gridBox}
                          >
                            <Controls.TextArea
                              label={
                                <Typography className={classes.typography}>
                                  Verordnende Ärztin/Verordnender Arzt
                                </Typography>
                              }
                              disabled
                              name="doctorData"
                              rows={5}
                              placeholder=""
                              value={
                                `${formData.doctorData?.contactInfo?.salutation || ""} ${
                                  formData.doctorData?.contactInfo?.firstNames || ""
                                } ${formData.doctorData?.contactInfo?.lastName || ""}\n` +
                                `${formData.doctorData?.contactInfo?.addressLine1 || ""}\n` +
                                `${formData.doctorData?.contactInfo?.addressLine2 || ""}\n` +
                                `${formData.doctorData?.contactInfo?.zip || ""}\n` +
                                `Tel: ${formData.doctorData?.contactInfo?.phone1 || ""}\n` +
                                `BSNR: ${formData.doctorData?.clinicNumber || ""}`
                              }
                              onChange={handleInputChange}
                              style={{ flex: 1, width: "100%" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        xs={4}
                      >
                        <Typography className={classes.typography}>
                          IK des Leitungserbringers
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                      >
                        <Box
                          sx={{
                            background: "white",
                            width: "100px",
                            height: "20px",
                          }}
                        ></Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
              <Modal
                open={loading}
                aria-labelledby="loading-modal-title"
                aria-describedby="loading-modal-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <CircularProgress size={60} />
                </div>
              </Modal>
            </Container>
          </ScalableComponentSimple>
        </Grid>
        {!readOnlyView && (
          <Grid
            item
            xs={12}
            md={3}
            sx={{ border: "0px dotted black" }}
          >
            <FlatCard title="Validierung">
              <ValidationCard
                patientData={patientValues}
                selectedRx={
                  // patientValues?.rxs?.filter((rx: any) => rx.rxNumber === rxFormData?.rxNumber)[0]
                  formData
                }
                form={formType.LONG}
              />
            </FlatCard>
          </Grid>
        )}
        {unsavedChangesWarning && (
          <WarningDialog
            open={true} // Ensure the warning dialog is open
            onClose={handleCloseUnsavedChangesWarning} // Function to close the dialog
            onContinue={handleUnsavedChangesWarning} // Function to continue despite unsaved changes
            title="Änderungen speichern?"
            content="Du hast einige Änderungen gemacht. Möchtest du diese speichern, bevor du schließt?"
            isConfirmSave
          />
        )}
      </Grid>
    );
    return useObserver(() => {
      return !readOnlyView ? (
        <Dialog
          open={openRx as boolean}
          onClose={handleCloseRxDialog}
          maxWidth="xl"
          fullWidth
        >
          <DialogTitle>Zahnarzt Dialog</DialogTitle>
          <DialogContent>{FormContent}</DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleCloseRxDialog}
            >
              Abbrechen
            </Button>
            <Button
              onClick={handleGenericSubmit}
              variant="contained"
              disabled={Object.values(rxerrors).some((error) => error !== "")}
            >
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        FormContent
      );
    });
  }
);

export default Zahnarzt;
