import React, { FC } from "react";
import { Box, Typography, Grid, TextField, IconButton, Button } from "@mui/material";
import { ExportBillingData } from "../../../../../../../api-client";
import { EditBillTable } from "../../exports";
import { useDownload } from "../../../../../../../hooks/useDownload";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

interface BillCardProps {
  bill: ExportBillingData;
  todayString: string;
  selectedRxId: string | null;
  notes: Record<string, string>;
  setNotes: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  onRowClick: (row: any) => void;
  setSelectedRxId: (id: string) => void;
  handleMenuOpen: (event: React.MouseEvent<HTMLButtonElement>, row: any) => void;
  handleTaxierenClick: (row: any) => void;
  onRemoveAll: (billId: string) => void;
}

export const BillCard: FC<BillCardProps> = ({
  bill,
  todayString,
  selectedRxId,
  notes,
  setNotes,
  onRowClick,
  setSelectedRxId,
  handleMenuOpen,
  handleTaxierenClick,
  onRemoveAll,
}) => {
  const { accountInfo, data, billNumber, userInfo, billId } = bill;
  const { rxs, payerDto, totalPrice } = data;
  const totalPriceFormatted = Number(totalPrice).toFixed(2).toString().replace(".", ",");
  const { handleDownloadPdf } = useDownload();

  return (
    <Box className="bill-card-header">
      <Grid
        container
        justifyContent="left"
        alignItems="center"
      >
        <Grid
          item
          xs={1}
        >
          <IconButton
            onClick={() => onRemoveAll(billId)}
            style={{ color: "#ff5722" }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={11}
        >
          <Box
            className="bill-card-body"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Typography
              variant="h6"
              textAlign="center"
            >
              {payerDto?.name}
            </Typography>
            {bill.paperCenterReceipt?.id && (
              <IconButton
                className="fancy-button"
                onClick={() => handleDownloadPdf(bill.paperCenterReceipt.id)}
                title="Download Paper Center PDF"
              >
                <HistoryEduIcon />
              </IconButton>
            )}
            {bill.billPdf?.id && (
              <IconButton
                className="fancy-button"
                onClick={() => handleDownloadPdf(bill.billPdf.id)}
                title="Download Bill PDF"
              >
                <RequestQuoteIcon />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ m: 1 }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          sx={{ mb: 2 }}
        >
          {payerDto?.paperReceivingCenter && (
            <Grid
              item
              xs={8}
            >
              <Typography
                variant="subtitle1"
                align="center"
              >
                Papierzentrum
              </Typography>
              <Box className="box">
                <Typography variant="subtitle2">{payerDto.paperReceivingCenter.name}</Typography>
              </Box>
            </Grid>
          )}
          <Grid
            item
            xs={4}
          >
            <Typography
              variant="subtitle1"
              align="center"
            >
              Mandant
            </Typography>
            <Box className="box">
              <Typography variant="subtitle2">{accountInfo.name}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Typography
              variant="subtitle1"
              align="center"
            >
              Bill-ID
            </Typography>
            <Box className="box">
              <Typography variant="subtitle2">{billNumber}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Typography
              variant="subtitle1"
              align="center"
            >
              Startdatum
            </Typography>
            <Box className="box">
              <Typography variant="subtitle2">{todayString}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Typography
              variant="subtitle1"
              align="center"
            >
              Erstellt von
            </Typography>
            <Box className="box">
              <Typography variant="subtitle2">
                {userInfo.firstName + " " + userInfo.lastName}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <Typography
              variant="subtitle1"
              align="center"
            >
              Gesamtpreis
            </Typography>
            <Box className="box">
              <Typography variant="subtitle2">{totalPriceFormatted} €</Typography>
            </Box>
          </Grid>
        </Grid>

        {rxs.length > 0 ? (
          <EditBillTable
            rxs={rxs}
            selectedRxId={selectedRxId}
            onRowClick={(row) => {
              onRowClick(row);
              setSelectedRxId(row.id);
            }}
            onMenuOpen={handleMenuOpen}
            onTaxierenClick={handleTaxierenClick}
          />
        ) : (
          <Typography
            variant="h6"
            align="center"
          >
            Keine Rechnungsrezepte zum Versenden an die {payerDto?.name} bereit.
          </Typography>
        )}

        <Box className="notes-section">
          <TextField
            sx={{ mr: 1, mb: 1 }}
            className="notes-textfield"
            placeholder="Add notes..."
            fullWidth
            value={notes[billId] || ""}
            onChange={(e) => {
              const updatedNote = e.target.value;
              setNotes((prevNotes) => ({
                ...prevNotes,
                [billId]: updatedNote,
              }));
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
