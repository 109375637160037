import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import useStore from "../../../../helpers/useStore";
import { TransactionsContext } from "../../../../stores/Transactions/Transactions.provider";
import { TopLevelPaper } from "../../../../themes/StyledComponents";
import { RxStoreContext } from "../../../../stores/Rx/rx.provider";
import AlertDialogSlide from "../../../Transactions/Kasse/TransactionMethod";
import notificationStore from "../../../../stores/Notification/notificationStore";
import PatientBillTable from "../tables/PatientBillTable";
import PatientAllTransactionTable from "../tables/PatientAllTransactionTable";
import { usePatientRechnungen } from "../hooks/usePatientBills";
import { RxBill } from "../types/PatientBills.types";
import {
  CreatePatientTransactionDto,
  CreateRechnungDto,
  DebitPermissionDto,
  PatientDto,
  UserDto,
} from "../../../../api-client";
import {
  paymentStatus,
  transactionMethods,
  transactionTypes,
} from "../../../Transactions/Kasse/types/kasse.types";
import { UserContext } from "../../../../stores/User/User.provider";
import PatientRechnungTable from "../tables/PatientRechnungTable";
import PayToPatientDialog from "../dialogs/PayToPatientDialog";
import DebitPermission from "./DebitPermission/DebitPermission";
import PermissionCard from "./DebitPermission/PermissionCard";
import { observer } from "mobx-react";
import FormDialog from "../../../Transactions/Kasse/TransactionForm";
interface PatientRechnungenProps {
  patient: PatientDto;
  onTransactionComplete: () => void;
}

const PatientRechnungen: FC<PatientRechnungenProps> = observer(
  ({ patient, onTransactionComplete }) => {
    //Store functions
    const TransactionsStore = useStore(TransactionsContext);
    const {
      getTransactionByPatientId,
      getPaidBillsByPatientId,
      lastUpdate,
      getDuePaymentByRxNumber,
      createRechnung,
      getRechnungByPatientId,
      getRechnungForRx,
      getDebitPermissionsByPatient,
    } = TransactionsStore;
    const RxStore = useStore(RxStoreContext);
    const { getRxByNumber } = RxStore;

    const UserStore = useStore(UserContext);

    //Custom hook
    const {
      paidBills,
      allTransactions,
      rechnungBills,
      fetchTransactions,
      fetchPaidBills,
      fetchRechnungBills,
      rechnungMap,
      isLoading,
    } = usePatientRechnungen({
      patientId: patient.id!,
      getTransactionByPatientId,
      getPaidBillsByPatientId,
      getRechnungByPatientId,
      getRechnungForRx,
      lastUpdate,
    });

    //state variables
    const [loadingRows, setLoadingRows] = useState<Record<string, boolean>>({});
    const [debitPermissions, setDebitPermissions] = useState<DebitPermissionDto[]>([]);
    const [debitPermissionDialog, setDebitPermissionDialog] = useState(false);
    const [dataRefreshFlag, setDataRefreshFlag] = useState<boolean>(false);
    const [loadPermission, setLoadPermission] = useState<boolean>(false);

    const [selectedRxData, setSelectedRxData] = useState<any>(null);
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
    const [openTransactionType, setOpenTransactionType] = useState<boolean>(false);
    const [isReturnToPatientDialogOpen, setIsReturnToPatientDialogOpen] = useState(false);
    const [returnTransactionData, setReturnTransactionData] =
      useState<CreatePatientTransactionDto | null>(null);
    const [user, setUser] = useState<UserDto | null>();
    // const accountId = user?.location?.id;

    useEffect(() => {
      UserStore.getUser()
        .then((user) => {
          setUser(user);
        })
        .catch((error) => {
          console.error("Failed to fetch user:", error);
        });
    }, [UserStore]);

    const handleZahlenClick = async (rxNumber: string | undefined) => {
      try {
        if (!rxNumber) {
          console.log("Rx number is not available");
          return;
        }
        const rxData = await getRxByNumber(rxNumber);
        const result = await getDuePaymentByRxNumber(rxNumber);
        if (!result.matchAppointments && !result.isTimeSlotZero) {
          notificationStore.showMessage("Es sind noch nicht alle Termine vergeben.", "error");
          throw new Error("Not all appointments are scheduled yet.");
        }
        setSelectedRxData(rxData);
        setIsAlertDialogOpen(true);
        setOpenTransactionType(true);
      } catch (error) {
        console.error("Failed to fetch Rx data", error);
      }
    };

    const handleLastschrift = async (rxNumber: string | undefined,permission:DebitPermissionDto) => {
      try {
        if (!rxNumber) {
          console.log("Rx number is not available");
          return;
        }
        const rxData = await getRxByNumber(rxNumber);
        setSelectedRxData({...rxData,debitPermission:permission});
        setDebitPermissionDialog(true);
      } catch (error) {
        console.error("Failed to fetch Rx data", error);
      }
    };

    const handleReturnToPatient = async (bill: RxBill) => {
      if (!bill.rxNumber) return;

      try {
        const rxData = await getRxByNumber(bill.rxNumber);
        const amountToPay = await getDuePaymentByRxNumber(bill.rxNumber);
        const motiv = "Rückerstattung des zu viel gezahlten Betrags an den Patienten";
        const tAccounts = rxData?.tarifGroupId !== "Privat" ? 4101 : 4105;
        const accountId = rxData?.account.id ?? "";

        const createCashData: CreatePatientTransactionDto = {
          amount: Number(amountToPay.dueAmount.toFixed(2)),
          method: transactionMethods.CASH,
          patientId: patient.id!,
          rxId: rxData?.id,
          text: motiv,
          type: transactionTypes.AUSGABE,
          accountId: accountId,
          userId: user?.id ?? "",
          tAccountId: tAccounts,
        };

        setReturnTransactionData(createCashData);
        setIsReturnToPatientDialogOpen(true);
      } catch (error) {
        console.error("Failed to create cash transaction", error);
      }
    };

    const onReturnToPatientSuccess = async () => {
      setIsReturnToPatientDialogOpen(false);
      await fetchTransactions();
      onTransactionComplete();
    };

    const handleCreateRechnung = async (bill: RxBill) => {
      if (!bill.rxNumber) return;
      try {
        // For loading spinner
        setLoadingRows((prev) => ({ ...prev, [bill.rxNumber!]: true }));
        const rxData = await getRxByNumber(bill.rxNumber);
        const amountForRechnung = await getDuePaymentByRxNumber(bill.rxNumber);
        if (!amountForRechnung.matchAppointments && !amountForRechnung.isTimeSlotZero) {
          notificationStore.showMessage("Es sind noch nicht alle Termine vergeben.", "error");
          throw new Error("Not all appointments are scheduled yet.");
        }
        const motiv = rxData?.tarifGroupId !== "Privat" ? "Co-payment" : "Payment";
        const tAccounts = rxData?.tarifGroupId !== "Privat" ? 4101 : 4105;
        const accountId = rxData?.account.id;
        if (!accountId) {
          notificationStore.showMessage("Account ID not found", "error");
          throw new Error("Account ID not found");
        }
        const rechnungData: CreateRechnungDto = {
          amount: Number(amountForRechnung.dueAmount.toFixed(2)),
          patientId: patient.id!,
          rxId: rxData?.id,
          text: motiv,
          type: transactionTypes.EINNAHME,
          paymentStatus: paymentStatus.NOT_PAID,
          accountId: accountId,
          userId: user?.id,
          tAccountId: tAccounts,
        };

        await createRechnung(rechnungData);
        notificationStore.showMessage("Rechnung erfolgreich erstellt!", "success");
        fetchRechnungBills();
        setDataRefreshFlag(!dataRefreshFlag);
      } catch (error: any) {
        console.error(error);
        let errorMessage = error.response?.data?.message || error.message;
        notificationStore.showMessage(
          `Rechnung konnte nicht erstellt werden: ${errorMessage}`,
          "error",
          error
        );
      } finally {
        setLoadingRows((prev) => ({ ...prev, [bill.rxNumber!]: false }));
      }
    };

    const handleCloseTransactionType = async () => {
      setOpenTransactionType(false);
      setDebitPermissionDialog(false);
      await fetchTransactions();
      onTransactionComplete();
    };

    const handleCloseDebitPermissionDialog = () => {
      setDataRefreshFlag(!dataRefreshFlag)
      setDebitPermissionDialog(false);
    };

    useEffect(() => {
      const fetchData = async () => {
        if (!patient.id) return;
        try {
          setLoadPermission(true);
          const data = await getDebitPermissionsByPatient(patient.id);
          setDebitPermissions(data);
        } catch (error) {
          notificationStore.showMessage("Error fetching permissions", "error");
        } finally {
          setLoadPermission(false);
        }
      };

      fetchData();
    }, [dataRefreshFlag]);

    return (
      <>
        <Backdrop
          open={isLoading}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
          &nbsp; Bitte warten...
        </Backdrop>
        <div>
          <TopLevelPaper>
            {loadPermission ? (
              <CircularProgress />
            ) : (
              <PermissionCard
                patientData={patient}
                debitPermissions={debitPermissions}
                setDebitPermissions={setDebitPermissions}
                dataRefreshFlag={dataRefreshFlag}
                setDataRefreshFlag={setDataRefreshFlag}
              />
            )}
          </TopLevelPaper>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h5" fontWeight="bold" marginBottom={1}>
              Rezepte
            </Typography>
          </Box>
          <TopLevelPaper>
            <PatientBillTable
              rxBills={paidBills}
              rechnungMap={rechnungMap}
              onClickZahlen={handleZahlenClick}
              onClickBill={handleCreateRechnung}
              onClickLastschrift={handleLastschrift}
              onClickReturnPatientBill={handleReturnToPatient}
              loadingRows={loadingRows}
              debitPermissions={debitPermissions}
            />
          </TopLevelPaper>
          {debitPermissionDialog && (
            <FormDialog
              open={true}
              handleTransactionClose={() => {
                handleCloseDebitPermissionDialog();
              }}
              patientData={selectedRxData?.patient}
              transactionMethod={transactionMethods.LASTSCHRIFT}
              selectedRow={selectedRxData}
            />
          )}
          {isAlertDialogOpen && (
            <AlertDialogSlide
              open={openTransactionType}
              handleClose={handleCloseTransactionType}
              patientData={selectedRxData?.patient}
              selectedRow={selectedRxData}
              dataRefreshFlag={dataRefreshFlag}
              setDataRefreshFlag={setDataRefreshFlag}
            />
          )}
        </div>
        <div>
          <Typography variant="h5" fontWeight="bold" marginBottom={1} marginTop={2}>
            Rechnungen
          </Typography>
          <TopLevelPaper>
            <PatientRechnungTable
              rechnungen={rechnungBills}
              userId={user?.id}
              fetchRechnungen={fetchRechnungBills}
              fetchPaidBills={fetchPaidBills}
            />
          </TopLevelPaper>
        </div>
        <div>
          <Typography variant="h5" fontWeight="bold" marginBottom={1} marginTop={2}>
            Alle Bewegungen
          </Typography>
          <TopLevelPaper>
            <PatientAllTransactionTable allTransactions={allTransactions} />
          </TopLevelPaper>
        </div>
        <PayToPatientDialog
          open={isReturnToPatientDialogOpen}
          onClose={() => setIsReturnToPatientDialogOpen(false)}
          transaction={returnTransactionData}
          onPaymentSuccess={onReturnToPatientSuccess}
        />
      </>
    );
  }
);

export default PatientRechnungen;
