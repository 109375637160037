import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {  Button, CardHeader, Grid } from "@mui/material";
import NewRx from "./NewRx";
import {
  AppointmentDto,
  ContinuousAppointmentDto,
  PatientAvailabilityDto,
  RxDto,
  TimeSlotDto,
} from "../../api-client";
import { useEffect, useState } from "react";
import { RxStoreContext } from "../../stores/Rx/rx.provider";
import useStore from "../../helpers/useStore";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import { Theme } from "@mui/material/styles";

import PatientAvailability from "./PatientAvailability";
import PatientUnavailability from "./PatientUnavailabilty";
import UnscheduledAppointments from "./UnscheduledAppointments";
import TerminView from "../PatientOverview/RxInfo/TerminView";
import { TerminViewConstants } from "../PatientOverview/TerminView";
import FlatCard from "../../atoms/FlatCard";
import { observer } from "mobx-react";
import { PatientsContext } from "../../stores/Patients/patients.provider";
import { UserContext } from "../../stores/User/User.provider";
import { AutoAwesome, EditCalendar } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AppointmentSuggestion from "./Appointments/AppointmentSuggestion";

const cardStyles = {
  card1: {
    // backgroundColor: (theme:Theme) => theme.palette.primary.light,
    margin: "10px",
  },
  card2: {
    // backgroundColor: "white",
    margin: "10px",
  },
  card3: {
    // backgroundColor: "white",
    margin: "10px",
  },
};
interface ScheduleOverviewProps {
  masterData: any[];
  patientData: any;
}
const therapyRxs: any[] = [
  {
    id: "1",
    therapyRx: ["KG", "MT", "KG"],
    start: "09:00",
    end: "10:00",
  },
  {
    id: "2",
    therapyRx: ["KT"],
    start: "11:00",
    end: "12:00",
  },
  {
    id: "3",
    therapyRx: ["MT", "KG"],
    start: "02:00",
    end: "03:00",
  },
  {
    id: "3",
    therapyRx: ["BT"],
    start: "02:00",
    end: "03:00",
  },
];

const ScheduleOverview: React.FC<ScheduleOverviewProps> = observer(({ masterData, patientData }) => {
  const RxStore = useStore(RxStoreContext);
  const PatientStore=useStore(PatientsContext);
  const UserStore=useStore(UserContext);
  const [rxnoAppointments, setRxnoAppointments] = useState<RxDto[]>([]); //make it RxDto type
  const [appointments, setAppointmentsPatient] = useState<AppointmentDto[]>([]);
  const [continousAppointments, setContinuousAppointments] = useState<ContinuousAppointmentDto[]>(
    []
  );
  const [cancelledAppointmentsList, setCancelledAppointmentsList] = useState<AppointmentDto[]>(
    []
  );
  const {patientDataRefreshFlag}=PatientStore
  const {user}=UserStore;

  const unscheduledApps = appointments.filter((appointment) => !appointment.start);
  const scheduledApps = appointments.filter((appointment) => appointment.start);
  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [isSuggestionDialogOpen, setsuggestionDialogOpen] = useState(false);
  const navigate = useNavigate();
  const openSuggestionDialog = () => {
    setsuggestionDialogOpen(true);
  };

  const handlePlanning = (patientId: string) => {
    navigate("/termin?pid=" + patientId);
  };
 
  const unscheduledContinuousApps = continousAppointments.filter(
    (appointment) => !appointment.start
  );
  const scheduledContinuousApps = continousAppointments.filter((appointment) => appointment.start);

  const { getRxWithNoAppointments } = RxStore;
  // Define a state variable to track changes in patientData and filteredValue
  const [dataRefreshFlag, setDataRefreshFlag] = useState(null);
  const AppointmentStore = useStore(AppointmentContext);
  const {
    getAppointmentsForPatient,
    getBlockedSlotsForPatient,
    getPatientUnavailabilityForPatient,
    getContinuousAppointmentsForPatient,
    getCancelledAppointmentsForPatient
  } = AppointmentStore;
  const [blockedData, setblockedData] = useState<TimeSlotDto[]>([]);
  const [patientAvailability, setPatientAvailability] = useState<PatientAvailabilityDto[]>([]);

  const fetchData = async () => {
    try {
      console.log("FECTSGHSHJSAJSJ", patientData);
      const fetchedData = await getBlockedSlotsForPatient(patientData);
      const patientAvailabilityData = await getPatientUnavailabilityForPatient(patientData);
      setblockedData(fetchedData);
      setPatientAvailability(patientAvailabilityData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dataRefreshFlag,patientDataRefreshFlag]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setAppointmentsLoading(true);
        const response = await getAppointmentsForPatient(patientData);
        console.log("APPONTMENTS", response);
        if (response) setAppointmentsPatient(response);

        const continuousAppointments = await getContinuousAppointmentsForPatient(patientData);
        
        if (continuousAppointments) setContinuousAppointments(continuousAppointments);
        const cancelledAppointments=await getCancelledAppointmentsForPatient(patientData)
        console.log("Cancelldkfkfkodoeo", cancelledAppointments);
        if(cancelledAppointments)
        // Extract all appointments from cancelledAppointments
            {const appointmentList = cancelledAppointments.map((cancelled:any) => cancelled.appointment);
              console.log("Cancelldkfkfkodoedsssso", appointmentList);


        setCancelledAppointmentsList(appointmentList)}
      } catch (error) {
        console.log("error", error);
      } finally {
        setAppointmentsLoading(false);
      }
    };

    fetchData();
  }, [dataRefreshFlag,patientDataRefreshFlag]);
  const handleAppointmentSuggestionClose = (wasSaved: boolean) => {
    setsuggestionDialogOpen(false);
    if (wasSaved) {
      setDataRefreshFlag(!dataRefreshFlag as any);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRxWithNoAppointments(patientData);
        setRxnoAppointments(response);
        // setDataRefreshFlag(null);
        // setItems(response);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [patientData, dataRefreshFlag,patientDataRefreshFlag,user]);

  console.log("RXWITHNOAPPII", rxnoAppointments, blockedData, patientAvailability);
  // Define an array of background colors
  return (
    // <Container maxWidth="xl" style={{ border: '1px solid red' }}>
    <Grid container spacing={2}>
      {/*Single Element: Termine */}
      <Grid item xs={12} sm={6} md={3}>
        <Card sx={cardStyles.card2}>
          <CardHeader title="Dauertermine" />
          <CardContent>
            {/* <Termin
              appointments={scheduledContinuousApps}
              setDataRefreshFlag={setDataRefreshFlag}
              patientId={patientData}
              dataRefreshFlag={dataRefreshFlag}
              blockedData={blockedData}
              setblockedData={setblockedData}
              patientAvailabilityData={patientAvailability}
              setPatientAvailability={setPatientAvailability}
              isContinuous={true}
            /> */}
            <TerminView
              appointments={scheduledContinuousApps}
              patientId={patientData}
              dataRefreshFlag={dataRefreshFlag}
              setDataRefreshFlag={setDataRefreshFlag}
              blockedData={blockedData}
              setblockedData={setblockedData}
              appointmentsLoading={appointmentsLoading}
              patientAvailabilityData={patientAvailability}
              setPatientAvailability={setPatientAvailability}
              isView={TerminViewConstants.CONTINOUS_TERMIN}
              isContinuous={true}
            />

            {/* <ComponentWithData prop3="Value3" prop4="Value4" /> */}
          </CardContent>
        </Card>

        <Card sx={cardStyles.card2}>
          <CardHeader title="Termine" />
          <CardContent>
            {/* <Termin
              appointments={scheduledApps}
              setDataRefreshFlag={setDataRefreshFlag}
              patientId={patientData}
              dataRefreshFlag={dataRefreshFlag}
              blockedData={blockedData}
              setblockedData={setblockedData}
              patientAvailabilityData={patientAvailability}
              setPatientAvailability={setPatientAvailability}
            />  */}
            <TerminView
              appointments={scheduledApps}
              patientId={patientData}
              dataRefreshFlag={dataRefreshFlag}
              setDataRefreshFlag={setDataRefreshFlag}
              blockedData={blockedData}
              setblockedData={setblockedData}
              appointmentsLoading={appointmentsLoading}
              patientAvailabilityData={patientAvailability}
              setPatientAvailability={setPatientAvailability}
              isView={TerminViewConstants.TERMIN_VIEW}
            />
            {/* <ComponentWithData prop3="Value3" prop4="Value4" /> */}
          </CardContent>
        </Card>
      </Grid>

      {/* Two Elements : Templates and Avaialbility */}
      <Grid item xs={12} sm={6} md={6}>
        {/* Neu Rezepte Card */}
        <Card sx={cardStyles.card1}>
          <CardHeader title="Terminformat für neue Rezepte" />
          <CardContent>
            <NewRx
              masterData={masterData}
              rxnoAppointments={rxnoAppointments}
              setDataRefreshFlag={setDataRefreshFlag}
              dataRefreshFlag={dataRefreshFlag}
              patientId={patientData}
              blockedData={blockedData}
              setblockedData={setblockedData}
              patientAvailablityData={patientAvailability}
              setPatientAvailablityData={setPatientAvailability}
            />
            {/* <ComponentWithData prop1="Value1" prop2="Value2" /> */}
          </CardContent>
        </Card>
        {/* <Grid item xs={12} sm={6} md={20}> */}

        {/* Verfügbarkeit Card */}
        <Card sx={cardStyles.card3}>
          <CardHeader title="Zeitliche Einschränkungen" />
          <Typography variant="body2" color="text.secondary">
            <CardContent>
              <FlatCard title="Wöchentlich">
                <PatientUnavailability
                  patientId={patientData}
                  patientAvailabilityData={patientAvailability}
                  setPatientAvailability={setPatientAvailability}
                />
              </FlatCard>

              <FlatCard title="Einmalig">
                <PatientAvailability
                  patientId={patientData}
                  blockedData={blockedData}
                  setblockedData={setblockedData}
                />
              </FlatCard>
            </CardContent>
            {/* <ComponentWithData prop5="Value5" prop6="Value6" /> */}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        {cancelledAppointmentsList.length>0 && <Card sx={cardStyles.card2}>
          <CardHeader title="Vorherige Stornierung" />
          <CardContent>
            {cancelledAppointmentsList.length > 0 && (
              <Grid
                item
                xs={"auto"}
                // alignSelf="flex-end"
                sx={{ border: "0px red solid", marginTop: 2 }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ marginRight: 1 }}
                  onClick={openSuggestionDialog}
                  startIcon={<AutoAwesome />}
                >
                  KI
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handlePlanning(patientData)}
                  startIcon={<EditCalendar />}
                >
                  Plannen
                </Button>
              </Grid>
            )}
            <TerminView
              appointments={cancelledAppointmentsList}
              patientId={patientData}
              dataRefreshFlag={dataRefreshFlag}
              setDataRefreshFlag={setDataRefreshFlag}
              blockedData={blockedData}
              setblockedData={setblockedData}
              appointmentsLoading={appointmentsLoading}
              patientAvailabilityData={patientAvailability}
              setPatientAvailability={setPatientAvailability}
              isView={TerminViewConstants.CANCELLED_TERMIN}
            />
            <AppointmentSuggestion
              open={isSuggestionDialogOpen}
              onClose={handleAppointmentSuggestionClose}
              setDataRefreshFlag={setDataRefreshFlag}
              dataRefreshFlag={dataRefreshFlag}
              patientId={patientData}
              blockedData={blockedData}
              setblockedData={setblockedData}
              patientAvailabilityData={patientAvailability}
              setPatientAvailabilityData={setPatientAvailability}
            />

            {/* <ComponentWithData prop3="Value3" prop4="Value4" /> */}
          </CardContent>
        </Card>}
        {(unscheduledApps.length > 0 || unscheduledContinuousApps.length > 0) && (
          <Card sx={cardStyles.card3}>
            <CardHeader title="Unterminierte Behandlungen" />
            <CardContent>
              <UnscheduledAppointments
                appointments={unscheduledApps}
                continuousAppointments={unscheduledContinuousApps}
                setDataRefreshFlag={setDataRefreshFlag}
                patientId={patientData}
                dataRefreshFlag={dataRefreshFlag}
                blockedData={blockedData}
                setblockedData={setblockedData}
                patientAvailabilityData={patientAvailability}
                setPatientAvailability={setPatientAvailability}
                appointmentsLoading={appointmentsLoading}
                cancelledAppointmentsList={cancelledAppointmentsList}
              />
              {/* <ComponentWithData prop3="Value3" prop4="Value4" /> */}
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
    // </Container>
    //     <Box style={{ display: 'flex', flexDirection: 'row' }}>
    //    <Card
    //      style={{ ...cardStyles.base, ...cardStyles.card1 }}
    //   >
    //     <CardContent>
    //       <Typography variant="h6" component="div">
    //         Neu Rezepte
    //       </Typography>
    //       <Typography variant="body2" color="text.secondary">
    //         <NewRx masterData={masterData} rxnoAppointments={rxnoAppointments}/>
    //         {/* <ComponentWithData prop1="Value1" prop2="Value2" /> */}
    //       </Typography>
    //     </CardContent>
    //   </Card>

    //   <Card
    //     style={{ ...cardStyles.base, ...cardStyles.card2 }}
    //   >
    //     <CardContent>
    //       <Typography variant="h5" component="div">
    //         Termine
    //       </Typography>
    //       <Typography variant="body2" color="text.secondary">
    //         <Termin therapies={therapies}/>
    //         {/* <ComponentWithData prop3="Value3" prop4="Value4" /> */}
    //       </Typography>
    //     </CardContent>
    //   </Card>

    //   <Card
    //     style={{ ...cardStyles.base, ...cardStyles.card3 }}
    //   >
    //     <CardContent>
    //       <Typography variant="h5" component="div">
    //         Card 3
    //       </Typography>
    //       <Typography variant="body2" color="text.secondary">
    //         {/* <ComponentWithData prop5="Value5" prop6="Value6" /> */}
    //       </Typography>
    //     </CardContent>
    //   </Card>
    // </Box>
  );
});

export default ScheduleOverview;
