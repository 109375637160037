import React, { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddressDto,
  AppointmentDto,
  NoteDto,
  TherapyDetailsDto,
  TherapyDto,
  TicketDto,
  UpdateAppointmentDto,
} from "../../../api-client";
import Notes from "../Notes/Notes";
import { FlexListContainer, LabelItem, ValueItem } from "../../../molecules/FlexList";
import { formatDateUI } from "../../../helpers/dateFormat";
import { mobiliTheme } from "../../../themes/mobiliTheme";
import { TicketsTable } from "../../Ticketing/TicketOverview";
import { observer } from "mobx-react";
import { TicketsStoreContext } from "../../../stores/Ticketing/tickets.provider";
import useStore from "../../../helpers/useStore";
import { NotesContext } from "../../../stores/Notes/notes.provider";
import { UserContext } from "../../../stores/User/User.provider";
import Controls from "../../../atoms/Controls";
import { PatientsContext } from "../../../stores/Patients/patients.provider";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import { MasterTablesContext } from "../../../stores/MasterTables/MasterTables.provider";
import { DateTimePicker } from "@mui/x-date-pickers";
import FlatCard from "../../../atoms/FlatCard";
import { TherapyExt } from "../../../molecules/Calendar/Calendar.type";
import moment from "moment";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { visitTypeMapping, visitTypeToIcon } from "../../../helpers/visitTypeMapping";
import AppointmentAddress from "./AppointmentAddress";
import { DoneAll, MoveDown } from "@mui/icons-material";
import notificationStore from "../../../stores/Notification/notificationStore";
import { TerminViewConstants } from "../../PatientOverview/TerminView";

interface AppointmentEditCardProps {
  open: boolean;
  onClose: (actionPerformed: boolean) => void;
  appointment: AppointmentDto;
  patientId: number;
  dataRefreshFlag: boolean;
  setDataRefreshFlag: any;
  isContinous?: boolean;
  isView?:TerminViewConstants;
}

interface AddressOption {
  label: string;
  value: string;
  id: string;
  visitLocation: string;
  icon:ReactElement;
}

const formatAddress = (address: AddressDto): string => {
  const { addressLine1, addressLine2, city, zip } = address.contactInfo || {};

  // Create an array of the parts of the address, filtering out empty or undefined values
  const addressParts = [addressLine1, addressLine2, city, zip].filter(
    (part) => part && part.trim()
  );

  // Join the parts with a comma and a space, ensuring no trailing comma
  return addressParts.join(", ");
};

const AppointmentEditCard: React.FC<AppointmentEditCardProps> = observer(
  ({ open, onClose,appointment , patientId, dataRefreshFlag, setDataRefreshFlag, isContinous,isView }) => {
    const [warningMessage, setWarningMessage] =useState(false);
    const [selectedTabs, setSelectedTabs] = useState<number>(1); // Assuming there are tabs for navigation
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [appointmentLoading, setIsAppointmentLoading] = useState<boolean>(false);
    const [ticketData, setTicketData] = useState<TicketDto[]>([]);
    const [appointmentNotes, setAppointmentNotes] = useState<NoteDto[]>([]);
    const [addressOptions, setAddressOptions] = useState<AddressOption[]>([]);
    const [selectedAddress, setSelectedAddress] = useState(
      appointment.address?.contactInfo?.addressLine1 ? formatAddress(appointment.address) : ""
    );
    const [formData, setFormData] = useState<UpdateAppointmentDto>({});
    const [patientAddress, setPatientAddress] = useState<AddressDto[]>([]);
    const [openAddressDialog, setOpenAddressDialog] = useState(false);


    const TicketsStore = useStore(TicketsStoreContext);
    const AppointmentStore = useStore(AppointmentContext);
    const { updateAppointment,updateMultipleAppointments,getFilteredAppointmentsForPatient } = AppointmentStore;
    const UserStore = useStore(UserContext);
    const NotesStore = useStore(NotesContext);
    const PatientStore = useStore(PatientsContext);
    const { getPatient } = PatientStore;
    const { getAppointmentNotes, postNotes, fetchNotes, setFetchNotes,updateNoteById } = NotesStore;
    const { getTicketsByAppointmentId } = TicketsStore;
    const masterTablesStore = useStore(MasterTablesContext);
    const { getTherapyId } = masterTablesStore;
    const { user } = UserStore;
    const [appointmentsListAddressUpdate, setAppointmentsListAddressUpdate] = useState<string[]>(
      []
    );
    useEffect(() => {
      const fetchAppointments = async () => {
        try{
          setIsAppointmentLoading(true)
          
        if (isView === TerminViewConstants.SERIES_VIEW || isView === TerminViewConstants.RX_VIEW) {
        
            const response = await getFilteredAppointmentsForPatient(patientId, {
              showOnlyFutureAppointments: false,
              showOnlyActiveRxs: false,
              showLastThreeMonths: false,
              rxIds: [],
              appointmentId: appointment?.id, 
            }, false);
            
           if(response)setFormData(response[0])
          }
        else{
          setFormData(appointment)
        }}
        catch (error) {
          notificationStore.showMessage("Error fetching appointments:", "error");
        }
        finally{
          setIsAppointmentLoading(false)
        }
      };
    
      fetchAppointments();
    }, []); 
    
    // Effect to check if ALL time slots have "keine" visitFee
    useEffect(() => {
      const allKeine = formData?.timeSlots?.every(
        (timeSlot) =>
          !timeSlot?.visitFee?.abbreviation || timeSlot?.visitFee?.abbreviation === "keine"
      );

      if (allKeine && formData?.address?.type?.toLowerCase() !== "praxis") {
        setWarningMessage(true);
      } else {
        setWarningMessage(false);
      }
    }, [formData?.timeSlots, formData?.address?.type]); // Runs when timeSlots or address type change
    const updateMultipleAppointmentsAddress = async (visitFee:TherapyDetailsDto | null) => {
      try {
        setIsLoading(true);

        // Check if formData.id is in the appointmentsListAddressUpdate, and add it if not
        const appointmentIds = [...appointmentsListAddressUpdate];
        if (formData.id && !appointmentIds.includes(formData.id)) {
          appointmentIds.push(formData.id); // Add the formData.id if it's not already in the list
        }

        // Prepare the data for updating
        const data: UpdateAppointmentDto[] = appointmentIds.map((appointmentId) => {
          // If formData.id exists, return formData directly
          if (formData.id && appointmentId === formData.id) {
            return {...formData,visitFee:visitFee!}; // Return the formData directly if the IDs match
          }
          // Initialize the appointment object
          const appointment: UpdateAppointmentDto = {
            id: appointmentId,
            address: formData.address,
            timeSlots: [], // Empty timeSlots as per your example, can be modified if needed
          };

          // Only include visitFee if it exists
          if (visitFee !== undefined && visitFee !== null) {
            appointment.visitFee = visitFee;
          }

          return appointment;
        });
        const response = await updateMultipleAppointments(data, isContinous);
        if (response) {
          setDataRefreshFlag(!dataRefreshFlag); // Toggle the refresh flag
        }

        // Close the dialog after the update
        onClose(true);
      } catch (error) {
        console.error("Error updating appointment:", error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsLoading(false); // Set loading state to false regardless of success or failure
      }
    };
    

    // const updateMultipleAppointmentsAddress = async () => {
    //   try {
    //     setIsLoading(true)
    //     const data: UpdateAppointmentDto[] = appointmentsListAddressUpdate.map((appointmentId) => {
    //       return {
    //         id: appointmentId, 
    //         address:formData.address, 
    //         timeSlots:[], 
    //       };
    //     });
    //     // Call your update API here with formData
    //     console.log("Updating appointment with data:", data);
    //     const response = await updateMultipleAppointments( data, isContinous);
    //     if (response) {
    //       setDataRefreshFlag(!dataRefreshFlag);
    //     }
    //     onClose(true);
    //   } catch (error) {
    //     console.error("Error updating appointment:", error);
    //     // Handle error (e.g., show error message to user)
    //   }
    //   finally {
    //     setIsLoading(false); // Set loading state to false regardless of success or failure
    //   }
    // };
    // const [refresh, setRefresh] = useState(false); // Use a flag

    // Wrapper function for setAppointmentNotes to match the required type
    const handleSetNotes = async (index: number, updatedCard: NoteDto) => {
      if (appointment.id) {
        updatedCard.appointmentId = appointment.id;
        if(updatedCard.id){
          await updateNoteById(updatedCard.id,user?.id as string,updatedCard)
       }
       else{
        await postNotes(user?.id as string, updatedCard);
       }
        const updatedNotes = [...appointmentNotes];
        updatedNotes[index] = updatedCard;
        setAppointmentNotes(updatedNotes);
        // setFetchNotes(!fetchNotes);
      } else {
        console.log("No selectedAppointmentId available.");
      }
    };

    // Data for the table
    useEffect(() => {
      const fetchData = async () => {
        if (appointment.id) {
          setIsLoading(true); // Set loading state to true before fetching data
          try {
            const response = await getTicketsByAppointmentId(appointment.id);
            setTicketData(response);
            const appointmentNotes = await getAppointmentNotes(appointment.id);
            setAppointmentNotes(appointmentNotes || []);
          } catch (error) {
            console.error("Error fetching tickets:", error);
            // Handle error (e.g., show error message to user)
          } finally {
            setIsLoading(false); // Set loading state to false regardless of success or failure
          }
        }
      };

      fetchData();
    }, [appointment.id, fetchNotes]);

    useEffect(() => {
      const fetchAddressOptions = async () => {
        try {
          let patientAddressOptions: any;
          patientAddressOptions = await getPatient(patientId);
          // Add user's address if it exists
          if (user?.location?.address?.contactInfo?.addressLine1) {
            patientAddressOptions.addresses = [
              ...patientAddressOptions.addresses,
              {
                ...user.location.address,
              },
            ];
          }

          setPatientAddress(patientAddressOptions ? patientAddressOptions.addresses : []);
          if (patientAddressOptions && patientAddressOptions.addresses) {
            const hasPraxisVisit = appointment.timeSlots?.some(
              (timeSlot: any) => timeSlot?.therapyRx?.rx?.visitType?.toLowerCase() === "praxis"
            );

            const formattedAddressOptions = patientAddressOptions.addresses
              .filter(
                (address: any) =>
                  !hasPraxisVisit ||
                  (!address.isVisit && !address.isPrimary && !address.isAlternativeBilling)
              )
              .map((address: any) => {
                const { addressLine1 } = address.contactInfo || {};
                if (addressLine1) {
                  return {
                    label: formatAddress(address),
                    value: formatAddress(address),
                    id: address.id,
                    // isVisit: address.isVisit,
                    visitLocation:address.type,
                    icon:address.type?visitTypeToIcon[address.type.toLowerCase()]:undefined
                  };
                }
                return undefined;
              })
              .filter(
                (addressOption: any): addressOption is AddressOption => addressOption !== undefined
              );

            setAddressOptions(formattedAddressOptions);
          }
          // if (user && user?.location?.address) {
          //   setAddresses((prevAddresses) => [
          //     ...(prevAddresses ? prevAddresses : []),
          //     user?.location?.address!,
          //   ]);
          // }
        } catch (error) {
          console.error("Error fetching patient address options:", error);
        }
      };

      fetchAddressOptions();
    }, [patientId, user]);

    // useEffect(() => {
    //   if (patientId) {
    //     getPatient(patientId)
    //       .then((patient) => {
    //         setAddresses((prevAddresses) => [
    //           ...(prevAddresses ? prevAddresses : []),
    //           ...(patient.addresses?.length ? patient.addresses : []),
    //         ]);
    //       })
    //       .catch((err) => {
    //         console.error(err);
    //       });
    //   }
    // }, [patientId]);

    // Set user address in addresses state arrray
    // useEffect(() => {
    //   if (user && user?.location?.address) {
    //     setAddresses((prevAddresses) => [
    //       ...(prevAddresses ? prevAddresses : []),
    //       user?.location?.address!,
    //     ]);
    //   }
    // }, [user]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, id } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: id ? id : value
      }));
    };

    const handleAddressChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedId: any = event.target.id;
      setSelectedAddress(event.target.value); 
      
      const selectedAddressOption: any = patientAddress.find(
        (address: any) => address.id === selectedId
      );
      if (
        !selectedAddressOption?.isVisit &&
        !selectedAddressOption?.isPrimary &&
        !selectedAddressOption?.isAlternativeBilling
      ) {
        const updatedTimeSlots = formData.timeSlots?.map((slot: any) => ({
          ...slot,
          visitFee: null,
        }));
        setFormData((prevFormValues: any) => ({
          ...prevFormValues,
          timeSlots: updatedTimeSlots,
        }));
      }
      if (selectedAddressOption) {
        setFormData((prevFormValues: any) => ({
          ...prevFormValues,
          address: selectedAddressOption,
        }));
      }
    };

    // Handle change in time slot
    const handleTimeSlotChange = (index: number, key: string, value: any) => {
      const updatedTimeSlots = [...(formData.timeSlots as any)];
      updatedTimeSlots[index] = { ...updatedTimeSlots[index], [key]: value };

      // Handle visitFee reset logic when changing time slot making sure only one have visitFee
      if (key === "visitFee") {
        updatedTimeSlots.forEach((slot, i) => {
          if (i !== index && slot.visitFee !== null) {
            updatedTimeSlots[i] = { ...updatedTimeSlots[i], visitFee: null };
          }
        });
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        timeSlots: updatedTimeSlots,
      }));
    };
    const handleDropDownMenuChange = async (
      index: number,
      newVisitFee: string,
      tarifGroup: any
    ) => {
      let newVisitFeeValue: any;
      if(!newVisitFee){
        handleTimeSlotChange(index, "visitFee", null);

      }else{

      newVisitFeeValue = await getTherapyId(tarifGroup, newVisitFee);
      if (
        !formData.address?.isVisit &&
        !formData.address?.isPrimary &&
        !formData.address?.isAlternativeBilling
      ) {
        setSelectedAddress("");
        setFormData((prevFormValues: any) => ({
          ...prevFormValues,
          address: null,
        }));
      }
      handleTimeSlotChange(index, "visitFee", {
        id: newVisitFeeValue,
        abbreviation: newVisitFee,
      });}
    };

    // Function to handle tab change
    const handleTabChange = (event: any, newValue: number) => {
      setSelectedTabs(newValue); // Update selectedTabs state variable with the new value
    };

    const handleSave = async () => {
      try {
        setIsLoading(true)
        // Call your update API here with formData
        console.log("Updating appointment with data:", formData);
        const response = await updateAppointment(formData.id as string, formData, isContinous);
        if (response) {
          setDataRefreshFlag(!dataRefreshFlag);
        }
        onClose(true);
      } catch (error) {
        console.error("Error updating appointment:", error);
        // Handle error (e.g., show error message to user)
      }
      finally {
        setIsLoading(false); // Set loading state to false regardless of success or failure
      }
    };

    const handleCancel=()=>{
      onClose(!open);
    }

    const visitFeeOptions = [
      { label: "HB2K", value: "HB2K" },
      { label: "HB7", value: "HB7" },
      { label: "HB", value: "HB" },
      { label: "HB2", value: "HB2" },
      {label:"keine",value:"keine"}
    ];

    const handleSwappingTreatment = () => {
      // const updatedTimeSlots = formData.timeSlots?.slice();
      const updatedTimeSlots = formData.timeSlots?.map(slot => ({ ...slot }));

      const firstTreatment = updatedTimeSlots?.[0];
      const secondTreatment = updatedTimeSlots?.[1];

      if (firstTreatment && secondTreatment) {
        const firstTreatmentDuration = (firstTreatment.therapyRx?.therapy as TherapyExt).duration;
        const secondTreatmentDuration = (secondTreatment.therapyRx?.therapy as TherapyExt).duration;

        const firstTherapyRx = firstTreatment.therapyRx;
        firstTreatment.therapyRx = secondTreatment.therapyRx;
        secondTreatment.therapyRx = firstTherapyRx;

        const firstStart = moment(firstTreatment.start);
        const secondEnd = moment(secondTreatment.end);

        firstTreatment.start = firstStart.toISOString();
        firstTreatment.end = firstStart.add(secondTreatmentDuration, "minutes").toISOString();

        secondTreatment.start = firstTreatment.end;
        secondTreatment.end = secondEnd.toISOString();
        
        setFormData((prevFormData) => ({
          ...prevFormData,
          timeSlots: updatedTimeSlots,
        }));
      }
    };

    
    const handlePassiveTreatment = () => {
      const updatedTimeSlots = formData.timeSlots 
      ? formData.timeSlots.map(slot => ({ ...slot })) 
      : [];
    
    
      if (updatedTimeSlots.length < 2) return;
    
      // Separate main therapies and heat treatments (supplementary treatments)
      const mainTherapies = updatedTimeSlots.filter(
        (slot) => !(slot.therapyRx?.therapy as TherapyDto)?.isSupplementary
      );
    
      const supplementaryTreatments = updatedTimeSlots.filter(
        (slot) => (slot.therapyRx?.therapy as TherapyDto)?.isSupplementary
      );
    
      if (mainTherapies.length === 0 || supplementaryTreatments.length === 0){     
         notificationStore.showMessage("Keine passive Behandlung zum Austauschen.","warning");
        return;}
    
      // If there are more than one supplementary treatment, log an error and return
      if (supplementaryTreatments.length > 1) { 
        notificationStore.showMessage("Pro Termin kann es nur eine passive Behandlung geben.","warning");
        return;
      }
    
      // Get the earliest and latest times for the main therapies (non-supplementary treatments)
      const mainTherapyStart = moment.min(mainTherapies.map((slot) => moment(slot.start)));
      const mainTherapyEnd = moment.max(mainTherapies.map((slot) => moment(slot.end)));
    
      // Loop through each supplementary treatment and adjust its time
      supplementaryTreatments.forEach((heatTreatment) => {
        const heatTherapyDuration = (heatTreatment?.therapyRx?.therapy as TherapyExt).duration;
    
        if (moment(heatTreatment.start).isBefore(mainTherapyStart)) {
          // If heat treatment is already before main therapy, move it AFTER the last main therapy
          heatTreatment.start = mainTherapyEnd.toISOString();
          heatTreatment.end = moment(heatTreatment.start).add(heatTherapyDuration, "minutes").toISOString();
        } else {
          // Otherwise, move it BEFORE the first main therapy
          heatTreatment.start = mainTherapyStart.clone().subtract(heatTherapyDuration, "minutes").toISOString();
          heatTreatment.end = moment(heatTreatment.start).add(heatTherapyDuration, "minutes").toISOString();
        }
      });
    
      // **Now, update the overall appointment start and end time:**
      // Find the earliest start and latest end from the updated time slots
      const earliestStart = moment.min(updatedTimeSlots.map(slot => moment(slot.start)));
      const latestEnd = moment.max(updatedTimeSlots.map(slot => moment(slot.end)));
       // **Now, sort the time slots** by their start time to ensure correct order
        const sortedTimeSlots = [...updatedTimeSlots].sort((a, b) => moment(a.start).isBefore(b.start) ? -1 : 1);
    
      // Update state
      setFormData((prevFormData) => ({
        ...prevFormData,
        start: earliestStart.toISOString(), // Total start time (earliest across all)
        end: latestEnd.toISOString(), // Total end time (latest across all)
        timeSlots: sortedTimeSlots, // Updated time slots
      }));
    };
    
     
    
    return (
      <Dialog open={open} onClose={handleCancel} maxWidth="lg" fullWidth>
        <DialogTitle>Termin bearbeiten</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "800px", // Set a fixed height
            marginTop: "4px",
          }}
        >
            {appointmentLoading &&   <Modal
                open={appointmentLoading}
                aria-labelledby="loading-modal-title"
                aria-describedby="loading-modal-description"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <CircularProgress size={60} />
                </div>
              </Modal>}
         {!appointmentLoading &&  <><FlexListContainer
            sx={{
              flex: "0 1 70%", // Allow FlexListContainer to take 70% of the height
              overflow: " hidden auto", // Enable vertical scrolling
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FlatCard title="">
                  <Grid container spacing={1}>
                    {/* Start DateTimePicker */}
                    <Grid item xs={12} md={3}>
                      <LabelItem label="Start" />
                      <DateTimePicker
                        label="DD.MM.YYYY"
                        name="start"
                        value={formData.start ? new Date(formData.start as string) : null}
                        onChange={(e: any) => handleInputChange(e)}
                        disabled />
                    </Grid>

                    {/* End DateTimePicker */}
                    <Grid item xs={12} md={3}>
                      <LabelItem label="Ende" />
                      <DateTimePicker
                        label="DD.MM.YYYY"
                        name="end"
                        value={formData.end ? new Date(formData.end) : null}
                        onChange={(e: any) => handleInputChange(e)}
                        disabled />
                    </Grid>

                    {/* Address Select */}
                    <Grid item xs={12} md={6}>
                      <LabelItem label="Addresse" />

                      {/* Flex container for the select and DoneAll icon */}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Controls.Select
                          name="address"
                          value={selectedAddress}
                          onChange={handleAddressChange}
                          options={addressOptions}
                          id={1}
                          style={{ width: "100%" }}
                          onBlur={true} />

                        {/* DoneAll icon with onClick toggle for address dialog */}
                        {/* <Tooltip title="Alle Termine aktualisieren">
    <DoneAll
      onClick={() => {
        setOpenAddressDialog(!openAddressDialog);
      }}
      sx={{ marginLeft: "1px", cursor: "pointer",color:mobiliTheme.palette.info.light }} // Added margin and cursor for better layout
    /></Tooltip> */}
                      </div>
                    </Grid>
                  </Grid>
                </FlatCard>
              </Grid>
              <Grid item xs={12}>
                {formData.timeSlots?.length! > 1 && (
                  <>
                    {/* New MoveDown Button */}
                    {formData.start && <Tooltip title="Behandlung nach unten verschieben">
                      <Button onClick={handlePassiveTreatment}>
                        <MoveDown />
                      </Button>
                    </Tooltip>}

                    {/* Existing Swap Button */}
                    <Tooltip title="Behandlungen tauschen">
                      <Button onClick={handleSwappingTreatment}>
                        <SwapHorizIcon />
                      </Button>
                    </Tooltip>
                  </>
                )}

                <Grid container spacing={1}>
                  {formData.timeSlots?.map((timeSlot, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <FlatCard title={`Behandlung ${index + 1}`}>
                        <React.Fragment key={index}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <LabelItem label="Start" />
                              <DateTimePicker
                                label="DD.MM.YYYY"
                                value={timeSlot.start ? new Date(timeSlot.start) : null}
                                onChange={(value) => handleTimeSlotChange(index, "start", value)} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <LabelItem label="End" />
                              <DateTimePicker
                                label="DD.MM.YYYY"
                                value={timeSlot.end ? new Date(timeSlot.end) : null}
                                onChange={(value) => handleTimeSlotChange(index, "end", value)}
                                sx={{ height: "calc(100% / 40)" }} />
                            </Grid>

                            <LabelItem label="Pauschale" />
                            <Grid item xs={12} md={6}>
                              <Controls.Select
                                name="visitFee"
                                value={timeSlot?.visitFee?.abbreviation || "keine"}
                                onChange={(e: any) => {
                                  const selectedValue = e.target.value === "keine" ? null : e.target.value;
                                  handleDropDownMenuChange(
                                    index,
                                    selectedValue,
                                    (timeSlot.therapyRx?.therapy as any)?.tarifGroup?.id
                                  );
                                } }
                                disabled={formData?.address?.type?.toLowerCase() === "praxis"}
                                options={visitFeeOptions}
                                style={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                  height: "unset",
                                }} />
                              {warningMessage && (
                                <Grid item xs={12}>
                                  <Typography
                                    sx={{
                                      color: mobiliTheme.palette.warning.dark,
                                      fontSize: "14px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Keine Pauschale für Hausbesuch angegeben
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>

                            <LabelItem label="Rezept" />
                            <Grid item xs={12} md={6}>
                              <ValueItem value={timeSlot.therapyRx?.rx?.rxNumber} />
                            </Grid>

                            <LabelItem label="Therapeut" />
                            <Grid item xs={12} md={6}>
                              <ValueItem
                                value={`${timeSlot?.user?.firstName || "-"} ${timeSlot?.user?.lastName || "-"}`} />
                            </Grid>

                            <LabelItem label="Behandlung" />
                            <Grid item xs={12} md={6}>
                              <ValueItem
                                value={`${(timeSlot?.therapyRx?.therapy as any)?.abbreviation || "-"}`} />
                            </Grid>

                            <LabelItem label="VisitType" />
                            <Grid item xs={12} md={6}>
                              <ValueItem value={`${formData.address?.type || "-"}`} />
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      </FlatCard>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <AppointmentAddress
              open={openAddressDialog}
              onClose={() => setOpenAddressDialog(false)}
              patientId={patientId}
              appointment={formData}
              setAppointmentsListAddressUpdate={setAppointmentsListAddressUpdate}
              appointmentsListAddressUpdate={appointmentsListAddressUpdate}
              updateMultipleAppointmentsAddress={updateMultipleAppointmentsAddress} />
          </FlexListContainer><Card
            sx={{
              flex: "0 1 30%", // Allow Card to take 30% of the height
              overflowY: "auto", // Enable vertical scrolling
              marginTop: "5px", // Add space between FlexListContainer and Card
            }}
          >
              <Tabs
                TabScrollButtonProps={{ sx: { height: "0px" } }} // This hides the scroll buttons To check any other effective way
                orientation="horizontal"
                value={selectedTabs}
                onChange={handleTabChange}
                aria-label="sub-tabs"
                sx={{ paddingLeft: mobiliTheme.topLevelPadding }}
              >
                <Tab iconPosition="start" label="Notes" value={1} />
                <Tab
                  iconPosition="start"
                  label="Tickets"
                  value={2}
                  sx={{
                    marginRight: "4px",
                  }} />
                <Tab iconPosition="start" label="History" value={3} />
              </Tabs>
              {(() => {
                switch (selectedTabs) {
                  case 1:
                    return (
                      <Notes
                        notes={appointmentNotes}
                        setNotes={handleSetNotes}
                        maxVisibleNotes={appointmentNotes.length} />
                    );
                  case 2:
                    return (
                      <>
                        {isLoading && <CircularProgress />}
                        {!isLoading && ticketData && ticketData.length === 0 && (
                          <Typography>Keine Tickets gefunden</Typography>
                        )}
                        {!isLoading && ticketData && ticketData.length > 0 && (
                          <TicketsTable
                            handleRowClick={() => console.log("jaj")}
                            ticketsData={ticketData} />
                        )}
                      </>
                    );
                  case 3:
                    return <Typography>History content goes here</Typography>; // Render the History component or content here
                  default:
                    return null; // Handle unexpected tab value
                }
              })()}
            </Card></>}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="text" onClick={handleCancel } disabled={isLoading}>
            Abbrechen
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained" disabled={isLoading}>
            Speichern
          </Button>
          <Button onClick={()=>setOpenAddressDialog(!openAddressDialog)} color="primary" variant="contained" disabled={isLoading}>
          Mehrere Termine aktualisieren
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default AppointmentEditCard;
