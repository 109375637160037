export const visitTypeReverseMapping: { [key: string]: string } = {
  'hm': 'Heim',
  'hb': 'Haus',
  'px':'Praxis'
  // Add more mappings as needed
};

export const handleParseInput = (input: string, field?: string) => {
  // const parts:any = input.split(/(hb|hm|HB|HM|PX|px|x\s*\d+(?:-\d+)?|\d+\s*|ml\d+)/i)
    if(field==="frequency"){
      input=input.concat("x")
    }
    // const parts :any= input.split(/(hb|hm|HB|HM|PX|px|s*\d+(?:-\d+)?\s*\x|\d+\s*|ml\d+|[A-Za-z]+\d+)/i)
    // .map(part => part.trim())
    // .filter(Boolean);
    //   console.log('PARTS',parts)


    // Match all possible parts: numbers, special codes, alphanumeric sequences with slashes, and numbers followed by x
    const parts :any= input.match(/(\d+-\d+x|\d+x|\d+|HB|HM|PX|hb|hm|px|[A-Za-z]+[A-Za-z0-9/]*|[A-Za-z]+)/g);
    
    // Final result array
    const finalParts = [];
    
    // Iterate through the parts
    let i = 0;
    while (i < parts.length) {
      const part = parts[i];
    
      // Step 1: Handle special codes (HB, HM, PX)
      if (["HB", "HM", "PX", "hb", "hm", "px"].includes(part)) {
        finalParts.push(part); // Add special code as a separate unit
        i++; 
      }
      // Step 2: Handle numbers or number ranges followed by 'x' (e.g., '1x', '2x', '1-2x')
      else if (/\d+x/.test(part) || /\d+-\d+x/.test(part)) {
        finalParts.push(part); // Add the number/range with 'x' as a separate unit
        i++;  // Move to next part
      }
      // Step 3: If it's a standalone number (e.g., '6', '8')
      else if (/^\d+$/.test(part)) {
        finalParts.push(part); // Add the standalone number as a separate unit
        i++; 
      }
      // Step 4: For alphanumeric sequences, including slashes (e.g., 'SM/MT/PT', 'KG VGN', 'KGN BO2')
      else if (/[A-Za-z]+[A-Za-z0-9/]*$/.test(part)) {
        if (i + 1 < parts.length && /[A-Za-z]/.test(parts[i + 1]) && !/\d+x/.test(parts[i + 1]) && !["HB", "HM", "PX"].includes(parts[i + 1])) {
          // If next part is another alphanumeric sequence, we merge them
          finalParts.push(part + " " + parts[i + 1]);
          i += 2; // Skip the next part as it’s combined with the current part
        } else {
          finalParts.push(part); // Otherwise, keep the alphanumeric sequence as a single unit
          i++;
        }
      }
      // Step 5: If none of the above conditions are met, treat the part as a separate unit
      else {
        finalParts.push(part);
        i++; 
      }
    }
    
    console.log(finalParts);
    
    
    
    console.log('PARTS',finalParts)
      const result:any = {};
      let therapyIndex = 0;
      let mengeIndex = 0;
      let frequencyIndex = 0;
      let frequencyValues: string[] = []; // Store frequency values
    
      for (let i = 0; i < finalParts.length; i++) {
        if (isNaN(finalParts[i])) {
          if (finalParts[i].toLowerCase().includes('hb') || finalParts[i].toLowerCase().includes('hm') || finalParts[i].toLowerCase().includes('px')) {
            result['visitType'] = finalParts[i].toLowerCase();
          }
          // else if (parts[i].startsWith('x')) {
          //   frequencyValues.push(parts[i].substring(1)); // Store frequency values
          // } 
           else if (finalParts[i].endsWith('x')) {
            frequencyValues.push(finalParts[i].split('x')[0].trim()); // Store frequency values
          }
           else {
            const therapyKey = `therapy${therapyIndex === 0 ? '' : therapyIndex}`;
            result[therapyKey] = finalParts[i].trim();
            therapyIndex++;
          }
        } else {
          const mengeKey = `amount${mengeIndex === 0 ? '' : mengeIndex}`;
          result[mengeKey] = finalParts[i].trim();
          mengeIndex++;
        }
      }
    
      // Assign frequency values to therapies based on the number of frequency values
      if (!result['visitType']) {
        result['visitType'] = 'px';
      }
    
      if (frequencyValues.length === 1) {
        // If only one frequency value, assign it to all therapies
        for (let i = 0; i < therapyIndex; i++) {
          const frequencyKey = `frequency${i === 0 ? '' : i}`;
          result[frequencyKey] = frequencyValues[0];
        }
      } else {
        // If multiple frequency values, assign based on therapy index
        for (let i = 0; i < therapyIndex; i++) {
          const frequencyKey = `frequency${i === 0 ? '' : i}`;
          result[frequencyKey] = frequencyValues[i] || frequencyValues[0]; // Use the corresponding frequency value or the first value
        }
      }
        // Set isHomeVisit based on visitType
  if (result["visitType"] === "hm" || result["visitType"] === "hb") {
    result["isHomeVisit"] = true;
  } else if (result["visitType"] === "px") {
    result["isHomeVisit"] = false;
  } else {
    result["isHomeVisit"] = null;
  }

    
      console.log('Result', result);
      return result;
    };

    export const treatmentToRows=(input:any)=> {
      console.log("Input",input)
      const grouped:any = [];

      // Iterate through the input object
      for (const key in input) {
          const match = key.match(/(\D+)(\d*)$/); // Regex to match non-digit characters and optional digits at the end of the key
          if (match) {
              const [, property, suffix] = match;
              const suffixKey = suffix || "0"; // If no suffix, use "0" as key
              grouped[suffixKey] = grouped[suffixKey] || { visitType: input.visitType,isHomeVisit:input.isHomeVisit }; // Include visitType
              grouped[suffixKey][property] = input[key];
          }
      }
       // Remove the 'id' property from each object
    for (const key in grouped) {
      if (grouped[key].hasOwnProperty('id')) {
          delete grouped[key].id;
      }
  }
      return grouped;
  }

    
    