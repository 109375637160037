import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import useStore from "../../../helpers/useStore";
import { AddressDtoTypeEnum, AppointmentDto, AppointmentQueryFilters, TherapyDetailsDto, UpdateAppointmentDto } from "../../../api-client";
import TerminView from "../../PatientOverview/RxInfo/TerminView";
import { TerminViewConstants } from "../../PatientOverview/TerminView";
import Controls from "../../../atoms/Controls";
import { observer } from "mobx-react";

interface AppointmentAddressDialogProps {
  open: boolean;
  onClose: () => void;
  patientId: number;
  appointment: UpdateAppointmentDto;
  setAppointmentsListAddressUpdate: React.Dispatch<React.SetStateAction<string[]>>;
  appointmentsListAddressUpdate: string[];
  updateMultipleAppointmentsAddress: (visitFee:TherapyDetailsDto | null) => void;
}

const AppointmentAddress: React.FC<AppointmentAddressDialogProps> = observer(
  ({
    open,
    onClose,
    patientId,
    appointment,
    setAppointmentsListAddressUpdate,
    appointmentsListAddressUpdate,
    updateMultipleAppointmentsAddress,
  }) => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [appointments, setAppointments] = useState<AppointmentDto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [saveAppointments, setSaveAppointments] = useState(false);
    const [updateVisitFee, setUpdateVisitFee] = useState(true);

    const initialFilters: AppointmentQueryFilters = {
      showOnlyFutureAppointments: false,
      showOnlyActiveRxs: false,
      showLastThreeMonths: false,
      rxIds: [],
    };

    const AppointmentStore = useStore(AppointmentContext);
    const {
      getAppointmentsForPatientWithRx,
      getAppointmentsForPatient,
      getFilteredAppointmentsForPatient,
    } = AppointmentStore;

    const handleSelection = async (option: string) => {
      setSelectedOption(option);
      setAppointments([]); // Reset appointments when changing option
      setAppointmentsListAddressUpdate([]); // Reset selected appointments list
      setLoading(true);
      try {
        let data: AppointmentDto[] = [];
        if (option === "single") {
          handleClose();
        }

        if (option === "rx") {
          const rxIds = appointment?.timeSlots?.map((t: any) => t.therapyRx?.rx?.id);
          data = (await getFilteredAppointmentsForPatient(
            patientId,
            { ...initialFilters, rxIds },
            false
          )) as any;
        } else if (option === "future") {
          data = (await getFilteredAppointmentsForPatient(
            patientId,
            { ...initialFilters, showOnlyFutureAppointments: true,start:appointment?.start },
            false
          )) as any;
        } else if (option === "select") {
          data = (await getAppointmentsForPatient(patientId as unknown as string)) as any;
        }
        // Automatically populate the list for "rx", "future", etc.
        if (option !== "select") {
          setAppointmentsListAddressUpdate(data.map((appointment: any) => appointment.id));
        }

        setAppointments(data);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setLoading(false);
      }
    };

    const handleClose = () => {
      setSelectedOption(null);
      setAppointments([]);
      setAppointmentsListAddressUpdate([]);
      setUpdateVisitFee(false);
      onClose();
    };

    const appointmentsUpdateLists = async () => {
      console.log("ajsjsididoidood",appointment,updateVisitFee)
      // const appointmentIds = appointmentsListAddressUpdate?.map((appointment: any) => appointment.id);
      // setAppointmentsListAddressUpdate(appointmentIds);
      // setSaveAppointments(true);
      let visitFee = null;
    
      if (updateVisitFee) {
        visitFee = appointment?.timeSlots?.find((t) => t.visitFee)?.visitFee || null;
      }
      updateMultipleAppointmentsAddress(visitFee);
      setSaveAppointments(false);
      handleClose();
    };

    //   useEffect(() => {
    //     if (appointmentsListAddressUpdate.length > 0 && saveAppointments) {
    //       updateMultipleAppointmentsAddress();
    //       setSaveAppointments(false)
    //       handleClose();
    //     }
    //   }, [saveAppointments]);

    const options = [
      { id: "single", label: "Nur diesen Termin" },
      { id: "rx", label: "Alle Termine mit diesem Rezept" },
      { id: "future", label: "Alle zukünftigen Termine für diesen Patienten" },
      { id: "select", label: "Termine manuell auswählen" },
    ];

    return (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Termine aktualisieren</DialogTitle>
        <DialogContent>
          <Typography sx={{ fontSize: "18px", mb: 2 }}>
            Wie möchten Sie die Termine aktualisieren?
          </Typography>

          <Controls.RadioGroup
            name="appointmentUpdate"
            label=""
            value={selectedOption}
            onChange={(e: any) => handleSelection(e.target.value)}
            options={options}
          />

          {/* Dropdown Section */}
          {selectedOption && (
            <Accordion expanded={!!selectedOption} sx={{ mt: 2 }}>
              <AccordionDetails>
                <Controls.Checkbox
                  value={updateVisitFee}
                  onChange={(e: any) => {
                    setUpdateVisitFee(e.target.value);
                  }}
                  label={
                    <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    Besuchsgebühr aktualisieren - {appointment?.timeSlots?.find((t) => t.visitFee)?.visitFee?.abbreviation ?? "keine"}
                  </Typography>
                  
                  }
                  // disabled={appointment.address?.type === AddressDtoTypeEnum.Praxis}
                />
                {loading ? (
                  <CircularProgress />
                ) : appointments.length > 0 ? (
                  <Box sx={{ width: "50%",margin:"0px" }}>
                    <TerminView
                      appointments={appointments}
                      patientId={patientId}
                      isView={TerminViewConstants.ALL_APPOINTMENTS}
                      dataRefreshFlag={loading}
                      setDataRefreshFlag={setLoading}
                      isSelectionMode={selectedOption === "select" ? true : false}
                      selectedAppointments={appointmentsListAddressUpdate}
                      setSelectedAppointments={setAppointmentsListAddressUpdate}
                    />
                  </Box>
                ) : (
                  <Typography>Keine Termine gefunden.</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="text">
            Abbrechen
          </Button>
          {selectedOption && appointments.length > 0 && (
            <Button onClick={appointmentsUpdateLists} variant="contained">
              Speichern
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

export default AppointmentAddress;
