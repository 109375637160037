import { Box, Card, CardContent, CardHeader, Grid, Paper, Tooltip, Typography } from "@mui/material";
import CumulativePatientsPlot from "./components/CumulativePatientsPlot";
import NewPatientsPlot from "./components/NewPatientsPlot";
import {RxOngoingCountsPlot, RxSettledCountsPlot} from "./components/RxStatusCountsPlot";
import {RxOngoingValuesPlot, RxSettledValuesPlot} from "./components/RxValuesPlot";
import ScheduleBasedGapsPlot from "./components/ScheduleBasedGapsPlot";
import TherapistCollapsibleTable from "./components/TherapistTable";
import usePatientStats from "./hooks/usePatientStats";
import { useEffect, useState } from "react";
import useRxStats from "./hooks/useRxStats";
import RxCountsPlot from "./components/RxCountsPlot";
import useScheduleBasedStats from "./hooks/useScheduleBasedStats";
import useTherapistStats from "./hooks/useTherapistStats";
import ScheduleBasedApptValuesPlot from "./components/ScheduleBasedApptValuesPlot";
import BinTypeSelector from "./components/BinTypeSelector";
import BIDateRangePicker from "./components/DateRangePicker";
import './components/BIDashboard.css'
import LoadingSpinner from "./components/LoadingSpinner";
import { addDays, addMonths } from "date-fns";
import PlotSelector from "./components/PlotSelector";


export const BIDashboardPage = () => {
  const [selectPatientPlot, setSelectPatientPlot] = useState<string>('cumulative patients');
  const [selectRxPlot, setSelectRxPlot] = useState<string>('all rx count');
  const [selectScheduleBasedPlot, setSelectScheduleBasedPlot] = useState<string>('all rx count');

  const [patientBinType, setPatientBinType] = useState<'week' | 'month'>('week');
  const [rxBinType, setRxBinType] = useState<'week' | 'month'>('week');
  const [scheduleBinType, setScheduleBinType] = useState<'day' | 'week'>('day');

  const today = new Date();
  const [scheduleStartDate, setScheduleStartDate] = useState<Date>(new Date(new Date().setDate(today.getDate() - 30)));
  const [scheduleEndDate, setScheduleEndDate] = useState<Date>(today);
  const [therapistStartDate, setTherapistStartDate] = useState<Date>(new Date(new Date().setDate(today.getDate() - 30)));
  const [therapistEndDate, setTherapistEndDate] = useState<Date>(today);

  const { patientCumulativeResults, patientCountResults, isLoading: isPatientLoading } = usePatientStats(patientBinType); 
  const { totalRxCountResults, rxStatusCountResults, totalRxValueResults, isLoading: isRxLoading } = useRxStats(rxBinType);
  const { totalScheduleBasedRxCountResults, totalApptValueResults, totalGapsResults, isLoading: isScheduleLoading } = useScheduleBasedStats(scheduleBinType, scheduleStartDate, scheduleEndDate);
  const { therapistData } = useTherapistStats(therapistStartDate, therapistEndDate);

 
  return (
    <Grid className="biDashboard" container spacing={2} sx={{ px: 1.2, height: "calc(100vh - 80px)", overflowY: "auto" }}>
      <Grid item xs={6} >
        <Card>
          <CardHeader title="Patient Stats" />
          <CardContent>
            <Paper className="custom-paper">
              <Box sx={{ minHeight: "45px", display: "flex", justifyContent: "space-between", alignItems: "center", px: 4, pt: 0.5, pb: 0, mb: 0 }}>
                <PlotSelector
                  plotType={selectPatientPlot}
                  onChange={setSelectPatientPlot}
                  availableOptions={['cumulative patients', 'new patients']}
                  optionLabels={{
                    'cumulative patients': 'Kumulative Anzahl der Patienten',
                    'new patients': 'Anzahl neuer Patienten',
                  }}
                />
                <BinTypeSelector
                  binType={patientBinType} 
                  onChange={setPatientBinType} 
                  availableOptions={['week', 'month']} 
                  sx={{ mb: 0, pt: 0.5, pb: 0 }}
                />          
              </Box>
              <Box sx={{ position: "relative", height: "400px", display: "flex", justifyContent: "center", alignItems: "center", pt: 0, mt: 0 }}>
                {selectPatientPlot === 'cumulative patients' && <CumulativePatientsPlot cumulativeData={patientCumulativeResults} />}
                {selectPatientPlot === 'new patients' && <NewPatientsPlot countData={patientCountResults} />}
                {isPatientLoading && <LoadingSpinner />}
              </Box> 
            </Paper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} >
        <Card>
          <CardHeader title="Rx Stats" />
          <CardContent>
            <Paper className="custom-paper">
              <Box sx={{ minHeight: "45px", display: "flex", justifyContent: "space-between", alignItems: "center", px: 4, pt: 0.5, pb: 0, mb: 0 }}>
                <PlotSelector
                  plotType={selectRxPlot}
                  onChange={setSelectRxPlot}
                  availableOptions={['all rx count', 'rx ongoing count', 'rx settled count', 'rx ongoing value', 'rx settled value']}
                  optionLabels={{
                    'all rx count': 'Anzahl der Verordnungen (alle)',
                    'rx ongoing count': 'Anzahl der Verordnungen (in Behandlung)',
                    'rx settled count': 'Anzahl der Verordnungen (abgerechnet)',
                    'rx ongoing value': 'Gesamtwert der Verordnungen (in Behandlung)',
                    'rx settled value': 'Gesamtwert der Verordnungen (abgerechnet)',
                  }}
                />
                {selectRxPlot !== 'all rx count' && <BinTypeSelector 
                  binType={rxBinType} 
                  onChange={setRxBinType} 
                  availableOptions={['week', 'month']} 
                  sx={{ mb: 0, pt: 0.5, pb: 0 }}
                />}
              </Box>
              <Box sx={{ position: "relative", height: "400px", display: "flex", justifyContent: "center", alignItems: "center", pt: 0, mt: 0 }}>
                {selectRxPlot === 'all rx count' && <RxCountsPlot countData={totalRxCountResults} />}
                {selectRxPlot === 'rx ongoing count' && <RxOngoingCountsPlot countData={rxStatusCountResults} />}
                {selectRxPlot === 'rx settled count' && <RxSettledCountsPlot countData={rxStatusCountResults} />}
                {selectRxPlot === 'rx ongoing value' && <RxOngoingValuesPlot rxValueData={totalRxValueResults} />}
                {selectRxPlot === 'rx settled value' && <RxSettledValuesPlot rxValueData={totalRxValueResults} />}
                {isRxLoading && <LoadingSpinner />}
              </Box>  
            </Paper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} >
        <Card>
          <CardHeader title="Schedule Stats" />
          <CardContent>
            <Paper className="custom-paper">
              <Box sx={{ minHeight: "45px", display: "flex", justifyContent: "space-between", alignItems: "center", px: 4, pt: 0.7, pb: 0, mb: 0 }}>
                <PlotSelector
                  plotType={selectScheduleBasedPlot}
                  onChange={setSelectScheduleBasedPlot}
                  availableOptions={['all rx count', 'total appointment value', 'total gaps']}
                  optionLabels={{
                    'all rx count': 'Anzahl der Verordnungen',
                    'total appointment value': 'Gesamtwert der Termine',
                    'total gaps': 'Total gaps',
                  }}
                />
                <BIDateRangePicker
                  startDate={scheduleStartDate}
                  endDate={scheduleEndDate}
                  onChange={({ startDate, endDate }) => {
                    console.log('Date range changed:', startDate, endDate);
                    setScheduleStartDate(startDate)
                    setScheduleEndDate(endDate)}}
                  binType={scheduleBinType}         
                />
                <BinTypeSelector 
                  binType={scheduleBinType} 
                  onChange={setScheduleBinType} 
                  availableOptions={['day', 'week']} 
                  sx={{ mb: 0, pt: 0.5, pb: 0 }}
                />
              </Box>
              <Box sx={{ position: "relative", height: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {selectScheduleBasedPlot === 'all rx count' && <RxCountsPlot countData={totalScheduleBasedRxCountResults} />}
                {selectScheduleBasedPlot === 'total appointment value' && <ScheduleBasedApptValuesPlot scheduleValueData={totalApptValueResults} />}
                {selectScheduleBasedPlot === 'total gaps' && <ScheduleBasedGapsPlot scheduleGapData={totalGapsResults} />}
                {isScheduleLoading && <LoadingSpinner />}
              </Box>
            </Paper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} >
        <Card>
          <CardHeader title="Therapist Stats" />
          <CardContent>
            <BIDateRangePicker 
              startDate={therapistStartDate} 
              endDate={therapistEndDate} 
              onChange={({startDate, endDate}) => {
                setTherapistStartDate(startDate)
                setTherapistEndDate(endDate)}}
            />
            <TherapistCollapsibleTable therapistData={therapistData} />
          </CardContent>
        </Card>  
      </Grid>
    </Grid>
  );
};
