import {
  Box,
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { FC } from "react";
import { MoreVert } from "@mui/icons-material";
import React from "react";
import { useState } from "react";
import useStore from "../../../../../helpers/useStore";
import { TransactionsContext } from "../../../../../stores/Transactions/Transactions.provider";
import { CreateInsuranceTransactionDto, PayerDto } from "../../../../../api-client";
import { transactionMethods } from "../../../Kasse/types/kasse.types";
import notificationStore from "../../../../../stores/Notification/notificationStore";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VerifiedIcon from "@mui/icons-material/Verified";

interface RxDetailsState {
  rxData: any[]; // Array of RxDto
  totalPrice: number; // Total price
}

interface BillInfoProps {
  handleFullScreenDialogClose: () => void;
  onRowClick: (rx: any) => void;
  account: string;
  payerIk: string;
  userName: string;
  userId: string;
  onRxDelete: (deletedRxId: string) => void;
}

const BillInfo: FC<BillInfoProps> = ({
  handleFullScreenDialogClose,
  onRowClick,
  account,
  payerIk,
  userName,
  userId,
  onRxDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRx, setSelectedRx] = useState<any | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [openSingleRxDialog, setOpenSingleRxDialog] = useState(false);
  const [rxNumberInput, setRxNumberInput] = useState("");
  const [rxNumberError, setRxNumberError] = useState("");

  const [tableVisible, setTableVisible] = useState(false);
  const TransactionStore = useStore(TransactionsContext);
  const {
    getRxsForIndirectBillsForInsurance,
    getBillingCentersFromPayer,
    createTransaction,
    getSingleRxForIndirectBillsForInsurance,
  } = TransactionStore;
  const [rxDetails, setRxDetails] = useState<RxDetailsState>({
    rxData: [], // Initialize rxData as an empty array
    totalPrice: 0,
  });

  const [payer, setPayer] = useState<PayerDto>();
  const today = new Date();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, rx: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRx(rx);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle delete actions
  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
    handleMenuClose();
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setSelectedRx(null);
  };

  const handleDeleteConfirm = () => {
    if (!selectedRx) return;

    // Update the rxDetails state to remove the selected RX
    setRxDetails((prevState) => {
      const updatedRxData = prevState.rxData.filter((rx) => rx.id !== selectedRx.id);
      const updatedTotalPrice = prevState.totalPrice - (selectedRx.price || 0);

      return {
        rxData: updatedRxData,
        totalPrice: updatedTotalPrice,
      };
    });

    // Notify the parent component
    onRxDelete(selectedRx.id); // Call the callback with the deleted RX ID

    notificationStore.showMessage("Rezept erfolgreich entfernt", "success");

    // Reset selected RX and close dialog
    setSelectedRx(null);
    setOpenDeleteDialog(false);
  };

  const handleAddSingleRx = async () => {
    if (!rxNumberInput.trim()) {
      setRxNumberError("Bitte geben Sie eine Rezept-Nummer ein");
      return;
    }

    try {
      // Fetch the single RX
      const rxInfo = await getSingleRxForIndirectBillsForInsurance(rxNumberInput.trim());

      if (rxInfo && rxInfo.rx) {
        const newRx = rxInfo.rx;

        // Check if the RX is already in the list
        const rxExists = rxDetails.rxData.some((rx) => rx.id === newRx.id);
        if (rxExists) {
          notificationStore.showMessage("Dieses Rezept ist bereits auf der Liste", "warning");
        } else {
          // Update the rxDetails state
          setRxDetails((prevState) => {
            const updatedTotalPrice = prevState.totalPrice + (newRx.price || 0);

            // Add the new RX to the rxData array
            const updatedRxData = [...prevState.rxData, newRx];

            return {
              rxData: updatedRxData,
              totalPrice: updatedTotalPrice,
            };
          });

          notificationStore.showMessage("Rezept erfolgreich hinzugefügt", "success");
        }
      } else {
        notificationStore.showMessage("Rezept nicht gefunden", "error");
      }

      // Close the dialog and reset input
      setOpenSingleRxDialog(false);
      setRxNumberInput("");
      setRxNumberError("");
    } catch (error) {
      console.error("Error fetching single RX:", error);
      notificationStore.showMessage("Rezept nicht gefunden", "error", error);
    }
  };

  const handleLoadAll = async () => {
    setTableVisible(true);
    try {
      const payers = await getBillingCentersFromPayer(payerIk, account);
      setPayer(payers);

      const info = await getRxsForIndirectBillsForInsurance(account);
      setRxDetails(info);
    } catch (error) {
      console.error("Error fetching RX details:", error);
    }
  };

  const totalPrice = rxDetails.totalPrice.toFixed(2);
  const rxs: string[] = rxDetails.rxData.map((rx: any) => rx.id);
  const paperCenter = payer?.paperReceivingCenter?.ik;
  const digitalCenter = payer?.digitalReceivingCenter?.ik;
  const payerId = payerIk;

  const dataSubmission: CreateInsuranceTransactionDto = {
    payerId: payerId as string,
    accountId: account as string,
    amount: Number(totalPrice),
    userId: userId,
    method: transactionMethods.VERSICHERUNG,
    rxIds: rxs,
    paperCenterId: paperCenter ? paperCenter : "",
    dataCenterId: digitalCenter ? digitalCenter : "",
    notes: "",
    billType: "GKV",
  };

  const handleSubmission = async () => {
    try {
      await createTransaction(dataSubmission);
      handleFullScreenDialogClose();
    } catch (error) {
      console.error("Error Creating the Transaction", error);
      notificationStore.showMessage("Error Creating the Transaction", "error", error);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {/* Header Buttons */}
      <Box sx={{ padding: 1 }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
        >
          <Grid
            item
            xs={2}
            className="header-button-container"
          >
            <Button
              className="button"
              endIcon={<FileDownloadIcon />}
              onClick={handleLoadAll}
            >
              Alle Laden
            </Button>
          </Grid>
          <Grid
            item
            xs={2.8}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Button
              className="button"
              endIcon={<AddIcon />}
              onClick={() => setOpenSingleRxDialog(true)}
            >
              Rezept hinzufügen
            </Button>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Button
              className="button"
              endIcon={<VerifiedIcon />}
            >
              Validierung
            </Button>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Button
              className="button"
              endIcon={<SaveIcon />}
              onClick={handleSubmission}
            >
              Speichern
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={openSingleRxDialog}
        onClose={() => setOpenSingleRxDialog(false)}
        aria-labelledby="single-rx-dialog-title"
      >
        <DialogTitle id="single-rx-dialog-title">Geben Sie die Rx-Nummer ein</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="RX Number"
            type="text"
            fullWidth
            value={rxNumberInput}
            onChange={(e) => setRxNumberInput(e.target.value)}
            error={Boolean(rxNumberError)}
            helperText={rxNumberError}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenSingleRxDialog(false)}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            onClick={handleAddSingleRx}
            color="primary"
          >
            Einreichen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Bill Info Section */}
      {tableVisible && (
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ border: "1px solid", borderRadius: "12px", mt: 1 }}>
            <Box sx={{ backgroundColor: "white", borderRadius: "12px 12px 0 0" }}>
              <Typography
                variant="h6"
                align="center"
              >
                Bill Info
              </Typography>
            </Box>

            <Box sx={{ m: 1 }}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <Grid
                  item
                  xs={3}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    align="center"
                  >
                    Payer
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{payer ? payer.name : ""}</Typography>
                  </Box>
                </Grid>
                {payer?.paperReceivingCenter && (
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      align="center"
                    >
                      Paper Center
                    </Typography>
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body2">{payer.paperReceivingCenter.name}</Typography>
                    </Box>
                  </Grid>
                )}
                <Grid
                  item
                  xs={3}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    align="center"
                  >
                    Account
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{account}</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    align="center"
                  >
                    Value
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{totalPrice} €</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    align="center"
                  >
                    Start Date
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{today.toDateString()}</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    align="center"
                  >
                    User
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">{userName}</Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    align="center"
                  >
                    Exported
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2">No</Typography>
                  </Box>
                </Grid>
              </Grid>
              {rxDetails.rxData.length > 0 ? (
                <TableContainer
                  component={Paper}
                  sx={{
                    borderRadius: "12px",
                    boxShadow: 3,
                    maxHeight: "505px",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#f1f1f1",
                      borderRadius: "8px", // Ensure track follows the rounded corners
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                      borderRadius: "8px", // Make scrollbar thumb rounded
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#555",
                    },
                  }}
                >
                  <Table stickyHeader>
                    <TableHead sx={{ backgroundColor: "#f0f0f0", zIndex: 1 }}>
                      <TableRow>
                        <TableCell sx={{ backgroundColor: "#f0f0f0" }}>Patient Name</TableCell>
                        <TableCell sx={{ backgroundColor: "#f0f0f0" }}>Prescription</TableCell>
                        <TableCell sx={{ backgroundColor: "#f0f0f0" }}>Validation</TableCell>
                        <TableCell sx={{ backgroundColor: "#f0f0f0" }}>Booking</TableCell>
                        <TableCell sx={{ backgroundColor: "#f0f0f0" }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rxDetails.rxData.map((row, index) => {
                        const allValid =
                          row.formvalidation &&
                          Object.values(row.formvalidation).every(
                            (validation: any) => validation.validity === "Valid"
                          );
                        console.log(row);
                        return (
                          <TableRow
                            key={index}
                            hover
                            sx={{ "&:hover": { backgroundColor: "#e0f7fa" }, cursor: "pointer" }}
                            onClick={() => onRowClick(row)}
                          >
                            <TableCell>
                              <Typography>
                                {row.patient?.firstName + " " + row.patient?.lastName}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: "4px 0 4px",
                                  border: "1px solid #ccc",
                                  borderRadius: "6px",
                                  backgroundColor: "#f9f9f9",
                                  width: "300px",
                                }}
                              >
                                <Grid
                                  container
                                  spacing={1}
                                >
                                  <Grid
                                    item
                                    xs={3}
                                  >
                                    <Typography>{row.rxNumber}</Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{ display: "flex", justifyContent: "flex-end" }}
                                  >
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {row.date}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={5}
                                    sx={{ display: "flex", justifyContent: "flex-end" }}
                                  >
                                    {row.therapyRxs.map((therapyRx: any, i: any) => (
                                      <Box
                                        key={i}
                                        sx={{
                                          display: "inline-block",
                                          bgcolor: "purple",
                                          color: "white",
                                          padding: "0.2em 0.5em",
                                          borderRadius: 2,
                                          mr: 1,
                                          fontSize: "0.75rem",
                                        }}
                                      >
                                        {therapyRx.therapy.abbreviation}
                                      </Box>
                                    ))}
                                  </Grid>
                                </Grid>
                              </Box>
                            </TableCell>
                            {/* Validation */}
                            <TableCell align="left">
                              {allValid ? (
                                <CheckCircleIcon color="success" />
                              ) : (
                                <CancelIcon color="error" />
                              )}
                            </TableCell>
                            {/* Booking */}
                            <TableCell align="left">
                              <Typography>Not Booked</Typography>
                            </TableCell>
                            {/* Actions */}
                            <TableCell align="center">
                              <IconButton onClick={(event) => handleMenuOpen(event, row)}>
                                <MoreVert />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  variant="h6"
                  align="center"
                >
                  Keine Rechnungsrezepte zum Versenden an die {payer?.name} bereit.
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 1,
                  padding: 1,
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                  boxShadow: 1,
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ mr: 2 }}
                >
                  Notes
                </Typography>
                <TextField
                  placeholder="Add notes..."
                  multiline
                  rows={1}
                  variant="outlined"
                  fullWidth
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Löschung bestätigen</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Möchten Sie diesen RX wirklich aus der Liste löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteCancel}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="secondary"
            autoFocus
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Menu for Actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>Löschen</MenuItem>
      </Menu>
    </Box>
  );
};

export default BillInfo;
