import { FormControl, MenuItem, Select, SxProps, Tooltip } from '@mui/material';

interface BinTypeSelectorProps<T> {
  binType: T; 
  onChange: (value: T) => void;
  availableOptions: T[];
  sx?: SxProps;
}

const BinTypeSelector = <T extends string>({ binType, onChange, availableOptions, sx }: BinTypeSelectorProps<T>) => {
  return (
    <Tooltip describeChild title="Datenansicht ändern" placement="top" slotProps={{
      popper: {
        modifiers: [{
          name: 'offset',
          options: {
            offset: [0, -15],
          },
        }],
      },
    }}>
      <FormControl sx={{ margin: '0px', padding: '0px', ...sx }}>
        <Select value={binType} onChange={(e) => onChange(e.target.value as T)} sx={{ fontSize: '12px', fontFamily: 'Inter', position: 'relative', margin: '0px', padding: '0px', '.MuiOutlinedInput-notchedOutline': { border: 0, alignItems: "center", } }} size="small" autoWidth>
          {availableOptions.includes('day' as T) && <MenuItem value="day" sx={{ fontSize: '12px', fontFamily: 'Inter' }}>Tag</MenuItem>}
          {availableOptions.includes('week' as T) && <MenuItem value="week" sx={{ fontSize: '12px', fontFamily: 'Inter' }}>Woche</MenuItem>}
          {availableOptions.includes('month' as T) && <MenuItem value="month" sx={{ fontSize: '12px', fontFamily: 'Inter' }}>Monat</MenuItem>}
        </Select>
      </FormControl>
    </Tooltip>
  );
};

export default BinTypeSelector;