import React, { FC, useEffect, useMemo, useState } from "react";
import { TopLevelPaper } from "../../../../themes/StyledComponents";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import {
  AllTransactionMethods,
  paymentStatus,
  recnungTransactionMethods,
} from "../types/kasse.types";
import useStore from "../../../../helpers/useStore";
import { TransactionsContext } from "../../../../stores/Transactions/Transactions.provider";
import { useTransaction } from "../hooks/useTransaction";
import { InsuranceBillsPagination } from "../../Insurance/Base/components/InsuranceBillsPagination";
import TransactionTable from "./TransactionTable";
import { UserContext } from "../../../../stores/User/User.provider";
import { GetRechnungDetailsDto, UserDto } from "../../../../api-client";

export const commonStyles = {
  bgcolor: "background.paper",
  border: 0,
  my: 1,
};

interface TransactionsDisplayContainerProps {
  transactionMethod: AllTransactionMethods;
}

const TransactionsDisplayContainer: FC<TransactionsDisplayContainerProps> = ({
  transactionMethod,
}) => {
  const transactionStore = useStore(TransactionsContext);
  const {
    getAllBankTransfer,
    getAllRechnung,
    getTransactionsByType,
    lastUpdate,
    getAllRechnungWithBillReminder,
  } = transactionStore;

  const UserStore = useStore(UserContext);
  const [user, setUser] = useState<UserDto | null>();
  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>(undefined);
  const [showMahnung, setShowMahnung] = useState(false);

  const [showPaidRechnung, setShowPaidRechnung] = useState(false);

  useEffect(() => {
    UserStore.getUser()
      .then((user) => {
        setUser(user);
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  }, [UserStore]);

  useEffect(() => {
    if (user?.location?.accounts?.length) {
      setSelectedAccountId(user.location.accounts[0].id);
    }
  }, [user]);

  const accounts = useMemo(() => user?.location?.accounts || [], [user]);

  const {
    paginatedData,
    allTransaction,
    page,
    setPage,
    limit,
    setLimit,
    searchValue,
    setSearchValue,
    handleStartDateChange,
    handleEndDateChange,
    fetchMahnungEligible,
    fetchAllTransaction,
    currentRange,
    refetchTransactions,
  } = useTransaction({
    getAllBankTransfer,
    getAllRechnung,
    getAllRechnungWithBillReminder,
    getTransactionsByType,
    transactionMethod,
    lastUpdate,
    accountId: selectedAccountId,
  });

  const filteredTransactions = useMemo(() => {
    // If not RECHNUNG, just return the data as-is
    // Because our transaction table is generic and can handle all types of transactions
    if (transactionMethod !== recnungTransactionMethods.RECHNUNG) {
      return allTransaction;
    }
    const rechnungTransactions = allTransaction as GetRechnungDetailsDto[];
    if (!showPaidRechnung) {
      // By default: NOT_PAID or PARTIALLY_PAID rehcnung will be shown
      return rechnungTransactions.filter(
        (t) =>
          t.paymentStatus === paymentStatus.NOT_PAID || t.paymentStatus === paymentStatus.PARTIAL
      );
    } else {
      // If toggle is active: show fully paid
      return rechnungTransactions.filter((t) => t.paymentStatus === paymentStatus.PAID);
    }
  }, [allTransaction, transactionMethod, showPaidRechnung]);

  useEffect(() => {
    if (!selectedAccountId) return;
    if (transactionMethod !== recnungTransactionMethods.RECHNUNG) return;

    if (showMahnung) {
      fetchMahnungEligible();
    } else {
      fetchAllTransaction();
    }
  }, [
    showMahnung,
    fetchMahnungEligible,
    transactionMethod,
    selectedAccountId,
    fetchAllTransaction,
  ]);

  return (
    <TopLevelPaper sx={{ borderRadius: "0 0 12px 12px" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container>
          <Grid
            item
            xs={6}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ height: 60 }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  label="Suche nach Transaktionsnummer oder Betrag"
                  size="small"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  sx={{
                    height: 40,
                    minWidth: 300,
                  }}
                />

                <Button
                  onClick={() => {}}
                  sx={{
                    marginTop: 0.5,
                    height: 40,
                    minWidth: 80,
                  }}
                >
                  Neu
                </Button>
              </Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: "1.6rem",
                  color: "#fff",
                  ml: "auto",
                }}
              >
                Kasse
              </Typography>
            </Box>
          </Grid>

          {/* Right side: Edit selected, date pickers */}
          <Grid
            item
            xs={6}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={1}
              sx={{ height: 60 }}
            >
              {transactionMethod === recnungTransactionMethods.RECHNUNG && (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showPaidRechnung}
                        onChange={() => setShowPaidRechnung((prev) => !prev)}
                      />
                    }
                    label={"Bezahlt"}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showMahnung}
                        onChange={() => setShowMahnung((prev) => !prev)}
                      />
                    }
                    label={"Mahnung"}
                  />
                </>
              )}
              {accounts.length > 0 && (
                <FormControl
                  variant="outlined"
                  size="small"
                >
                  <InputLabel id="account-select-label">Mandant</InputLabel>
                  <Select
                    labelId="account-select-label"
                    label="Account"
                    value={selectedAccountId || ""}
                    onChange={(e) => setSelectedAccountId(e.target.value as string)}
                  >
                    {accounts.map((account) => (
                      <MenuItem
                        key={account.id}
                        value={account.id}
                      >
                        {account.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {/* Date Pickers */}
              <DatePicker
                label="Startdatum"
                value={currentRange.startDate}
                defaultValue={dayjs()}
                onChange={(date) => handleStartDateChange(date)}
                maxDate={currentRange.endDate || dayjs()}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
              <DatePicker
                label="Enddatum"
                value={currentRange.endDate}
                defaultValue={dayjs()}
                onChange={(date) => handleEndDateChange(date)}
                minDate={currentRange.startDate || dayjs()}
                maxDate={dayjs()}
                sx={{
                  "& .MuiInputBase-root": {
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>

        {filteredTransactions.length > 0 ? (
          <>
            <TransactionTable
              transactions={filteredTransactions}
              transactionMethod={transactionMethod}
              onRefresh={refetchTransactions}
            />

            {paginatedData && paginatedData.meta.totalItems > 0 && (
              <InsuranceBillsPagination
                totalItems={paginatedData.meta.totalItems}
                totalPages={paginatedData.meta.totalPages}
                limit={limit}
                page={page}
                onPageChange={setPage}
                onLimitChange={(newLimit) => {
                  setLimit(newLimit);
                  setPage(1);
                }}
              />
            )}
          </>
        ) : (
          <Box
            sx={{
              ...commonStyles,
              maxHeight: "70vh",
              display: "flex",
              flexDirection: "column",
              borderRadius: "12px",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{ flex: 1, overflow: "auto" }}
            >
              <Typography
                margin={2}
                variant="subtitle1"
              >
                Für den ausgewählten Status wurden keine Transaktionen gefunden.
              </Typography>
            </TableContainer>
          </Box>
        )}
      </LocalizationProvider>
    </TopLevelPaper>
  );
};

export default TransactionsDisplayContainer;
