import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import AccountSelectionDialog from "./AccountSelectionDialog";
import useStore from "../../../../../helpers/useStore";
import { UserContext } from "../../../../../stores/User/User.provider";
import { Share, Mail, CurrencyExchange, PriceCheck } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

interface PaymentMethodDialogProps {
  open: boolean;
  handleClose: () => void;
}

const PaymentMethodDialog: FC<PaymentMethodDialogProps> = ({ open, handleClose }) => {
  const [openDialog, setOpenDialog] = useState({
    direct: false,
    indirect: false,
    post: false,
  });

  const UserStore = useStore(UserContext);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    UserStore.getUser()
      .then((user) => {
        setUser(user);
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  }, [UserStore]);

  const userName = user?.firstName + " " + user?.lastName;
  const userID = user?.id;
  const userLocations = user?.location.accounts;

  console.log("User Locations", userLocations);

  const handleOpenDialog = (type: string) => {
    setOpenDialog({ ...openDialog, [type]: true });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Rechnungen an den Kostenträger"}</DialogTitle>
        <DialogContent sx={{ marginTop: 2 }}>
          <Grid
            container
            spacing={1}
            justifyContent={"space-evenly"}
          >
            <Grid item>
              <List>
                <ListItemButton onClick={() => handleOpenDialog("direct")}>
                  <ListItemIcon>
                    <Share />
                  </ListItemIcon>
                  <ListItemText
                    primary="GKV digitale Abrechnung"
                    secondary="Elektronischen Abrechnungsverfahren mit den gesetzlichen Krankenkassen"
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemIcon>
                    <CurrencyExchange />
                  </ListItemIcon>
                  <ListItemText
                    primary="GKV Korrekturverfahren"
                    secondary="Korrekturverfahren für gestzliche Krankenkassen"
                  />
                </ListItemButton>
                <ListItemButton>
                  <ListItemIcon>
                    <PriceCheck />
                  </ListItemIcon>
                  <ListItemText
                    primary="GKV Zuzahlungsforderung"
                    secondary="Für die Rückforderung erstellt der Leistungserbringer eine neue Rechnung"
                  />
                </ListItemButton>
                <Divider />
                <ListItemButton onClick={() => handleOpenDialog("post")}>
                  <ListItemIcon>
                    <Mail />
                  </ListItemIcon>
                  <ListItemText
                    primary="Postabrechnung"
                    secondary="Rechnung per Post an Bundeswehr, BG, Feuerwehr, Polizei"
                  />
                </ListItemButton>
              </List>
            </Grid>
            <Grid item>
              {openDialog.post && (
                <AccountSelectionDialog
                  paymentType="post"
                  isOpen={openDialog.post}
                  onCancel={handleClose}
                  userName={userName}
                  userId={userID}
                  userAccounts={userLocations}
                />
              )}

              {openDialog.direct && (
                <AccountSelectionDialog
                  paymentType="direct"
                  isOpen={openDialog.direct}
                  onCancel={handleClose}
                  userName={userName}
                  userId={userID}
                  userAccounts={userLocations}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PaymentMethodDialog;
