
import { Dayjs } from "dayjs";
import { GetInsuranceTransactionDto } from "../../../../../api-client";
import { BillingStatus } from "../pages/InsurancePageLayout";
import { Paginated } from "../../../../../helpers/pagination.interface";


export interface DateRange {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

// export type BillingType = "GKV" | "POST";

export enum BillingTypeEnum {
  GKV = "GKV",
  BG = "BG",
}

export type DateRangesByStatus = {
  [status in BillingStatus]?: DateRange;
};

export interface SearchTransaction {
  page?: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
  searchStr?: string;
}

export type PaginatedGetInsuranceTransaction = Paginated<GetInsuranceTransactionDto>;

export interface InsuranceBillsContainerProps {
  billStatus: BillingStatus;
  billingType?: BillingTypeEnum;
}

