import React, { FC } from "react";
import { Box } from "@mui/material";
import { ExportBillingData } from "../../../../../../api-client";
import { BillCard } from "./sub-components/BillCard";

interface BillCardListProps {
  bills: ExportBillingData[];
  todayString: string;
  selectedRxId: string | null;
  notes: Record<string, string>;
  setNotes: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  onRowClick: (row: any) => void;
  setSelectedRxId: (id: string) => void;
  handleMenuOpen: (event: React.MouseEvent<HTMLButtonElement>, row: any) => void;
  handleTaxierenClick: (row: any) => void;
  onRemoveAll: (billId: string) => void;
}
const BillCardList: FC<BillCardListProps> = ({
  bills,
  todayString,
  selectedRxId,
  notes,
  setNotes,
  onRowClick,
  setSelectedRxId,
  handleMenuOpen,
  handleTaxierenClick,
  onRemoveAll,
}) => {
  return (
    <>
      {bills.map((bill, billIndex) => (
        <Box
          className="bill-card"
          key={billIndex}
        >
          <BillCard
            bill={bill}
            todayString={todayString}
            selectedRxId={selectedRxId}
            notes={notes}
            setNotes={setNotes}
            onRowClick={onRowClick}
            setSelectedRxId={setSelectedRxId}
            handleMenuOpen={handleMenuOpen}
            handleTaxierenClick={handleTaxierenClick}
            onRemoveAll={onRemoveAll}
          />
        </Box>
      ))}
    </>
  );
};

export default BillCardList;
