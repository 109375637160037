import { useState, useEffect, useRef } from "react";
import { EditBillInfoProps, PrintData } from "../types/EditBillInfo.types";
import { useReactToPrint } from "react-to-print";
import { ExportBillingData, UpdateInsuranceTransactionDto } from "../../../../../../api-client";
import useStore from "../../../../../../helpers/useStore";
import { TransactionsContext } from "../../../../../../stores/Transactions/Transactions.provider";
import { RxStoreContext } from "../../../../../../stores/Rx/rx.provider";
import notificationStore from "../../../../../../stores/Notification/notificationStore";
import { BillingStatus } from "../../../Base/pages/InsurancePageLayout";
import { TestType, TestTypeInterface } from "../types/TestTypes.interface";

/**
 * Custom hook that encapsulates the main logic of EditBillInfo.
 * It returns states and handlers for the EditBillInfo component.
 */
export const useEditBillInfo = ({
  bills,
  setBills,
  onRowClick,
  onRxDelete,
  onRemoveAll,
  onValidationFilter,
  handleFullScreenDialogClose,
}: EditBillInfoProps) => {
  // Local states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRx, setSelectedRx] = useState<any | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isSoftDeleteDialogOpen, setIsSoftDeleteDialogOpen] = useState(false);
  const [rxNumber, setRxNumberInput] = useState(""); // For adding single RX (input value)
  const [rxNumberError, setRxNumberError] = useState("");
  const [openSingleRxDialog, setOpenSingleRxDialog] = useState(false);
  const [notes, setNotes] = useState<Record<string, string>>({});
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [selectedRxId, setSelectedRxId] = useState<string | null>(null);
  const [openTaxierenDialog, setOpenCancelTaxierenDialog] = useState(false);

  const [printData, setPrintData] = useState<PrintData | null>(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isDrucken, setIsDrucken] = useState(false);
  const printRef = useRef<HTMLDivElement>(null);
  const [testToggleChecked, setTestToggleChecked] = useState(false);
  const [openTestTypeDialog, setOpenTestTypeDialog] = useState(false);
  const [testTypeData, setTestTypeData] = useState<TestTypeInterface>({
  type: TestType.GLOBAL,
  email: undefined,      
});
  // State for loading spinner
    const [isExportLoading, setIsExportLoading] = useState(false);
  const today = new Date();
  const todayString = today.toDateString();


  // State for Stepper
  const [billsChanged, setBillsChanged] = useState(false);
  const [billsSnapshot, setBillsSnapshot] = useState(JSON.stringify(bills));

  // If bills changes at any time, detect it
useEffect(() => {
  const newSnapshot = JSON.stringify(bills);
  // If the snapshot differs from the last "saved" snapshot, mark as changed
  if (newSnapshot !== billsSnapshot) {
    setBillsChanged(true);
  }
}, [bills, billsSnapshot]);

  // API wrappers from stores
  const TransactionStore = useStore(TransactionsContext);
  const {
    getSingleRxForIndirectBillsForInsurance,
    submitEditedBillingData,
    exportData,
    updateExportAndBillingStatus,
    softDeleteTransaction,
    printBillingData,
  } = TransactionStore;

  const RxStore = useStore(RxStoreContext);
  const { updateIsTaxieren } = RxStore;


  // Initialize notes with data from bills
  useEffect(() => {
    const initialNotes = bills.reduce((acc, bill) => {
      acc[bill.billId] = bill.notes || "";
      return acc;
    }, {} as Record<string, string>);
    setNotes(initialNotes);
  }, [bills]);

  // ----------------
  // Menu / Kebab
  // ----------------
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, rx: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRx(rx);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // ----------------
  // Delete RX
  // ----------------

  const handleDeleteClick = () => {
    // setOpenDeleteDialog(true);
    // handleMenuClose();
    if (
      bills.length === 1 &&
      bills[0].data.rxs.length === 1 &&
      bills[0].data.rxs.some((rx) => rx.id === selectedRx?.id)
    ) {
      setIsSoftDeleteDialogOpen(true); // Open soft delete dialog
    } else {
      setOpenDeleteDialog(true); // Open normal delete dialog
    }
    handleMenuClose();
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setSelectedRx(null);
  };

  const handleDeleteConfirm = () => {
    if (!selectedRx) return;

    setBills((prevBills) =>
      prevBills
        .map((bill) => {
          if (bill.data.rxs.some((rx) => rx.id === selectedRx.id)) {
            const updatedRxData = bill.data.rxs.filter((rx) => rx.id !== selectedRx.id);
            const updatedTotalPrice = updatedRxData.reduce(
              (total, rx) => total + (rx.rxPrice.grossValue || 0),
              0
            );
            return {
              ...bill,
              data: {
                ...bill.data,
                rxs: updatedRxData,
                totalPrice: updatedTotalPrice,
              },
            };
          }
          return bill;
        })
        .filter((bill) => bill.data.rxs.length > 0)
    );

    notificationStore.showMessage("Rezept erfolgreich entfernt", "success");

    onRxDelete(selectedRx.id);
    setSelectedRx(null);
    setOpenDeleteDialog(false);
  };

  // ----------------
  // Soft Delete
  // ----------------

  const handleSoftDeleteCancel = () => {
    setIsSoftDeleteDialogOpen(false);
    setSelectedRx(null);
  };

  const handleSoftDeleteConfirm = async () => {
    if (!bills[0].billId) return;

    try {
      await softDeleteTransaction(bills[0].billId);
      setBills([]);
      notificationStore.showMessage("Soft delete successful", "success");
    } catch (error) {
      console.error("Soft delete failed:", error);
      notificationStore.showMessage("Soft delete failed", "error");
    }
    setSelectedRx(null);
    setIsSoftDeleteDialogOpen(false);
    handleFullScreenDialogClose(true);
  };

  // ----------------
  // Printing (Taxieren)
  // ----------------
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: async () => {
      if (!selectedRx) {
        setIsPrinting(false);
        return;
      }

      try {
        const response = await updateIsTaxieren(selectedRx.id, "true");
        if (!response || !response.rx) {
          throw new Error("No updated RX returned from server");
        }

        const updatedRx = response.rx;
        const updatedIsTaxieren = updatedRx.isTaxieren;

        // Update the bills state
        setBills((prevBills) =>
          prevBills.map((bill) => {
            if (bill.data.rxs.some((rx) => rx.id === selectedRx.id)) {
              return {
                ...bill,
                data: {
                  ...bill.data,
                  rxs: bill.data.rxs.map((rx) =>
                    rx.id === selectedRx.id ? { ...rx, isTaxieren: updatedIsTaxieren } : rx
                  ),
                },
              };
            }
            return bill;
          })
        );

        notificationStore.showMessage("Rezept wurde taxiert", "success");
      } catch (error) {
        console.error("Error updating isTaxieren after print:", error);
        notificationStore.showMessage("Fehler beim Taxieren des Rezepts", "error");
      }

      setIsPrinting(false);
      setPrintData(null);
      setSelectedRx(null);
    },
  });

  const handleTaxierenClick = (row: any) => {
    if (!row) return;
    
    setSelectedRx(row);

      const bill = bills.find((b) => b.data.rxs.some((rx) => rx.id === row.id));
      if (!bill) {
        notificationStore.showMessage("Fehler: Keine Rechnung für dieses Rezept gefunden", "error");
        return;
      }
    
    const careProviderIk = bill.accountInfo.ik || "";
    const billNumber = bill.billNumber;
    const rxNumber = row.rxNumber;
    const isZahnarzt = row.rxtype.id === "Zahnarzt" ? true : false;

    setPrintData({ billNumber, rxNumber, careProviderIk, isZahnarzt });
    setIsPrinting(true);

  };


  const handleCancelTaxierenClick = () => {
    setOpenCancelTaxierenDialog(true);
    handleMenuClose();
  };


  const handleCancelTaxierenConfirm = async() => {
    if (!selectedRx) return;

    try {
        const response = await updateIsTaxieren(selectedRx.id, "false");
        if (!response || !response.rx) {
          throw new Error("No updated RX returned from server");
        }

        const updatedRx = response.rx;
        const updatedIsTaxieren = updatedRx.isTaxieren;

        // Update the bills state
        setBills((prevBills) =>
          prevBills.map((bill) => {
            if (bill.data.rxs.some((rx) => rx.id === selectedRx.id)) {
              return {
                ...bill,
                data: {
                  ...bill.data,
                  rxs: bill.data.rxs.map((rx) =>
                    rx.id === selectedRx.id ? { ...rx, isTaxieren: updatedIsTaxieren } : rx
                  ),
                },
              };
            }
            return bill;
          })
        );

        notificationStore.showMessage("Sie haben die Taxieren erfolgreich storniert", "success");
      } catch (error) {
        console.error("Error updating isTaxieren after print:", error);
        notificationStore.showMessage("Fehler beim Untaxieren des Rezepts", "error");
    }
    
    setOpenCancelTaxierenDialog(false);
  };

  useEffect(() => {
    if (printData && isPrinting) {
      handlePrint();
    }
  }, [printData, isPrinting]);

  // ----------------
  // Add Single Rx
  // ----------------
  const handleAddSingleRx = async (rxNumber: string) => {
    if (!rxNumber.trim()) {
      setRxNumberError("Bitte geben Sie eine Rezept-Nummer ein");
      return;
    }

    try {
      const rxInfo = await getSingleRxForIndirectBillsForInsurance(
        rxNumber.trim()
      );

      if (rxInfo && rxInfo.rx) {
        const newRx = rxInfo.rx;
        // check if already in the list
        const rxExists = bills.some((bill) => bill.data.rxs.some((rx) => rx.id === newRx.id));
        if (rxExists) {
          notificationStore.showMessage("Dieses Rezept ist bereits auf der Liste", "warning");
          return;
        }

        // get payer
        const payerIK = newRx.payer?.payerId;
        if (!payerIK) {
          notificationStore.showMessage("Payer IK not found for the Rx", "error");
          return;
        }

        // see if there's an existing bill with same payer
        const billIndex = bills.findIndex((bill) => bill.payerIK === payerIK);
        if (billIndex !== -1) {
          // add rx
          setBills((prevBills) => {
            const updatedRxData = [...prevBills[billIndex].data.rxs, newRx];
            const updatedTotalPrice =
              Number(prevBills[billIndex].data.totalPrice) + (Number(newRx.rxPrice.grossValue) || 0);

            const updatedBill: ExportBillingData = {
              ...prevBills[billIndex],
              data: {
                ...prevBills[billIndex].data,
                rxs: updatedRxData,
                totalPrice: updatedTotalPrice,
              },
            };

            return [
              ...prevBills.slice(0, billIndex),
              updatedBill,
              ...prevBills.slice(billIndex + 1),
            ];
          });

          notificationStore.showMessage("Rezept erfolgreich hinzugefügt", "success");
        } else {
          notificationStore.showMessage(
            "Das Rezept kann nicht hinzugefügt werden, da kein vorhandener Rechnung mit dem gleichen Payer existiert.",
            "error"
          );
          return;
        }
      } else {
        notificationStore.showMessage("Rezept nicht gefunden", "error");
      }

      setOpenSingleRxDialog(false);
      setRxNumberInput("");
      setRxNumberError("");
    } catch (error) {
      console.error("Error fetching single RX:", error);
      notificationStore.showMessage("Rezept nicht gefunden", "error");
    }
  };

  // ----------------
  // Filter by Validation
  // ----------------
  const handleValidationFilter = () => {
    const updatedBills = bills
      .map((bill) => {
        const filteredRxs = bill.data.rxs.filter((rx) => {
          const validations = rx.formvalidation;
          return (
            validations &&
            Object.values(validations).every((validation) => validation.validity === "Valid")
          );
        });
        const updatedTotalPrice = filteredRxs.reduce((total, rx) => total + (rx.rxPrice.grossValue || 0), 0);

        return {
          ...bill,
          data: {
            ...bill.data,
            rxs: filteredRxs,
            totalPrice: updatedTotalPrice,
          },
        };
      })
      .filter((bill) => bill.data.rxs.length > 0);

    setBills(updatedBills);

    // notify parent
    const remainingRxs = updatedBills.flatMap((bill) => bill.data.rxs);
    onValidationFilter?.(remainingRxs);
  };

  // ----------------
  // Save All
  // ----------------
  const handleSave = async () => {
    try {
      const updatePromises = bills.map(async (bill) => {
        const { payerIK, accountInfo, data, billId, userInfo, wasExported } = bill;
        const { rxs, payerDto, totalPrice } = data;

        const rxsIds = rxs.map((rx) => rx.id).filter((id): id is string => !!id);
        const paperCenter = payerDto?.paperReceivingCenter?.ik;
        const digitalCenter = payerDto?.digitalReceivingCenter?.ik;

        const dataSubmission: UpdateInsuranceTransactionDto = {
          payerId: payerIK,
          accountId: accountInfo.id,
          amount: totalPrice,
          userId: userInfo.id!,
          rxIds: rxsIds,
          paperCenterId: paperCenter || undefined,
          dataCenterId: digitalCenter || undefined,
          notes: notes[bill.billId] || "",
          wasExported: wasExported,
          tAccountId: rxs[0].tarifGroupId === "Privat" ? 4105 : 4101,
        };

        await submitEditedBillingData(billId, dataSubmission);
      });

      await Promise.all(updatePromises);

      notificationStore.showMessage("All transactions updated successfully", "success");
      setBillsChanged(false);
    setBillsSnapshot(JSON.stringify(bills));
    } catch (error) {
      console.error("Error updating transactions", error);
      notificationStore.showMessage("Error updating transactions", "error");
    }
  };

  // ----------------
  // Bill Printing
  // ----------------

  const handleDrucken = async (testType: TestType) => {
    try {
      setIsDrucken(true);
      const updatePromises = bills.map(async (bill) => {
        const { payerIK, accountInfo, data, billId, userInfo, wasExported } = bill;
        const { rxs, payerDto, totalPrice } = data;

        const rxsIds = rxs.map((rx) => rx.id).filter((id): id is string => !!id);
        const paperCenter = payerDto?.paperReceivingCenter?.ik;
        const digitalCenter = payerDto?.digitalReceivingCenter?.ik;

        const dataSubmission: UpdateInsuranceTransactionDto = {
          payerId: payerIK,
          accountId: accountInfo.id,
          amount: totalPrice,
          userId: userInfo.id!,
          rxIds: rxsIds,
          paperCenterId: paperCenter || undefined,
          dataCenterId: digitalCenter || undefined,
          notes: notes[bill.billId] || "",
          wasExported: wasExported,
          tAccountId: rxs[0].tarifGroupId === "Privat" ? 4105 : 4101,
        };

        let response = await printBillingData(billId, dataSubmission, testType);

        if (response && response.paperCenterReceipt && response.billPdf) {
          setBills((prevBills) => { 
            return prevBills.map((bill) => {
              if (bill.billId === billId) {
                return {
                  ...bill,
                  paperCenterReceipt: response.paperCenterReceipt!,
                  billPdf: response.billPdf!,

                };
              }
              return bill;
            });
          })
        }
      });

      await Promise.all(updatePromises);

      notificationStore.showMessage("Bills Printed", "success");
      setBillsChanged(false);
      setBillsSnapshot(JSON.stringify(bills));
    } catch (error: any) {
      let errorMessage = error.response?.data?.message || error.message;
      console.error("Error Printing Bills", error);
      notificationStore.showMessage(`Beim Drucken der Rechnung ist ein Problem aufgetreten: ${errorMessage}`, "error", error);
    } finally {
      setIsDrucken(false);
    }
  };



  // ----------------
  // Export
  // ----------------
  const handleExport = () => {
    setOpenExportDialog(true);
  };

  const handleExportConfirm = async (testOptions: TestTypeInterface) => {

    try {
      setOpenExportDialog(false);
      setIsExportLoading(true);
      console.log("testOptions too see if it works", testOptions);
      const updatePromises = bills.map(async (bill) => {
        const { payerIK, accountInfo, data, billId, userInfo, wasExported } = bill;
        const { rxs, payerDto, totalPrice } = data;

        const rxsIds = rxs.map((rx) => rx.id).filter((id): id is string => !!id);
        const paperCenter = payerDto?.paperReceivingCenter?.ik;
        const digitalCenter = payerDto?.digitalReceivingCenter?.ik;

        const dataSubmission: UpdateInsuranceTransactionDto = {
          payerId: payerIK,
          accountId: accountInfo.id,
          amount: totalPrice,
          userId: userInfo.id!,
          rxIds: rxsIds,
          paperCenterId: paperCenter || undefined,
          dataCenterId: digitalCenter || undefined,
          notes: notes[bill.billId] || "",
          wasExported: wasExported,
          tAccountId: rxs[0].tarifGroupId === "Privat" ? 4105 : 4101,
        };

        await submitEditedBillingData(billId, dataSubmission);
      });

      await Promise.all(updatePromises);

      // Then export
      await exportData(bills, testOptions);
      

      // const statusUpdatePromises = bills.map((bill) =>
      //   updateExportAndBillingStatus(bill.billId, {
      //     wasExported: true,
      //     billStatus: BillingStatus.OPEN,
      //   })
      // );

      // await Promise.all(statusUpdatePromises);

      setBills([]);
      handleFullScreenDialogClose(true);
    } catch (error) {
      console.error("Error exporting transactions", error);
      notificationStore.showMessage("Fehler beim Exportieren der Rechnungen", "error");
    } finally {
      setIsExportLoading(false);
    }
  };

  // Test toggle
  const handleTestToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setTestToggleChecked(isChecked);

  if (isChecked) {
    // If user just enabled test mode, open the dialog to let them pick
    setOpenTestTypeDialog(true);
  } else {
    // If user just turned test mode off, revert to default
    setTestTypeData({ type: TestType.GLOBAL, email: undefined });
  }
};


  const handleTestTypeDialogClose = () => {
    setOpenTestTypeDialog(false);
  };

  // Return everything needed by the component
  return {
    // states
    anchorEl,
    selectedRx,
    openDeleteDialog,
    isSoftDeleteDialogOpen,
    bills,
    rxNumberInput: rxNumber,
    rxNumberError,
    openSingleRxDialog,
    notes,
    openExportDialog,
    selectedRxId,
    openTaxierenDialog,
    printData,
    isPrinting,
    isDrucken,
    isExportLoading,
    printRef,
    todayString,
    testToggleChecked,
    openTestTypeDialog,
    testTypeData,
     billsChanged,

    // handlers
    setAnchorEl,
    setSelectedRx,
    setOpenDeleteDialog,
    setBills,
    setRxNumberInput,
    setRxNumberError,
    setOpenSingleRxDialog,
    setNotes,
    setOpenExportDialog,
    setSelectedRxId,
    setOpenCancelTaxierenDialog,
    setTestTypeData,
    setOpenTestTypeDialog,
    setTestToggleChecked,
    setBillsChanged,

    handleMenuOpen,
    handleMenuClose,
    handleDeleteClick,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleSoftDeleteCancel,
    handleSoftDeleteConfirm,
    handleTaxierenClick,
    handleCancelTaxierenConfirm,
    handleCancelTaxierenClick,
    handleAddSingleRx,
    handleValidationFilter,
    handleSave,
    handleDrucken,
    handleExport,
    handleExportConfirm,
    handleTestToggleChange,
    handleTestTypeDialogClose,

    // from props
    onRowClick,
    onRxDelete,
    onRemoveAll,
  };
};
