import { Box, FormControlLabel, Switch, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
// import ChartLayout from "../visualisation/Charts/ChartLayout";
import { InsuranceBillsContainer } from "../components/InsuranceBillsContainer";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import EditNoteIcon from "@mui/icons-material/EditNote";
// import BarChartIcon from "@mui/icons-material/BarChart";
import { BillingTypeEnum } from "../types/insuranceBills.types";

export enum BillingStatus {
  START = "START",
  OPEN = "OPEN",
  PREVIOUS = "PREVIOUS",
}

const InsurancePageLayout = () => {
  // Which sub-tab is selected (Gestartete, Offene, Vorherige, Analytik)
  const [selectedTabs, setSelectedTabs] = useState(1);

  // High-level toggle: GKV or Post
  const [billingType, setBillingType] = useState<BillingTypeEnum>(BillingTypeEnum.GKV);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTabs(newValue);
  };

  // Handle the toggle switch
  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    // If checked, set "GKV", otherwise "POST"
    setBillingType(event.target.checked ? BillingTypeEnum.BG : BillingTypeEnum.GKV);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {/* 1. Toggle Switch for GKV vs Post */}
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={billingType === "BG"}
              onChange={handleToggle}
              color="primary"
            />
          }
          label={billingType === "GKV" ? "GKV digitale Abrechnung" : "Postabrechnung"}
        />
        <Tabs
          orientation="horizontal"
          value={selectedTabs}
          onChange={handleTabChange}
          aria-label="sub-tabs"
        >
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                GESTARTETE RECHNUNGEN
              </span>
            }
            value={1}
            icon={
              <EditNoteIcon
                sx={{
                  fontWeight: selectedTabs === 1 ? "bold" : "normal",
                }}
              />
            }
          />
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                OFFENE RECHNUNGEN
              </span>
            }
            value={2}
            icon={
              <ReceiptLongIcon
                sx={{
                  fontWeight: selectedTabs === 2 ? "bold" : "normal",
                }}
              />
            }
          />
          <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                VORHERIGE RECHNUNGEN
              </span>
            }
            value={3}
            icon={
              <MarkChatReadIcon
                sx={{
                  fontWeight: selectedTabs === 3 ? "bold" : "normal",
                }}
              />
            }
          />
          {/* <Tab
            iconPosition="start"
            label={
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                ANALYTIK
              </span>
            }
            value={4}
            icon={
              <BarChartIcon
                sx={{
                  fontWeight: selectedTabs === 4 ? "bold" : "normal",
                }}
              />
            }
          /> */}
        </Tabs>
      </Box>
      <Box>
        {(() => {
          switch (selectedTabs) {
            case 1:
              return (
                <InsuranceBillsContainer
                  billStatus={BillingStatus.START}
                  billingType={billingType}
                />
              );
            case 2:
              return (
                <InsuranceBillsContainer
                  billStatus={BillingStatus.OPEN}
                  billingType={billingType}
                />
              );
            case 3:
              return (
                <InsuranceBillsContainer
                  billStatus={BillingStatus.PREVIOUS}
                  billingType={billingType}
                />
              );
            // case 4:
            //   return <ChartLayout />;
            default:
              return null;
          }
        })()}
      </Box>
      {/* {(() => {
        switch (selectedTabs) {
          case 1:
            return <InsuranceBillsContainer billStatus={BillingStatus.START} />;
          case 2:
            return <InsuranceBillsContainer billStatus={BillingStatus.OPEN} />;
          case 3:
            return <InsuranceBillsContainer billStatus={BillingStatus.PREVIOUS} />;
          case 4:
            return <ChartLayout />;
          default:
            return null;
        }
      })()} */}
    </div>
  );
};

export default InsurancePageLayout;
