import React, { useMemo, useState } from "react";
import { DocumentTypeEnum, UploadDocumentMetadataDto } from "../../api-client";

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  List,
  ListItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import FileUpload from "../../molecules/FileUpload";
import FloatingActionButton from "../../molecules/FloatingButton";
import CameraCapture from "./capture/cameraCapture/CameraCapture";
import FileCapture from "./capture/fileCapture/FileCapture";
import Loading from "../../molecules/Loading";
import { AttachFile } from "@mui/icons-material";
import { mobiliTheme } from "../../themes/mobiliTheme";
import notificationStore from "../../stores/Notification/notificationStore";
import { TransactionsContext } from "../../stores/Transactions/Transactions.provider";
import useStore from "../../helpers/useStore";

interface DocumentUploadProps {
  documentMetaData: UploadDocumentMetadataDto;
  setDocumentsLoading: (value: boolean) => void;
  documentsLoading: boolean;
  uploadId?:string;
}

const DocumentUpload = ({
  documentMetaData,
  setDocumentsLoading,
  documentsLoading,
  uploadId
}: DocumentUploadProps) => {
  const { uploadDocument, isUploading } = FileUpload();
  const TransactionStore=useStore(TransactionsContext);
  const {uploadSignedDocument}=TransactionStore;
  const [showDocuTypeModal, setShowDocuTypeModal] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [selectedDocuType, setSelectedDocuType] = useState<DocumentTypeEnum | null>(null);

  const closeModal = () => {
    setShowDocuTypeModal(false);
  };

  const handleDismiss = () => {
    closeModal();
    setSelectedDocuType(null);
    return Promise.resolve(true);
  };

  const handleConfirm = async () => {
    try {
      if (!selectedDocuType) {
        return notificationStore.showMessage("Please select a document type.", "warning");
      }

      if (!files || files.length === 0) {
        return notificationStore.showMessage(
          "No file selected. Please upload a document.",
          "error"
        );
      }

      const file = files[0];

      documentMetaData.docuType = selectedDocuType;

      // Upload document
      const response = uploadId?await uploadSignedDocument(uploadId,{ ...documentMetaData, file }):await uploadDocument(file, documentMetaData);

      // Refresh documents state after upload
      setDocumentsLoading(!documentsLoading);

      closeModal();
    } catch (error: any) {
      notificationStore.showMessage(error.message || "An unexpected error occurred.", "error");
    }
  };

  // Memoize the file list rendering
  const fileItems = useMemo(() => {
    return files.map((file, index) => (
      <ListItem key={file.name + index}>
        <Typography sx={{ fontWeight: "bold", cursor: "pointer" }}>
          <a
            href={URL.createObjectURL(file)}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            {file.name}
          </a>
        </Typography>
      </ListItem>
    ));
  }, [files]);

  const docuTypes = useMemo(() => {
    const docuValues = Object.values(DocumentTypeEnum);
    return docuValues.map((docuType) => (
      <FormControlLabel
        key={docuType}
        control={<Radio sx={{ padding: "6px" }} />}
        label={docuType}
        value={docuType}
      />
    ));
  }, [DocumentTypeEnum]);

  return (
    <>
      <Loading showLoading={isUploading} title="Uploading..." />
     {!uploadId && <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <FloatingActionButton
            mainIcon={<AttachFile />}
            icons={[
              {
                icon: (
                  <CameraCapture
                    onCapture={(file: File) => {
                      setShowDocuTypeModal(true);
                      setFiles([file]);
                    } } />
                ),
                onClick: () => { },
              },
              {
                icon: (
                  <FileCapture
                    onCapture={(file: File) => {
                      setShowDocuTypeModal(true);
                      setFiles([file]);
                    } } />
                ),
                onClick: () => { },
              },
            ]} />
        </Grid>
      </Grid>
     }<Dialog open={showDocuTypeModal} onClose={closeModal}>
          <DialogTitle>Art des Dokuments</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Ausgewählte Datei:</Typography>
                <List>{fileItems}</List>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Dokumenttyp auswählen:</Typography>
                <RadioGroup
                  // sx={{ display: "flex", padding: "4px", margin: "3px" }}
                  value={selectedDocuType}
                  onChange={(e: any) => setSelectedDocuType(e.target.value)}
                  aria-label="selectedDocuType"
                  name="selectedDocuType"
                >
                  {docuTypes}
                </RadioGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDismiss} variant="text">
              Abbrechen
            </Button>
            <Button
              onClick={handleConfirm}
              variant="contained"
              disabled={!(selectedDocuType && files.length)}
            >
              {isUploading ? <CircularProgress size={24} /> : "Hochladen"}
            </Button>
          </DialogActions>
        </Dialog>


    {uploadId &&   <FileCapture
                    onCapture={(file: File) => {
                      setShowDocuTypeModal(true);
                      setFiles([file]);
                    }}
                  />
                  
                  }
    </>
  );
};

export default DocumentUpload;
