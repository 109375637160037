import Plot from "react-plotly.js";
import { Layout } from "plotly.js";
import * as d3 from "d3";
import { hexToRgba } from "../helpers/hexToRgba";
import "plotly.js/dist/plotly-locale-de"


interface LineChartProps {
    countData: { locationId: string, locationName: string, date: Date, totalCount: number }[];
}

const RxCountsPlot: React.FC<LineChartProps> = ({countData}) => {
    const groupedData = Object.groupBy(countData, ({ locationId }) => locationId);

    const traces: Plotly.Data[] = [];
    const numLocations = Object.keys(groupedData).length
    const colorPalette = d3.scaleOrdinal(d3['schemeTableau10']).range().slice(0, numLocations);
        
    Object.keys(groupedData).forEach((locationId, index) => {
        const color = colorPalette[index % colorPalette.length];
        const trace: Plotly.Data = {
            x: groupedData[locationId]?.map(entry => entry.date),  
            y: groupedData[locationId]?.map(entry => entry.totalCount), 
            type: 'scatter',
            mode: 'lines+markers',
            name: locationId, 
            marker: {
                line: {
                    width: 7,
                    color: hexToRgba(color, 0.25),
                }
            },
            line: { 
                color: color,
                width: 1,
            },
            cliponaxis: false,
            connectgaps: true,
        };
        
        const trace2: Plotly.Data = {
            x: groupedData[locationId]?.map(entry => entry.date),  
            y: groupedData[locationId]?.map(entry => entry.totalCount), 
            type: 'scatter',
            mode: 'markers', 
            name: locationId,
            marker: {
                color: color,
                line: {
                    width: 1, 
                    color: 'white',  // for the white outer circle
                },
            },
            legendgroup: locationId,
            showlegend: false,
            cliponaxis: false,   
        };

        traces.push(trace);
        traces.push(trace2);
    });

    const layout: Partial<Layout> = {
        height: 400,
        xaxis: {
            type: 'date',
            tickformat: '%-d %b %Y',
            tickmode: 'auto',
            nticks: 5,
            tickfont: {
                family: 'Roboto', 
                size: 12,
            },
            griddash: 'solid',
            linecolor: 'rgb(204, 204, 204)',
            gridcolor: 'rgb(204, 204, 204)',
            layer: 'below traces'
        },
        yaxis: {
            title: 'count',
            tickmode: 'auto',
            tickfont: {
                family: 'Roboto', 
                size: 12,
            },
            griddash: 'dot',
            rangemode: 'tozero',
            zeroline: true,
            zerolinecolor: 'rgb(204, 204, 204)',
            linecolor: 'rgb(204, 204, 204)',
            gridcolor: 'rgb(204, 204, 204)',
            layer: 'below traces'
        },
        showlegend: true,
        legend: {
            orientation: 'h',
            x: 0.5,
            xanchor: 'center',
            font: {
                family: 'Roboto',
                size: 12,
            }
        },
        hoverlabel: {
            font: {
                family: 'Roboto',
                size: 12,
            }
        },
        margin: {
            t: 40, 
            b: 50, 
            l: 50, 
            r: 50, 
        },
        modebar: {
            orientation: 'v'
        }
    };

    return <Plot data={traces} layout={layout} config={{ locale: 'de' }} />;
}

export default RxCountsPlot;