import React from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";

const steps = ["Speichern", "Ausdrucke erstellen", "Versenden"];

export interface BillingStepperProps {
  activeStep: number;
  stepNames?: string[]; // Optional custom step names
}

export const BillingStepper: React.FC<BillingStepperProps> = ({ activeStep, stepNames }) => {
  const actualSteps = stepNames ?? steps;
  return (
    <Box sx={{ width: "100%", mb: 2 }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
      >
        {actualSteps.map((label, index) => {
          const isCompleted = index < activeStep;
          const isActive = index === activeStep;

          return (
            <Step
              key={label}
              completed={isCompleted}
              active={isActive}
            >
              <StepLabel
                sx={{
                  "& .MuiStepLabel-label.Mui-completed": {
                    color: "#6FBF99",
                  },
                  "& .MuiStepLabel-label.Mui-active": {
                    color: "#dbdce0",
                  },
                  "& .MuiStepLabel-label": {
                    // color: "gray",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
