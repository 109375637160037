import React, { useEffect, useRef, useState } from "react";
import { InputBase, InputLabel, TextField, Tooltip, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";

const Input = (props: any) => {
  const textFieldStyle = {
    borderBottom: "2px solid grey", // Add a black underline
    // fontSize: '10px', // Adjust the label font size
    flexGrow: 1, // Take up all available space
  };

  const {
    name,
    label,
    value,
    required,
    error = null,
    onChange,
    disabled,
    onKeyPress,
    onKeyDown,
    onBlur,
    onMouseLeave,
    style,
  } = props;

  return (
    <>
        <InputLabel sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
          {label}{" "}
          {required && (
            <Typography component="span" variant="body2" color="error">
              *
            </Typography>
          )}
        </InputLabel>
      {disabled ? (
        // If disabled, show just the as text instead of a date picker
        <Typography
          sx={{
            ...(style ? style : textFieldStyle), // Merging the provided style with default styles
            pointerEvents: "none",
            cursor: "default",
          }}
        >
          {value?.toString() || "-"}
        </Typography>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
        <InputBase
          name={name}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onMouseLeave={onMouseLeave}
          disabled={disabled}
          inputProps={{ shrink: true }}
          style={style ? { ...textFieldStyle, ...style } : textFieldStyle}
        />
        {error && (
          <Tooltip title={error}>
            <ErrorOutline color="error" fontSize="small" style={{ marginLeft: "8px" }} />
          </Tooltip>
        )}
      </div>
      )}
    </>
  );
};

export default Input;
