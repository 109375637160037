import { action, computed, makeObservable, observable, reaction } from "mobx";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";

import {
  PermissionDto,
  UserDto,
  UserFilterDto,
  UserProfileApi,
  UserRegisterDto,
  UserRelationsEnum,
  WeeklyWorkDayDto,
  WorkPlanDto,
} from "../../api-client";
import AuthStore from "../Auth/authStore";
import notificationStore from "../Notification/notificationStore";

export default class UserStore {
  public authStore: AuthStore;
  //public user: UserDto|null = null;
  @observable
  public user: UserDto | null = null;
  //public authStore: AuthStore;

  @observable
  public allUsers: UserDto[] = [];

  @observable
  public permissionMap: Map<string, PermissionDto> = new Map<string, PermissionDto>();

  public apiClient: UserProfileApi;

  @observable
  public apiInfo: any | null = null;

  //  @observable
  // public authToken: string;

  public constructor(apiClient: UserProfileApi, authStore: AuthStore) {
    makeObservable(this);
    this.apiClient = apiClient;
    this.authStore = authStore;

    //this.authStore = authStore;
    //this.authToken = authToken;
  }

  @action
  initialize = async () => {
    let limit, page;

    await this.getAllUsers({
      limit,
      page,
      relations: [
        UserRelationsEnum.Location,
        UserRelationsEnum.TherapistLocation,
        UserRelationsEnum.Therapies,
        UserRelationsEnum.Roles,
        UserRelationsEnum.RolesPermissions,
        UserRelationsEnum.Permissions,
        UserRelationsEnum.WeeklyWorkDays,
        UserRelationsEnum.LocationAddress
      ],
      withDeleted: true,
    });
  };

  @action
  public fetchAPIInfo = async () => {
    try {
      const { data } = (await this.apiClient.apiInfo()) as any;
      this.apiInfo = data;
      return data;
    } catch (error: any) {
      console.error("Error fetching API info:", error);
      const errorMessage = error.response?.data?.message || "Error fetching API Data";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error;
    }
  };

  public setUser(user: UserDto) {
    this.user = user;
    this.permissionMap.clear();

    if (user.permissions) {
      user.permissions.forEach((permission) => {
        this.permissionMap.set(permission.id, permission);
      });
    }
  }

  // @computed private get isRole() {
  // return (roleName: string) => {
  private isRole(roleName: string) {
    console.log("Checking role:", this.user, roleName);
    //if(this.user?.roles) console.log("Roles:", this.user?.roles[0]);
    if (
      this.user &&
      this.user.roles &&
      this.user.roles.map((role: any) => role.name).includes(roleName)
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasPermission = (permissionId: string) => {
    return this.user && this.permissionMap.has(permissionId) ? true : false;
  };

  // Use @action to mark methods that modify observable state
  @action
  isTherapist = () => {
    return this.isRole("Therapist");
  };

  @action
  getRoles = (): string[] => {
    if (this.user && this.user.roles) {
      const roles = this.user.roles as any[];
      return roles.map((role) => role.name);
    } else {
      return [];
    }
  };

  listPermissions = (): string[] => {
    if (this.user && this.user.permissions) {
      return this.user.permissions.map((permission) => permission.name);
    } else {
      return [];
    }
  };

  @observable
  public resourceRequest: IPromiseBasedObservable<any> | null = null;

  @action
  public userRegister = async (userRegisterDto: UserRegisterDto) => {
    const { data } = (await this.apiClient.userRegister(userRegisterDto)) as any;
    return data || [];
  };
  @action
  public changeUserLoginDetails = async (userRegisterDto: UserRegisterDto) => {
    try {
      const { data } = (await this.apiClient.changeUserLoginDetails(userRegisterDto)) as any;
      if (this.user) {
        this.setUser(data);
      }
      notificationStore.showMessage("Benutzeranmeldeinformationen erfolgreich geändert", "success");
      return data || [];
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Benutzeranmeldeinformationen konnten nicht gespeichert werden.";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public sendOtp = async (user: string) => {
    try {
      const { data } = (await this.apiClient.sendOtp(user)) as any;
      notificationStore.showMessage("OTP wurde erfolgreich gesendet", "success");
      return data || [];
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "OTP konnte nicht gesendet werden";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public changeUserLocation = async (locationId: string) => {
    try {
      console.log("User:", this.user?.id, locationId);
      const { data } = (await this.apiClient.changeUserLocation(
        this.user?.id as string,
        locationId
      )) as any;
      // Set the notification message using the provided notification store
      if (data) {
        notificationStore.showMessage("Benutzerstandort erfolgreich geändert", "success");
      }

      if (this.user) {
        this.setUser(data);
      }

      return data || [];
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage = error.response?.data?.message || "Fehler beim Benutzerupdate";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public saveUserInfo = async (user: UserDto) => {
    try {
      const { data } = (await this.apiClient.saveUserInfo(user)) as any;

      const savedUserIndex = this.allUsers.findIndex((user: UserDto) => user.id === data.saved.id);
      if (savedUserIndex > -1) {
        this.allUsers[savedUserIndex] = data.saved;
      } else {
        this.allUsers.push(data.saved);
      }
      notificationStore.showMessage("Benutzerdaten wurden erfolgreich gespeichert!", "success");

      return data.saved || [];
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage =
        error.response?.data?.message || "Fehler beim Speichern der Benutzerdaten";

      notificationStore.showMessage(errorMessage, "error", error);
      return null;
    }
  };

  @action
  public getUser = async (): Promise<UserDto | null> => {
    try {
      const relations = [
        UserRelationsEnum.Location,
        UserRelationsEnum.TherapistLocation,
        UserRelationsEnum.Therapies,
        UserRelationsEnum.Roles,
        UserRelationsEnum.RolesPermissions,
        UserRelationsEnum.Permissions,
        UserRelationsEnum.WeeklyWorkDays,
        UserRelationsEnum.LocationAddress
      ];
      const response = (await this.apiClient.getAuthenticatedUser(relations)) as any;
      console.log("Response:", response);
      this.setUser(response.data);
      //console.log("User:", this.user);

      this.fetchAPIInfo();
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        // Handle 401 error
        console.error("Unauthorized access. logging out");
        this.authStore.setAuthToken(null);
      } else {
        // Handle other errors
        console.error("An error occurred:", error.message);
      }
    }
    return null;
  };

  @action
  public activateOrDeactivateUser = async (userId: string, softDeleteUser: boolean) => {
    try {
      const { data } = (await this.apiClient.activateOrDeactivateUser(
        userId,
        softDeleteUser
      )) as any;

      return data || [];
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
    }
  };

  @action
  public getAllUsers = async (filters: UserFilterDto): Promise<UserDto[]> => {
    const { limit, page, relations, withDeleted } = filters;
    const { data } = (await this.apiClient.getUsers(limit, page, relations, withDeleted)) as any;
    this.allUsers = data;
    console.log("All users:", this.allUsers);
    return data || [];
  };

  @action
  public getUserBy = async (locationId: string): Promise<UserDto[]> => {
    try {
      const { data } = (await this.apiClient.getUsersBy(locationId)) as any;
      this.allUsers = data;
      console.log("All users:", this.allUsers);
      return data || [];
    } catch (error: any) {
      console.error("Error fetching user:", error);
      const errorMessage = error.response?.data?.message || "internal server error";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error; // or return [] if you want to return an empty array
    }
  };

  public getUserById = async (userId: string): Promise<UserDto> => {
    const { data } = (await this.apiClient.getUserById(userId)) as any;
    return data;
  };

  /**
   * This can be used as a final block to ensure that the user has the correct permissions and will throw an error
   * You should do a check upstream of this to avoid throwing errors
   *
   * @param criteria
   */
  enforce(criteria: boolean) {
    if (!criteria) throw new Error("Permissions enformcement failed");
  }

  @action
  public getWeeklyWorkDays = async (userId: string): Promise<WeeklyWorkDayDto[]> => {
    try {
      const { data } = (await this.apiClient.getWeeklyWorkDays(userId)) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error fetching weekly work days:", error);
      const errorMessage = error.response?.data?.message || "internal server error";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error;
    }
  };

  @action
  public getWeeklyWorkDaysByWorkPlan = async (
    userId: string,
    workPlanId: number
  ): Promise<WeeklyWorkDayDto[]> => {
    try {
      const { data } = (await this.apiClient.getWeeklyWorkDaysByWorkPlan(
        userId,
        workPlanId
      )) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error fetching weekly work days:", error);
      const errorMessage = error.response?.data?.message || "internal server error";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error;
    }
  };

  @action
  public getWorkPlans = async (userId: string): Promise<WorkPlanDto[]> => {
    try {
      const { data } = (await this.apiClient.getWorkPlans(userId)) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error fetching work plans:", error);
      const errorMessage = error.response?.data?.message || "internal server error";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error;
    }
  };

  @action
  public createWorkPlan = async (workPlan: WorkPlanDto): Promise<WorkPlanDto> => {
    try {
      const { data } = (await this.apiClient.createWorkPlan(workPlan)) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error creating work plan:", error);
      const errorMessage = error.response?.data?.message || "internal server error";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error;
    }
  };

  @action
  public deleteWorkPlan = async (workPlanId: number): Promise<WorkPlanDto> => {
    try {
      const { data } = (await this.apiClient.deleteWorkPlan(workPlanId)) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error deleting work plan:", error);
      const errorMessage = error.response?.data?.message || "internal server error";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error;
    }
  };

  @action
  public createWeeklyWorkDay = async (
    weeklyWorkDay: WeeklyWorkDayDto
  ): Promise<WeeklyWorkDayDto> => {
    try {
      const { data } = (await this.apiClient.createWeeklyWorkDay(weeklyWorkDay)) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error creating weekly work day:", error);
      const errorMessage = error.response?.data?.message || "internal server error";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error;
    }
  };

  @action
  public deleteWeeklyWorkDay = async (weeklyWorkDayId: number): Promise<WeeklyWorkDayDto> => {
    try {
      const { data } = (await this.apiClient.deleteWeeklyWorkDay(weeklyWorkDayId)) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error deleting weekly work day:", error);
      const errorMessage = error.response?.data?.message || "internal server error";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error;
    }
  };

  @action
  public updateWeeklyWorkDay = async (
    weeklyWorkDay: WeeklyWorkDayDto
  ): Promise<WeeklyWorkDayDto> => {
    try {
      const { data } = (await this.apiClient.putWeeklyWorkDay(weeklyWorkDay)) as any;
      return data || [];
    } catch (error: any) {
      console.error("Error updating weekly work day:", error);
      const errorMessage = error.response?.data?.message || "internal server error";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error;
    }
  };

  public fetchIsWorkPlanOutdated = async (workPlanId: number): Promise<boolean> => {
    try {
      const { data } = await this.apiClient.isWorkPlanOutdated(workPlanId);
      return data as boolean;
    } catch (error: any) {
      console.error("Error fetching is work plan outdated:", error);
      const errorMessage = error.response?.data?.message || "internal server error";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error;
    }
  };

  public fillSlotsForWorkPlan = async (workPlanId: number) => {
    try {
      const { data } = await this.apiClient.fillSlotsForWorkPlan(workPlanId);
      notificationStore.showMessage(data, "success");
    } catch (error: any) {
      console.error("Error fetching is work plan outdated:", error);
      const errorMessage = error.response?.data?.message || "internal server error";
      notificationStore.showMessage(errorMessage, "error", error);
      throw error;
    }
  };
}
