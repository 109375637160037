import Plot from "react-plotly.js";
import Plotly, { Layout } from "plotly.js";
import * as d3 from "d3";
import { hexToRgba } from "../helpers/hexToRgba";
import "plotly.js/dist/plotly-locale-de"


interface LineChartProps {
    cumulativeData: { locationId: string, locationName: string, date: Date, cumulativeSum: number }[];
}

const CumulativePatientsPlot: React.FC<LineChartProps> = ({cumulativeData}) => {
    const groupedData = Object.groupBy(cumulativeData, ({ locationName }) => locationName);
    // console.log(cumulativeData.map(entry => entry.date));

    const traces: Plotly.Data[] = [];
    const numLocations = Object.keys(groupedData).length
    const colorPalette = d3.scaleOrdinal(d3['schemeTableau10']).range().slice(0, numLocations);
    
    Object.keys(groupedData).forEach((locationName, index) => {
        const color = colorPalette[index % colorPalette.length];
        // generate a trace for current location
        const trace: Plotly.Data = {
            x: groupedData[locationName]?.map(entry => entry.date),  
            y: groupedData[locationName]?.map(entry => entry.cumulativeSum), 
            type: 'scatter',
            mode: 'lines+markers',
            name: locationName, 
            marker: {
                color: color,
                line: {
                    width: 7,
                    color: hexToRgba(color, 0.25), // convert to rgba so we can set opacity for the "bigger circle"
                }
            },
            line: { 
                color: color,
                width: 1, 
            },
            cliponaxis: false,
            connectgaps: true,
        };
        
        const trace2: Plotly.Data = {
            x: groupedData[locationName]?.map(entry => entry.date),
            y: groupedData[locationName]?.map(entry => entry.cumulativeSum),
            type: 'scatter',
            mode: 'markers', 
            name: locationName,
            marker: {
                color: color,
                line: {
                    width: 1, 
                    color: 'white',  // for the white outer circle
                },
            },
            legendgroup: locationName,
            showlegend: false,
            cliponaxis: false,   
        };
        traces.push(trace);
        traces.push(trace2);
    });

    const layout: Partial<Layout> = {
        height: 400,
        xaxis: {
            type: 'date',
            tickformat: '%-d %b %Y',
            tickmode: 'auto',
            nticks: 5,
            tickfont: {
                family: 'Roboto', 
                size: 12,
            },
            griddash: 'solid', 
            linecolor: 'rgb(204, 204, 204)',
            gridcolor: 'rgb(204, 204, 204)',
        },
        yaxis: {
            tickmode: 'auto',
            tickfont: {
                family: 'Roboto',  
                size: 12,
            },
            griddash: 'dot',
            rangemode: 'tozero',
            zeroline: true,
            zerolinecolor: 'rgb(204, 204, 204)',
            linecolor: 'rgb(204, 204, 204)',
            gridcolor: 'rgb(204, 204, 204)'
        },
        showlegend: true,
        legend: {
            orientation: 'h',
            x: 0.5,
            xanchor: 'center',
            font: {
                family: 'Roboto',
                size: 12,
            }
        },
        hoverlabel: {
            font: {
                family: 'Roboto',
                size: 12,
            }
        },
        margin: {
            t: 40, 
            b: 50, 
            l: 50, 
            r: 50, 
        },
        modebar: {
            orientation: 'v'
        },
    };

    return <Plot data={traces} layout={layout} config={{ locale: 'de' }} />
}
export default CumulativePatientsPlot;