// helper functions for rx stats
import { RxStatsDto } from "../../../api-client";
import { startOfWeek, addWeeks, startOfMonth, addMonths, isEqual, isBefore } from 'date-fns';

// structure of grouped data (group by location)
interface GroupedData {
    [locationId: string]: RxStatsDto[]
}

interface TotalRxCountData {
    locationId: string, 
    locationName: string,
    date: Date, 
    totalCount: number
}

// function to calculate total number of Rxs by date
export const calculateTotalRx = (rxData: RxStatsDto[]): TotalRxCountData[] => {
    // Array to store the results
    const totalCountData: TotalRxCountData[] = [];

    // Group by location
    const groupedData: GroupedData = Object.groupBy(rxData, ({ locationId }) => locationId) as GroupedData;

    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.rxCreatedAt).getTime() - new Date(b.rxCreatedAt).getTime());

        const firstEntryDate = new Date(groupedData[locationId][0].rxCreatedAt);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].rxCreatedAt);
        console.log("rx earliest date: ", firstEntryDate);
        console.log("rx latest date: ", lastEntryDate);
        
        // get unique dates
        const uniqueDates = [
            ...new Set(groupedData[locationId].map((entry) => new Date(entry.rxCreatedAt).toISOString().split('T')[0]))
        ];
        
        // For each unique date, get the number of Rxs for that date
        uniqueDates.forEach((date) => {
            const total = groupedData[locationId].filter((entry) => new Date(entry.rxCreatedAt).toISOString().split('T')[0] === date
            ).length;
        
            totalCountData.push({ 
                locationId: locationId, 
                locationName: groupedData[locationId][0].locationName,
                date: new Date(date), 
                totalCount: total 
            });
        });
    });
    return totalCountData;
}


interface RxStatusCountData {
    locationId: string, 
    locationName: string,
    date: Date, 
    ongoingRxCount: number, 
    settledRxCount: number
}

// function to calculate number of Rxs by status by bin
export const calculateRxStatusCount = (rxData: RxStatsDto[], binType: 'week' | 'month'): RxStatusCountData[] => {
    // Array to store the results
    const countData: RxStatusCountData[] = [];

    // Group by locationId 
    const groupedData: GroupedData = Object.groupBy(rxData, ({ locationId }) => locationId) as GroupedData;

    // Loop through each location
    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.rxCreatedAt).getTime() - new Date(b.rxCreatedAt).getTime());

        const firstEntryDate = new Date(groupedData[locationId][0].rxCreatedAt);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].rxCreatedAt);

        let binStart: Date;
        let nextBinStart: Date;

        if (binType === 'week') {
            binStart = startOfWeek(firstEntryDate, { weekStartsOn: 1 });
        } else {
            binStart = startOfMonth(firstEntryDate);
        }

        while(isBefore(binStart, lastEntryDate)) {
            if (binType === 'week') {
                nextBinStart = addWeeks(binStart, 1);
            } else {
                nextBinStart = addMonths(binStart, 1);
            }
            
            // count Rxs (ongoing) in current bin
            const ongoingRxsInBin = groupedData[locationId].filter(entry => 
                new Date(entry.rxCreatedAt) >= binStart && new Date(entry.rxCreatedAt) < nextBinStart
                && entry.status === "in Behandlung"
            ).length;

            // count Rxs (settled) in current bin
            const settledRxsInBin = groupedData[locationId].filter(entry => 
                new Date(entry.rxCreatedAt) >= binStart && new Date(entry.rxCreatedAt) < nextBinStart
                && entry.status === "abgerechnet"
            ).length;

            countData.push({
                locationId: locationId,
                locationName: groupedData[locationId][0].locationName,
                date: binStart,
                ongoingRxCount: ongoingRxsInBin,
                settledRxCount: settledRxsInBin,
            });

            binStart = nextBinStart;
        }  
    });
    return countData;
}


interface TotalRxValueData {
    locationId: string, 
    locationName: string,
    date: Date, 
    totalOngoingRxPrice: number, 
    totalSettledRxPrice: number
}

// function to calculate total Rx value by bin
export const calculateTotalRxValue = (rxData: RxStatsDto[], binType: 'week' | 'month'): TotalRxValueData[] => {
    // Array to store the results
    const totalRxValueData: TotalRxValueData[] = [];

    // Group by locationId 
    const groupedData: GroupedData = Object.groupBy(rxData, ({ locationId }) => locationId) as GroupedData;

    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.rxCreatedAt).getTime() - new Date(b.rxCreatedAt).getTime());

        const firstEntryDate = new Date(groupedData[locationId][0].rxCreatedAt);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].rxCreatedAt);

        let binStart: Date;
        let nextBinStart: Date;

        if (binType === 'week') {
            binStart = startOfWeek(firstEntryDate, { weekStartsOn: 1 });
        } else {
            binStart = startOfMonth(firstEntryDate);
        }

        while(isBefore(binStart, lastEntryDate)) {
            if (binType === 'week') {
                nextBinStart = addWeeks(binStart, 1);
            } else {
                nextBinStart = addMonths(binStart, 1);
            }
            
            // calculate total price of Rxs (ongoing) in current bin
            const totalOngoingRxPrice = groupedData[locationId].filter(entry => 
                new Date(entry.rxCreatedAt) >= binStart && new Date(entry.rxCreatedAt) < nextBinStart
                && entry.status === "in Behandlung"
            ).reduce((total, entry) => total + entry.realPrice, 0);

            // calculate total price of Rxs (settled) in current bin
            const totalSettledRxPrice = groupedData[locationId].filter(entry => 
                new Date(entry.rxCreatedAt) >= binStart && new Date(entry.rxCreatedAt) < nextBinStart
                && entry.status === "abgerechnet"
            ).reduce((total, entry) => total + entry.realPrice, 0);

            totalRxValueData.push({
                locationId: locationId,
                locationName: groupedData[locationId][0].locationName,
                date: binStart,
                totalOngoingRxPrice: totalOngoingRxPrice,
                totalSettledRxPrice: totalSettledRxPrice,
            });

            binStart = nextBinStart;
        }
    });
    return totalRxValueData;
}
