import { FC } from "react";
import { PostEditBillInfoProps } from "../types/PostEditBillInfo.types";
import { usePostEditBillInfo } from "../hooks/usePostEditBillInfo";
import { Box, Menu, MenuItem } from "@mui/material";
import { PrintingBackdrop } from "../../../DirectBilling/Edit/components/sub-components/BackDrop";
import {
  A5PrintComponent,
  ExportConfirmationDialog,
  TaxierenConfirmationDialog,
} from "../../../DirectBilling/Edit/exports";
import { DeleteConfirmationDialog } from "../../../DirectBilling/common/exports";
import A4PrintComponent from "../../../DirectBilling/Edit/components/A4PrintComponent";
import { HeaderButtons } from "./sub-components/PostHeaderButtons";
import PostBillCardList from "./PostEditBillCards";
import { usePostBillSummary } from "../hooks/usePostBillSummary";
import BillSummary from "../../../DirectBilling/NewBill/components/sub-components/BillSummary";
import { useBillSummary } from "../../../DirectBilling/NewBill/hooks/useBillSummary";

const PostEditBillInfo: FC<PostEditBillInfoProps> = (props) => {
  const {
    // states
    anchorEl,
    openDeleteDialog,
    bills,
    notes,
    openExportDialog,
    selectedRxId,
    openTaxierenDialog,
    printData,
    isPrinting,
    isDrucken,
    printRef,
    todayString,
    // isSoftDeleteDialogOpen,
    // testToggleChecked,
    // openTestTypeDialog,
    testTypeData,
    isExporting,

    // handlers
    setOpenCancelTaxierenDialog,
    setOpenExportDialog,
    setSelectedRxId,
    setNotes,
    // setTestTypeData,
    // setTestToggleChecked,
    handleMenuOpen,
    handleMenuClose,
    handleDeleteClick,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleTaxierenClick,
    handleCancelTaxierenConfirm,
    handleCancelTaxierenClick,
    // handleAddSingleRx,
    handleValidationFilter,
    handleSave,
    handleDrucken,
    handleExport,
    handleExportConfirm,
    // handleSoftDeleteCancel,
    // handleSoftDeleteConfirm,
    // handleTestToggleChange,
    // handleTestTypeDialogClose,

    // from props
    onRowClick,
  } = usePostEditBillInfo(props);

  const { totalRxs, passedValidationRxs, problematicRxs, taxiertRxs, numberOfPayers } =
    useBillSummary({ bills });

  //   const [isSpotlightOpen, setIsSpotlightOpen] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {/* Backdrop for printing */}
      <PrintingBackdrop
        isPrinting={isPrinting || isDrucken || isExporting}
        message={
          isPrinting || isDrucken
            ? "Bitte warten, Druckvorgang läuft... "
            : "Bitte warten.  Export läuft...."
        }
      />

      {/* Header Buttons */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "#5c758a",
          borderRadius: "0px 0px 10px 10px",
        }}
      >
        <HeaderButtons
          handleValidationFilter={handleValidationFilter}
          handleSave={handleSave}
          handleDrucken={() => handleDrucken(testTypeData.type)}
          handleExport={handleExport}
        />

        <Box sx={{ px: 1 }}>
          <BillSummary
            totalRxs={totalRxs}
            passedValidationRxs={passedValidationRxs}
            problematicRxs={problematicRxs}
            taxiertRxs={taxiertRxs}
            numberOfPayers={numberOfPayers}
          />
        </Box>
      </Box>

      {/* BILLS */}
      <PostBillCardList
        bills={bills}
        todayString={todayString}
        selectedRxId={selectedRxId}
        notes={notes}
        setNotes={setNotes}
        onRowClick={onRowClick}
        setSelectedRxId={setSelectedRxId}
        handleMenuOpen={handleMenuOpen}
        handleTaxierenClick={handleTaxierenClick}
      />

      {/* Export Confirmation Dialog */}
      <ExportConfirmationDialog
        open={openExportDialog}
        onClose={() => {
          setOpenExportDialog(false);
        }}
        onConfirm={() => {
          handleExportConfirm();
        }}
      />

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />

      {/* Taxieren Confirmation Dialog */}
      <TaxierenConfirmationDialog
        open={openTaxierenDialog}
        onClose={() => {
          setOpenCancelTaxierenDialog(false);
        }}
        onConfirm={handleCancelTaxierenConfirm}
      />

      {/* Print Component For Taxiren*/}
      {printData && (
        <div style={{ display: "none" }}>
          {printData.isZahnarzt ? (
            <A4PrintComponent
              ref={printRef}
              billNumber={printData.billNumber}
              rxNumber={printData.rxNumber}
              careProviderIk={printData.careProviderIk}
            />
          ) : (
            <A5PrintComponent
              ref={printRef}
              billNumber={printData.billNumber}
              rxNumber={printData.rxNumber}
              careProviderIk={printData.careProviderIk}
            />
          )}
        </div>
      )}

      {/* Kebab Menu (Actions) */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>Entfernen</MenuItem>
        <MenuItem onClick={handleCancelTaxierenClick}>Untaxieren</MenuItem>
      </Menu>
    </Box>
  );
};

export default PostEditBillInfo;
