import React, { FC, useEffect, useState } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { useEditBillInfo } from "../hooks/useEditBillInfo";
import { EditBillInfoProps } from "../types/EditBillInfo.types";
import "../../common/helpers/css/direct-bill-info.css";

// Sub-components
import {
  A5PrintComponent,
  ExportConfirmationDialog,
  TaxierenConfirmationDialog,
  InputTestTypeDialog,
  BillCardList,
} from "../exports";
import { PrintingBackdrop } from "./sub-components/BackDrop";
import { DeleteConfirmationDialog } from "../../common/exports";
import { HeaderButtons } from "./sub-components/HeaderButtons";
import { TestType } from "../types/TestTypes.interface";
import { useBillSummary } from "../../NewBill/hooks/useBillSummary";
import BillSummary from "../../NewBill/components/sub-components/BillSummary";
import SpotlightSearch from "../../common/components/SpotlightSearch";
import A4PrintComponent from "./A4PrintComponent";
import { BillingStepper } from "../../../../Base/components/Stepper";

const EditBillInfo: FC<EditBillInfoProps> = (props) => {
  const {
    // states
    anchorEl,
    openDeleteDialog,
    bills,
    notes,
    openExportDialog,
    selectedRxId,
    openTaxierenDialog,
    printData,
    isPrinting,
    isDrucken,
    printRef,
    isExportLoading,
    todayString,
    isSoftDeleteDialogOpen,
    testToggleChecked,
    openTestTypeDialog,
    testTypeData,
    billsChanged,

    // handlers
    setOpenCancelTaxierenDialog,
    setOpenExportDialog,
    setSelectedRxId,
    setNotes,
    setTestTypeData,
    setTestToggleChecked,
    handleMenuOpen,
    handleMenuClose,
    handleDeleteClick,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleTaxierenClick,
    handleCancelTaxierenConfirm,
    handleCancelTaxierenClick,
    handleAddSingleRx,
    handleValidationFilter,
    handleSave,
    handleDrucken,
    handleExport,
    handleExportConfirm,
    handleSoftDeleteCancel,
    handleSoftDeleteConfirm,
    handleTestToggleChange,
    handleTestTypeDialogClose,

    // from props
    onRowClick,
    onRemoveAll,
  } = useEditBillInfo(props);

  const { totalRxs, passedValidationRxs, problematicRxs, taxiertRxs, numberOfPayers } =
    useBillSummary({ bills });

  const [isSpotlightOpen, setIsSpotlightOpen] = useState(false);

  // State for stepper
  const [activeStep, setActiveStep] = useState(0);

  // If user edits bills after step 0, revert back to step 0
  useEffect(() => {
    if (billsChanged && activeStep > 0) {
      setActiveStep(0);
    }
  }, [billsChanged, activeStep]);

  const handleSaveClicked = async () => {
    await handleSave();
    setActiveStep(1);
  };

  const handleDruckenClicked = async () => {
    await handleDrucken(testTypeData.type);
    setActiveStep(2);
  };

  const handleExportClicked = async () => {
    await handleExport();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {/* Backdrop for printing */}
      <PrintingBackdrop isPrinting={isPrinting || isDrucken} />

      {/* Header Buttons */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "#5c758a",
          borderRadius: "0px 0px 10px 10px",
        }}
      >
        <Box sx={{ mt: 1 }}>
          <BillingStepper activeStep={activeStep} />
        </Box>
        <HeaderButtons
          onAddSingleRx={() => setIsSpotlightOpen(true)}
          handleValidationFilter={handleValidationFilter}
          // handleSave={handleSave}
          // handleDrucken={() => handleDrucken(testTypeData.type)}
          // handleExport={handleExport}
          // ============ NEW HANDLERS TO INTEGRATE STEPPERS============
          handleSave={handleSaveClicked}
          handleDrucken={handleDruckenClicked}
          handleExport={handleExportClicked}
          /// ============ END NEW HANDLERS ============
          testToggleChecked={testToggleChecked}
          handleTestToggleChange={handleTestToggleChange}
          testTypeData={testTypeData}
        />
        <Box sx={{ px: 1 }}>
          <BillSummary
            totalRxs={totalRxs}
            passedValidationRxs={passedValidationRxs}
            problematicRxs={problematicRxs}
            taxiertRxs={taxiertRxs}
            numberOfPayers={numberOfPayers}
          />
        </Box>
      </Box>

      {/* BILLS */}
      <BillCardList
        bills={bills}
        todayString={todayString}
        selectedRxId={selectedRxId}
        notes={notes}
        setNotes={setNotes}
        onRowClick={onRowClick}
        setSelectedRxId={setSelectedRxId}
        handleMenuOpen={handleMenuOpen}
        handleTaxierenClick={handleTaxierenClick}
        onRemoveAll={onRemoveAll}
      />

      <SpotlightSearch
        open={isSpotlightOpen}
        onOpen={() => setIsSpotlightOpen(true)}
        onClose={() => setIsSpotlightOpen(false)}
        onRxSelect={(rx) => {
          handleAddSingleRx(rx.rxNumber);
          setIsSpotlightOpen(false);
        }}
      />

      {/* Export Confirmation Dialog */}
      <ExportConfirmationDialog
        open={openExportDialog}
        onClose={() => {
          setOpenExportDialog(false);
        }}
        onConfirm={() => {
          if (testToggleChecked) {
            handleExportConfirm(testTypeData); // If user is in test mode, pass the testType, If not in test mode, pass nothing => defaults to GLOBAL
          } else {
            handleExportConfirm({ type: TestType.GLOBAL, email: undefined });
          }
        }}
      />

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />

      {/* Soft Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={isSoftDeleteDialogOpen}
        onCancel={handleSoftDeleteCancel}
        onConfirm={handleSoftDeleteConfirm}
        title="Transaktionsbestätigung löschen"
        content="Dies ist das letzte Element. Möchten Sie die Transaktion wirklich löschen?"
      />

      {/* Taxieren Confirmation Dialog */}
      <TaxierenConfirmationDialog
        open={openTaxierenDialog}
        onClose={() => {
          setOpenCancelTaxierenDialog(false);
        }}
        onConfirm={handleCancelTaxierenConfirm}
      />

      {/* Print Component For Taxiren*/}
      {printData && (
        <div style={{ display: "none" }}>
          {printData.isZahnarzt ? (
            <A4PrintComponent
              ref={printRef}
              billNumber={printData.billNumber}
              rxNumber={printData.rxNumber}
              careProviderIk={printData.careProviderIk}
            />
          ) : (
            <A5PrintComponent
              ref={printRef}
              billNumber={printData.billNumber}
              rxNumber={printData.rxNumber}
              careProviderIk={printData.careProviderIk}
            />
          )}
          {/* <A5PrintComponent
            ref={printRef}
            billNumber={printData.billNumber}
            rxNumber={printData.rxNumber}
            careProviderIk={printData.careProviderIk}
          /> */}
        </div>
      )}

      {/* Test Type Dialog - For Test Mode Only */}
      <InputTestTypeDialog
        isOpen={openTestTypeDialog}
        onClose={() => {
          setTestToggleChecked(false);
          handleTestTypeDialogClose();
        }}
        onConfirm={(chosenType, chosenEmail) => {
          setTestTypeData({ type: chosenType, email: chosenEmail });
          handleTestTypeDialogClose();
        }}
      />

      {/* Backdrop for export */}
      {isExportLoading && (
        <PrintingBackdrop
          isPrinting={isExportLoading}
          message="Bitte warten Sie. Exportieren... "
        />
      )}

      {/* Kebab Menu (Actions) */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>Entfernen</MenuItem>
        <MenuItem onClick={handleCancelTaxierenClick}>Untaxieren</MenuItem>
      </Menu>
    </Box>
  );
};

export default EditBillInfo;
