import React, { useState } from "react";
import { Button, Grid, Typography, Box, TextField, TableContainer, Paper } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import PaymentMethodDialog from "../dialogs/PaymentMethodDialog";
import { BillFullScreenDialog } from "../dialogs/BillFullScreenDialog";
import { EditFullScreen } from "../../DirectBilling/Edit/exports";

// Hooks & store
import { useInsuranceBills } from "../hooks/useInsuranceBills";

// Types

import { InsuranceBillsTable } from "./InsuranceBillsTable";
import { InsuranceBillsPagination } from "./InsuranceBillsPagination";
import { BillingTypeEnum, InsuranceBillsContainerProps } from "../types/insuranceBills.types";
import useStore from "../../../../../helpers/useStore";
import { TransactionsContext } from "../../../../../stores/Transactions/Transactions.provider";
import { TopLevelPaper } from "../../../../../themes/StyledComponents";
import { BillingStatus } from "../pages/InsurancePageLayout";
import { DeleteConfirmationDialog } from "../../DirectBilling/common/exports";
import ExitWarningDialog from "../../DirectBilling/common/dialogs/ExitWarningDialog";
import { commonStyles } from "../../../Kasse/components/TransactionsDisplayContainer";
import PostEditFullScreen from "../../PostBilling/Edit/pages/PostEditFullScreen";
import { PrintingBackdrop } from "../../DirectBilling/Edit/components/sub-components/BackDrop";

export const InsuranceBillsContainer: React.FC<InsuranceBillsContainerProps> = ({
  billStatus,
  billingType = BillingTypeEnum.GKV,
}) => {
  // State for PaymentMethodDialog
  const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(false);

  // State for edit dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [editBills, setEditBills] = useState<any[]>([]);

  // Selected bills for editing
  const [selectedBillIds, setSelectedBillIds] = useState<string[]>([]);

  //State for delete dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [transactionIdToDelete, setTransactionIdToDelete] = useState<string | null>(null);

  // State for loading spinner
  const [isLoading, setIsLoading] = useState(false);

  // Store
  const transactionStore = useStore(TransactionsContext);
  const {
    getAllBGInsuranceBill,
    getAllInsuranceBill,
    lastUpdate,
    getBillingDataForEditing,
    softDeleteTransaction,
  } = transactionStore;

  // Use the custom hook to manage fetching, pagination, date filtering
  const {
    allBill,
    searchValue,
    paginatedData,
    page,
    limit,
    setPage,
    setLimit,
    currentRange,
    fetchAllBill,
    handleStartDateChange,
    handleEndDateChange,
    setSearchValue,
  } = useInsuranceBills({
    getAllBGInsuranceBill,
    getAllInsuranceBill,
    billStatus,
    lastUpdate,
    billType: billingType,
  });

  const handleNewRechnung = () => {
    setOpenPaymentDialog(true);
  };

  const handleClosePaymentDialog = () => {
    setOpenPaymentDialog(false);
  };

  // Edit selected bills
  const handleSubmissionForEdit = async () => {
    try {
      setIsLoading(true);
      const bills = await getBillingDataForEditing(selectedBillIds);
      setEditBills(bills);
      setIsDialogOpen(true);
      setSelectedBillIds([]);
    } catch (error) {
      console.error("Error fetching billing data for editing:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDialogClose = (skipWarning: boolean = false) => {
    if (skipWarning) {
      setIsDialogOpen(false);
      setEditBills([]);
      fetchAllBill();
      return;
    }
    setIsWarningDialogOpen(true);
  };

  const confirmClose = () => {
    setIsWarningDialogOpen(false);
    setIsDialogOpen(false);
    setEditBills([]);
    fetchAllBill();
  };

  const cancelClose = () => {
    setIsWarningDialogOpen(false); // Close only the warning dialog
  };

  // 2. Handler to open the dialog with the transaction ID
  const handleRequestDelete = (id: string) => {
    setTransactionIdToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setTransactionIdToDelete(null);
  };

  // 4. Confirm deletion
  const handleConfirmDelete = async () => {
    if (!transactionIdToDelete) return;
    try {
      await softDeleteTransaction(transactionIdToDelete);
      fetchAllBill();
    } catch (error) {
      console.error("Error soft-deleting transaction:", error);
    }

    // Close dialog and reset state
    setDeleteDialogOpen(false);
    setTransactionIdToDelete(null);
  };

  // Table selection logic
  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const newSelecteds = allBill.map((n) => n.id);
      setSelectedBillIds(newSelecteds);
    } else {
      setSelectedBillIds([]);
    }
  };

  const handleSelectOne = (id: string) => {
    setSelectedBillIds((prev) => {
      const isSelected = prev.includes(id);
      if (isSelected) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  const handleEditOne = async (id: string) => {
    try {
      setIsLoading(true);
      const bills = await getBillingDataForEditing([id]);
      setEditBills(bills);
      setIsDialogOpen(true);
    } catch (error) {
      console.error("Error fetching billing data for editing:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // You can conditionally set the title or other differences based on billingType
  const titleText = billingType === "GKV" ? "GKV digitale Abrechnung" : "Postabrechnung";
  // Render

  return (
    <>
      <TopLevelPaper sx={{ borderRadius: "0 0 12px 12px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container>
            {/* Left side: Search & 'Neu' Button */}
            <Grid
              item
              xs={6.5}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ height: 60 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    label="Suche nach Kostenträger-IK oder Name"
                    size="small"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    sx={{
                      height: 40,
                      minWidth: 300,
                    }}
                  />

                  <Button
                    onClick={handleNewRechnung}
                    sx={{
                      marginTop: 0.5,
                      height: 40,
                      minWidth: 80,
                    }}
                  >
                    Neu
                  </Button>
                </Box>
                {openPaymentDialog && (
                  <PaymentMethodDialog
                    open={openPaymentDialog}
                    handleClose={handleClosePaymentDialog}
                  />
                )}
                {/* Dynamic heading: GKV or Post */}
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "right",
                    fontWeight: "bold",
                    fontSize: "1.6rem",
                    color: "#000",
                    ml: "auto",
                  }}
                >
                  {titleText}
                </Typography>
              </Box>
            </Grid>

            {/* Right side: "Edit Selected Bills" button, date pickers */}
            <Grid
              item
              xs={5.5}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gap={2}
                sx={{ height: 60 }}
              >
                <Button
                  className="fancy-button"
                  onClick={handleSubmissionForEdit}
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "capitalize",
                    height: 40,
                    minWidth: 150,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 0.5,
                  }}
                  disabled={selectedBillIds.length === 0 || billStatus !== BillingStatus.START}
                >
                  Edit Selected Bills
                </Button>

                {/* Date Pickers */}
                <DatePicker
                  label="Startdatum"
                  value={currentRange.startDate}
                  defaultValue={dayjs()}
                  onChange={(date) => handleStartDateChange(date)}
                  maxDate={currentRange.endDate || dayjs()}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 40,
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                />
                <DatePicker
                  label="Enddatum"
                  value={currentRange.endDate}
                  defaultValue={dayjs()}
                  onChange={(date) => handleEndDateChange(date)}
                  minDate={currentRange.startDate || dayjs()}
                  maxDate={dayjs()}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 40,
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* Table / Content */}
          {allBill.length > 0 ? (
            <>
              <InsuranceBillsTable
                bills={allBill}
                billStatus={billStatus}
                selectedBillIds={selectedBillIds}
                onSelectAll={handleSelectAll}
                onSelectOne={handleSelectOne}
                onEdit={handleEditOne}
                onDelete={handleRequestDelete}
              />

              {/* Delete Confirmation Dialog */}
              <DeleteConfirmationDialog
                open={deleteDialogOpen}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                title="Transaktionsbestätigung löschen"
                content="Möchten Sie die Transaktion wirklich löschen?"
              />

              {/* Pagination */}
              {paginatedData && paginatedData.meta.totalItems > 0 && (
                <InsuranceBillsPagination
                  totalItems={paginatedData.meta.totalItems}
                  totalPages={paginatedData.meta.totalPages}
                  limit={limit}
                  page={page}
                  onPageChange={setPage}
                  onLimitChange={(newLimit) => {
                    setLimit(newLimit);
                    setPage(1);
                  }}
                />
              )}
            </>
          ) : (
            <Box
              sx={{
                ...commonStyles,
                maxHeight: "70vh",
                display: "flex",
                flexDirection: "column",
                borderRadius: "12px",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{ flex: 1, overflow: "auto" }}
              >
                <Typography
                  margin={2}
                  variant="subtitle1"
                >
                  Für den ausgewählten Status wurden keine Transaktionen gefunden.
                </Typography>
              </TableContainer>
            </Box>
          )}
        </LocalizationProvider>

        {/* Loading Spinner */}
        {isLoading && (
          <PrintingBackdrop
            isPrinting={isLoading}
            message="Lade Rechnungen..."
          />
        )}

        {/* Full Screen Edit Dialog */}
        {isDialogOpen && (
          <BillFullScreenDialog
            open={isDialogOpen}
            fullScreen
            titleComp={"VERSICHERUNGSRECHNUNGEN BEARBEITEN"}
            actions={
              <Button
                variant="text"
                onClick={() => handleDialogClose(false)}
              >
                Abbrechen
              </Button>
            }
          >
            {/* <EditFullScreen
              handleFullScreenDialogClose={handleDialogClose}
              initialBills={editBills}
            /> */}
            {billingType === BillingTypeEnum.GKV ? (
              <EditFullScreen
                handleFullScreenDialogClose={handleDialogClose}
                initialBills={editBills}
              />
            ) : (
              <PostEditFullScreen
                handleFullScreenDialogClose={handleDialogClose}
                initialBills={editBills}
              />
            )}
          </BillFullScreenDialog>
        )}
        <ExitWarningDialog
          isOpen={isWarningDialogOpen}
          onConfirm={confirmClose}
          onCancel={cancelClose}
        />
      </TopLevelPaper>
    </>
  );
};
