import { GetRechnungDetailsDto } from "../../../../api-client";
import { paymentStatus } from "../types/kasse.types";
import dayjs from "dayjs";

function daysBetween(date1: string | Date, date2: string | Date): number {
  const d1 = dayjs(date1);
  const d2 = dayjs(date2);
  return d2.diff(d1, "day");
}

export function canCreateReminder(rechnung: GetRechnungDetailsDto): boolean {
  if (
    rechnung.paymentStatus === paymentStatus.PAID
  ) {
    return false;
  }

  //Check tarif group
  const isPrivat = rechnung.tarifGroup === "Privat";

  //Count existing reminders
  const reminders = rechnung.billReminders || [];
  const existingLevels = reminders.map((r) => r.level);
  
  // If not privat => only 1 reminder allowed
  if (!isPrivat) {
    // Already has FIRST_REMINDER => no more
    if (existingLevels.includes(1)) {
      return false;
    }
  } else {
    // If privat => up to 3
    if (existingLevels.includes(3)) {
      return false;
    }
  }

  // This is the 14 days rule for reminders
  // If there's at least one reminder, find the latest
  const sorted = reminders.slice().sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  let lastReminderDate: Date;
  if (sorted.length > 0) {
    lastReminderDate = new Date(sorted[0].createdAt);
  } else {
    // no reminders => compare rechnung.createdAt
    lastReminderDate = new Date(rechnung.createdAt);
  }

  const diffDays = daysBetween(lastReminderDate, new Date());
  if (diffDays < 14) {
    return false;
  }

  return true;
}