import { useEffect, useState } from "react";
import { CreateInsuranceTransactionDto, GroupedDataDto } from "../../../../../../api-client";
import { NewBillInfoProps } from "../exports";
import useStore from "../../../../../../helpers/useStore";
import { TransactionsContext } from "../../../../../../stores/Transactions/Transactions.provider";
import notificationStore from "../../../../../../stores/Notification/notificationStore";
import { transactionMethods } from "../../../../Kasse/types/kasse.types";
import { BillingTypeEnum } from "../../../Base/types/insuranceBills.types";
export function useNewBillInfo(props: NewBillInfoProps) {
  const {
    handleFullScreenDialogClose,
    onRowClick,
    account,
    userName,
    userId,
    onRxDelete,
    onValidationFilter,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRx, setSelectedRx] = useState<any | null>(null);
  const [selectedRxId, setSelectedRxId] = useState<string | null>(null);
    const [notes, setNotes] = useState<Record<string, string>>({});

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSingleRxDialog, setOpenSingleRxDialog] = useState(false);
  const [rxNumber, setRxNumberInput] = useState("");
  const [rxNumberError, setRxNumberError] = useState("");

  const [tableVisible, setTableVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bills, setBills] = useState<GroupedDataDto[]>([]);

    // State for Stepper
    const [billsChanged, setBillsChanged] = useState(false);
    const [billsSnapshot, setBillsSnapshot] = useState(JSON.stringify(bills));
  
    // If bills changes at any time, detect it
  useEffect(() => {
    const newSnapshot = JSON.stringify(bills);
    // If the snapshot differs from the last "saved" snapshot, mark as changed
    if (newSnapshot !== billsSnapshot) {
      setBillsChanged(true);
    }
  }, [bills, billsSnapshot]);

  const TransactionStore = useStore(TransactionsContext);
  const {
    directBillingAllInfo,
    createTransaction,
    getSingleRxForIndirectBillsForInsurance,
  } = TransactionStore;

  // Kebab Menu
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, rx: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRx(rx);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Delete
  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
    handleMenuClose();
  };
  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setSelectedRx(null);
  };
  const handleDeleteConfirm = () => {
    if (!selectedRx) return;

    setBills((prev) =>
      prev
        .map((bill) => {
          if (bill.data.rxs.some((rx) => rx.id === selectedRx.id)) {
            const updatedRxData = bill.data.rxs.filter((rx) => rx.id !== selectedRx.id);
            const updatedTotalPrice = updatedRxData.reduce((total, rx) => total + (rx.rxPrice.grossValue || 0), 0);

            return {
              ...bill,
              data: {
                ...bill.data,
                rxs: updatedRxData,
                totalPrice: updatedTotalPrice,
              },
            };
          }
          return bill;
        })
        .filter((bill) => bill.data.rxs.length > 0)
    );

    notificationStore.showMessage("Rezept erfolgreich entfernt", "success");
    onRxDelete(selectedRx.id);

    setSelectedRx(null);
    setOpenDeleteDialog(false);
  };

  // Add Single Rx
  const handleAddSingleRx = async (rxNumber: string) => {
    if (!rxNumber.trim()) {
      setRxNumberError("Bitte geben Sie eine Rezept-Nummer ein");
      return;
    }

    try {
      const rxInfo = await getSingleRxForIndirectBillsForInsurance(rxNumber.trim());
      if (rxInfo && rxInfo.rx) {
        const newRx = rxInfo.rx;
        // Check if the RX already exists
        const rxExists = bills.some((bill) => bill.data.rxs.some((rx) => rx.id === newRx.id));
        if (rxExists) {
          notificationStore.showMessage("Dieses Rezept ist bereits auf der Liste", "warning");
          return;
        }

        const payerIK = newRx.payer?.payerId;
        if (!payerIK) {
          notificationStore.showMessage("Payer IK not found for the Rx", "error");
          return;
        }

        // Update bills
        setBills((prev) => {
          const billIndex = prev.findIndex((b) => b.payerIK === payerIK);
          if (billIndex !== -1) {
            const updatedRxData = [...prev[billIndex].data.rxs, newRx];
            const updatedTotalPrice = prev[billIndex].data.totalPrice + (newRx.rxPrice.grossValue || 0);

            const updatedBill = {
              ...prev[billIndex],
              data: {
                ...prev[billIndex].data,
                rxs: updatedRxData,
                totalPrice: updatedTotalPrice,
              },
            };
            return [...prev.slice(0, billIndex), updatedBill, ...prev.slice(billIndex + 1)];
          } else {
            // Create new
            const newBill = {
              payerIK,
              data: {
                payerDto: newRx.insurancePayer,
                rxs: [newRx],
                totalPrice: newRx.rxPrice.grossValue || 0,
              },
            };
            return [...prev, newBill];
          }
        });

        notificationStore.showMessage("Rezept erfolgreich hinzugefügt", "success");
      } else {
        notificationStore.showMessage("Rezept nicht gefunden", "error");
      }
    } catch (error) {
      console.error("Error fetching single RX:", error);
      notificationStore.showMessage("Rezept nicht gefunden", "error");
    }
  };

  // Load All
  const handleLoadAll = async () => {
    setIsLoading(true);
    setTableVisible(true);
    try {
      const fetchedBills = await directBillingAllInfo(account);
      setBills(fetchedBills);
      setBillsChanged(false);
      setBillsSnapshot(JSON.stringify(bills));
    } catch (error) {
      console.error("Error fetching Bills:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Save All
  const handleSave = async () => {
    try {
      for (const bill of bills) {
        const { payerIK, data } = bill;
        const { rxs, payerDto, totalPrice } = data;

        const totalPriceFormatted = totalPrice.toFixed(2);
        const rxsIds = rxs.map((rx) => rx.id).filter((id): id is string => !!id);
        const paperCenter = payerDto?.paperReceivingCenter?.ik;
        const digitalCenter = payerDto?.digitalReceivingCenter?.ik;

        const dataSubmission: CreateInsuranceTransactionDto = {
          payerId: payerIK,
          accountId: account,
          amount: Number(totalPriceFormatted),
          userId,
          method: transactionMethods.VERSICHERUNG,
          rxIds: rxsIds,
          paperCenterId: paperCenter || "",
          dataCenterId: digitalCenter || "",
          notes: notes[payerIK] || "", // or store in local notes[payerIK] if you want
          tAccountId: rxs[0].tarifGroupId === "Privat" ? 4105 : 4101,
          billType: BillingTypeEnum.GKV,
        };

        await createTransaction(dataSubmission);

        // Remove from local state (since it’s “saved”)
        //setBills((prev) => prev.filter((b) => b.payerIK !== payerIK));
      }
      notificationStore.showMessage("All transactions created successfully", "success");
      // handleFullScreenDialogClose();
      setBillsChanged(false);
      setBillsSnapshot(JSON.stringify(bills));
    } catch (error) {
      console.error("Error creating transactions", error);
      notificationStore.showMessage("Error creating transactions", "error");
    }
  };

  // Validation Filter
  const handleValidationFilter = () => {
    const updatedBills = bills
      .map((bill) => {
        const filteredRxs = bill.data.rxs.filter((rx) => {
          const validations = rx.formvalidation;
          return (
            validations &&
            Object.values(validations).every((v) => v.validity === "Valid")
          );
        });
        const updatedTotalPrice = filteredRxs.reduce((sum, rx) => sum + (rx.rxPrice.grossValue || 0), 0);

        return {
          ...bill,
          data: {
            ...bill.data,
            rxs: filteredRxs,
            totalPrice: updatedTotalPrice,
          },
        };
      })
      .filter((b) => b.data.rxs.length > 0);

    setBills(updatedBills);
    const remainingRxs = updatedBills.flatMap((b) => b.data.rxs);
    onValidationFilter?.(remainingRxs);
  };


  function handleRemoveAll(payerIK: string) {
  // Find the removed bill
  const removedBill = bills.find((bill) => bill.payerIK === payerIK);
  if (!removedBill) return;

  // the parent tracks selectedRx. So we need to notify the parent
  // if the selected Rx is in removedBill. 
  if (selectedRx && removedBill.data.rxs.some((rx) => rx.id === selectedRx.id)) {
    // Child can call a parent callback to unselect:
    onRxDelete(selectedRx.id);
  }

  // Filter the removed bill out of `bills` state
  setBills((prev) => prev.filter((bill) => bill.payerIK !== payerIK));
}

  return {
    // states
    anchorEl,
    selectedRxId,
    openDeleteDialog,
    openSingleRxDialog,
    rxNumberInput: rxNumber,
    rxNumberError,
    tableVisible,
    isLoading,
    bills,
    notes,
    billsChanged,

    // setters
    setSelectedRxId,
    setRxNumberInput,
    setOpenSingleRxDialog,
    setNotes,

    // handlers
    handleLoadAll,
    handleAddSingleRx,
    handleValidationFilter,
    handleSave,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleDeleteClick,
    handleMenuOpen,
    handleMenuClose,
    handleRemoveAll,

    // from props
    onRowClick,
    userName
  };
}
