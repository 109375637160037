import React, { FC, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Box,
  Collapse,
  Button,
  Chip,
} from "@mui/material";
import { AllTransactionMethods } from "../types/kasse.types";
import {
  BaseTransactionDetailsDto,
  GetBankTransferDto,
  GetRechnungDetailsDto,
  UserDto,
} from "../../../../api-client";
import "../../Insurance/DirectBilling/common/helpers/css/direct-bill-info.css";
import { formatDateUI } from "../../../../helpers/dateFormat";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import "../../Insurance/DirectBilling/common/helpers/css/scrollBar-styles.css";

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import { useDownload } from "../../../../hooks/useDownload";
import { TransactionsContext } from "../../../../stores/Transactions/Transactions.provider";
import useStore from "../../../../helpers/useStore";
import notificationStore from "../../../../stores/Notification/notificationStore";
import DeleteIcon from "@mui/icons-material/Delete";
import PayRechnungDialog from "../../../pages/PatientBills/dialogs/PayBankTransferDialog";
import { DeleteConfirmationDialog } from "../../Insurance/DirectBilling/common/exports";
import { UserContext } from "../../../../stores/User/User.provider";
import { canCreateReminder } from "../utility/CanCreateReminder";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";

interface TransactionTableProps {
  transactionMethod: AllTransactionMethods;
  transactions: any[];
  onRefresh?: () => void;
}

const TransactionTable: FC<TransactionTableProps> = ({
  transactionMethod,
  transactions,
  onRefresh,
}) => {
  // State to track which rows are open for RECHNUNG
  const [openRows, setOpenRows] = useState<Record<string, boolean>>({});
  const { handleDownloadPdf } = useDownload();

  const UserStore = useStore(UserContext);
  const [user, setUser] = useState<UserDto | null>();
  // const accountId = user?.location?.id;

  useEffect(() => {
    UserStore.getUser()
      .then((user) => {
        setUser(user);
      })
      .catch((error) => {
        console.error("Failed to fetch user:", error);
      });
  }, [UserStore]);

  const [selectedRechnung, setSelectedRechnung] = useState<GetRechnungDetailsDto | null>(null);
  const [payDialogOpen, setPayDialogOpen] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRechnung, setDeleteRechnung] = useState<GetRechnungDetailsDto | null>(null);

  const transactionStore = useStore(TransactionsContext);
  const { softDeleteRechnung, createBillReminder } = transactionStore;

  const filterIcons: any = {
    1: Filter1Icon,
    2: Filter2Icon,
    3: Filter3Icon,
  };

  const handleOpenPayDialog = (rechnung: GetRechnungDetailsDto) => {
    setSelectedRechnung(rechnung);
    setPayDialogOpen(true);
  };

  const handlePaymentSuccess = () => {
    setPayDialogOpen(false);
    onRefresh?.();
  };

  const handleOpenDeleteDialog = (rechnung: GetRechnungDetailsDto) => {
    setDeleteRechnung(rechnung);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteRechnung(null);
  };

  const handleDeleteConfirm = async () => {
    if (!deleteRechnung) return;
    try {
      await softDeleteRechnung(deleteRechnung.id);
      setDeleteDialogOpen(false);
      setDeleteRechnung(null);
      onRefresh?.();
      // fetchRechnungen();
      // fetchPaidBills();
    } catch (error) {
      console.error("Error deleting rechnung:", error);
      notificationStore.showMessage("Fehler beim Löschen der Rechnung", "error");
    }
  };

  const handleCreateReminder = async (rechnung: GetRechnungDetailsDto) => {
    try {
      if (!rechnung) return;
      await createBillReminder(rechnung.id);
      onRefresh?.();
    } catch (error) {
      console.error("Error creating reminder:", error);
      notificationStore.showMessage("Fehler beim Erstellen der Mahnung", "error");
    }
  };

  const handleToggleRow = (rechnungNumber: string) => {
    setOpenRows((prev) => ({
      ...prev,
      [rechnungNumber]: !prev[rechnungNumber],
    }));
  };

  let headers: string[] = [];
  let renderRow: (item: any) => JSX.Element;

  switch (transactionMethod) {
    /**
     * CASH, EC, and LASTSCHRIFT share the BaseTransactionDetailsDto shape
     */
    case "CASH":
    case "EC":
    case "LASTSCHRIFT":
      headers = [
        "Transaktion-Nr.",
        "Rezept-Nr.",
        "Rezeptdatum",
        "Patient",
        "Motiv",
        "Typ",
        "Erstellt von",
        "Mandant",
        "Erstellt am",
        "Preis (€)",
        "Exportiert",
        "Dokumentieren",
      ];
      renderRow = (item: BaseTransactionDetailsDto) => (
        <TableRow key={item.transactionNumber}>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.transactionNumber}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.rx ? item.rx.rxNumber : ""}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.rx?.rxDate ? formatDateUI(item.rx.rxDate) : "Keine Datum"}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.patient ? `${item.patient.lastName}, ${item.patient.firstName}` : ""}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.text}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.type}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.user ? `${item.user.lastName}, ${item.user.firstName}` : ""}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.account ? item.account.name : ""}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{formatDateUI(item.createdAt)}</TableCell>
          <TableCell
            sx={{ padding: "5px 0px 5px 5px", color: item.amount < 0 ? "red" : "inherit" }}
          >
            {item.amount < 0
              ? `(${Math.abs(Number(item.amount)).toFixed(2).replace(".", ",")} €)`
              : `${Number(item.amount).toFixed(2).replace(".", ",")} €`}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.wasExported ? "Yes" : "No"}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.docDto && (
              <IconButton onClick={() => handleDownloadPdf(item.docDto!.id)}>
                <PictureAsPdfIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      );
      break;

    /**
     * BANK_TRANSFER uses GetBankTransferDto with AllocationsDto[]
     * We split out each allocation property into its own column
     */
    case "BANKTRANSFER":
      headers = [
        "Transaktion-Nr.",
        "Rezept-Nr.",
        "Rezeptdatum",
        "Patient",
        "Motiv",
        "Typ",
        "Erstellt von",
        "Mandant",
        "Erstellt am",
        "Preis (€)",
        "Exportiert",
        "Rechnungnummer",
        // "Dokumentieren",
      ];
      renderRow = (item: GetBankTransferDto) => (
        <TableRow key={item.transactionNumber}>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.transactionNumber}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.rxNumber || ""}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.rxDate ? formatDateUI(item.rxDate) : "Keine Datum"}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.patientName}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.text}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.type}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.userName}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.accountName}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{formatDateUI(item.createdAt)}</TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {Number(item.amount).toFixed(2).replace(".", ",") + " €"}
          </TableCell>
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.wasExported ? "Yes" : "No"}
          </TableCell>

          {/* Allocation: Rechnung Number */}
          <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {item.allocations && item.allocations.length > 0
              ? item.allocations.map((alloc) => alloc.rechnungNumber).join(", ")
              : "None"}
          </TableCell>
          {/* <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
            {}
            <IconButton onClick={() => {}}>
              <PictureAsPdfIcon />
            </IconButton>
          </TableCell> */}
        </TableRow>
      );
      break;

    /**
     * RECHNUNG uses GetRechnungDetailsDto with BankTransferAllocationDto[]
     */
    case "RECHNUNG":
      headers = [
        "",
        "Rechnung-Nr.",
        "Rezept-Nr.",
        "Rezeptdatum",
        "Patient",
        "Motiv",
        "Erstellt von",
        "Mandant",
        "Erstellt am",
        "Originalpreis (€)",
        "Gesaamtpreis (€)",
        "Bezahlt (€)",
        "Fällig (€)",
        "Status",
        "Dokumentieren",
        "Mahnung Level",
        "Aktionen",
      ];
      renderRow = (item: GetRechnungDetailsDto) => {
        const isOpen = openRows[item.rechnungNumber] || false;
        return (
          <React.Fragment key={item.rechnungNumber}>
            {/* Main row */}
            <TableRow>
              {/* Expand/Collapse Icon Cell */}
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                <IconButton
                  size="small"
                  onClick={() => handleToggleRow(item.rechnungNumber)}
                  aria-label="expand row"
                >
                  {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>

              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.rechnungNumber}</TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.rxNumber}</TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {item.rxDate ? formatDateUI(item.rxDate) : "Keine Datum"}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {`${item.patientLastName}, ${item.patientFirstName}`}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.text || ""}</TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.userName || ""}</TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.accountName || ""}</TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {formatDateUI(item.createdAt)}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {Number(item.amount).toFixed(2).replace(".", ",") + " €"}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {Number(item.amountWithMahnung).toFixed(2).replace(".", ",") + " €"}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {Number(item.totalPaid).toFixed(2).replace(".", ",") + " €"}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {Number(item.due).toFixed(2).replace(".", ",") + " €"}
              </TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>{item.paymentStatus}</TableCell>
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                <IconButton onClick={() => handleDownloadPdf(item.docDto!.id)}>
                  <PictureAsPdfIcon />
                </IconButton>
                {item.billReminders?.map((reminder, index, array) => {
                  // Only render if there is an associated document.
                  if (!reminder.document) return null;
                  // Calculate the icon number: the last element gets number 1.
                  const iconNumber = array.length - index;
                  // Dynamically select the icon component.
                  const IconComponent = filterIcons[iconNumber] || Filter1Icon; // Fallback if not defined
                  return (
                    <IconButton
                      key={reminder.id}
                      onClick={() => handleDownloadPdf(reminder.document!.id)}
                      sx={{ ml: 1 }} // margin-left for spacing
                    >
                      <IconComponent />
                    </IconButton>
                  );
                })}
              </TableCell>
              {/* <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                <IconButton onClick={() => handleDownloadPdf(item.docDto!.id)}>
                  <PictureAsPdfIcon />
                </IconButton>
                {item.billReminders?.map((reminder) =>
                  reminder.document ? (
                    <IconButton
                      key={reminder.id}
                      onClick={() => handleDownloadPdf(reminder.document!.id)}
                      sx={{ ml: 1 }} // margin-left or some spacing
                    >
                      <Filter1Icon />
                    </IconButton>
                  ) : null
                )}
              </TableCell> */}
              <TableCell sx={{ padding: "5px 0px 5px 5px" }}>
                {(() => {
                  // 1. If there are no reminders at all, we show "N/A"
                  if (!item.billReminders?.length) return "N/A";

                  // 2. Sort by createdAt descending to get the *latest* reminder
                  const sorted = [...item.billReminders].sort(
                    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                  );
                  const lastReminder = sorted[0];
                  return lastReminder.level;
                })()}
              </TableCell>
              <TableCell sx={{ padding: "5px" }}>
                {/* Payment button or text */}
                {Number(item.due.toFixed(2)) > 0 ? (
                  <>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      gap={1}
                    >
                      <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        sx={{
                          fontSize: "0.7rem",
                          padding: "2px 5px",
                          minWidth: "auto",
                        }}
                        onClick={() => handleOpenPayDialog(item)}
                      >
                        Zahlen
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          fontSize: "0.7rem",
                          padding: "2px 5px",
                          minWidth: "auto",
                        }}
                        color="warning"
                        disabled={!canCreateReminder(item)}
                        onClick={() => handleCreateReminder(item)}
                      >
                        Mahnung
                      </Button>
                      <IconButton
                        size="medium"
                        color="error"
                        onClick={() => handleOpenDeleteDialog(item)}
                        title="Rechnung löschen"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <Chip
                    label="Vollständig bezahlt"
                    color="success"
                    variant="outlined"
                  />
                )}
              </TableCell>
            </TableRow>

            {/* Collapsible row (contains allocations) */}
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={headers.length}
              >
                <Collapse
                  in={isOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box margin={1}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                    >
                      Banküberweisungen
                    </Typography>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Transaktionnummer</TableCell>
                          <TableCell>Bezahlter Betrag (€)</TableCell>
                          <TableCell>Bezahlt am</TableCell>
                          <TableCell>Erstellt von</TableCell>
                          {/* <TableCell>Document</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.allocations && item.allocations.length > 0 ? (
                          item.allocations.map((alloc) => (
                            <TableRow key={alloc.transactionNumber}>
                              <TableCell>{alloc.transactionNumber}</TableCell>
                              <TableCell>
                                {Number(alloc.paidAmount).toFixed(2).replace(".", ",")} €
                              </TableCell>
                              <TableCell>{formatDateUI(alloc.createdAt)}</TableCell>
                              <TableCell>
                                {`${alloc.userLastName}, ${alloc.userFirstName}`}
                              </TableCell>
                              {/* <TableCell>
                                <IconButton onClick={() => {}}>
                                  <PictureAsPdfIcon />
                                </IconButton>
                              </TableCell> */}
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2}>Keine Banküberweisungen vorhanden</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      };
      break;

    default:
      headers = ["No columns defined"];
      renderRow = () => <TableRow />;
  }

  const sortedTransactions = transactions.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  return (
    <>
      <TableContainer
        component={Paper}
        className="scrollable"
        sx={{ maxHeight: "650px" }}
      >
        <Table
          size="small"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {headers.map((header, idx) => (
                <TableCell
                  key={idx}
                  sx={{ fontWeight: "bold" }}
                  className="table-header-cell"
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedTransactions && sortedTransactions.length > 0 ? (
              sortedTransactions.map((transaction) => renderRow(transaction))
            ) : (
              <TableRow>
                <TableCell colSpan={headers.length}>
                  <Typography align="center">
                    Für die ausgewählten Daten wurden keine Transaktionen gefunden.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Payment Dialog */}
      <PayRechnungDialog
        open={payDialogOpen}
        onClose={() => setPayDialogOpen(false)}
        rechnung={selectedRechnung}
        accountId={selectedRechnung?.accountName || ""}
        userId={user?.id || ""}
        onPaymentSuccess={handlePaymentSuccess}
      />

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        title="Rechnung löschen?"
        content="Möchten Sie diese Rechnung wirklich löschen? Der Vorgang kann rückgängig gemacht werden."
      />
    </>
  );
};

export default TransactionTable;
