import { useMemo } from "react";
import { PostBillSummaryProps } from "../types/PostBillSummary.types";

export function usePostBillSummary(props: PostBillSummaryProps) {
  const { bills } = props;

  const summary = useMemo(() => {
    let totalRxs = 0;
    let passedValidationRxs = 0;
    let problematicRxs = 0;
    let taxiertRxs = 0;
    const payers = new Set<string>();

    bills.forEach((bill) => {
      payers.add(bill.payer.ik);
      totalRxs += bill.rxs.length;
      bill.rxs.forEach((rx) => {
        if (rx.formvalidation) {
          const isValid = Object.values(rx.formvalidation).every((v) => v.validity === "Valid");
          if (isValid) {
            passedValidationRxs++;
          } else {
            problematicRxs++;
          }
        }
        if (rx.isTaxieren) {
          taxiertRxs++;
        }
      });
    });

    return {
      totalRxs,
      passedValidationRxs,
      problematicRxs,
      taxiertRxs,
      numberOfPayers: payers.size,
    };
  }, [bills]);

  return summary;
}
