
import { Box, TextareaAutosize } from '@mui/material';

const TextArea = (props:any) => {

    const { value, onChange,label, placeholder,disabled, rows,style, ...rest }=props;
  return (
    <>
      {label && <Box style={{ fontSize: '11px', fontWeight: 'normal'}}  >{label}</Box>}
      <TextareaAutosize
        minRows={rows}
        placeholder={!value ? placeholder : ''}
        value={value}
        onChange={onChange}
        disabled={disabled} // This will disable input but retain the appearance of the textarea
        style={{
          ...style,
          width: '100%',
          resize: 'none',
          border: '1px solid lightgray',
          padding: '8px',
          pointerEvents: disabled ? 'none' : 'auto', // Disables interaction without fading effect
          backgroundColor: disabled ? 'white' : 'white', // Optional: ensures background remains the same
          color: disabled ? 'black' : 'inherit', // Ensures text color is black when disabled
        }}
        {...rest}
      />
    </>
  );
};

export default TextArea;
