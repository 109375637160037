import React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { mobiliTheme, therapyColors } from "../themes/mobiliTheme";
import { Badge, Box, Tooltip } from "@mui/material";
import { visitTypeToIcon } from "../helpers/visitTypeMapping";
import { Euro } from "@mui/icons-material";

const GenericChip = ({ label, onDelete, sx, ...props }: any) => {
  return (
    <Chip
      label={label}
      onDelete={onDelete}
      sx={
        sx
          ? sx
          : {
              width: "100%",
              wordWrap: "break-word",
              border: "3px solid blue", // Add a border for debugging
              padding: "0px",
            }
      }
      {...props}
    />
  );
};


export const SmallChip = ({  sx, ...props }: any) => {
  let bgColor: string = mobiliTheme.topLevelBackground;
  const defaultSx = {
    width: "100%",
    wordWrap: "break-word",
    border: "0px solid blue", // Add a border for debugging
    padding: "0px",
    fontSize: "0.6em",
    borderRadius: ".9em",
    backgroundColor: bgColor,
    "& .MuiChip-label": {
      // Targeting the label specifically
      padding: "6px", // Adjust the padding as needed
    },
  }

  return (
      <Chip
        sx={
          sx
            ? { ...defaultSx, ...sx }
            : defaultSx
        }
        {...props}
      />
  );
};


export const TherapyChip = ({ therapyName, amount, sx, ...props }: any) => {
  const colorMap = therapyColors as any;

  let bgColor: string = colorMap[therapyName] || "grey";

  const defaultSx = {
    backgroundColor: bgColor,
  }

  return (
    <Tooltip title={therapyName}>
      <Box>
      <SmallChip
        label={amount ? `${amount} ${therapyName}` : therapyName}
        sx={
          sx
            ? { ...defaultSx, ...sx }
            : defaultSx
        }
        {...props}
      /></Box></Tooltip>
   
  );
};

interface VisitTypeBadgeProps {
  visitType: string;
  visitFee?: string | null;
  sx?:any;
}

export const VisitTypeBadge: React.FC<VisitTypeBadgeProps> = ({ visitType, visitFee ,sx}) => {
  const icon = visitTypeToIcon[visitType?.toLowerCase()] || null;
  const hasFee = Boolean(visitFee && visitFee !== "keine");

  return (
    visitType && (
      <Tooltip
        title={
          <>
            Visit Type: {visitType}
            {hasFee && (
              <>
                <br />
                Visit Fee: {visitFee}
              </>
            )}
          </>
        }
      >
        <Box display="inline-block">
          <Badge
            badgeContent={hasFee ? <Euro fontSize="small" sx={{ fontSize: "12px" }} /> : null}
            color="primary"
            overlap="circular"
            sx={{
              "& .MuiBadge-badge": {
                color: mobiliTheme.palette.warning.contrastText,
                borderRadius: "90%",
                minWidth: "14px",
                height: "14px",
                padding: "2px",
              },
            }}
          >
            {icon}
          </Badge>
        </Box>
      </Tooltip>
    )
  );
  
};
// const GenericChipList = ({ chips }:any) => {
//   return (
//     <Stack direction="row" spacing={1}>
//       {chips.map((chip:any, index:any) => (
//         <GenericChip
//           key={index}

//           label={chip.label}
//           onDelete={chip.onDelete ? () => chip.onDelete(index) : undefined}
//           {...chip.props}
//         />
//       ))}
//     </Stack>
//   );
// };

export default GenericChip;
