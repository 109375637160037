import React, { useState } from "react";
import { PatientDto } from "../../api-client";

export function useForm(
  initialFValues: any,
  validateOnChange = false,
  validate: any,
  setUnsavedChanges: any
) {
  const [values, setValues] = useState(initialFValues);
  // const [errors, setErrors] = useState({});
  const [errors, setErrors] = useState<Record<string, string>>({
    firstName: "",
    dob: "",
    // personType:"",
    payer: "",
    coPayment: "",
    insuranceNo: "",
    contact: "",
    lastName: "", // Add the 'lastName' property here
  });
  const handleInputChange = (e: any) => {
    const { name, value, id } = e.target;
    setUnsavedChanges(true);

    setValues((prevValues: any) => ({
      ...prevValues,
      [name]: id ? id : value,
    }));

    if (validateOnChange) {
      validate({ [name]: value });
    }
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}
export function rxuseForm(initialFValues: any, validateOnChange = false, validate: any) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [rxerrors, rxsetErrors] = useState<Record<string, string>>({
    dob: "",
    rxtype: "",
    accountId: "",
    chipcard: "",
    practiceNumber: "",
    doctorNumber: "",
    doctorId: "", // Add the 'lastName' property here
  });

  return {
    rxerrors,
     rxsetErrors,
  };
}

const Form = (props: any) => {
  // const classes = useStyles();
  const { children, ...other } = props;
  return (
    <form autoComplete="off" {...other}>
      {props.children}
    </form>
  );
};
export default Form;
