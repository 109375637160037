import { ClickAwayListener, Box, TextField, Popper, Paper, Tooltip, InputAdornment } from "@mui/material";
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import DateRangeIcon from "@mui/icons-material/DateRange";

interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (dates: { startDate: Date; endDate: Date }) => void;
  binType?: "day" | "week";
}

const BIDateRangePicker: React.FC<DateRangePickerProps> = ({ startDate, endDate, onChange, binType }) => {
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([dayjs(startDate), dayjs(endDate)]);
  const [isSelectingEndDate, setIsSelectingEndDate] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (startDate && endDate) {
      setDateRange([dayjs(startDate), dayjs(endDate)]);
      setIsSelectingEndDate(false);
    }
  
  }, [startDate, endDate]);

  const handleDateSelect = (newDate: Dayjs | null) => {
    console.log('handleDateSelect triggered');
    if (!isSelectingEndDate) {
      setDateRange([newDate, null]);
      setIsSelectingEndDate(true);
    } else {
      let updatedDateRange: [Dayjs | null, Dayjs | null] = [dateRange[0], newDate];

      // if start date is after end date, then swap
      if (updatedDateRange[0]?.isAfter(updatedDateRange[1])) {
        updatedDateRange = [updatedDateRange[1], updatedDateRange[0]];
      }


      if ((binType === 'day' || binType === 'week') && updatedDateRange[0] && updatedDateRange[1]) {
        console.log('binType:', binType);

        const dayDifference = (updatedDateRange[1].valueOf() - updatedDateRange[0].valueOf()) / (1000 * 60 * 60 * 24);
        console.log('Day difference:', dayDifference);
  
        // Make sure the date range is at least 30 days for day/week bins
        if (dayDifference < 30) {
          alert("Bitte wählen Sie einen Zeitraum von mindestens 30 Tagen aus.");
          return;
        }
      }

      setDateRange(updatedDateRange);
      setIsSelectingEndDate(false);

      if (updatedDateRange[0] && updatedDateRange[1]) {
        setAnchorEl(null);
        onChange({
          startDate: updatedDateRange[0]?.toDate() || new Date(),
          endDate: updatedDateRange[1]?.toDate() || new Date(),
        });
      }
    }
  };
    
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "transparent"}}>
          <Tooltip title="Datumbereich wählen" placement="top">
            <TextField
              value={dateRange[0] ? `${dateRange[0].format("DD.MM.YYYY")}${
                    dateRange[1] ? ` - ${dateRange[1].format("DD.MM.YYYY")}` : ""}`: ""
              }
              onClick={(event) => setAnchorEl(event.currentTarget)}
              variant="standard"
              size="small"
              sx={{
                // width: "170px",
                // height: 25,
                cursor: "pointer",
                padding: '0px',
                margin: '0px',
                backgroundColor: "transparent",
                ".MuiInputBase-root": {
                  height: 25,
                  padding: "0px",
                  margin: '0px',
                  fontSize: "12px",
                  fontFamily: "Inter",
                  backgroundColor: "transparent",
                },
                ".MuiInputBase-input": {
                  // textAlign: "left",
                  backgroundColor: "transparent",
                  marginBottom: '-5px',
                  padding: "0px",
                },
              }}
              InputProps={{ readOnly: true, 
                startAdornment: (
                <InputAdornment position="start">
                  <DateRangeIcon fontSize="small" />
                </InputAdornment>
              ), }}
            />
          </Tooltip>
          <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start">
            <Paper elevation={3} sx={{ p: 1 }}>
              <DateCalendar
                value={dateRange[0] || undefined}
                onChange={handleDateSelect}
                sx={{
                  ".MuiPickersDay-root": {
                    fontSize: '12px',
                    fontFamily: 'Inter'
                  },
                  ".MuiCalendarPicker-root": {
                    fontSize: '12px',
                    fontFamily: 'Inter',
                    padding: '0px',
                    margin: '0px'
                  },
                }}
              />
            </Paper>
          </Popper>
        </Box>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};

export default BIDateRangePicker;
