import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Dialog,
  MenuItem,
  Select,
  CircularProgress,
  CardHeader,
  Menu,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/CloudUpload";
import PrintIcon from "@mui/icons-material/Print";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  DebitPermissionDto,
  DocumentTypeEnum,
  PatientDto,
  UploadDocumentMetadataDto,
} from "../../../../../api-client";
import useStore from "../../../../../helpers/useStore";
import { TransactionsContext } from "../../../../../stores/Transactions/Transactions.provider";
import FileCapture from "../../../../DocumentUpload/capture/fileCapture/FileCapture";
import DocumentUpload from "../../../../DocumentUpload/DocumentUpload";
import notificationStore from "../../../../../stores/Notification/notificationStore";
import DebitPermission from "./DebitPermission";
import { transactionMethods } from "../../../../Transactions/Kasse/types/kasse.types";
import { MoreVert, PictureAsPdf } from "@mui/icons-material";
import { useDownload } from "../../../../../hooks/useDownload";
import { observer } from "mobx-react";

interface PermissionCardProps {
  patientData: PatientDto;
  debitPermissions: DebitPermissionDto[];
  setDebitPermissions: React.Dispatch<React.SetStateAction<DebitPermissionDto[]>>;
  dataRefreshFlag: boolean;
  setDataRefreshFlag:any;
}

const PermissionCard: React.FC<PermissionCardProps> = observer(({
  patientData,
  debitPermissions,
  setDebitPermissions,
  dataRefreshFlag,
  setDataRefreshFlag
}) => {
  console.log("szzduduudu", patientData);
  const [isEditing, setIsEditing] = useState(false);

  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [selectedRow,setSelectedRow]=useState<DebitPermissionDto>();
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const TransactionsStore = useStore(TransactionsContext);
  const { generateDebitPermissionPdf,softDeleteDebitPermission } = TransactionsStore;
  const fileMetaData: UploadDocumentMetadataDto = {
    docuType: DocumentTypeEnum.Mandat,
    ownerType: "Patient",
    ownerId: patientData.id as unknown as string,
    makeAccessible: true,
  };
  const [menuAnchorEl, setMenuAnchorEl] = useState<{ [key: string]: HTMLElement | null }>({});

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    setMenuAnchorEl((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleMenuClose = (id: string) => {
    setMenuAnchorEl((prev) => ({ ...prev, [id]: null }));
  };

  const handleOpenFormDialog = (permission:DebitPermissionDto) =>{ 
    setSelectedRow(permission);
    setIsEditing(true);
    setOpenFormDialog(true)
  };
  const handleCloseFormDialog = () => {
    setIsEditing(false);
    setOpenFormDialog(false);}
  const [accessUrl, setAccessUrl] = useState<string>();
  const handleDownloadPdf = async (id: string) => {
    try {
      const response = await generateDebitPermissionPdf(id);
      setAccessUrl(response.accessUrl);
    } catch (err) {
      console.error("Error downloading pdf", err);
    }
  };

  useEffect(() => {
    if (accessUrl) {
      console.log("azssuusu", accessUrl);
      window.open(accessUrl, "_blank");
    }
  }, [accessUrl]);

  const handleDeleteForm = async (formId: string) => {
    try {
      const response = await softDeleteDebitPermission(formId);
    
        setDataRefreshFlag(!dataRefreshFlag)
    
    } catch (error) {
      console.error("Error deleting form:", error);
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title="SEPA-Lastschriftmandate"
        subheader={`${debitPermissions.length} mal per Lastschrift bezahlt`}
        action={
          <Button
            startIcon={<AddIcon />}
            size="medium"
            variant="contained"
            color="secondary"
            onClick={()=>setOpenFormDialog(true) }
            sx={{
              fontSize: "0.7rem",
              padding: "2px 5px",
              minWidth: "auto",
            }}
          >
            Dauerlastschrift
          </Button>
        }
      />

      <CardContent>
  {debitPermissions && debitPermissions.length > 0 ? (
          <List>
            {debitPermissions.map((form) => (
              <React.Fragment key={form.id}>
                <ListItem>
                  <ListItemText
                    primary={`${form.firstName} ${form.lastName}`}
                    secondary={`Mandatsreferenz: ${form.id || "-"} | IBAN: ${form.iban || "-"} | BIC: ${form.bic || "-"} | Single Use: ${
                      form.isSingleUse ? "Ja" : "Nein"
                    } ${form.isSingleUse ? ` | Amount: ${Number(form.amount).toFixed(2).replace(".", ",")} €` : ""}`}
                  />

                  {/* Menu Icon Button */}
                  <IconButton onClick={(event) => handleMenuOpen(event, form.id)}>
                    <MoreVert />
                  </IconButton>

                  {/* Dropdown Menu for Each Row */}
                  <Menu
                    anchorEl={menuAnchorEl[form.id]}
                    open={Boolean(menuAnchorEl[form.id])}
                    onClose={() => handleMenuClose(form.id)}
                  >
                    <MenuItem
                      onClick={() => {
                        handleDeleteForm(form.id);
                        handleMenuClose(form.id);
                      }}
                    >
                      <DeleteIcon /> Löschen
                    </MenuItem>

                    {form.id && (
                      <MenuItem
                        onClick={() => {
                          handleDownloadPdf(form.id);
                          handleMenuClose(form.id);
                        }}
                      >
                        <PictureAsPdf /> PDF herunterladen
                      </MenuItem>
                    )}
                    {!form.isSingleUse && (
                      <MenuItem onClick={() =>{handleOpenFormDialog(form);
                      handleMenuClose(form.id)}}>
                        <EditIcon /> Bearbeiten
                      </MenuItem>
                    )}

                    <MenuItem>
                      <DocumentUpload
                        documentMetaData={fileMetaData}
                        setDocumentsLoading={setDocumentsLoading}
                        documentsLoading={documentsLoading}
                        uploadId={form.id}
                      />
                    </MenuItem>
                  </Menu>
                </ListItem>
                <Divider />
               
              </React.Fragment>

            ))}

          </List>
        ) : (
          <Typography>Keine Berechtigungen</Typography>
        )}
         {openFormDialog && (
                      <DebitPermission
                        open={openFormDialog}
                        handleTransactionClose={handleCloseFormDialog}
                        patientData={patientData}
                        transactionMethod={transactionMethods.LASTSCHRIFT}
                        isSingleUse={false}
                        selectedRow={selectedRow}
                        setDataRefreshFlag={setDataRefreshFlag}
                        dataRefreshFlag={dataRefreshFlag}
                        isEditing={isEditing}
                      />
                    
                    )}
      </CardContent>


    </Card>
  );
});

export default PermissionCard;
