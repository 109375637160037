import React, { useEffect, useState, useRef } from "react";
import Draggable from "react-draggable";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import useStore from "../../../helpers/useStore";
import {
  AppointmentDto,
  AppStatsDto,
  RxDto,
  RxDtoProcessingStatusEnum,
  TherapyRxDto,
  TimeSlotDto,
} from "../../../api-client";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  Box,
  Typography,
  Tooltip,
  DialogActions,
  Button,
  CardHeader,
  CardContent,
  Card,
  CircularProgress,
  Modal,
} from "@mui/material";
import TerminView, {
  AppointmentCardView,
  formatGap,
  TherapyCardView,
} from "../../PatientOverview/RxInfo/TerminView";
import { mobiliTheme } from "../../../themes/mobiliTheme";
import { formatDateDisplay, formatDateUI, formatTime } from "../../../helpers/dateFormat";
import { RxStoreContext } from "../../../stores/Rx/rx.provider";
import { visitTypeToIcon } from "../../../helpers/visitTypeMapping";
import { getColorForRxNumber } from "../../../helpers/statusColor";
import Controls from "../../../atoms/Controls";
import { SmallChip, TherapyChip, VisitTypeBadge } from "../../../atoms/Chip";
import { AllInclusive, CheckCircle, EventBusy, HelpRounded } from "@mui/icons-material";
import { MobiliAvatar } from "../../../atoms/MobiliAvatar";
import { FullScreenDialog } from "../../../atoms/FullScreenDialog";
import { TopLevelPaper } from "../../../themes/StyledComponents";
import "./style.css";
import notificationStore from "../../../stores/Notification/notificationStore";
import { observer } from "mobx-react";
import { TerminViewConstants } from "../../PatientOverview/TerminView";

interface AppointmentSchedulerModalProps {
  open: boolean;
  onClose: () => void;
  patientId: number;
  refresh: boolean;
  setRefresh: any;
}

const TherapyCardViewScheduler = ({
  therapyRx,
  appsStats,
}: {
  therapyRx: TherapyRxDto;
  appsStats?: AppStatsDto;
}) => {
  // Get the color based on the Rx number

  return (
    <Grid container direction="column">
      <Grid container></Grid>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          <TherapyChip
            therapyName={(therapyRx?.therapy as any).abbreviation}
            sx={{ fontSize: "0.5em" }}
          />
        </Grid>
        <Grid item xs={2}>
          <Controls.Chip
            label={
              <Typography>
                VO =
                {!appsStats
                  ? ""
                  : appsStats.amountPrescribed < 0
                  ? "∞"
                  : appsStats.amountPrescribed}
              </Typography>
            }
            variant="outlined"
            sx={{ height: 30 }}
          />
        </Grid>{" "}
        <Grid item xs={4}>
          <Controls.Chip
            label={<Typography>PLANNED ={appsStats ? appsStats.amountDefined : ""}</Typography>}
            variant="outlined"
            sx={{ height: 30 }}
          />
        </Grid>
        <Grid item xs={1}>
          {therapyRx?.rx?.isPerpetual && (
            <Tooltip title="">
              <AllInclusive fontSize={"small"} />
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const AppointmentScheduler: React.FC<AppointmentSchedulerModalProps> = observer(
  ({ open, onClose, patientId, refresh, setRefresh }) => {
    const [appointments, setAppointments] = useState<AppointmentDto[]>([]);
    const [selectedTherapyRx, setSelectedTherapyRx] = useState<TherapyRxDto>({});
    const [allRxs, setAllRxs] = useState<RxDto[]>([]);
    const dialogRef = useRef<HTMLDivElement>(null);
    const [appointmentForTherapyRx, setAppointmentsForTherapyRx] = useState<AppointmentDto[]>([]);

    const AppointmentStore = useStore(AppointmentContext);
    const { getAppointmentsForPatient, getAppointmentsForTherapyRx, updateAppointment } =
      AppointmentStore;
    const RxStore = useStore(RxStoreContext);
    const { getAllRx, getAppStats } = RxStore;
    const [draggedTherapyRx, setDraggedTherapyRx] = useState<TherapyRxDto | null>(null); // Track the currently dragged therapyRx
    const [draggedTimeSlot, setDraggedTimeSlot] = useState<TimeSlotDto | null>(null);
    const [draggingElement, setDraggingElement] = useState<any>(null); // Track the dragged element dynamically
    const [dragOverTarget, setDragOverTarget] = useState<any>(null); // Track the current drag-over target for styling
    const [loading, setLoading] = useState(false); // Loading state
    const [dataRefreshFlag, setDataRefreshFlag] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [appStats, setAppStats] = useState<AppStatsDto[]>([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadData(true);
          const response = await getAppointmentsForPatient(patientId as unknown as string);
          if (response) setAppointments(response);
        } catch (error) {
          console.log("Error fetching appointments", error);
        } finally {
          setLoadData(false);
        }
      };

      fetchData();
    }, [patientId, dataRefreshFlag]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          setAppStats([]); // Clear previous state before fetching new data
          const response = await getAllRx(patientId);
          setAllRxs(response);
          await getAppStatsData(response); // Fetch app stats after setting Rx data
        } catch (error) {
          console.error("Error fetching Rx data:", error);
        }
      };

      fetchData();
    }, [patientId, dataRefreshFlag]);

    const getAppStatsData = async (rxData: RxDto[]) => {
      if (!rxData || rxData.length === 0) return; // Ensure data exists

      // Extract therapyRxs from all RxDto items
      const therapyRxs = rxData.flatMap((rx: RxDto) => rx.therapyRxs || []);

      if (therapyRxs.length === 0) return; // No therapyRxs, no need to continue

      const rxIds = rxData.map((rx: RxDto) => rx?.id).filter((id): id is string => Boolean(id)); // Ensure valid IDs only

      try {
        const data = await getAppStats(rxIds);

        // Map therapyRxs to corresponding stats
        const sortedStats: any[] = therapyRxs.map((therapyRx) => {
          const stat = data.find((stat: AppStatsDto) => stat.therapyRxId === therapyRx.id);
          return (
            stat || {
              therapyRxId: therapyRx.id!,
              amountDefined: 0,
              amountPrescribed: 0,
              amountScheduled: 0,
            }
          );
        });

        setAppStats(sortedStats);
      } catch (error) {
        console.error("Error fetching app stats data:", error);
      }
    };

    const handleTherapyRxClick = async (therapyRx: TherapyRxDto, isContinuous: boolean) => {
      try {
        setLoading(true);
        setSelectedTherapyRx(therapyRx);
        const response = await getAppointmentsForTherapyRx(therapyRx.id!, false);
        setAppointmentsForTherapyRx(response);
      } catch (error) {
        notificationStore.showMessage("Error while fetching appointments", "error");
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (selectedTherapyRx) handleTherapyRxClick(selectedTherapyRx, false);
    }, [dataRefreshFlag]);

  // Drag Start Handler (TherapyRx)
  const handleTherapyRxDragStart = (
    event: React.DragEvent<HTMLElement>,
    therapyRx: TherapyRxDto
  ) => {
    setDraggedTherapyRx(therapyRx); // Set the therapyRx being dragged
    const draggedElement = event.target as HTMLElement;
    draggedElement.classList.add('dragging-source'); // Add the dragging class for visual feedback
    setDraggingElement(draggedElement); // Store the element that is being dragged
  };

  const handleTimeSlotDragStart = (
    event: React.DragEvent<HTMLElement>,
    timeSlot: TimeSlotDto,
    appointmentId: string
  ) => {
    setDraggedTimeSlot({ ...timeSlot, appointmentId }); // Set the timeSlot and appointment being dragged
    const draggedElement = event.target as HTMLElement;
    draggedElement.classList.add('dragging-source'); // Add the dragging class for visual feedback
    setDraggingElement(draggedElement); // Store the element that is being dragged
  };

  const handleDragEnd = (e: React.DragEvent<HTMLElement>) => {
    const draggedElement = e.target as HTMLElement;
    draggedElement.classList.remove('dragging-source'); // Remove the highlight when dragging ends
    setDraggedTimeSlot(null);
    setDraggedTherapyRx(null)
    setDraggingElement(null);
  };

  const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    const dropTarget = e.currentTarget as HTMLElement;
    dropTarget.classList.add('drop-target'); // Highlight the drop target
  };

  const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
    const dropTarget = e.currentTarget as HTMLElement;
    dropTarget.classList.remove('drop-target'); // Remove drop target highlight
  };

  const handleDrop = (
    e: React.DragEvent<HTMLElement>,
    appointmentId?: string | null,
    timeSlotId?: string | null,
    draggedTherapyDataContent?: TherapyRxDto
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    const timeSlotFetched = timeSlotId || draggedTimeSlot?.id;
    const appointmentFetched = appointmentId || draggedTimeSlot?.appointmentId;
    const draggedTherapyData = draggedTherapyRx || draggedTherapyDataContent;

    if (!draggedTherapyData || !appointmentFetched || !timeSlotFetched) {
      notificationStore.showMessage("Kein gezogenes Element", "warning");
      draggingElement.classList.remove('dragging-source');
      handleDragLeave(e)
      setDraggedTherapyRx(null);
      setDraggedTimeSlot(null);

      setLoading(false); // Stop loading in case of invalid drag
      return;
    }
    // Optional: Add the drop target highlight
    const targetElement = e.currentTarget;
      
        let updatedAppointment: any = null;
      const updatedAppointments = appointments.map((appointment) => {
        if (appointment.id === appointmentFetched.toString()) {
          const updatedTimeSlots = appointment.timeSlots?.map((timeSlot) => {
            if (timeSlot.id === timeSlotFetched.toString()) {
              return { ...timeSlot, therapyRx: draggedTherapyData };
            }
            return timeSlot;
          });

          updatedAppointment = { ...appointment, timeSlots: updatedTimeSlots };
          return updatedAppointment;
        }
        return appointment;
      });
        setAppointments(updatedAppointments);
        targetElement.classList.add('dropped-target'); // Apply a style after drop
   
    

    setDraggedTherapyRx(null);
    setDraggedTimeSlot(null);
    updateAppointmentData(updatedAppointment);
    if (draggingElement) {
      draggingElement.classList.remove('dragging-source'); // Ensure visual feedback is removed
    }}


    const updateAppointmentData = async (updatedAppointment: any) => {
      try {
        const response = await updateAppointment(updatedAppointment.id!, updatedAppointment);

        if (response) {
          notificationStore.showMessage("Termin erfolgreich aktualisiert!", "success");
        }
        setRefresh(!refresh);
        setDataRefreshFlag(!dataRefreshFlag);
      } catch (error) {
        notificationStore.showMessage("Error updating appointment", "error");
      } finally {
        setLoading(false);
      }
    };

 

    const nonContinuousRxs = allRxs.filter(
      (rx) => !rx.isPerpetual && rx.processingStatus != RxDtoProcessingStatusEnum.Abgerechnet
    );
    const continuousRxs = allRxs.filter(
      (rx) => rx.isPerpetual && rx.processingStatus != RxDtoProcessingStatusEnum.Abgerechnet
    );
    const getValidStartDate = (appointment: any, index: number): Date => {
      if (index >= appointmentForTherapyRx.length - 1) {
        return appointment.start; // Return the start date of the appointment if it's the last one
      }
      return !appointment.end
        ? getValidStartDate(appointmentForTherapyRx[index + 1], index + 1)
        : appointment.start;
    };
    return (
      <FullScreenDialog
        open={open}
        onClose={onClose}
        fullScreen
        titleComp={"Planer"}
        actions={
          <>
            <Button variant="text" onClick={onClose}>
              Abbrechen
            </Button>
          </>
        }
      >
        <Grid container spacing={2} sx={{ padding: "20px" }}>
          {loadData && (
            <Modal
              open={loadData}
              aria-labelledby="loading-modal-title"
              aria-describedby="loading-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <CircularProgress size={60} />
              </div>
            </Modal>
          )}

          <Grid item xs={12} sm={4} sx={{ height: "100%" }}>
            <TopLevelPaper
              sx={{
                height: "100%",
                overflowY: "auto",
                paddingTop: "8px",
                paddingBottom: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                }}
              >
                <Card>
                  <CardHeader title="Termine" />
                  {!loadData && appointments && appointments.length > 0 && (
                    <CardContent>
                      {appointments.map((appointment: any) => (
                        <Box
                          key={appointment.id}
                          sx={{
                            position: "relative",
                            border: `1px solid ${mobiliTheme.mainHeader}`,
                            p: 0,
                            marginBottom: "8px",
                            backgroundColor: mobiliTheme.lowLevelContainerBackground,
                            borderRadius: "0.4em",
                            width: "100%",
                          }}
                        >
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography
                                sx={{
                                  fontSize: "0.75em",
                                  fontWeight: "bold",
                                  textDecoration:
                                    appointment.end === null && appointment.originalDate
                                      ? "line-through"
                                      : "none",
                                  textDecorationThickness: "2px",
                                }}
                              >
                                {appointment.start && !appointment.unschedule
                                  ? formatDateUI(appointment.start)
                                  : ""}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                              {!appointment.unschedule && (
                                <Typography
                                  noWrap
                                  sx={{
                                    fontSize: "0.75em",
                                    fontWeight: "bold",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {appointment.start
                                    ? formatTime(appointment?.start as any)
                                    : "Nicht geplant"}{" "}
                                  - {appointment.end ? formatTime(appointment.end as any) : ""}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>

                          <Grid container direction="column">
                          <div className="drag-and-drop-container">
                            {appointment?.timeSlots
                              ?.sort((a: any, b: any) => a.therapyRx.order - b.therapyRx.order)
                              .map((timeSlot: any, index: any) => (
                                <Grid item key={`${appointment.id}-${index}`} xs={12}>
                                  {/* Use Flexbox to position TherapyCardView and Rx Assignment Box side by side */}
                                  <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    {/* Therapy Card */}
                                    <Box
                                      id={`timeSlot-${timeSlot.id}`} // Ensure this ID is set
                                      key={timeSlot.id}
                                      draggable
                                      onDrop={(e: React.DragEvent<HTMLElement>) => {
                                        handleDrop(e, appointment.id, timeSlot.id);
                                      }}
                                      onDragStart={(e: React.DragEvent<HTMLElement>) =>
                                        handleTimeSlotDragStart(e, timeSlot, appointment.id)
                                      } // Ensure the correct timeSlot is passed
                                      onDragOver={(e: React.DragEvent<HTMLElement>) =>
                                        handleDragOver(e)
                                      } // Ensuring DragOver is attached
                                      onDragLeave={(e: React.DragEvent<HTMLElement>) =>
                                        handleDragLeave(e)
                                      }
                                      onDragEnd={(e: React.DragEvent<HTMLElement>) =>
                                        handleDragEnd(e)
                                      }
                                      sx={{
                                        padding: "10px",
                                        margin: "10px 0",
                                        borderRadius: "5px",
                                        minHeight: "50px", // Optional to make the drop area more visible
                                        flexGrow: 1, // Allows TherapyCard to take available space
                                        background: mobiliTheme.palette.primary.main,
                                        cursor:
                                          draggedTimeSlot && draggedTimeSlot.id === timeSlot.id
                                            ? "grab"
                                            : "move",
                                        transition: "transform 0.3s ease-in-out", // Smooth pull-in effect
                                        transform:
                                          draggedTimeSlot && draggedTimeSlot.id === timeSlot.id
                                            ? "scale(1.2)"
                                            : "scale(1)",
                                      }}
                                    >
                                      <TherapyCardView
                                        timeSlot={timeSlot}
                                        appointment={appointment}
                                        index={index}
                                        totalSlots={appointment?.timeSlots?.length || 0}
                                        displayTimeslotTime={true}
                                      />
                                    </Box>
                                  </Box>
                                </Grid>
                              ))}
                               </div>
                          </Grid>
                        </Box>
                      ))}
                    </CardContent>
                  )}
                  {!loadData && appointments && appointments.length === 0 && (
                    <Typography>No Termine</Typography>
                  )}
                </Card>
              </Box>
            </TopLevelPaper>
          </Grid>

          <Grid item xs={3} sx={{ marginLeft: "50px", height: "100%" }}>
            <TopLevelPaper
              sx={{
                height: "100%",
                overflowY: "auto",
                paddingTop: "8px",
                paddingBottom: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                }}
              >
                <Card>
                  <CardHeader title="Rezepte" />
                  {!loadData && (
                    <CardContent>
                      <Box border={1} p={2}>
                        {nonContinuousRxs.length > 0 && (
                          <Box>
                            {nonContinuousRxs.map((rx) => (
                              <Box key={rx.id}  sx={{marginBottom:"30px"}}>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                                >
                                  {/* RX Number */}
                                  <Tooltip title={rx.rxNumber}>
                                    <Controls.Chip
                                      label={rx.rxNumber}
                                      sx={{
                                        backgroundColor: getColorForRxNumber(rx.rxNumber!),
                                        fontSize: "0.8em",
                                        direction: "rtl",
                                      }}
                                      variant="outlined"
                                    />
                                  </Tooltip>

                                  {/* Date */}
                                  {rx.date ? (
                                    <Tooltip title={formatDateDisplay(rx.date)}>
                                      <Controls.Chip
                                        sx={{
                                          fontSize: "0.75em",
                                          padding: "0px 4px",
                                          borderRadius: "0.5em",
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                        label={formatDateDisplay(rx.date)}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="No date">
                                      <Controls.Chip
                                        sx={{
                                          fontSize: "1em",
                                          padding: "0px 4px",
                                          borderRadius: "0.5em",
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                        label={<EventBusy sx={{ color: "grey" }} />}
                                      />
                                    </Tooltip>
                                  )}
                                </Grid>

                                {/* Therapy Rxs Mapping */}
                                <div className="drag-and-drop-container">
                                {rx?.therapyRxs?.map((therapyRx: any) => (
                               
                                  <Box
                                    id={`therapyRx-${therapyRx.id}`}
                                    key={therapyRx.id}
                                    draggable
                                    onDrop={(e: React.DragEvent<HTMLElement>) =>
                                      handleDrop(e, null, null, { ...therapyRx, rx: rx })
                                    }
                                    onDragOver={(e: React.DragEvent<HTMLElement>) =>
                                      handleDragOver(e)
                                    }
                                    onDragLeave={(e: React.DragEvent<HTMLElement>) =>
                                      handleDragLeave(e)
                                    }
                                    onDragEnd={(e: React.DragEvent<HTMLElement>) =>
                                      handleDragEnd(e)
                                    }
                                    onDragStart={(e: React.DragEvent<HTMLElement>) =>
                                      handleTherapyRxDragStart(e, { ...therapyRx, rx: rx })
                                    }
                                    onClick={() =>
                                      handleTherapyRxClick(
                                        { ...therapyRx, rx: rx },
                                        rx.isPerpetual!
                                      )
                                    }
                                    sx={{
                                      padding: "5px",
                                      margin: "5px",
                                      borderRadius: "5px",
                                      cursor:
                                        draggedTherapyRx && draggedTherapyRx.id === therapyRx.id
                                          ? "grab"
                                          : "move",
                                      transition: "transform 0.3s ease-in-out",
                                      transform:
                                        draggedTherapyRx && draggedTherapyRx.id === therapyRx.id
                                          ? "scale(1.2)"
                                          : "scale(1)",
                                      background:
                                        selectedTherapyRx.id === therapyRx.id
                                          ? mobiliTheme.palette.secondary.main
                                          : mobiliTheme.palette.primary.main,
                                    }}
                                  >
                                    <TherapyCardViewScheduler
                                      therapyRx={{ ...therapyRx, rx: rx }}
                                      appsStats={appStats?.find(
                                        (stats) => stats.therapyRxId === therapyRx?.id
                                      )}
                                    />
                                  </Box>
                                ))}
                                 </div>
                              </Box>
                            ))}
                          </Box>
                        )}

                        {continuousRxs.length > 0 && (
                          <Box >
                            <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "10px" }}>
                              Dauerrezept
                            </Typography>

                            {continuousRxs.map((rx) => (
                              <Box key={rx.id}  sx={{marginBottom:"30px"}}>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                                >
                                  {/* RX Number */}
                                  <Tooltip title={rx.rxNumber}>
                                    <Controls.Chip
                                      label={rx.rxNumber}
                                      sx={{
                                        backgroundColor: getColorForRxNumber(rx.rxNumber!),
                                        fontSize: "0.8em",
                                        direction: "rtl",
                                      }}
                                      variant="outlined"
                                    />
                                  </Tooltip>

                                  {/* Date */}
                                  {rx.date ? (
                                    <Tooltip title={formatDateDisplay(rx.date)}>
                                      <Controls.Chip
                                        sx={{
                                          fontSize: "0.75em",
                                          padding: "0px 4px",
                                          borderRadius: "0.5em",
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                        label={formatDateDisplay(rx.date)}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="No date">
                                      <Controls.Chip
                                        sx={{
                                          fontSize: "1em",
                                          padding: "0px 4px",
                                          borderRadius: "0.5em",
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                        label={<EventBusy sx={{ color: "grey" }} />}
                                      />
                                    </Tooltip>
                                  )}
                                </Grid>

                                {/* Therapy Rxs Mapping */}
                                <div className="drag-and-drop-container">
                                {rx?.therapyRxs?.map((therapyRx: any) => (
                                  <Box
                                    id={`therapyRx-${therapyRx.id}`}
                                    key={therapyRx.id}
                                    draggable
                                    onDrop={(e: React.DragEvent<HTMLElement>) =>
                                      handleDrop(e, null, null, { ...therapyRx, rx: rx })
                                    }
                                    onDragStart={(e: React.DragEvent<HTMLElement>) =>
                                      handleTherapyRxDragStart(e, { ...therapyRx, rx: rx })
                                    }
                                    onClick={() => handleTherapyRxClick( { ...therapyRx, rx: rx }, rx.isPerpetual!)}
                                    onDragOver={(e: React.DragEvent<HTMLElement>) =>
                                      handleDragOver(e)
                                    }
                                    onDragLeave={(e: React.DragEvent<HTMLElement>) =>
                                      handleDragLeave(e)
                                    }
                                    onDragEnd={(e: React.DragEvent<HTMLElement>) =>
                                      handleDragEnd(e)
                                    }
                                    sx={{
                                      padding: "5px",
                                      margin: "5px 0",
                                      borderRadius: "5px",
                                      cursor:
                                        draggedTherapyRx && draggedTherapyRx.id === therapyRx.id
                                          ? "grab"
                                          : "move",
                                      background:
                                        selectedTherapyRx.id === therapyRx.id
                                          ? mobiliTheme.palette.secondary.main
                                          : mobiliTheme.palette.primary.main,
                                      transition: "transform 0.3s ease-in-out",
                                      transform:
                                        draggedTherapyRx && draggedTherapyRx.id === therapyRx.id
                                          ? "scale(1.2)"
                                          : "scale(1)",
                                    }}
                                  >
                                    <TherapyCardViewScheduler
                                      therapyRx={{ ...therapyRx, rx: rx }}
                                      appsStats={appStats.find(
                                        (stats) => stats.therapyRxId === therapyRx.id
                                      )}
                                    />
                                  </Box>
                                ))}
                                </div>
                              </Box>
                            ))}
                          
                          </Box>
                        )}
                   
                      </Box>
                    </CardContent>
                  )}
                </Card>
              </Box>
            </TopLevelPaper>
          </Grid>

          {/* Show all timeslots for an appointment */}
          {loading && (
            <Modal
              open={loading}
              aria-labelledby="loading-modal-title"
              aria-describedby="loading-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <CircularProgress size={60} />
              </div>
            </Modal>
          )}
          {selectedTherapyRx.id && !loading && (
            <Grid item xs={12} sm={4} sx={{ height: "100%", marginLeft: "50px" }}>
              <TopLevelPaper
                sx={{
                  height: "100%",
                  overflowY: "auto",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    overflowY: "auto",
                  }}
                >
                  <Card>
                    <CardHeader
                      title={
                        <Typography sx={{fontSize:"20px"}

                        }>
                          Termine - {(selectedTherapyRx?.therapy as any)?.abbreviation}{" "}
                          {selectedTherapyRx.rx?.rxNumber}
                        </Typography>
                      }
                    />

                    <CardContent>
                      {appointmentForTherapyRx &&
                        appointmentForTherapyRx.length > 0 &&
                        appointmentForTherapyRx?.map((appointment: any, index: any) => (
                          <AppointmentCardView
                            appointment={appointment}
                            appointments={appointmentForTherapyRx}
                            patientId={patientId}
                            dataRefreshFlag={dataRefreshFlag}
                            setDataRefreshFlag={setDataRefreshFlag}
                            gapInDays={
                              appointmentForTherapyRx[index + 1] &&
                              appointmentForTherapyRx[index]?.end
                                ? formatGap(
                                    new Date(getValidStartDate(appointment, index)),
                                    new Date(
                                      getValidStartDate(
                                        appointmentForTherapyRx[index + 1],
                                        index + 1
                                      )
                                    )
                                  )
                                : null
                            }
                            isView={TerminViewConstants.READ_ONLY_VIEW}
                          />
                        ))}
                      {appointmentForTherapyRx && appointmentForTherapyRx.length === 0 && (
                        <Typography>No Termine</Typography>
                      )}
                    </CardContent>
                  </Card>
                </Box>
              </TopLevelPaper>
            </Grid>
          )}
        </Grid>
      </FullScreenDialog>
    );
  }
);

export default AppointmentScheduler;
