import { TherapistStatsDto } from "../../../api-client";
import { useEffect, useRef, useState } from 'react';
import useStore from "../../../helpers/useStore";
import { BIContext } from "../../../stores/BIDashboard/biDashboard.provider";
import { calculateTherapistStats, mapTherapistTableData } from "../helpers/therapistStatsHelpers";

interface TherapistTotalData {
  locationId: string,
  therapistCount: number,
  totalGaps: number,
}

interface TherapistTableData {
  firstName: string | undefined,
  lastName: string | undefined,
  nickName: string | undefined,
  value: number,
  gaps: number,
  rxCount: number,
}
  
interface TherapistData {
  locationId: string,
  therapistCount: number,
  totalGaps: number,
  table: TherapistTableData[],
}

const useTherapistStats = (startDate: Date, endDate: Date) => {
  const BIStore = useStore(BIContext); 
  const { getTherapistStats } = BIStore; 

  const [therapistData, setTherapistData] = useState<TherapistData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setIsLoading(true);
        const response = await getTherapistStats(startDate, endDate);
        if (response) {
          const totalResults = calculateTherapistStats(response);

          const therapistData = mapTherapistTableData(response, totalResults);
          setTherapistData(therapistData);
          
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  return { therapistData };
};

export default useTherapistStats;