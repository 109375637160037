import { useCallback, useEffect, useState } from "react";
import { BaseTransactionDetailsDto, GetBankTransferDto, GetRechnungDetailsDto, PaginatedBaseTransactionDetailsDto, PaginatedGetBankTransferDto, PaginatedGetRechnungDto, UserDto } from "../../../../api-client";
import { SearchTransaction } from "../../Insurance/Base/types/insuranceBills.types";
import { AllTransactionMethods, DateRangesByMethod, recnungTransactionMethods, transactionMethods } from "../types/kasse.types";
import { useDebounce } from "../../Insurance/Base/hooks/useDebounce";
import { Dayjs } from "dayjs";

interface UseTransactionProps {
    getAllBankTransfer: (
      // accountIds: string[],
      accountId: string,
    searchQuery: SearchTransaction
  ) => Promise<PaginatedGetBankTransferDto>;
    getAllRechnung: (
      // accountIds: string[],
      accountId: string,
        searchQuery: SearchTransaction
    ) => Promise<PaginatedGetRechnungDto>;
  getAllRechnungWithBillReminder: (
      // accountIds: string[],
      accountId: string,
        searchQuery: SearchTransaction
    ) => Promise<PaginatedGetRechnungDto>;
    getTransactionsByType: (
      // accountIds: string[],
      accountId: string,
        transactionType: transactionMethods,
        searchQuery: SearchTransaction
    ) => Promise<PaginatedBaseTransactionDetailsDto>;
    transactionMethod: AllTransactionMethods;
    lastUpdate?: any;
  // accountIds: string[];
  accountId: string | undefined;
}

export function useTransaction({
    getAllBankTransfer,
  getAllRechnung,
    getAllRechnungWithBillReminder,
    getTransactionsByType,
    transactionMethod,
    lastUpdate,
  // accountIds,
    accountId
}: UseTransactionProps) {
    const [paginatedData, setPaginatedData] = useState<PaginatedBaseTransactionDetailsDto | PaginatedGetBankTransferDto | PaginatedGetRechnungDto>();
    const [allTransaction, setAllTransaction] = useState < BaseTransactionDetailsDto[] | GetBankTransferDto[] | GetRechnungDetailsDto[]>([]);

  // Pagination states
  const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(15);
    

    const [dateRanges, setDateRanges] = useState<DateRangesByMethod>({});
      const currentRange = dateRanges[transactionMethod] || {
        startDate: null,
        endDate: null,
      };

  // -------------------------
  // Debounced searching
  // -------------------------
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const debouncedSearchValue = useDebounce(searchValue, 400);
    
  const fetchAllTransaction = useCallback(async () => {
    try {
      const searchQuery: SearchTransaction = {
        page,
        limit,
        startDate: currentRange.startDate?.format("YYYY-MM-DD"),
        endDate: currentRange.endDate?.format("YYYY-MM-DD"),
        searchStr: debouncedSearchValue || undefined,
      };

    //   let data;

    // if (accountIds && accountIds.length > 0) {
    //   if (transactionMethod === recnungTransactionMethods.BANK_TRANSFER) {
    //       data = await getAllBankTransfer(accountIds, searchQuery);
    //   } else if (transactionMethod === recnungTransactionMethods.RECHNUNG) {
    //       data = await getAllRechnung(accountIds, searchQuery);
    //   } else {
    //       data = await getTransactionsByType(accountIds, transactionMethod, searchQuery);
    //   }
    //   setPaginatedData(data);
    //   setAllTransaction(data.data);
    // } else {
    //   throw new Error("No accounts available for this location.");
    // }
        
        let data;
        if (accountId) {
            if (transactionMethod === recnungTransactionMethods.BANK_TRANSFER) {
                data = await getAllBankTransfer(accountId,searchQuery);
            } else if (transactionMethod === recnungTransactionMethods.RECHNUNG) {
                data = await getAllRechnung(accountId,searchQuery);
            } else {
                data = await getTransactionsByType(accountId, transactionMethod, searchQuery);
            }
            setPaginatedData(data);
            setAllTransaction(data.data);
        } else {
            throw new Error("Account ID is undefined.");
        }
        
    } catch (error) {
      console.error("Error fetching all transactions:", error);
    }

  }, [page, limit, currentRange.startDate, currentRange.endDate, debouncedSearchValue, transactionMethod, getAllBankTransfer, getAllRechnung, getTransactionsByType, accountId]);

  useEffect(() => {
    fetchAllTransaction();
  }, [fetchAllTransaction, lastUpdate]);

  const refetchTransactions = useCallback(async () => {
    await fetchAllTransaction();
  }, [fetchAllTransaction]);
    
    
    // Handlers for date range changes
      const handleStartDateChange = (newDate: Dayjs | null) => {
        setDateRanges((prev) => ({
          ...prev,
          [transactionMethod]: {
            ...prev[transactionMethod],
            startDate: newDate,
          },
        }));
        setPage(1);
      };
    
      const handleEndDateChange = (newDate: Dayjs | null) => {
        setDateRanges((prev) => ({
          ...prev,
          [transactionMethod]: {
            ...prev[transactionMethod],
            endDate: newDate,
          },
        }));
        setPage(1);
      };
  
  
  
  /**
   * New function: fetchMahnungEligible
   * This calls the store's getAllRechnungWithMahnung
   * then sets local state with the results.
   */
  const fetchMahnungEligible = useCallback(async () => {
    //if (!getAllRechnungWithMahnung) return; // or throw an error if not provided
    if (!accountId) return;

    try {
      const searchQuery: SearchTransaction = {
        page,
        limit,
        startDate: currentRange.startDate?.format("YYYY-MM-DD"),
        endDate: currentRange.endDate?.format("YYYY-MM-DD"),
        searchStr: debouncedSearchValue || undefined,
      };

      const data = await getAllRechnungWithBillReminder(accountId, searchQuery);
      setPaginatedData(data);
      setAllTransaction(data.data);
    } catch (error) {
      console.error("Error fetching Mahnung-eligible rechnungen:", error);
    }
  }, [
    page,
    limit,
    currentRange.startDate,
    currentRange.endDate,
    debouncedSearchValue,
    getAllRechnungWithBillReminder,
    accountId
  ]);


  return {
    paginatedData,
    allTransaction,
    page,
    setPage,
    limit,
    setLimit,
    searchValue,
    setSearchValue,
    handleStartDateChange,
    handleEndDateChange,
    fetchMahnungEligible,
    fetchAllTransaction,
    currentRange,
    refetchTransactions
  };
}
