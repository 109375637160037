import { PatientStartDateDto } from "../../../api-client";
import { useEffect, useState } from 'react';
import { calculateCumulativePatients, calculateNewPatients } from "../helpers/patientStatsHelpers";
import useStore from "../../../helpers/useStore";
import { BIContext } from "../../../stores/BIDashboard/biDashboard.provider";

interface PatientCumulativeData {
  locationId: string, 
  locationName: string, 
  date: Date, 
  cumulativeSum: number
}
  
interface PatientCountData {
  locationId: string, 
  locationName: string, 
  date: Date, 
  newPatients: number
}

const usePatientStats = (binType: 'week' | 'month') => {
  const BIStore = useStore(BIContext); 
  const { getPatientStats } = BIStore; 

  const [patientStats, setPatientStats] = useState<PatientStartDateDto[]>([]);
  const [patientCumulativeResults, setPatientCumulativeResults] = useState<PatientCumulativeData[]>([]);
  const [patientCountResults, setPatientCountResults] = useState<PatientCountData[]>([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getPatientStats();
        if (response) {
          setPatientStats(response);
  
          // calculate the cumulative and count results
          const patientCumulativeResults = calculateCumulativePatients(response, binType);
          const patientCountResults = calculateNewPatients(response, binType);
  
          setPatientCumulativeResults(patientCumulativeResults);
          setPatientCountResults(patientCountResults);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (patientStats.length === 0) {
      fetchData();
    } else {
      // for when binType is changed by user, it will not refetch data and loading spinner will not appear
      const patientCumulativeResults = calculateCumulativePatients(patientStats, binType);
      const patientCountResults = calculateNewPatients(patientStats, binType);
  
      setPatientCumulativeResults(patientCumulativeResults);
      setPatientCountResults(patientCountResults);
    }
    
  }, [binType, patientStats]);

  return { patientCumulativeResults, patientCountResults, isLoading };
};

export default usePatientStats;