import { RxStatsDto } from "../../../api-client";
import { useEffect, useState } from 'react';
import useStore from "../../../helpers/useStore";
import { BIContext } from "../../../stores/BIDashboard/biDashboard.provider";
import { calculateRxStatusCount, calculateTotalRx, calculateTotalRxValue } from "../helpers/rxStatsHelpers";

interface TotalRxCountData {
  locationId: string, 
  locationName: string,
  date: Date, 
  totalCount: number
}

interface RxStatusCountData {
  locationId: string, 
  locationName: string,
  date: Date, 
  ongoingRxCount: number, 
  settledRxCount: number
}

interface TotalRxValueData {
  locationId: string, 
  locationName: string,
  date: Date, 
  totalOngoingRxPrice: number, 
  totalSettledRxPrice: number
}

const useRxStats = (binType: 'week' | 'month') => {
  const BIStore = useStore(BIContext); 
  const { getRxStats } = BIStore; 

  const [rxStats, setRxStats] = useState<RxStatsDto[]>([]);
  const [totalRxCountResults, setTotalRxCountResults] = useState<TotalRxCountData[]>([]);
  const [rxStatusCountResults, setRxStatusCountResults] = useState<RxStatusCountData[]>([]);
  const [totalRxValueResults, setTotalRxValueResults] = useState<TotalRxValueData[]>([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getRxStats();
        if (response) {
          setRxStats(response);
  
          // calculate the stats if rxStats are set
          const totalRxCountResults = calculateTotalRx(response);
          const rxStatusCountResults = calculateRxStatusCount(response, binType);
          const totalRxValueResults = calculateTotalRxValue(response, binType);

          setTotalRxCountResults(totalRxCountResults);
          setRxStatusCountResults(rxStatusCountResults);
          setTotalRxValueResults(totalRxValueResults);
          
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (rxStats.length === 0) {
      fetchData();
    } else {
      // for when binType is changed by user, it will not refetch data and loading spinner will not appear
      const rxStatusCountResults = calculateRxStatusCount(rxStats, binType);
      const totalRxValueResults = calculateTotalRxValue(rxStats, binType);

      setRxStatusCountResults(rxStatusCountResults);
      setTotalRxValueResults(totalRxValueResults);
    }
  }, [binType, rxStats]);

  return { totalRxCountResults, rxStatusCountResults, totalRxValueResults, isLoading };
};

export default useRxStats;