import React from "react";
import { InputLabel, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { formatISO, isValid, parse } from "date-fns";
import { de } from "date-fns/locale";
import { formatDateDisplay } from "../helpers/dateFormat";

const DatePicker = (props: any) => {
  const { name, label, value, required, onChange, disabled, formatDate, style, floatingLabel } =
    props;

  const convertToDefEventPara = (name: any, value: any) => ({
    target: {
      name,
      value,
    },
  });

  const handleDateChange = (date: any, event: any) => {
    // Check if the date is valid
    if (isValid(date)) {
      // Format the date to ISO string format
      const isoDateString = formatISO(date);
      onChange(convertToDefEventPara(name, isoDateString), event);
    } else {
      // Handle the invalid date case if necessary
      onChange(convertToDefEventPara(name, null), event);
    }
  };

  const textFieldStyle = {
    fontSize:"12px",
    borderBottom: "1px solid", // Add a black underline
  };

  const formattedValue = value ?parse(value, "yyyy-MM-dd", new Date()) : ""; // Assuming `value` is a valid date

  console.log("Date debug: ", formattedValue, value, formatDate);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      {!floatingLabel ? (
        <InputLabel sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
          {label}{" "}
          {required && (
            <Typography component="span" variant="body2" color="error" style={{ fontSize: "20px" }}>
              *
            </Typography>
          )}
        </InputLabel>
      ) : null}
      {disabled ? (
        // If disabled, show just the formatted date as text instead of a date picker
        <Typography sx={{ pointerEvents: "none", cursor: "default",background:"white" }}>
          {formattedValue ? formatDateDisplay(formattedValue) : "-"}
        </Typography>
      ) : (
        <MuiDatePicker
          slotProps={{
            actionBar: {
              actions: ["clear", "accept"],
            },
            // field: { clearable: true },
          }}
          label={
            floatingLabel ? (
              <>
                {label}{" "}
                {required && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="error"
                    style={{ fontSize: "20px" }}
                  >
                    *
                  </Typography>
                )}
              </>
            ) : null
          }
          value={formattedValue}
          format={formatDate ? formatDate : "dd.MM.yyyy"}
          disabled={disabled}
          onChange={handleDateChange}
          onAccept={(date: any) => handleDateChange(date, null)} // Trigger on complete date
          sx={style ? style : textFieldStyle}
        />
      )}
    </LocalizationProvider>
  );
};

export default DatePicker;
