import React, { FC, useState } from "react";
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { RxBill } from "../types/PatientBills.types";
import { DebitPermissionDto, GetRechnungDetailsDto } from "../../../../api-client";
import Controls from "../../../../atoms/Controls";
import notificationStore from "../../../../stores/Notification/notificationStore";

interface PatientBillTableProps {
  rxBills: RxBill[];
  rechnungMap: Record<string, GetRechnungDetailsDto | undefined>;
  onClickZahlen: (rxNumber: string | undefined) => void;
  onClickLastschrift: (rxNumber: string | undefined,permission:DebitPermissionDto) => void;
  onClickBill: (bill: RxBill) => void;
  onClickReturnPatientBill: (bill: RxBill) => void;
  loadingRows: Record<string, boolean>;
  debitPermissions:DebitPermissionDto[];
}

const PatientBillTable: FC<PatientBillTableProps> = ({
  rxBills,
  onClickZahlen,
  onClickBill,
  onClickLastschrift,
  onClickReturnPatientBill,
  rechnungMap,
  loadingRows,
  debitPermissions
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
const [selectedSepa, setSelectedSepa] = useState<{ [key: string]: string }>({});
const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});

const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>,validSepaPermissions:DebitPermissionDto[], bill: RxBill) => {
  if (!bill?.rxNumber) return; // Ensure rxNumber is defined

  if (validSepaPermissions.length === 1) {
    if(bill && bill?.account && bill.account.id!==validSepaPermissions[0].account?.id ){
      notificationStore.showMessage("Debit Permission-Konto und Rx-Konto stimmen nicht überein","warning")
      return
    }
    onClickLastschrift(bill.rxNumber, validSepaPermissions[0]); // Auto-select if only one SEPA
  } else {
    setAnchorEl(event.currentTarget);
    setOpenMenus((prev) => ({ ...prev, [String(bill.rxNumber)]: true }));
  }
};

const handleMenuClose = (rxNumber?: string) => {
  if (!rxNumber) return; // Ensure rxNumber is defined
  setOpenMenus((prev) => ({ ...prev, [rxNumber]: false }));
};

const handleSepaSelect = (permission: DebitPermissionDto, bill: RxBill) => {
  if (!bill?.rxNumber) return; // Ensure rxNumber is defined

  if(bill.account && bill.account.id!==permission.account?.id ){
    notificationStore.showMessage("Debit Permission-Konto und Rx-Konto stimmen nicht überein","warning")
    return
  }

  // Fix: Use rxNumber as a valid key by checking if it's defined
  setSelectedSepa((prev) => ({ ...prev, [String(bill.rxNumber)]: permission.id }));
  setOpenMenus((prev) => ({ ...prev, [String(bill.rxNumber)]: false }));
  onClickLastschrift(bill.rxNumber, permission);
};
  

  
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell>RX Nummer</TableCell>
            <TableCell align="left">Rx Datum</TableCell>
            <TableCell align="left">Heilmittel und Menge</TableCell>
            <TableCell align="left">Gesamtpreis (€)</TableCell>
            <TableCell align="left">Bezahlt (€)</TableCell>
            <TableCell align="left">Fällig (€)</TableCell>
            <TableCell align="center">Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rxBills.map((bill, index) => {
            const shouldWarn = bill.isEstimated;
            const rxId = bill.rxId;
            const rechnung = rxId ? rechnungMap[rxId] : undefined;
            const rxPrice =
              bill.isEstimated && bill.isTimeSlotZero ? bill.estimatedPrice : bill.realPrice;

            const tooltipTitle = shouldWarn
              ? "Dies ist ein geschätzter Preis. Bitte überprüfen Sie die Zeitfenster"
              : rechnung && Number(rechnung.amount.toFixed(2)) === Number(rxPrice.toFixed(2))
              ? ""
              : "Klicken zum Bezahlen";

               // Check if there are any debitPermissions with isSingleUse false and amount matching the rxBill's amount
               const validSepaPermissions = debitPermissions.filter(
                (permission) =>
                  (!permission.isSingleUse || 
                    (permission.amount && permission.amount.toString() === bill.dueAmount.toString())
                  ) 
              );

              

            let rechnungElem: React.ReactNode;
            if (!rechnung) {
              rechnungElem = (
                <>
                  <Grid
                    container
                    gap={1}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {validSepaPermissions && validSepaPermissions.length > 0 ? (
                      <>
                        <Button
                          size="medium"
                          variant="contained"
                          color="secondary"
                          onClick={(event) =>
                            handleButtonClick(event, validSepaPermissions, bill)
                          }
                          sx={{ fontSize: "0.7rem", padding: "2px 5px", minWidth: "auto" }}
                        >
                          per Lastschrift
                        </Button>

                        {/* Popup Menu Below Button */}
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(bill.rxNumber && openMenus[bill.rxNumber])} // Ensuring rxNumber is defined here
                          onClose={() => handleMenuClose(bill.rxNumber)}
                          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                          transformOrigin={{ vertical: "top", horizontal: "left" }}
                        >
                          {validSepaPermissions.map((permission) => (
                            <MenuItem
                              key={permission.id}
                              onClick={() => handleSepaSelect(permission, bill)}
                            >
                              {permission.firstName} {permission.lastName} ({permission.id})
                            </MenuItem>
                          ))}
                        </Menu>
                      </>
                    ) : (
                      <>
                        <Button
                          size="medium"
                          variant="contained"
                          color="secondary"
                          onClick={() => onClickZahlen(bill.rxNumber)}
                          sx={{
                            fontSize: "0.7rem",
                            padding: "2px 5px",
                            minWidth: "auto",
                          }}
                        >
                          Zahlen
                        </Button>
                        <Button
                          variant="contained"
                          size="medium"
                          color="secondary"
                          sx={{
                            fontSize: "0.7rem",
                            padding: "2px 5px",
                            minWidth: "auto",
                          }}
                          disabled={loadingRows[bill.rxNumber ?? ""]}
                          onClick={() => onClickBill(bill)}
                        >
                          {loadingRows[bill.rxNumber ?? ""] ? (
                            <>
                              <CircularProgress size={16} color="inherit" />
                              <span style={{ marginLeft: 8 }}>Bitte warten...</span>
                            </>
                          ) : (
                            "Rechnung erstellen"
                          )}
                        </Button>
                      </>
                    )}
                  </Grid>
                </>
              );
            } else {
              const rechnungAmountFixed = Number(rechnung.amount.toFixed(2));
              const rxPriceFixed = Number(rxPrice.toFixed(2));
              if (rechnungAmountFixed === rxPriceFixed) {
                rechnungElem = (
                  <Chip
                    label="Rechnung erstellt"
                    color="success"
                    variant="outlined"
                  />
                );
              } else {
                rechnungElem = (
                  <>
                    <Grid
                      container
                      gap={1}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Button
                        size="medium"
                        variant="contained"
                        color="secondary"
                        onClick={() => onClickZahlen(bill.rxNumber)}
                        sx={{
                          fontSize: "0.7rem",
                          padding: "2px 5px",
                          minWidth: "auto",
                        }}
                      >
                        Zahlen
                      </Button>
                      <Button
                        variant="contained"
                        size="medium"
                        color="secondary"
                        sx={{
                          fontSize: "0.7rem",
                          padding: "2px 5px",
                          minWidth: "auto",
                        }}
                        onClick={() => onClickBill(bill)}
                      >
                        Rechnung erstellen
                      </Button>
                    </Grid>
                  </>
                );
              }
            }
            return (
              <TableRow key={index}>
                <TableCell
                  component="th"  
                  scope="row"
                >
                  {
                    <Chip
                      key={index}
                      label={bill.rxNumber}
                      color="primary"
                      style={{ margin: "2px" }} // Add some margin for spacing
                    />
                  }
                </TableCell>
                <TableCell align="left">
                  {
                    <Chip
                      key={index}
                      label={bill.date}
                      color="primary"
                      style={{ margin: "2px" }}
                    />
                  }
                </TableCell>
                <TableCell align="left">
                  {bill.therapies.map((therapyInfo, index) => (
                    <Chip
                      key={index}
                      label={`${therapyInfo.therapy} - ${therapyInfo.amount}`}
                      color="primary"
                      style={{ margin: "2px" }}
                    />
                  ))}
                </TableCell>
                <TableCell align="left">
                  <Chip
                    key={index}
                    label={
                      <span>
                        {Number(
                          shouldWarn && bill.isTimeSlotZero ? bill.estimatedPrice : bill.realPrice
                        )
                          .toFixed(2)
                          .replace(".", ",")}
                        {" € "}
                        {shouldWarn && bill.isTimeSlotZero ? (
                          <span style={{ color: "#9C1A35" }}> geschätzter Preis</span>
                        ) : (
                          ""
                        )}
                      </span>
                    }
                    color="primary"
                    style={{ margin: "2px" }}
                  />
                </TableCell>
                <TableCell align="left">
                  {
                    <Chip
                      key={index}
                      label={Number(bill.amountPaid).toFixed(2).replace(".", ",") + " €"}
                      color="primary"
                      style={{ margin: "2px" }}
                    />
                  }
                </TableCell>
                <TableCell align="left">
                  {
                    <Chip
                      key={index}
                      label={Number(bill.dueAmount).toFixed(2).replace(".", ",") + " €"}
                      color="primary"
                      style={{ margin: "2px" }}
                    />
                  }
                </TableCell>
                <TableCell align="center">
                  {bill.dueAmount === 0 ? (
                    <Chip
                      key={index}
                      label={"Bezahlt"}
                      style={{ marginRight: "2px", color: "#6FBF99" }}
                      color="success"
                      variant="outlined"
                    />
                  ) : bill.dueAmount < 0 ? (
                    // <Chip
                    //   key={index}
                    //   label={"Überbezahlt"}
                    //   style={{ marginRight: "2px", color: "#9C1A35" }}
                    //   color="error"
                    //   variant="outlined"
                    //   />
                    <Button
                      size="medium"
                      variant="contained"
                      color="secondary"
                      onClick={() => onClickReturnPatientBill(bill)}
                      sx={{
                        fontSize: "0.7rem",
                        padding: "2px 5px",
                        minWidth: "auto",
                      }}
                    >
                      Überbezahlt
                    </Button>
                  ) : (
                    <Tooltip
                      title={tooltipTitle}
                      arrow
                      placement="top" 
                    >
                      <span>{rechnungElem}</span>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientBillTable;
