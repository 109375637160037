import { useState } from "react";
import { PostBillInfoProps } from "../types/PostBillInfo.types";
import { CreateInsuranceTransactionDto, ProcessedBGBillingDto } from "../../../../../../api-client";
import useStore from "../../../../../../helpers/useStore";
import { TransactionsContext } from "../../../../../../stores/Transactions/Transactions.provider";
import notificationStore from "../../../../../../stores/Notification/notificationStore";
import { transactionMethods } from "../../../../Kasse/types/kasse.types";
import { BillingTypeEnum } from "../../../Base/types/insuranceBills.types";

export function usePostBillInfo(props: PostBillInfoProps) {
  const {
    handleFullScreenDialogClose,
    onRowClick,
    account,
    userName,
    userId,
    onRxDelete,
    onValidationFilter,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRx, setSelectedRx] = useState<any | null>(null);
  const [selectedRxId, setSelectedRxId] = useState<string | null>(null);
  const [notes, setNotes] = useState<Record<string, string>>({});

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSingleRxDialog, setOpenSingleRxDialog] = useState(false);
  const [rxNumber, setRxNumberInput] = useState("");
  const [rxNumberError, setRxNumberError] = useState("");

  const [tableVisible, setTableVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bills, setBills] = useState<ProcessedBGBillingDto[]>([]);

  const TransactionStore = useStore(TransactionsContext);
  const { BGBillingAllInfo, createTransaction, getSingleRxForIndirectBillsForInsurance } =
    TransactionStore;

  // Kebab Menu
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, rx: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRx(rx);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Delete
  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
    handleMenuClose();
  };
  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setSelectedRx(null);
  };
  const handleDeleteConfirm = () => {
    if (!selectedRx) return;

    setBills((prev) =>
      prev
        .map((bill) => {
          if (bill.rxs.some((rx) => rx.id === selectedRx.id)) {
            const updatedRxData = bill.rxs.filter((rx) => rx.id !== selectedRx.id);
            const updatedTotalPrice = updatedRxData.reduce(
              (total, rx) => total + (rx.rxPrice.grossValue || 0),
              0
            );

            return {
              ...bill,
              rxs: updatedRxData,
              totalPrice: updatedTotalPrice,
            };
          }
          return bill;
        })
        .filter((bill) => bill.rxs.length > 0)
    );

    notificationStore.showMessage("Rezept erfolgreich entfernt", "success");
    onRxDelete(selectedRx.id);

    setSelectedRx(null);
    setOpenDeleteDialog(false);
  };

  // Add Single Rx
  const handleAddSingleRx = async (rxNumber: string) => {
    if (!rxNumber.trim()) {
      setRxNumberError("Bitte geben Sie eine Rezept-Nummer ein");
      return;
    }

    try {
      const rxInfo = await getSingleRxForIndirectBillsForInsurance(rxNumber.trim());
      if (rxInfo && rxInfo.rx) {
        const newRx = rxInfo.rx;
        // Check if the RX already exists
        const rxExists = bills.some((bill) => bill.rxs.some((rx) => rx.id === newRx.id));
        if (rxExists) {
          notificationStore.showMessage("Dieses Rezept ist bereits auf der Liste", "warning");
          return;
        }

        const payerIK = newRx.payer?.payerId;
        if (!payerIK) {
          notificationStore.showMessage("Payer IK not found for the Rx", "error");
          return;
        }

        // Update bills
        setBills((prev) => {
          const newBill = {
            payer: newRx.insurancePayer,
            rxs: [newRx],
            totalPrice: newRx.rxPrice?.grossValue || newRx.price || 0,
          };
          return [...prev, newBill];
        });

        notificationStore.showMessage("Rezept erfolgreich hinzugefügt", "success");
      } else {
        notificationStore.showMessage("Rezept nicht gefunden", "error");
      }
    } catch (error) {
      console.error("Error fetching single RX:", error);
      notificationStore.showMessage("Rezept nicht gefunden", "error");
    }
  };

  // Load All
  const handleLoadAll = async () => {
    setIsLoading(true);
    setTableVisible(true);
    try {
      const fetchedBills = await BGBillingAllInfo(account);
      setBills(fetchedBills);
    } catch (error) {
      console.error("Error fetching Bills:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Save All
  const handleSave = async () => {
    try {
      for (const bill of bills) {
        const { rxs, payer, totalPrice } = bill;
        const payerIK = payer.ik;

        const totalPriceFormatted = totalPrice.toFixed(2);
        const rxsIds = rxs.map((rx) => rx.id).filter((id): id is string => !!id);

        const dataSubmission: CreateInsuranceTransactionDto = {
          payerId: payerIK,
          accountId: account,
          amount: Number(totalPriceFormatted),
          userId,
          method: transactionMethods.VERSICHERUNG,
          rxIds: rxsIds,
          notes: notes[payerIK] || "", // or store in local notes[payerIK] if you want
          paperCenterId: "",
          dataCenterId: "",
          tAccountId: rxs[0].tarifGroupId === "Privat" ? 4105 : 4101,
          billType: BillingTypeEnum.BG,
        };

        await createTransaction(dataSubmission);

        // Remove from local state (since it’s “saved”)
        setBills((prev) => prev.filter((b) => b.payer.ik !== payerIK));
      }
      notificationStore.showMessage("All transactions created successfully", "success");
      handleFullScreenDialogClose();
    } catch (error) {
      console.error("Error creating transactions", error);
      notificationStore.showMessage("Error creating transactions", "error");
    }
  };

  // Validation Filter
  const handleValidationFilter = () => {
    const updatedBills = bills
      .map((bill) => {
        const filteredRxs = bill.rxs.filter((rx) => {
          const validations = rx.formvalidation;
          return validations && Object.values(validations).every((v) => v.validity === "Valid");
        });
        const updatedTotalPrice = filteredRxs.reduce(
          (sum, rx) => sum + (rx.rxPrice.grossValue || 0),
          0
        );

        return {
          ...bill,
          data: {
            ...bill,
            rxs: filteredRxs,
            totalPrice: updatedTotalPrice,
          },
        };
      })
      .filter((b) => b.data.rxs.length > 0);

    setBills(updatedBills);
    const remainingRxs = updatedBills.flatMap((b) => b.data.rxs);
    onValidationFilter?.(remainingRxs);
  };

  return {
    // states
    anchorEl,
    selectedRxId,
    openDeleteDialog,
    openSingleRxDialog,
    rxNumberInput: rxNumber,
    rxNumberError,
    tableVisible,
    isLoading,
    bills,
    notes,

    // setters
    setSelectedRxId,
    setRxNumberInput,
    setOpenSingleRxDialog,
    setNotes,

    // handlers
    handleLoadAll,
    handleAddSingleRx,
    handleValidationFilter,
    handleSave,
    handleDeleteCancel,
    handleDeleteConfirm,
    handleDeleteClick,
    handleMenuOpen,
    handleMenuClose,

    // from props
    onRowClick,
    userName,
  };
}
