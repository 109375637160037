import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";

interface SummaryProps {
  totalRxs: number;
  passedValidationRxs: number;
  problematicRxs: number;
  taxiertRxs: number;
  numberOfPayers: number;
}

const BillSummary: React.FC<SummaryProps> = ({
  totalRxs,
  passedValidationRxs,
  problematicRxs,
  taxiertRxs,
  numberOfPayers,
}) => {
  return (
    <Box sx={{ py: 1 }}>
      <Typography
        variant="h4"
        gutterBottom
        textAlign={"center"}
        fontWeight={"bold"}
        color="#ffffff"
      >
        Zusammenfassung
      </Typography>
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
          md={2.4}
        >
          <Paper
            elevation={3}
            sx={{ p: 1, textAlign: "center", borderRadius: 2 }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={"bold"}
            >
              Gesamtrezepte
            </Typography>
            <Typography
              variant="body2"
              color="secondary"
              fontWeight={"bold"}
            >
              {totalRxs}
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.4}
        >
          <Paper
            elevation={3}
            sx={{ p: 1, textAlign: "center", borderRadius: 2 }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={"bold"}
            >
              Valide
            </Typography>
            <Typography
              variant="body2"
              color="success.main"
              fontWeight={"bold"}
            >
              {passedValidationRxs}
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.4}
        >
          <Paper
            elevation={3}
            sx={{ p: 1, textAlign: "center", borderRadius: 2 }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={"bold"}
            >
              Ungültige Rx
            </Typography>
            <Typography
              variant="body2"
              color="error"
              fontWeight={"bold"}
            >
              {problematicRxs}
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.4}
        >
          <Paper
            elevation={3}
            sx={{ p: 1, textAlign: "center", borderRadius: 2 }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={"bold"}
            >
              Taxiert Rx
            </Typography>
            <Typography
              variant="body2"
              color="info.main"
              fontWeight={"bold"}
            >
              {taxiertRxs}
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.4}
        >
          <Paper
            elevation={3}
            sx={{ p: 1, textAlign: "center", borderRadius: 2 }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={"bold"}
            >
              Kostenträger
            </Typography>
            <Typography
              variant="body2"
              color="secondary"
              fontWeight={"bold"}
            >
              {numberOfPayers}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillSummary;
