import { ScheduleBasedStatsDto } from "../../../api-client";
import { useEffect, useState } from 'react';
import useStore from "../../../helpers/useStore";
import { BIContext } from "../../../stores/BIDashboard/biDashboard.provider";
import { calculateScheduleBasedTotalApptValue, calculateScheduleBasedTotalGaps, calculateScheduleBasedTotalRx } from "../helpers/scheduleBasedStatsHelpers";

interface TotalRxCountData {
  locationId: string, 
  locationName: string,
  date: Date, 
  totalCount: number
}

interface TotalApptValueData {
  locationId: string, 
  locationName: string,
  date: Date, 
  totalValue: number
}

interface TotalGapsData {
  locationId: string, 
  locationName: string,
  date: Date, 
  totalGaps: number
}

const useScheduleBasedStats = (binType: 'week' | 'day', startDate: Date, endDate: Date) => {
  const BIStore = useStore(BIContext); 
  const { getScheduleBasedStats } = BIStore; 

  const [scheduleBasedStats, setScheduleBasedStats] = useState<ScheduleBasedStatsDto[]>([]);
  const [totalScheduleBasedRxCountResults, setTotalRxCountResults] = useState<TotalRxCountData[]>([]);
  const [totalApptValueResults, setTotalApptValueResults] = useState<TotalApptValueData[]>([]);
  const [totalGapsResults, setTotalGapsResults] = useState<TotalGapsData[]>([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      if (!startDate || !endDate) {
        console.log("Waiting for user to select both dates...");
        return;
      }
      
      try {
        setIsLoading(true);
        const response = await getScheduleBasedStats(startDate, endDate);
        if (response) {
          setScheduleBasedStats(response);

          const totalScheduleBasedRxCountResults = calculateScheduleBasedTotalRx(response, binType);
          const totalApptValueResults = calculateScheduleBasedTotalApptValue(response, binType);
          const totalGapsResults = calculateScheduleBasedTotalGaps(response, binType);

          setTotalRxCountResults(totalScheduleBasedRxCountResults);
          setTotalApptValueResults(totalApptValueResults);
          setTotalGapsResults(totalGapsResults);

        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    
  }, [binType, startDate, endDate]);

  return { totalScheduleBasedRxCountResults, totalApptValueResults, totalGapsResults, isLoading };
};

export default useScheduleBasedStats;