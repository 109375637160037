import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Box,
  Grid,
  Tooltip,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import "../../common/helpers/css/direct-bill-info.css";
import { NewBillTableProps } from "../exports";
import RubberStamp from "../../common/components/RubberStamp";

export const NewBillTable: React.FC<NewBillTableProps> = ({
  bill,
  selectedRxId,
  onRowClick,
  onMenuOpen,
}) => {
  const { data } = bill;
  const { rxs } = data;

  const INSURANCE_ORDER = ["10000", "30000", "50000"];

  const sortedRxs = [...rxs].sort((a, b) => {
    const statusA = a.insuranceStatus || "";
    const statusB = b.insuranceStatus || "";

    //Compare by insurance status order
    const indexA = INSURANCE_ORDER.indexOf(statusA);
    const indexB = INSURANCE_ORDER.indexOf(statusB);
    if (indexA !== indexB) {
      return indexA - indexB;
    }

    //If insurance status is the same, compare by lastName
    const lastNameA = (a.patient as { lastName?: string })?.lastName?.toLowerCase() || "";
    const lastNameB = (b.patient as { lastName?: string })?.lastName?.toLowerCase() || "";

    if (lastNameA !== lastNameB) {
      return lastNameA.localeCompare(lastNameB);
    }

    //  final sort using rxNumber
    const rxA = (a.rxNumber || "").toString();
    const rxB = (b.rxNumber || "").toString();
    return rxA.localeCompare(rxB);
  });

  // const sortedRxs = [...rxs].sort((a, b) => {
  //   const lastNameA = (a.patient as { lastName?: string })?.lastName?.toLowerCase() || "";
  //   const lastNameB = (b.patient as { lastName?: string })?.lastName?.toLowerCase() || "";
  //   return lastNameA.localeCompare(lastNameB);
  // });

  return (
    <TableContainer
      component={Paper}
      className="table-container"
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="table-header-cell">Name</TableCell>
            <TableCell className="table-header-cell">Rx Details</TableCell>
            <TableCell className="table-header-cell">Validierung</TableCell>
            <TableCell className="table-header-cell">Taxieren</TableCell>
            <TableCell className="table-header-cell"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRxs.map((row: any, index: number) => {
            const allValid =
              row.formvalidation &&
              Object.values(row.formvalidation).every(
                (validation: any) => validation.validity === "Valid"
              );

            const isSelected = row.id === selectedRxId;

            return (
              <TableRow
                key={index}
                className={`table-row ${isSelected ? "table-row-selected" : ""}`}
                onClick={() => {
                  onRowClick(row);
                }}
              >
                <TableCell>
                  <Typography>
                    {row.patient?.lastName}, {row.patient?.firstName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box className="rx-box">
                    <Grid
                      container
                      spacing={1}
                    >
                      <Grid
                        item
                        xs={3}
                      >
                        <Typography>{row.rxNumber}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sx={{ textAlign: "right" }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                        >
                          {new Date(row.date).toLocaleDateString("de-DE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sx={{ textAlign: "right" }}
                      >
                        {row?.therapyRxs?.map((therapyRx: any, i: number) => (
                          <Box
                            key={i}
                            className="therapy-tag"
                          >
                            {therapyRx.therapy.abbreviation}
                          </Box>
                        ))}
                      </Grid>
                    </Grid>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  {allValid ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon style={{ color: "#fa7e7e" }} />
                  )}
                </TableCell>
                <TableCell align="left">
                  <Tooltip title="Sie müssen die Rechnung speichern, um das Rezept zu taxieren">
                    <span>
                      <RubberStamp
                        text={row.isTaxieren ? "Done" : "No"}
                        isdisabled={true}
                      />
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={(event) => onMenuOpen(event, row)}>
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
