import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Box,
  Button,
  IconButton,
  TextField,
  Grid,
  CircularProgress,
  Modal,
  Card,
  CardHeader,
  CardContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import Controls from "../../atoms/Controls";
import EditTable, { validateFrequency, validateTherapy } from "../../molecules/Editable";
import { rxuseForm, useForm } from "./Form";
import { PatientsContext } from "../../stores/Patients/patients.provider";
import useStore from "../../helpers/useStore";
import { v4 as uuidv4 } from "uuid";
import {
  DoubleTreatmentRxDto,
  RxDto
} from "../../api-client";
import { DoctorContext } from "../../stores/Doctor/doctor.provider";
import DoctorDialog from "./Doctor";
import { MasterTablesContext } from "../../stores/MasterTables/MasterTables.provider";
import { format, parseISO } from "date-fns";
import { observer, useObserver } from "mobx-react";
import WarningDialog from "../../atoms/WarningDialog";
import { UserContext } from "../../stores/User/User.provider";
import { observe, toJS } from "mobx";
import notificationStore from "../../stores/Notification/notificationStore";
import { TopLevelPaper } from "../../themes/StyledComponents";
import FlatCard from "../../atoms/FlatCard";
import Private from "../PatientOverview/RxInfo/RxForms/Private/Private";
import BG from "../PatientOverview/RxInfo/RxForms/BG/BG";
import Verordnung13 from "../PatientOverview/RxInfo/RxForms/Kasse/Verordnung13";
import Bundeswehr from "../PatientOverview/RxInfo/RxForms/Bundeswehr/Bundeswehr";
import Zahnarzt from "../PatientOverview/RxInfo/RxForms/Zahnarzt/Zahnarzt";
import Blankoverordnung from "../PatientOverview/RxInfo/RxForms/Blankoverordnung/Blankoverordnung";
import { Delete } from "@mui/icons-material";

interface ReceiptDataEntryDialogProps {
  isEditing?: boolean;
  openDialog: boolean;
  handleCloseDialog: () => void;
  // handleSubmit: () => void;
  selectedRow: any; // Adjust the type of selectedRow accordingly
  patientValues: any;
  masterData: any;
  handleData: any;
  handleAddColumnSet: any;
  onTableDataChange: any;
  rxTableData: any;
  parseInput: any;
  additionalRx?: boolean;
  patientForm?:boolean;
}
interface DiagnoseGroup {
  a: string;
  b: string;
  c: string;
  d: string;
}

const ReceiptDataEntryDialog: React.FC<ReceiptDataEntryDialogProps> = observer(({
  isEditing,
  openDialog,
  handleCloseDialog,
  // handleSubmit,
  selectedRow,
  patientValues,
  masterData,
  handleData,
  handleAddColumnSet,
  onTableDataChange,
  rxTableData,
  parseInput,
  additionalRx,
  patientForm
}) => {
  console.log(
    "SelectedrtteRow",
    selectedRow,
    patientValues,
    handleData,
    handleAddColumnSet,
    onTableDataChange,
    rxTableData,
    parseInput
  );
  const [therapytableData, settherapyTableData] = useState([]);
  const [selectedLANR, setSelectedLANR] = useState<string | null>(null);
  const [selectedBSNR, setSelectedBSNR] = useState<string | null>(null);
  const [selectedChipCard, setSelectedChipCard] = useState<string | null>(null);
  const [selectedPayer, setSelectedPayer] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const DoctorStore = useStore(DoctorContext);
  const { doctorOptions } = DoctorStore;
  const UserStore = useStore(UserContext);
  const { user } = UserStore;
  const masterTablesStore = useStore(MasterTablesContext);
  const patientStore = useStore(PatientsContext);
  const { postPatients } = patientStore;
  const { getICD, getTarifGroupIds } = masterTablesStore;
  const [formChanged, setFormChanged] = useState(false);
  // const [doctorOptions, setDoctorOptions] = useState([]);
  const [fetchedData, setFetchedData] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [selectedDiagnoseGroup, setSelectedDiagnoseGroup] =
    useState<DiagnoseGroup | null>(null);
  console.log("DIAGNOSETH", selectedDiagnoseGroup, selectedDiagnoseGroup?.c);
  const [loading, setLoading] = useState(false);

  const [isAddEditDialogOpen, setAddEditDialogOpen] = useState(false);

  // const handleOpenAddEditDialog = () => {
  //   setAddEditDialogOpen(true);
  // };
  const handleOpenAddEditDialog = (
    lanr: string | null,
    bsnr: string | null
  ) => {
    setSelectedLANR(lanr);
    setSelectedBSNR(bsnr);
    setAddEditDialogOpen(true);
  };

  const handleCloseWarning = () => {
    setShowWarning(false);
  };

  const handleCloseAddEditDialog = (data: any) => {
    // Handle doctor data, LANR, and BSNR passed back from DoctorDialog
    console.log("Received doctor data:", data);
    setAddEditDialogOpen(false);

    // Here you can perform further actions with the received data
    if (data && "number" in data) {
      const { number, clinicNumber, contactInfo, id } = data;

      // Update states or perform other operations based on the received data
      setSelectedLANR(number);
      setSelectedBSNR(clinicNumber);
      setSelectedDoctor(contactInfo?.lastName);
      setFormValues((prevFormValues: any) => ({
        ...prevFormValues,
        doctorNumber: number,
        practiceNumber: clinicNumber,
        doctorId: id,
      }));

      // For example, you can update doctor form values if needed
      // setDoctorFormValues(doctorData);
    }
  };

  // const fetchDoctorOptions = async () => {
  //   try {
  //     const response = await getAllDoctors();
  //     console.log("Resppnse", response);
  //     setDoctorOptions(response);
  //   } catch (error) {
  //     console.error("Error fetching doctor options:", error);
  //   }
  // };
  const handleUnsavedChanges = (status: boolean) => {
    setUnsavedChanges(status);
  };
  const handleCloseRxDialog = () => {
    console.log("shjsakjsajj",unsavedChanges)
    if (unsavedChanges) {
      // If there are unsaved changes, set a state to open the warning dialog
      setShowunsavedChangesWarning(true);
    } else {
      handleCloseDialog();
    }
  };



  const handleUnsavedChangesWarning = () => {
    handleSubmit();
  };

  const handleCloseUnsavedChangesWarning = () => {
    handleCloseDialog();
    // If user cancels and chooses not to continue, close the warning dialog
    setShowunsavedChangesWarning(false);
  };

  const handleDoctorChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue: any = event.target.value;
    setSelectedDoctor(selectedValue);
    console.log("SELECTED", selectedValue);
    // Autopopulate lanr and bsnr based on doctor selection
    const selectedDoctor: any = doctorOptions.find(
      (doctor: any) => doctor.contactInfo.lastName === selectedValue
    );

    if (selectedDoctor) {
      setFormValues((prevFormValues: any) => ({
        ...prevFormValues,
        doctorNumber: selectedDoctor.number,
        practiceNumber: selectedDoctor.clinicNumber,
      }));
      setSelectedLANR(selectedDoctor.number);
      setSelectedBSNR(selectedDoctor.clinicNumber);
      console.log("SELECTED", selectedDoctor, formValues, selectedLANR);
    }

    // Filter doctor options based on selectedValue
    const filteredOptions: any = doctorOptions.filter(
      (doctor: any) => doctor.contactInfo.lastName === selectedValue
    );

    console.log("Filtered", filteredOptions, selectedValue);

    if (filteredOptions.length > 0) {
      // If filteredOptions has a value, set it as the fetchedData
      setFetchedData(filteredOptions[0]);
      // Update receiptForm with selected doctor
      // if (!isEditing) {
      setFormValues({
        ...formValues,
        doctorId: filteredOptions[0].id,
        practiceNumber: filteredOptions[0].clinicNumber,
        doctorNumber: filteredOptions[0].number,
      });
      // } else {
      //   setFormValues((prevFormValues: any) => ({
      //     ...prevFormValues,
      //     rxs: prevFormValues.rxs.map((rx: any) => ({
      //       ...rx,
      //       doctorId: filteredOptions[0].id,
      //       practiceNumber: filteredOptions[0].clinicNumber,
      //       doctorNumber: filteredOptions[0].number,
      //     })),
      //   }));
      // }
    } else {
      setFetchedData(null);
      setFormValues({
        ...formValues,
        doctorId: "",
        practiceNumber: null,
        doctorNumber: null,
      });
    }
    console.log("FormValueöö", formValues, isEditing);
  };

  // const handleDoctorChange = (selectedValue) => {
  //   setSelectedDoctor(selectedValue);
  //   fetchData(selectedValue);
  // };
  const handleDeleteTherapy = (therapyId: string) => {
    // Filter out the therapy that matches the given ID
    const updatedTherapies = formValues.therapyRxs.filter(
      (therapy: any) => therapy.id !== therapyId
    );
    settherapyTableData(updatedTherapies)
 
    // Update formData state with new therapy list
    setFormValues({
      ...formValues,
      therapyRxs: updatedTherapies,
    });
  };

  const treatmentColumns: any[] = [
    // { field: "therapy", headerName: "Heilmittel", editable: true },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params: any) => (
        <IconButton size="small" onClick={() => handleDeleteTherapy(params.row.id)}>
          <Delete fontSize="small" />
        </IconButton>
      ),
    },
    { field: "amount", headerName: "Menge", editable: true },

    { field: "frequency", headerName: "Frequency", editable: true },
  ];
  const rxType = masterData
    .filter((obj: any) => "rxType" in obj) // Filter objects that have the insuranceStatus property
    .map((obj: any) => obj.rxType);
  const diagnoseGroups = masterData
    .filter((obj: any) => "diagnosisGroups" in obj)
    .map((obj: any) => obj.diagnosisGroups);
  const insurer = masterData
    .filter((obj: any) => "insurer" in obj)
    .map((obj: any) => obj.insurer);

  const account = masterData
    .filter((obj: any) => "account" in obj)
    .map((obj: any) => obj.account);
  // const doctors = masterData
  // .filter((obj: any) => "doctor" in obj)
  // .map((obj: any) => obj.doctor);
  const selectedInsurer = insurer[0]?.find(
    (insurer: any) =>
      insurer.id === patientValues?.payer ||
      insurer.id === patientValues?.payer?.id
  );
  
  // const rxStatusOptions = Object.values(RxTherapyDtoStatusEnum).map(
  //   (value) => ({
  //     label: value,
  //     value: value,
  //   })
  // );
  const initialFormValues = {
    // receiptId
    //  PatientId
    rxtype:selectedRow?.rxtype || "" ,
    firstName: patientValues.firstName,
    lastName: patientValues.lastName,
    dob: patientValues.dob,
    accountId: "",
    employee: "",
    chipcard: selectedInsurer?.ik ?? "",
    tarifGroupId: patientValues.tarifGroupId,
    // symptomCode: "",
    a: false,
    b: false,
    c: false,
    d: false,
    symptoms: "",
    Receiptpayer: patientValues?.payer,
    insuranceStatus: patientValues.status,
    insureeNumber: patientValues.insuranceNo,
    doctorId: "",
    signatureStatus: "",
    isPerpetual: false,
    unvirtualize: false,
    isCopaymentExempted: false,
    isAccident: false,
    isBVG: false,
    status: "",
    hasIndividualSymptoms: false,
    hygiene: false,
    isAutoAddReport: false,
    urgent: false,
    // homeVisit: false,
    therapyReport: false,
    // isHomeVisitFree: false,
    date: patientValues.date,
    icdCodeEntries: [{ userText: "" }, { userText: "" }],
    practiceNumber: null,
    // number:"",
    doctorNumber: null,
    additionalNotes: "",
    diagnosegroup:  null,
    diagnosisText: "",
    isPerpetualApproved: false,
    therapyRxs: [],
    creationDate: "",
    isHomeVisit: selectedRow.isHomeVisit || null,
    problematic: false,
    changeRequested: false,
  };

  // Fetch the insurer property from the selected insurer object, or use an empty string if not found
  const insurerName = selectedInsurer?.name ?? "";
  console.log("Insurer", initialFormValues.icdCodeEntries, toJS(user));
  const tarifGroupsMasterData = getTarifGroupIds();

  const [formValues, setFormValues] = useState(initialFormValues);
  const [icdCodeEntries, setIcdCodeEntries] = useState(
    initialFormValues.icdCodeEntries
  );
  const [unsavedChangesWarning, setShowunsavedChangesWarning] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false); // Flag to track unsaved changes
  console.log("UNsavedChanges",unsavedChanges)
  const rxvalidate = (fieldValues = formValues) => {
    let temp: any = { ...rxerrors };
    // if ("rxtype" in fieldValues)
    //   temp.rxtype = fieldValues.rxtype ? "" : "This field is required.";
      // Validate chipcard (if chipcard exists in fieldValues)
      if ("chipcard" in fieldValues) {
        const chipCardValue = fieldValues.chipcard;
        if (chipCardValue && !insurer[0]?.some((option: any) => option.ik === chipCardValue)) {
          temp.chipcard = "Ungültige Chipkartennummer";
        } else {
          temp.chipcard = ""; // Clear error if valid
        }
      }
    if ("accountId" in fieldValues)
      temp.accountId = fieldValues.accountId ? "" : "This field is required.";
    // if ("chipcard" in fieldValues)
    //   temp.chipcard = fieldValues.chipcard ? "" : "Pflichtfeld";
    // if ("Receiptpayer" in fieldValues)
    //   temp.Receiptpayer = fieldValues.Receiptpayer ? "" : "Pflichtfeld";
    if ("tarifGroupId" in fieldValues)
      temp.tarifGroupId = fieldValues.tarifGroupId ? "" : "Pflichtfeld";
    // if ("practiceNumber" in fieldValues)
    //   temp.practiceNumber = fieldValues.practiceNumber
    //     ? ""
    //     : "This field is required.";
    // if ("doctorNumber" in fieldValues)
    //   temp.doctorNumber = fieldValues.doctorNumber
    //     ? ""
    //     : "This field is required.";
    // if ("doctorId" in fieldValues)
    //   temp.doctorId = fieldValues.doctorId ? "" : "This field is required.";

    // if ('email' in fieldValues)
    //     temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."

    rxsetErrors({
      ...temp,
    });

    // Check if all values in the temp object are empty strings (valid)
    const isValid = Object.values(temp).every((x) => x === "");

    return isValid;

    // if (fieldValues == formValues)
    //   return Object.values(temp).every((x) => x == "");
  };
  const { rxerrors, rxsetErrors } = rxuseForm(
    initialFormValues,
    true,
    rxvalidate
  );

  // Initialize with two input boxes
  useEffect(() => {
    const fetchData = async () => {
      if (isEditing && selectedRow.rxNumber) {
        try {
          let rxs: [] = [];
          let updatedFormValues = { ...formValues };
          if (selectedRow.rxNumber) {
            const selectedRxNumber = selectedRow.rxNumber; // Assuming the property name is `number` for rxNumber in the selected row
            const filteredRxs = patientValues.rxs?.filter(
              (rx: any) => rx.rxNumber === selectedRxNumber
            );

            const formattedValues = { ...patientValues, rxs: filteredRxs };

            console.log(
              "56878",
              patientValues,
              formattedValues.rxs,
              selectedRxNumber,
              filteredRxs
            );
            rxs = formattedValues.rxs
              ? formattedValues.rxs.flatMap((rx: any) => {
                  const therapyRxs = rx.therapyRxs || [];
                  console.log("THERRTZZ", therapyRxs);
                  return therapyRxs?.map((therapy: any) => ({
                    id: therapy.id,
                    date: rx.date,
                    therapy: therapy.therapy.abbreviation || therapy.therapy,
                    amount: therapy.amount,
                    frequency: therapy.frequency?.text || therapy.frequency,
                    isDoubled: therapy.isDoubled,
                    doubledTreatmentIds: therapy.doubledTreatmentIds,
                    rxSeries: therapy.rxSeries,
                  }));
                })
              : [];
            formattedValues.rxs = formattedValues.rxs
              ? formattedValues.rxs.map((rx: any) => {
                  let icdCodeEntries;

                  if (rx.icdCodeEntries && rx.icdCodeEntries.length > 0) {
                    if (rx.icdCodeEntries.length === 1) {
                      icdCodeEntries = [...rx.icdCodeEntries, { userText: "" }];
                    } else {
                      icdCodeEntries = rx.icdCodeEntries;
                    }
                  } else {
                    icdCodeEntries = [{ userText: "" }, { userText: "" }];
                  }
                  return {
                    ...rx,
                    firstName: formattedValues.firstName,
                    lastName: formattedValues.lastName,
                    dob: formattedValues.dob,
                    insuranceStatus:rx.insuranceStatus || formattedValues.status,
                    insureeNumber:rx.insureeNumber || formattedValues.insuranceNo,
                    Receiptpayer:
                      insurer[0].find(
                        (option: any) => option.id === rx.Receiptpayer
                      )?.name || rx.payer?.name,
                    chipcard: rx.payer?.ik,
                    doctorId:
                      doctorOptions?.find(
                        (option: any) => option.id === rx.doctorId
                      )?.contactInfo.lastName ||
                      rx.doctor?.contactInfo?.lastName,
                    diagnosegroup:
                      diagnoseGroups[0].find(
                        (option: any) => option.id === rx.diagnosegroup
                      )?.code || rx.diagnosisGroup?.code,
                    rxtype:
                      rxType[0].find((option: any) => option.id === rx.rxtype)
                        ?.text || rx.rxtype?.text,
                    accountId:
                      account[0].find(
                        (option: any) => option.id === rx.accountId
                      )?.name || rx.account?.name,
                    icdCodeEntries: icdCodeEntries,
                  };
                })
              : [];
            console.log("Formateed", formattedValues.rxs);
            updatedFormValues = {
              ...formValues,
              ...formattedValues.rxs[0], // Assuming only one item is filtered
            
            };
            console.log("GZTUUU", formValues, updatedFormValues);

            // handleData({...patientValues,rxs:filteredRxs})
          }
          // else {
          //   console.log("ARB", formValues, selectedRow);
          //   const formattedValues = { ...patientValues, rxs: [selectedRow] };

          //   rxs = formattedValues.rxs
          //     ? formattedValues.rxs.map((rx: any) => ({
          //         id: rx.id,
          //         date: rx.date,
          //         number: rx.number,
          //         therapy: rx.therapy,
          //         frequency: rx.frequency,
          //         therapyId: rx.therapyId,
          //       }))
          //     : [];
          // }
          setFormValues(updatedFormValues);
          console.log("RDFF", rxs, formValues);
          settherapyTableData(rxs);
        } catch (error) {
          console.log("error", error);
        }
        // };

        // fetchData();
        // dummyApiFetch().then((data) => {
        //   setValues(data);
        // });
      }
      // Make API request to fetch patient data based on patient ID or any other identifier
      else {
        console.log("Patientzzuu", patientValues);
        const filteredRxs = patientValues.rxs?.filter(
          (rx: any) => rx.id === selectedRow.id
        );
        console.log("POOO", formValues, filteredRxs);
        const rxs = filteredRxs
          ? filteredRxs.flatMap((rx: any) => {
              const therapyRxs = rx.therapyRxs || [];
              console.log("THERAPPP", therapyRxs);
              return therapyRxs?.map((therapy: any) => ({
                id: therapy.id || uuidv4(),
                date: rx.date,
                therapy: therapy.therapy.abbreviation,
                amount: therapy.amount,
                frequency: therapy.frequency.text || null, 
                isDoubled: therapy.isDoubled,
                doubledTreatmentIds: therapy.doubledTreatmentIds,
                rxSeries: therapy.rxSeries,
              }));
            })
          : [];

        console.log(
          "Uiii",
          patientValues.rxs,
          filteredRxs,
          selectedRow,
          rxs,
          formValues
        );
        settherapyTableData(rxs);
        const updatedRx = {
          ...formValues,
          ...filteredRxs[0],
          accountId:
            account[0].find(
              (option: any) => option.id === filteredRxs[0]?.accountId
            )?.name ||
            filteredRxs[0]?.accountId ||
            user?.location?.primaryAccount.id,
          diagnosegroup:
            diagnoseGroups[0].find(
              (option: any) => option.id === filteredRxs[0]?.diagnosegroup
            )?.code || filteredRxs[0]?.diagnosisGroup?.code,
          rxtype:
            rxType[0].find(
              (option: any) => option.id === filteredRxs[0]?.rxtype
            )?.text || filteredRxs[0]?.rxtype?.text,
          doctorId:
            doctorOptions?.find(
              (option: any) => option.id === filteredRxs[0]?.doctorId
            )?.contactInfo.lastName || filteredRxs[0]?.doctorId,
          Receiptpayer:
            insurer[0].find(
              (option: any) => option.id === filteredRxs[0]?.Receiptpayer
            )?.name || insurerName,
          // icdCodeEntries:filteredRxs[0].icdCodeEntries,
        
        };

        // // // const updatedFormValues = { ...formValues, rxs: updatedRxs };
        setFormValues(updatedRx);
        console.log("OPOKK", formValues, updatedRx, doctorOptions);
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, patientValues.rxs, selectedRow]);

  // useEffect(() => {
  //   fetchDoctorOptions();
  // }, []);
  // Call the function whenever isAddEditDialogOpen becomes true
  // useEffect(() => {
  //   if (isAddEditDialogOpen) {
  //     fetchDoctorOptions();
  //   }
  // }, [isAddEditDialogOpen]);
  // useEffect to log the updated formValues

  useEffect(() => {
    setIcdCodeEntries(formValues.icdCodeEntries);
    console.log("Updated icdCodeEntries:", formValues.icdCodeEntries);
  }, [formValues]);

  console.log("Fteched", formValues);
  const [isHausbesuchSelected, setHausbesuchSelected] = useState<boolean | null>(formValues.isHomeVisit);
  const handleCheckboxChange = (event: any) => {
    const selectedValue = event.target.value; // "yes" or "no"
    
    if (selectedValue === "yes") {
      setHausbesuchSelected(true);
      // Set isHomeVisit to true if "yes" is selected
      handleInputChange({
        target: {
          name: "isHomeVisit",
          value: true,
        } as unknown as EventTarget & HTMLInputElement,  // Cast to EventTarget & HTMLInputElement
      } as React.ChangeEvent<HTMLInputElement>);
    } else if (selectedValue === "no") {
      setHausbesuchSelected(false);
      // Set isHomeVisit to false if "no" is selected
      handleInputChange({
        target: {
          name: "isHomeVisit",
          value: false,
        } as unknown as EventTarget & HTMLInputElement,  // Cast to EventTarget & HTMLInputElement
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      setHausbesuchSelected(null); // Set null if neither is selected
      // Set isHomeVisit to null if neither checkbox is selected
      handleInputChange({
        target: {
          name: "isHomeVisit",
          value: null,
        } as unknown as EventTarget & HTMLInputElement,  // Cast to EventTarget & HTMLInputElement
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };
  

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index?: any,
    icdEntryId?: string
  ) => {
    console.log("LUUZZ", event);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { name, value, checked, type, id } = event.target;
    setUnsavedChanges(true);

    const inputValue = type === "checkbox" ? checked : value;
    setFormChanged(true);
    console.log("input", inputValue, checked);
    console.log("Event:", event.target.name, event.target.value, index);
    // Check if the input is for icdCodeEntries
    if (name.startsWith("icdCodeEntries")) {
      const inputElement = document.querySelector(`[name="${name}"]`) as HTMLInputElement | HTMLTextAreaElement;
      const cursorPosition = inputElement?.selectionStart || 0;
      let updatedValue = value;
      let newCursorPosition = cursorPosition; // Initialize new cursor position
    
      // Check if the length is at least 4 and if a period needs to be inserted
      if (value.length >= 4) {
        // Check if the period already exists at the correct position
        if (!value.includes(".")) {
          // Insert a period after the third character if it's not already there
          updatedValue = value.slice(0, 3) + "." + value.slice(3);
    
          // Adjust cursor position if a period was inserted before the original cursor position
          if (cursorPosition > 3) {
            newCursorPosition = cursorPosition + 1; // Move cursor one position forward
          }
        }
      }
    
      // Update the form values with the correctly formatted value
      setFormValues((prevValues: any) => ({
        ...prevValues,
        icdCodeEntries: prevValues.icdCodeEntries.map((icdEntry: any, i: number) => {
          return i === index
            ? { id: icdEntryId || undefined, userText: updatedValue }
            : { id: icdEntry?.id, userText: icdEntry?.userText || "" };
        }),
      }));
    
      // Restore cursor position after the update
      setTimeout(() => {
        if (inputElement) {
          inputElement.selectionStart = inputElement.selectionEnd = newCursorPosition;
        }
      }, 0);
    }
    

    // if (name.startsWith("icdCodeEntries")) {
    //   setFormValues((prevValues: any) => ({
    //     ...prevValues,
    //     icdCodeEntries: prevValues.icdCodeEntries.map((icdEntry: any, i: number) => {
    //       return i === index ? { id:icdEntryId || undefined, userText: inputValue } : {id:icdEntry?.id,userText:icdEntry?.userText|| ""};
    //     }),
    //   }));
    // }
    if (name === "hasIndividualSymptoms") {
      // When the checkbox is selected, clear symptom codes and text
      // if (inputValue ) {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        a: false, // Clear symptom code
        b: false,
        c: false,
        d: false,
        symptoms: "",
        hasIndividualSymptoms: inputValue, // Clear symptoms text
      }));
      // }
    } else if (
      (name === "a" || name === "b" || name === "c" || name === "d") &&
      formValues?.hasIndividualSymptoms
    ) {
      // If the checkbox is selected, and symptom code is changed, uncheck the checkbox
      setFormValues((prevValues: any) => ({
        ...prevValues,
        hasIndividualSymptoms: false,
        [name]: inputValue,
      }));
    } else {
      console.log("InputValue", inputValue, id);
      // Update other form fields based on their names
      setFormValues((prevValues: any) => ({
        ...prevValues,
        [name]: id ? id : inputValue,
      }));
    }

    // setFormValues((prevValues: any) => ({
    //   ...prevValues,
    //   rxs: prevValues.rxs.map((rx: any) => ({
    //     ...rx,
    //     [name]: id ? id : inputValue,
    //   })),
    // }));
    if (name === "doctorNumber") {
      // Autopopulate doctor and bsnr based on LANR selection
      const selectedDoctor: any = doctorOptions.find(
        (doctor: any) => doctor.number === Number(value)
      );
      setFetchedData(selectedDoctor);
      console.log("UZZHHHH", selectedDoctor);
      if (selectedDoctor) {
        setFormValues((prevFormValues: any) => ({
          ...prevFormValues,
          doctorId: selectedDoctor.id,
          practiceNumber: selectedDoctor.clinicNumber,
        }));
        setSelectedDoctor(selectedDoctor.contactInfo.lastName);
        setSelectedBSNR(selectedDoctor.clinicNumber);
      }
    }

    // if (name === 'rxtype') {
    //   filterDiagnoseGroups(value);
    // }

    if (name === "rxtype") {
      setFormValues((prevValues) => ({
        ...prevValues,
        diagnosegroup: null, // Clear diagnosegroup when rxtype changes
      }));
      filterDiagnoseGroups(value);
    }
    // if (name === "practiceNumber") {
    //   // Autopopulate doctor and bsnr based on LANR selection
    //   const selectedDoctor: any = doctorOptions.find(
    //     (doctor: any) => doctor.clinicNumber === Number(value)
    //   );
    //   setFetchedData(selectedDoctor);
    //   if (selectedDoctor) {
    //     setFormValues((prevFormValues: any) => ({
    //       ...prevFormValues,
    //       doctorId: selectedDoctor.id,
    //       doctorNumber: selectedDoctor.number,
    //     }));
    //     setSelectedDoctor(selectedDoctor.contactInfo.lastName);
    //     setSelectedLANR(selectedDoctor.number);
    //   }
    // }
    if (name === "Receiptpayer") {
      // Autopopulate doctor and bsnr based on LANR selection
      const selectedPayer: any = insurer[0].find(
        (option: any) => option.id === id || option.ik === value
      );
      console.log("UZZHHHH", selectedPayer);
      if (selectedPayer) {
        setFormValues((prevFormValues: any) => ({
          ...prevFormValues,
          chipcard: selectedPayer?.ik,
          tarifGroupId: selectedPayer?.tarifGroup.id,
        }));
        setSelectedChipCard(selectedPayer.ik);
        console.log("selectedPayer", selectedPayer.name);
      }
    }

    // if (name === "chipcard") {
    //   const selectedPayer: any = insurer[0].find(
    //     (option: any) => option.ik === value
    //   );
    //   console.log("UZZHHHH", selectedPayer);
    //   if (selectedPayer) {
    //     setFormValues((prevFormValues: any) => ({
    //       ...prevFormValues,
    //       Receiptpayer: selectedPayer.id,
    //       tarifGroupId: selectedPayer?.tarifGroup?.id,
    //     }));

    //     setSelectedPayer(selectedPayer.name);
    //     console.log("selectedPayer", selectedPayer.name);
    //   }
    // }
    console.log("GTII", formValues);

    // else if (name === "doctor") {
    //   // Autopopulate doctor and bsnr based on LANR selection
    //   const selectedDoctor: any = doctorOptions.find(
    //     (doctor: any) => doctor.contactInfo.firstNames===value

    //   );
    //   if (selectedDoctor) {
    //     setFormValues((prevFormValues: any) => ({
    //       ...prevFormValues,
    //       lanr: selectedDoctor.number,
    //       bsnr: selectedDoctor.clinicNumber,
    //     }));
    //     setSelectedLANR(selectedDoctor.number);
    //   }
    //   console.log('DOCTOR',selectedDoctor,formValues,value,doctorOptions)
    // }
  };

  const handleChipCardDataEntry = (event: React.KeyboardEvent  | React.FocusEvent) => {
    rxvalidate();
    const chipCardValue = (event.currentTarget as HTMLInputElement).value;
    if ((event as React.KeyboardEvent).key === "Enter" || event.type === "blur" || event.type === "mouseleave") {
      // Check if the chipcard number is valid
      const selectedPayer = insurer[0]?.find((option: any) => option.ik === chipCardValue);
      if (selectedPayer) {
        // If valid, autofill payer info
        setFormValues((prevFormValues: any) => ({
          ...prevFormValues,
          Receiptpayer: selectedPayer?.id,
          tarifGroupId: selectedPayer?.tarifGroup?.id,
        }));
        setSelectedPayer(selectedPayer.name);
        notificationStore.showMessage(
          `${selectedPayer?.id} ist ein IK für ${selectedPayer?.name}`,
          "success"
        );
      }  else if(chipCardValue){
        // Show error if chipcard is invalid
        notificationStore.showMessage(
          "Bitte geben Sie eine gültige Chipkartennummer ein.",
          "warning"
        );
         // Pass all form values with updated chipcard value for validation
 
      }
    }
  };
  useEffect(()=>{
    rxvalidate()
  },[formValues])
  const handleAddInput = () => {
    setIcdCodeEntries([...icdCodeEntries, { userText: "" }]);

    // Update the formValues state as well to reflect the new entry
    setFormValues((prevValues) => ({
      ...prevValues,
      icdCodeEntries: [...prevValues.icdCodeEntries, { userText: "" }],
    }));
  };

  const handleICDCodeEntrySubmit = async (icdCode: any, index: number) => {
    try {
      const icdObject: any = await getICD(icdCode.userText);
      console.log("OOPP", icdObject);
      if (icdObject) {
        // If the API call is successful and returns an icdObject, update the icdCodeEntries state with the normCode
        setFormValues((prevValues: any) => {
          const updatedICDCodeEntries = prevValues.icdCodeEntries.map(
            (icdEntry: any, i: number) => {
              return i === index
                ? { id: icdCode?.id || "", userText: icdCode.userText }
                : icdEntry;
            }
          );

          const concatenatedDiagnosisText = prevValues.diagnosisText
            ? `${prevValues.diagnosisText} ${icdObject.text}` // Append the new icdObject.text to the existing diagnosisText
            : icdObject.text;
          return {
            ...prevValues,
            icdCodeEntries: updatedICDCodeEntries,
            diagnosisText: concatenatedDiagnosisText.trim(), // Set diagnosisText to the concatenated value
          };
        });
      }
    } catch (error) {
      console.error("Error fetching ICD object:", error);
    }
  };

  console.log("UZRRFCFASG", icdCodeEntries);

  useEffect(() => {
    if (formValues.diagnosegroup) {
      const diagnoseGroupIsId = diagnoseGroups[0].some(
        (group: any) => group.id === formValues.diagnosegroup
      );
      // Find the matching diagnose group based on the selected code
      const selectedDiagnoseGroup = diagnoseGroupIsId
        ? diagnoseGroups[0].find(
            (group: any) => group.id === formValues.diagnosegroup
          )
        : diagnoseGroups[0].find(
            (group: any) => group.code === formValues.diagnosegroup
          );
      console.log(
        "formVa",
        formValues.diagnosegroup,
        selectedDiagnoseGroup,
        diagnoseGroups
      );

      if (selectedDiagnoseGroup) {
        // Generate symptoms based on selected checkboxes
        let selectedSymptoms = "";
        if (formValues.a) selectedSymptoms += selectedDiagnoseGroup.a + " ";
        if (formValues.b) selectedSymptoms += selectedDiagnoseGroup.b + " ";
        if (formValues.c) selectedSymptoms += selectedDiagnoseGroup.c + " ";
        if (formValues.d) selectedSymptoms += selectedDiagnoseGroup.d + " ";
        console.log("SELECTE", selectedSymptoms, selectedDiagnoseGroup);
        setSelectedDiagnoseGroup(selectedDiagnoseGroup);
        setFormValues((prevValues) => ({
          ...prevValues,
          a: selectedDiagnoseGroup.a ? prevValues.a : false,
          b: selectedDiagnoseGroup.b ? prevValues.b : false,
          c: selectedDiagnoseGroup.c ? prevValues.c : false,
          d: selectedDiagnoseGroup.d ? prevValues.d : false,
          symptoms: selectedSymptoms.trim(),
        }));
      }
    }
  }, [
    formValues.diagnosegroup,
    formValues.a,
    formValues.b,
    formValues.c,
    formValues.d,
  ]);

  const warningDoctorSignatureConditions = () => {
    return (
      (formValues.status === "vorhanden" ||
        formValues.status === "beim Therapeut") &&
      formValues.signatureStatus === ""
    );
  };

  const  handleSubmit = async (event?: any) => {
    if (event) {
      setLoading(false);
      event.preventDefault(); // Prevent default form submission behavior
      console.log("Event", event);
    }
    try {
      setLoading(true);
      if (warningDoctorSignatureConditions() && event) {
        // Show a warning/alert here

        setShowWarning(true);
      } else {
        console.log(
          "HJJUU",
          formValues,
          therapytableData.length,
          formValues.therapyRxs.length
        );
        //       const newColumnSet = [...treatmentColumns];
        //       const newRowAdded = Math.abs(formValues.therapies.length - therapytableData.length);
        // console.log('Newro',newRowAdded)
        //       for (let i = 0; i < newRowAdded; i++) {
        //         handleAddColumnSet(newColumnSet);
        //       }

        if (rxvalidate()) {
          const updatedRxValues: any = {
            ...formValues,
            id:
              patientValues.rxs.find((rx: any) => rx.id === selectedRow.id)
                ?.id || "",
            rxNumber:
              patientValues.rxs.find((rx: any) => rx.id === selectedRow.id)
                ?.rxNumber || "",
            // date:
            //   patientValues.rxs.find((rx: any) => rx.id === selectedRow.id)
            //     ?.date || "",
            accountId:
              account[0].find(
                (option: any) => option.name === formValues.accountId
              )?.id || formValues.accountId,
            rxtype:
              rxType[0].find((option: any) => option.text === formValues.rxtype)
                ?.id || formValues.rxtype,
            diagnosegroup:
              diagnoseGroups[0].find(
                (option: any) => option.code === formValues.diagnosegroup
              )?.id || formValues.diagnosegroup,
            doctorId:
              (doctorOptions as any).find(
                (option: any) =>
                  option.contactInfo.lastName === formValues.doctorId
              )?.id || formValues.doctorId,
            Receiptpayer:
              insurer[0].find(
                (option: any) => option.id === formValues.chipcard
              )?.id || formValues.Receiptpayer,
            therapyRxs: (formValues.therapyRxs || []) .filter((obj:any) => obj.therapy.id !== "")
              .map((therapy: any) => {
                if ( !(therapy.frequency && therapy.frequency.id) || !(therapy.therapy && therapy.therapy.id) || !therapy.amount) {
                  throw new Error(
                    "Bitte fügen Sie die Behandlung hinzu, um das Rezept zu speichern/bearbeiten (Therapie, Menge, Häufigkeit sind Pflichtfelder) und deaktivieren Sie auch die Zelle nach der Bearbeitung!"
                  );
                }
                const validTherapy=validateTherapy(therapy.therapy.abbreviation,masterData)
                const validFrequency=validateFrequency(therapy.frequency.id,masterData)
                if(!validTherapy || !validFrequency){
                  throw new Error("Bitte geben Sie eine gültige Therapie und Häufigkeit ein.")
                }
                return {
                  ...therapy,
                  id: therapy.therapyId || therapy.id,
                  frequency: therapy.frequency,
                  isDoubled: therapy.isDoubled,
                  doubledTreatmentIds: therapy.doubledTreatmentIds,
                  rxSeries: therapy.rxSeries,
                  isSupplementary:therapy?.isSupplementary
                };
              })
          };

          console.log(
            "AJJJJ",
            updatedRxValues,
            selectedRow.id,
            formValues.isHomeVisit
          );

          const updatedFormData = {
            ...patientValues,
            rxs: patientValues.rxs.map((rx: any) =>
              rx.id === selectedRow.id ? updatedRxValues : rx
            ),
          };
          console.log("updateddgghhh", updatedFormData, updatedRxValues);
          // rxTableData([updatedRxValues])
          setFormValues(updatedRxValues);
          if (additionalRx) {
            // Filter out items where id and therapy are empty
            const filteredRxs = updatedFormData.rxs.filter((rx: any) => rx.id);
            const response = await postPatients({
              ...updatedFormData,
              rxs: filteredRxs,
            });
            setUnsavedChanges(false);
            const updatedPatientData = updatedFormData?.rxs?.forEach(
              (updatedRx: any) => {
                const correspondingRx = response.rxs.find(
                  (rx: any) => rx.id === updatedRx.id
                );
                if (correspondingRx) {
                  updatedRx.icdCodeEntries = correspondingRx.icdCodeEntries;
                }
              }
            );
            // if(!response.patient?.messages){
            //     notificationStore.showMessage("Das Rezept.","success");}
          } else {
            onTableDataChange(updatedFormData.rxs);
            notificationStore.showMessage(
              "Das Rezept ist gespeichert. Bitte senden Sie das Patientenformular ab, um es zu speichern.",
              "success"
            );
          }
          handleData(updatedFormData);
          handleCloseDialog();
          //   const updatedTherapies = formValues.therapies;

          // const updatedRx = {
          //   ...selectedRow,
          //   therapies: updatedTherapies,
          // };

          // const updatedRxs = patientValues.rxs.map((rx: any) =>
          //   rx.id === updatedRx.id ? updatedRx : rx
          // );

          // const updatedPatientValues = {
          //   ...patientValues,
          //   rxs: updatedRxs,
          // };

          // handleData(updatedPatientValues);
          // handleCloseDialog();
        } else {
          notificationStore.showMessage(
            "Bitte füllen Sie alle erforderlichen Felder aus",
            "warning"
          );
        }
      }
    } catch (error: any) {
      console.log("Error adding rezept:", error);
      notificationStore.showMessage(error.message, "error");
    } finally {
      // Set loading back to false when the API call is complete
      setLoading(false);
    }
  };
  const handleContinueWarning = () => {
    setShowWarning(false); // Close the warning dialog
    handleSubmit(); // Call handleSubmit directly
  };

  // function to check the order of therapies
  const hasInvalidTherapyOrder = (data: any[]): boolean => {
    const heatTreatments = ["PA", "HL"];
    let encounteredNonHeatTreatment = false;

    for (let i = 0; i < data.length; i++) {
      const therapyAbbreviation = data[i].therapy.abbreviation;
      if (heatTreatments.includes(therapyAbbreviation)) {
        if (encounteredNonHeatTreatment) {
          // if we encounter a heat treatment after a non-heat treatment, return invalid
          return true;
        }
      } else {
        // mark that we've encountered a non-heat treatment
        encounteredNonHeatTreatment = true;
      }
    }
    return false;
  };

  const handletherapyTableDataChange = (data: DoubleTreatmentRxDto[]) => {
    console.log("datatttzz", data);
    // // Create a new array to hold the updated data
    const updatedData: any[] = [];
    data.forEach((item: any, index: number) => {
      // Divide the amount by 2 for the original item
      const updatedAmount = item.amount;

      // Update the original item with the new amount
      const updatedItem = {
        ...item,
        therapy:{id:item.therapy,abbreviation:item.therapy,isSupplementary:item?.isSupplementary},
        frequency:{id:item.frequency,text:item.frequency},
        amount: updatedAmount,
        isDoubled: item.isDoubled,
        order: data.length > 1 ? (index + 1) : null,
      };

      // Always add the original item to the updated data array
      updatedData.push(updatedItem);

      // If the item has isDoubled: true, create a new row without isDoubled: true and with the updated amount
      if (item.isDoubled) {
        const newId = uuidv4();
        // const newDuplicateItem = { ...item, id: newId, amount: updatedAmount, isDoubled: false };

        // Add the new ID to the doubleTreatmentIds array
        updatedItem.doubledTreatmentIds =
          updatedItem.doubledTreatmentIds &&
          updatedItem.doubledTreatmentIds.length > 0
            ? updatedItem.doubledTreatmentIds
            : [newId];
      }
    });
    
    const isInvalidOrder = hasInvalidTherapyOrder(updatedData);
    if (isInvalidOrder) {
      console.log("Invalid therapy order detected");
      notificationStore.showMessage("Die Reihenfolge der Therapien ist ungültig. Bitte überprüfen Sie die Reihenfolge der Therapien und korrigieren Sie sie.", "error");
      return;
    }

    // setFormValues((prevData: any) => ({
    //   ...prevData,
    //   therapyRxs: updatedData,
    // }));
    // Update the form values state and call setUnsaved(true) if the data has changed
    setFormValues((prevData: any) => {
      const newFormValues = {
        ...prevData,
        therapyRxs: updatedData,
      };
      // Compare new form values with previous ones
      if (
        prevData.therapyRxs && JSON.stringify(prevData.therapyRxs) !== JSON.stringify(updatedData) &&
        prevData.therapyRxs.length > 0 &&
        updatedData.length > 0
      ) {
        setUnsavedChanges(true);
      }

      return newFormValues;
    });
    console.log("jkkkkk", formValues);
  };

  console.log("FORM", formValues.symptoms);
  let formattedDob: any; // Initialize formattedDob to an empty string

  if (formValues?.dob) {
    const parsedDate: any = new Date(formValues.dob);
    if (!isNaN(parsedDate)) {
      // Check if the parsed date is valid
      formattedDob = format(parsedDate, "dd.MM.yyyy");
    }
  }

  const [filteredDiagnoseGroups, setFilteredDiagnoseGroups] = useState([]);

  const filterDiagnoseGroups = (rxtype: any) => {
    const filtered = diagnoseGroups[0].filter(
      (option: any) => option.RxType?.id === rxtype
    );
    setFilteredDiagnoseGroups(filtered);
  };

  useEffect(() => {
    filterDiagnoseGroups(formValues.rxtype);
  }, [formValues.rxtype]);

  const [showWarningDialog, setShowWarningDialog] = useState(false);
  useEffect(() => {

    if (openDialog && formValues && (formValues as any)?.id && !formValues?.rxtype ) {
      setShowWarningDialog(true);
    } 
  }, [openDialog, formValues?.rxtype]);
  
  
  



  const handleWarningDialogClose = () => {
    setShowWarningDialog(false);
  };

  const handleRxTypeSelection = (e: any) => {
    handleInputChange(e);
    setShowWarningDialog(false);
  };
  const [isSubmit,setIsSubmit]=useState(false);
  const setFormData = (rxFormData: any) => { 
    console.log("sakaksksa",unsavedChanges)
    // setUnsavedChanges(false);
    setFormValues(rxFormData)
    setIsSubmit(true);
  

  
  };

  useEffect(() => {
    if (formValues && isSubmit) { // Ensure formValues is not null or undefined
      console.log("sjkaslkakllkdalkdaksdadsakdla",formValues)
      handleSubmit();
      
    }
  }, [formValues]);

  const renderRxComponent = () => {
    switch (formValues?.rxtype) {
      case 'Privat/Selbstzahler':
        return (
          <Private
            selectedRow={formValues}
            patientValues={patientValues}
            updateFormData={setFormData}
            handleData={handleData}
            // therapyTableData={therapytableData}
            openRx={openDialog as boolean}
            onCloseRx={handleCloseDialog}
            newRx={patientForm}
          />
        );
      case 'Berufsgenossenschaft':
        return (
          <BG
            selectedRow={formValues}
            patientValues={patientValues}
            updateFormData={setFormData}
            handleData={handleData}
            openRx={openDialog as boolean}
            onCloseRx={handleCloseDialog}
            newRx={patientForm}
            
          />
        );
        case 'Kasse':
        case 'Entlassmanagement':
          return( <Verordnung13
            rxFormData={
              formValues
            }
            patientValues={patientValues}
            updateFormData={setFormData}
            handleData={handleData}
            masterTablesData={masterData}
            // setUnsavedChanges={handleUnsavedChanges}
            newRx={patientForm}
            openRx={openDialog as boolean}
            onCloseRx={handleCloseDialog}
            // ref={saveGenericViewData}
          />)
          case 'Blankoverordnung':
              return( <Blankoverordnung
                rxFormData={
                  formValues
                }
                patientValues={patientValues}
                updateFormData={setFormData}
                handleData={handleData}
                masterTablesData={masterData}
                // setUnsavedChanges={handleUnsavedChanges}
                newRx={patientForm}
                openRx={openDialog as boolean}
                onCloseRx={handleCloseDialog}
                // ref={saveGenericViewData}
              />)
          case 'Bundeswehr':
            return(
              <Bundeswehr
            rxFormData={
              formValues
            }
            patientValues={patientValues}
            updateFormData={setFormData}
            handleData={handleData}
            masterTablesData={masterData}
            // setUnsavedChanges={handleUnsavedChanges}
            newRx={patientForm}
            openRx={openDialog as boolean}
            onCloseRx={handleCloseDialog}
            // ref={saveGenericViewData}
          />

            )
            case 'Zahnarzt':
              return( <Zahnarzt
                rxFormData={
                  formValues
                }
                patientValues={patientValues}
                updateFormData={setFormData}
                handleData={handleData}
                masterTablesData={masterData}
                // setUnsavedChanges={handleUnsavedChanges}
                newRx={patientForm}
                openRx={openDialog as boolean}
                onCloseRx={handleCloseDialog}
                />)
      // Add other cases for different rxtypes as needed
      default:
        return      (         <Grid container spacing={2}>
          <Grid item xs={12} lg={3} sx={{ border: "0px solid red" }}>
            <FlatCard title="Eigenschaften">
              <Controls.Select
                name="rxtype"
                label="Rezept-typ"
                // id={1}
                value={formValues?.rxtype}
                onChange={handleInputChange}
                options={rxType[0]?.map((option: any) => ({
                  label: option.text.toString(),
                  value: option.text.toString(),
                  id: option.id.toString(),
                }))}
                // required
                error={rxerrors.rxtype}
              />
              <Controls.Select
                name="accountId"
                label="Mandant"
                // id={1}
                value={formValues?.accountId}
                onChange={handleInputChange}
                options={user?.location?.accounts.map((option: any) => ({
                  label: option.name.toString(),
                  value: option.name.toString(),
                  id: option.id.toString(),
                }))}
                required
                error={rxerrors.accountId}
                // error={errors.departmentId}
              />
              <Controls.DatePicker
                name="date"
                label="Rezeptdatum"
                value={formValues?.date}
                onChange={handleInputChange}
                formatDate="dd.MM.yy"
              />
               <Grid item xs={12}>
                <Controls.Checkbox
                  name="isPerpetual"
                  label="Dauerrezept"
                  value={formValues?.isPerpetual}
                  onChange={handleInputChange}
                />
              </Grid>
              <Controls.Select
                name="status"
                label="Rezeptverbleib"
                value={formValues?.status}
                onChange={handleInputChange}
                options={[
                  {
                    label: "noch nicht erstellt",
                    value: "noch nicht erstellt",
                  },
                  { label: "beim Patient", value: "beim Patient" },
                  { label: "beim Therapeut", value: "beim Therapeut" },
                  { label: "vorhanden", value: "vorhanden" },
                ]}
                // error={errors.departmentId}
              />
            </FlatCard>
          </Grid>
          <Grid item xs={12} lg={3}>
            <FlatCard title="Versicherung">
            <Controls.Input
                    disabled
                    name="Receiptpayer"
                    label="Kostenträger"
                    value={selectedPayer || formValues.Receiptpayer}
                    onChange={handleInputChange}
                  
                  />
                  <Controls.Input
                    name="chipcard"
                    label="IK der Chipkarte"
                    value={selectedChipCard || formValues?.chipcard}
                    onChange={handleInputChange}
                    onKeyDown={handleChipCardDataEntry}
                    onBlur={handleChipCardDataEntry}
                    error={rxerrors.chipcard}
                  
                  />
              {/* <Controls.Select
                name="Receiptpayer"
                label="Kostenträger"
                id={1}
                value={selectedPayer || formValues.Receiptpayer}
                onChange={handleInputChange}
                options={insurer[0]?.map((option: any) => ({
                  label: option?.ik.toString(),
                  value: option?.name.toString(),
                  id: option.id.toString(),
                }))}
                // required
                // error={errors.departmentId}
              />

              <Controls.Select
                name="chipcard"
                label="IK der Chipkarte"
                value={selectedChipCard || formValues?.chipcard}
                onChange={handleInputChange}
                options={insurer[0]?.map((insurer: any) => ({
                  label: insurer.name.toString(),
                  value: insurer.ik.toString(),
                }))} */}
                {/* // required
                // error={rxerrors.chipcard}
                // error={errors.fullName} */}
              {/* /> */}
              <Controls.Select
                name="tarifGroupId"
                label="Tarif Group"
                id={1}
                value={formValues?.tarifGroupId}
                onChange={handleInputChange}
                options={tarifGroupsMasterData?.map((value: any) => ({
                  label: value.toString(),
                  value: value.toString(),
                }))}
                required
                error={rxerrors.tarifGroupId}
              />
              <Controls.Input
                name="insureeNumber"
                label="Versichertennummer"
                value={formValues?.insureeNumber}
                onChange={handleInputChange}
                // error={errors.fullName}
              />

              <Controls.Select
                name="insuranceStatus"
                label="Status"
                value={formValues?.insuranceStatus}
                onChange={handleInputChange}
                options={[
                  { label: "10000", value: "10000" },
                  { label: "30000", value: "30000" },
                  { label: "50000", value: "50000" },
                ]}
                // error={errors.departmentId}
              />
            </FlatCard>
          </Grid>

          <Grid item xs={12} lg={3}>
            <FlatCard title="Diagnose">
              {icdCodeEntries?.map((icdEntry: any, index: any) => (
                <div key={`icd-${index}`}>
                  <Controls.Input
                    label={`ICD ${index + 1}`}
                    name={`icdCodeEntries-${index}`}
                    value={icdEntry.userText}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      console.log("Eventssaa", e, index);
                      handleInputChange(e, index, icdEntry?.id);
                    }}
                    onKeyPress={(
                      e: React.KeyboardEvent<HTMLInputElement>
                    ) => {
                      console.log("KEY", e.key);
                      if (e.key === "Tab" || e.key === "Enter") {
                        e.preventDefault();
                        // handleICDCodeEntrySubmit(formValues.icdCodeEntries[index], index);
                        handleICDCodeEntrySubmit(icdEntry, index);
                      }
                    }}
                    // error={errors.email}
                  />
                </div>
              ))}
              <Button onClick={handleAddInput}>
                <AddIcon />
                ICD Hinzufügen
              </Button>
              <Controls.TextArea
                name="diagnosisText"
                rows={4}
                placeholder="Textdiagnose"
                value={formValues?.diagnosisText}
                onChange={handleInputChange}
              />
            </FlatCard>
          </Grid>
          <Grid item xs={12} lg={3}>
            <FlatCard title="Leitsymptomatik">
              <Controls.Select
                name="diagnosegroup"
                label="Diagnosegruppe"
                // id={1}
                value={formValues?.diagnosegroup}
                onChange={handleInputChange}
                options={filteredDiagnoseGroups?.map((option: any) => ({
                  label: option.description.toString(),
                  value: option.code.toString(),
                  id: option.id.toString(),
                }))}
                // error={errors.departmentId}
              />

              <Controls.Checkbox
                name="a"
                label="a"
                value={formValues?.a}
                onChange={handleInputChange}
                disabled={!selectedDiagnoseGroup?.a}
              />
              <Controls.Checkbox
                name="b"
                label="b"
                value={formValues?.b}
                onChange={handleInputChange}
                disabled={!selectedDiagnoseGroup?.b}
              />
              <Controls.Checkbox
                name="c"
                label="c"
                value={formValues?.c}
                onChange={handleInputChange}
                disabled={!selectedDiagnoseGroup?.c}
              />
              <Controls.Checkbox
                name="d"
                label="d"
                value={formValues?.d}
                onChange={handleInputChange}
                disabled={!selectedDiagnoseGroup?.d}
              />

              <Controls.Checkbox
                name="hasIndividualSymptoms"
                label="Patientenindividuelle Leitsymptomatik"
                value={formValues?.hasIndividualSymptoms}
                onChange={handleInputChange}
              />

              <Controls.TextArea
                name="symptoms"
                rows={4}
                placeholder="Patientenindividuelle Leitsymptomatik als
                  Freitext angeben"
                value={formValues?.symptoms}
                onChange={handleInputChange}
              />
            </FlatCard>
          </Grid>
          <Grid item xs={12} lg={3}>
            <FlatCard
              title="Optionen"
              sxContent={{
                display: "flex",
                flexDirection: "column",
                gap: "0px",
                padding: 0,
                height: "100%",
              }}
            >
              <Controls.Checkbox
                name="isPerpetualApproved"
                label="Langfristgenehmigung"
                value={formValues?.isPerpetualApproved}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="isCopaymentExempted"
                label="Zuzahlungsfrei"
                value={formValues?.isCopaymentExempted}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="isAccident"
                label="Unfallfolgen"
                value={formValues?.isAccident}
                onChange={handleInputChange}
              />
              <Controls.Checkbox
                name="isBVG"
                label="BVG"
                value={formValues?.isBVG}
                onChange={handleInputChange}
              />
            </FlatCard>
          </Grid>
          <Grid item xs={12} lg={3}>
            <FlatCard title="Arzt">
              <Controls.Select
                name="doctorNumber"
                label="LANR"
                value={selectedLANR || formValues?.doctorNumber}
                onChange={handleInputChange}
                options={doctorOptions?.map((doctor: any) => ({
                  label: doctor.number.toString(),
                  value: doctor.number.toString(),
                }))}
                // required
                error={rxerrors.doctorNumber}
                // error={errors.fullName}
              />
              <Button
                onClick={() =>
                  handleOpenAddEditDialog(selectedLANR, selectedBSNR)
                }
                style={{}} // Adjust the width and other styles as needed
              >
                <AddIcon />
                <Typography fontSize={"0.7rem"}>
                  Arzt hinzufügen / bearbeiten
                </Typography>
              </Button>
              {isAddEditDialogOpen && (
                <DoctorDialog
                  openAddEditDialog={isAddEditDialogOpen}
                  handleCloseAddEditDialog={handleCloseAddEditDialog}
                  doctorOptions={doctorOptions}
                  selectedLANR={formValues.doctorNumber}
                  selectedBSNR={formValues.practiceNumber}
                />
              )}
              <Controls.Select
                name="doctor"
                label="Arzt"
                id={1}
                value={selectedDoctor || formValues.doctorId}
                onChange={handleDoctorChange}
                options={doctorOptions?.map((doctor: any) => ({
                  label: doctor.contactInfo.lastName.toString(),
                  value: doctor.contactInfo.lastName.toString(),
                  id: doctor.id.toString(),
                }))}
                // required
                error={rxerrors.doctor}
              />
              <Controls.Input
                name="practiceNumber"
                label="BSNR"
                value={selectedBSNR || formValues?.practiceNumber}
                onChange={handleInputChange}
                // required
                error={rxerrors.practiceNumber}
              />
            </FlatCard>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FlatCard title="Behandlungen">
              <EditTable
                initialColumns={treatmentColumns}
                initialRows={therapytableData}
                onTableDataChange={handletherapyTableDataChange}
                parseInput={parseInput}
                masterTablesData={masterData}
                selectedRx={formValues}
              />
            </FlatCard>
          </Grid>
          <Grid item xs={12} lg={4}>
            <FlatCard title="Anforderungen">
              <Box style={{ width: "350px" }} flexDirection="column">
                <Typography variant="h6">Anforderungen</Typography>
                <Box display="flex" alignItems="center">
                              <Typography
                                variant="body1"
                                sx={{ marginRight: "8px" }}
                              >
                                Hausbesuch
                              </Typography>
                              <FormControlLabel
                                label=""
                                control={
                                  <>
                                     <Checkbox
                                      checked={formValues.isHomeVisit === true}
                                      onChange={handleCheckboxChange}
                                      name="visitTypeCheckboxJa"
                                      value="yes"
                                    />
                                    <Typography variant="body1">ja</Typography>
                                    <Checkbox
                                      checked={formValues.isHomeVisit === false}
                                      onChange={handleCheckboxChange}
                                      name="visitTypeCheckboxNein"
                                      value="no"
                                    />
                                    <Typography variant="body1">nein</Typography>
                                  </>
                                }
                              />
                            </Box>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1px",
                  }}
                >
                  <Controls.Checkbox
                    name="problematic"
                    label="auf Kontrolle"
                    value={formValues?.problematic}
                    onChange={handleInputChange}
                  />
                  <Controls.Checkbox
                    name="changeRequested"
                    label="auf zum Ändern"
                    value={formValues?.changeRequested}
                    onChange={handleInputChange}
                  />
                  {/* <Controls.Checkbox
                  name="booked"
                  label="abgerechnet"
                  value={formValues?.booked}
                  onChange={handleInputChange}
                /> */}

                  <Controls.Checkbox
                    name="therapyReport"
                    label="Therapiebericht"
                    value={formValues?.therapyReport}
                    onChange={handleInputChange}
                  />
                  {/* <Controls.Checkbox
                    name="homeVisit"
                    label="Haus-/Heimbesuch"
                    value={formValues?.homeVisit}
                    onChange={handleInputChange}
                  /> */}
                  {/* <Controls.Checkbox
                    name="isHomeVisitFree"
                    label="Heimbesuch zuzahlungsfrei"
                    value={formValues?.isHomeVisitFree}
                    onChange={handleInputChange}
                  /> */}
                  <Controls.Checkbox
                    name="urgent"
                    label="Dringlicher Behandlungsbedarf"
                    value={formValues?.urgent}
                    onChange={handleInputChange}
                  />
                  {/* <Controls.Checkbox
                    name="isAutoAddReport"
                    label="Berichtsposition automatisch hinzufügen"
                    value={formValues?.isAutoAddReport}
                    onChange={handleInputChange}
                  /> */}
                  <Controls.Checkbox
                    name="hygiene"
                    label="Hygieneposition automatisch hinzufügen"
                    value={formValues?.hygiene}
                    onChange={handleInputChange}
                  />
                </div>
              </Box>
            </FlatCard>
          </Grid>

          <Grid item xs={12} lg={4}>
            <FlatCard title="ggf. Therapieziele/weitere med. Befunde und hinweise">
              <Controls.TextArea
                name="additionalNotes"
                rows={4}
                placeholder=""
                value={formValues?.additionalNotes}
                onChange={handleInputChange}
              />
            </FlatCard>
          </Grid>
          <Grid item xs={12} lg={4}>
            <FlatCard title="Arztunterschrift">
              <Controls.RadioGroup
                name="signatureStatus"
                value={formValues?.signatureStatus}
                options={[
                  { id: "nicht kontrolliert", label: "nicht kontrolliert" },
                  { id: "vorhanden", label: "vorhanden" },
                  { id: "fehlend", label: "fehlend" },
                ]}
                onChange={handleInputChange}
              />
            </FlatCard>
          </Grid>
        </Grid>)
    }
  };

  const highlightStyle = { color: "secondary.main" };
  return (
    <Dialog maxWidth="lg" open={openDialog} onClose={handleCloseRxDialog}>
      <DialogTitle>Rezept Erstellen</DialogTitle>
      <DialogContent style={{ paddingTop: "8px" }}>
        {selectedRow && (
        renderRxComponent()
        )}
      </DialogContent>
   <Dialog open={showWarningDialog} onClose={handleWarningDialogClose}>
        <DialogTitle>
          Bitte wählen Sie den entsprechenden Rezept-typ aus, um fortzufahren.
          Wenn Sie mit der Standardeinstellung fortfahren möchten, klicken Sie
          auf "Abbrechen".
        </DialogTitle>
        <DialogContent>
          <Controls.Select
            name="rxtype"
            label="Rezept-typ"
            // id={1}
            value={formValues?.rxtype}
            onChange={(e: any) => handleRxTypeSelection(e)}
            options={rxType[0]?.map((option: any) => ({
              label: option.text.toString(),
              value: option.text.toString(),
              id: option.id.toString(),
            }))}
            // required
            error={rxerrors.rxtype}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleWarningDialogClose}>
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={loading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <CircularProgress size={60} />
        </div>
      </Modal>
      {unsavedChangesWarning  && (
        <WarningDialog
          open={true} // Ensure the warning dialog is open
          onClose={handleCloseUnsavedChangesWarning} // Function to close the dialog
          onContinue={handleUnsavedChangesWarning} // Function to continue despite unsaved changes
          title="Änderungen speichern?"
          content="Du hast einige Änderungen gemacht. Möchtest du diese speichern, bevor du schließt?"
          isConfirmSave
        />
      )}
      <DialogActions>
        <Button variant="text" onClick={handleCloseRxDialog}>
          Abbrechen
        </Button>
        <Button onClick={handleSubmit}  disabled={loading || Object.values(rxerrors).some((error) => error !== "")} variant="contained">
          Speichern
        </Button>
        <WarningDialog
          open={showWarning}
          onClose={handleCloseWarning}
          onContinue={handleContinueWarning}
          title="Achtung auf Arztunterschrift"
          content="Bitte überprüfen Sie den Unterschriftsstatus des Arztes, bevor Sie das Formular absenden."
        />
      </DialogActions>
    </Dialog>
  );
});

export default ReceiptDataEntryDialog;
