import React, { useEffect, useState } from "react";
import DoctorReportCard from "../DoctorReportCard/DoctorReportCard";
import { Grid, Switch, FormControlLabel, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { observer } from "mobx-react";
import { DoctorReportDto, DoctorReportTimeSlotDto } from "../../../../api-client";
import Controls from "../../../../atoms/Controls";

interface DoctorReportProps {
  doctorReport: DoctorReportDto;
  setDoctorReport: (report: DoctorReportDto) => void;
  editMode?: boolean;
}

const TreatmentPeriodCard = observer(
  ({ doctorReport, setDoctorReport, editMode }: DoctorReportProps) => {
    const [latestTimeSlotStart, setLatestTimeSlotStart] = useState<string>("");
    const [earliestTimeSlotStart, setEarliestTimeSlotStart] = useState<string>("");
    const [treatmentStart, setTreatmentStart] = useState<Date | null>(null);
    const [treatmentEnd, setTreatmentEnd] = useState<Date | null>(null);
    const [isDifferentTreatmentPeriod, setIsDifferentTreatmentPeriod] = useState<boolean | null>(
      null
    );

    useEffect(() => {
      if (doctorReport) {
        setTreatmentStart(
          doctorReport.treatmentStart ? new Date(doctorReport.treatmentStart as any) : null
        );
        setTreatmentEnd(
          doctorReport.treatmentEnd ? new Date(doctorReport.treatmentEnd as any) : null
        );
        setIsDifferentTreatmentPeriod(doctorReport.isDifferentTreatmentPeriod ?? null);
        setEarliestTimeSlotStart(
          doctorReport.earliestTimeSlotStart
            ? moment( doctorReport.earliestTimeSlotStart).format("YYYY-MM-DD")
            : ""
        );
        setLatestTimeSlotStart(
          doctorReport.latestTimeSlotStart
            ? moment( doctorReport.latestTimeSlotStart).format("YYYY-MM-DD")
            : ""
        );
      }
    }, [doctorReport]);

    const handleDateChange = (field: "treatmentStart" | "treatmentEnd", value: Date | null) => {
      if (value) {
        if (field === "treatmentStart") {
          setTreatmentStart(value);
        } else {
          setTreatmentEnd(value);
        }
        handleChangeDoctorReport(field, value.toISOString());
      }
    };

    const handleChangeDoctorReport = (key: string, value: any) => {
      const updatedDoctorReport = { ...doctorReport, [key]: value };
      setDoctorReport(updatedDoctorReport);
    };

    return (
      <DoctorReportCard title="Zeitraum der Behandlung" editMode={editMode}>
        <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
          Durchführung
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controls.Chip label={earliestTimeSlotStart || "-"} sx={{minWidth:"80px"}} />
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1">bis</Typography>
          </Grid>
          <Grid item xs={5}>
            <Controls.Chip label={latestTimeSlotStart || "-"} sx={{minWidth:"80px"}} />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="body1">Abweichender Zeitraum</Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={!!isDifferentTreatmentPeriod}
                  onChange={() => {
                    setIsDifferentTreatmentPeriod(!isDifferentTreatmentPeriod);
                    handleChangeDoctorReport(
                      "isDifferentTreatmentPeriod",
                      !isDifferentTreatmentPeriod
                    );
                  }}
                />
              }
              label="Ja/Nein"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DatePicker
              label="von"
              value={treatmentStart}
              onChange={(date) => handleDateChange("treatmentStart", date)}
              disabled={!isDifferentTreatmentPeriod}
              // renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              label="bis"
              value={treatmentEnd}
              onChange={(date) => handleDateChange("treatmentEnd", date)}
              disabled={!isDifferentTreatmentPeriod}
              // renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
        </Grid>
      </DoctorReportCard>
    );
  }
);

export default TreatmentPeriodCard;
