// helper functions to calculate therapist stats
import { TherapistStatsDto } from '../../../api-client';

interface GroupedData {
    [locationId: string]: TherapistStatsDto[]
}

interface TotalData {
    locationId: string, 
    therapistCount: number, 
    totalGaps: number
}

// function to calculate total gaps and total number of therapists by location
export const calculateTherapistStats = (therapistData: TherapistStatsDto[]): TotalData[] => {
    // Array to store the results
    const totalData: TotalData[] = [];

    // Group by location
    const groupedData: GroupedData = Object.groupBy(therapistData, ({ locationId }) => locationId) as GroupedData;

    Object.keys(groupedData).forEach(locationId => {
        // calculate total number of therapists
        const count = groupedData[locationId].length;

        // calculate total gaps
        const total = Number(groupedData[locationId].reduce((total, entry) => total + entry.gaps, 0).toFixed(2));

        totalData.push({
            locationId: locationId,
            therapistCount: count,
            totalGaps: total,
        });
    });
    return totalData;
}


interface TherapistTableData {
    firstName: string | undefined,
    lastName: string | undefined,
    nickName: string | undefined,
    value: number,
    gaps: number,
    rxCount: number,
}

interface TherapistData {
    locationId: string,
    therapistCount: number,
    totalGaps: number,
    table: TherapistTableData[],
}

// function to map therapist table data
export const mapTherapistTableData = (therapistData: TherapistStatsDto[], totalResults: TotalData[]): TherapistData[] => {
    // array to store results
    const therapistResults: TherapistData[] = [];

    totalResults.forEach((row) => {
        const therapistLocation = therapistData.filter(
            (entry) => entry.locationId === row.locationId
        );

        const tableData = therapistLocation.map((entryRow) => ({
            firstName: entryRow.user.firstName,
            lastName: entryRow.user.lastName,
            nickName: entryRow.user.nickName,
            value: entryRow.value,
            gaps: entryRow.gaps,
            rxCount: entryRow.rxCount,
        }));

        therapistResults.push({
            locationId: row.locationId,
            therapistCount: row.therapistCount,
            totalGaps: row.totalGaps,
            table: tableData,
        }); 
    });
    return therapistResults;    
};