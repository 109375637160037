// helper functions for patient stats

import { PatientStartDateDto } from "../../../api-client";
import { startOfWeek, addWeeks, startOfMonth, addMonths, isEqual, isBefore } from 'date-fns';


// structure of the resulting flattened data
interface FlattenedPatientData {
    locationId: string,
    locationName: string,
    patientStartDates: Date,
}

// function to flatten data (since PatientStartDates is array of string dates)
const flattenData = (patientStats: PatientStartDateDto[]): FlattenedPatientData[] => {
    return patientStats.flatMap(entry => 
        entry.patientStartDates.map(date => ({
            locationId: entry.locationId,
            locationName: entry.locationName,
            patientStartDates: new Date(date),
        }))
    )
}


// structure of grouped data (group by location)
interface GroupedData {
    [locationId: string]: FlattenedPatientData[]
}

interface CumulativeData {
    locationId: string, 
    locationName: string, 
    date: Date, 
    cumulativeSum: number
}

// function to calculate cumulative count
export const calculateCumulativePatients = (patientStats: PatientStartDateDto[], binType: 'week' | 'month'): CumulativeData[] => {
    // Array to store the results
    const cumulativeData: CumulativeData[] = [];
    
    // flatten data first
    const flattenedData: FlattenedPatientData[] = flattenData(patientStats);

    // Group by locationId 
    const groupedData: GroupedData = Object.groupBy(flattenedData, ({ locationId }) => locationId) as GroupedData;

    // Loop through each location group
    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => a.patientStartDates.getTime() - b.patientStartDates.getTime());

        const firstEntryDate = new Date(groupedData[locationId][0].patientStartDates);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].patientStartDates);
        console.log("patients earliest date: ", firstEntryDate);
        console.log("patients latest date: ", lastEntryDate);

        let cumulativeSum = 0; 
        let binStart: Date;
        let nextBinStart: Date;

        if (binType === 'week') {
            binStart = startOfWeek(firstEntryDate, { weekStartsOn: 1 });
        } else {
            binStart = startOfMonth(firstEntryDate);
        }

        while(isBefore(binStart, lastEntryDate)) {
            if (binType === 'week') {
                nextBinStart = addWeeks(binStart, 1);
            } else {
                nextBinStart = addMonths(binStart, 1);
            }
            console.log("bins: ", `(${binStart} - ${nextBinStart})`);

            // count patients in current bin
            const patientsInBin = groupedData[locationId].filter(entry => 
                entry.patientStartDates >= binStart && entry.patientStartDates < nextBinStart
            ).length;

            cumulativeSum += patientsInBin;

            cumulativeData.push({
                locationId: locationId,
                locationName: groupedData[locationId][0].locationName,
                date: binStart,
                cumulativeSum: cumulativeSum
            });

            binStart = nextBinStart;
        }
    });
    return cumulativeData;
}


interface CountData {
    locationId: string, 
    locationName: string, 
    date: Date, 
    newPatients: number
}

// function to calculate number of new patients
export const calculateNewPatients = (patientStats: PatientStartDateDto[], binType: 'week' | 'month'): CountData[] => {
    // Array to store the results
    const countData: CountData[] = [];

    // flatten data first
    const flattenedData: FlattenedPatientData[] = flattenData(patientStats);

    // Group by locationId 
    const groupedData: GroupedData = Object.groupBy(flattenedData, ({ locationId }) => locationId) as GroupedData;

    // Loop through each location group
    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => a.patientStartDates.getTime() - b.patientStartDates.getTime());

        const firstEntryDate = new Date(groupedData[locationId][0].patientStartDates);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].patientStartDates);

        let binStart: Date;
        let nextBinStart: Date;

        if (binType === 'week') {
            binStart = startOfWeek(firstEntryDate, { weekStartsOn: 1 });
        } else {
            binStart = startOfMonth(firstEntryDate);
        }

        while(isBefore(binStart, lastEntryDate)) {
            if (binType === 'week') {
                nextBinStart = addWeeks(binStart, 1);
            } else {
                nextBinStart = addMonths(binStart, 1);
            }

            // count patients in current bin
            const patientsInBin = groupedData[locationId].filter(entry => 
                entry.patientStartDates >= binStart && entry.patientStartDates < nextBinStart
            ).length;

            countData.push({
                locationId: locationId,
                locationName: groupedData[locationId][0].locationName,
                date: binStart,
                newPatients: patientsInBin
            });

            binStart = nextBinStart;
        }
    });
    return countData;
}