import React, { FC } from "react";
import { Grid, Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VerifiedIcon from "@mui/icons-material/Verified";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";
import ToggleUISwitch from "../../../common/components/ToggleUISwitch";
import { TestType, TestTypeInterface } from "../../types/TestTypes.interface";

interface HeaderButtonsProps {
  onAddSingleRx: (open: boolean) => void;
  handleValidationFilter: () => void;
  handleSave: () => void;
  handleDrucken: () => void;
  handleExport: () => void;
  testToggleChecked: boolean;
  handleTestToggleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  testTypeData: TestTypeInterface;
}

export const HeaderButtons: FC<HeaderButtonsProps> = ({
  onAddSingleRx,
  handleValidationFilter,
  handleDrucken,
  handleSave,
  handleExport,
  testToggleChecked,
  handleTestToggleChange,
  testTypeData,
}) => {
  return (
    <Box sx={{ padding: 1 }}>
      <Grid container alignItems="center" justifyContent={"space-evenly"} spacing={0.5}>
        <Grid item xs={0} className="header-button-container">
          <Button className="button" endIcon={<AddIcon />} onClick={() => onAddSingleRx(true)}>
            Rezept hinzufügen
          </Button>
        </Grid>
        <Grid item xs={0} className="header-button-container">
          <Button className="button" endIcon={<VerifiedIcon />} onClick={handleValidationFilter}>
            nur Valide
          </Button>
        </Grid>
        <Grid item xs={0} className="header-button-container">
          <Button className="button" endIcon={<SaveIcon />} onClick={handleSave}>
            Speichern
          </Button>
        </Grid>
        <Grid item xs={0} className="header-button-container">
          <Button className="button" onClick={handleDrucken} endIcon={<PrintIcon />}>
            Ausdrucke erstellen
          </Button>
        </Grid>
        <Grid item xs={0} className="header-button-container">
          <Button
            className="button"
            onClick={handleExport}
            endIcon={<IosShareIcon />}
            disabled={
              !(
                process.env.REACT_APP_INSTANCE_NAME == "production" ||
                testTypeData.type == TestType.INTERNAL
              )
            }
          >
            Versenden
          </Button>
        </Grid>
        <Grid item xs={0} className="header-button-container">
          <ToggleUISwitch checked={testToggleChecked} onChange={handleTestToggleChange} />
        </Grid>
      </Grid>
    </Box>
  );
};
