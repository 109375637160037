import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import BillInfo from "./IndirectBillInfoBox";
import { TopLevelPaper } from "../../../../../themes/StyledComponents";
import { AppointmentDto } from "../../../../../api-client";
import useStore from "../../../../../helpers/useStore";
import { MasterTablesContext } from "../../../../../stores/MasterTables/MasterTables.provider";
import { PatientsContext } from "../../../../../stores/Patients/patients.provider";
import RxsOverview from "../../Base/components/RxsOverview";
import TerminView from "../../../../PatientOverview/RxInfo/TerminView";
import { TerminViewConstants } from "../../../../PatientOverview/TerminView";
import { AppointmentContext } from "../../../../../stores/Appointment/appointment.provider";
import BillBoxWrapper from "../../DirectBilling/common/components/BillBoxWrapper";

interface FullScreenBillProps {
  handleFullScreenDialogClose: () => void;
  selectedAccount: string;
  selectedInsurance: string;
  userName: string;
  userId: string;
}

const FullScreenBill: FC<FullScreenBillProps> = ({
  handleFullScreenDialogClose,
  selectedAccount,
  selectedInsurance,
  userName,
  userId,
}) => {
  const [selectedRx, setSelectedRx] = useState<any>(null); // Add state to store selected rx
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentDto[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const MasterTableDataStore = useStore(MasterTablesContext);
  const { masterTablesData } = MasterTableDataStore;
  const PatientStore = useStore(PatientsContext);
  const { patientDataRefreshFlag, setPatientDataRefreshFlag } = PatientStore;
  const AppointmentStore = useStore(AppointmentContext);
  const { getAppointmentsForPatientWithRx } = AppointmentStore;

  const handleRxDelete = (deletedRxId: string) => {
    if (selectedRx && selectedRx.id === deletedRxId) {
      setSelectedRx(null);
      setIsVisible(false);
    }
  };

  const handleRowClick = (rx: any) => {
    setSelectedRx(rx); // Update the selected rx when a row is clicked
    setIsVisible(true);
  };

  useEffect(() => {
    // Fetch appointments for the selected
    const fetchData = async () => {
      if (selectedRx) {
        const data = await getAppointmentsForPatientWithRx(selectedRx.rxNumber);
        setSelectedAppointment(data);
      }
    };
    fetchData();
  }, [getAppointmentsForPatientWithRx, selectedRx]);

  console.log("After Clicking The Button", selectedAppointment);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={1}
        sx={{ height: "100%", marginTop: "1px" }}
      >
        <BillBoxWrapper xs={5}>
          <TopLevelPaper className="scrollable rounded-container">
            {/* Pass handleRowClick to BillInfo */}
            <BillInfo
              handleFullScreenDialogClose={handleFullScreenDialogClose}
              onRowClick={handleRowClick}
              account={selectedAccount}
              payerIk={selectedInsurance}
              userName={userName}
              userId={userId}
              onRxDelete={handleRxDelete}
            />
          </TopLevelPaper>
        </BillBoxWrapper>
        {isVisible && (
          <>
            <BillBoxWrapper xs={5}>
              <TopLevelPaper className="scrollable rounded-container">
                {/* Pass the selected rx to BillOverview */}
                <RxsOverview
                  rowData={selectedRx}
                  patientValues={selectedRx?.patient}
                  masterData={masterTablesData}
                  handleMoreButtonClick={() => {}}
                />
              </TopLevelPaper>
            </BillBoxWrapper>
            <BillBoxWrapper xs={2}>
              <TopLevelPaper className="scrollable rounded-container">
                <TerminView
                  appointments={selectedAppointment}
                  patientId={selectedRx?.patient?.id} // Use selectedRx to pass patientId
                  dataRefreshFlag={patientDataRefreshFlag}
                  setDataRefreshFlag={setPatientDataRefreshFlag}
                  isView={TerminViewConstants.RX_VIEW}
                />
              </TopLevelPaper>
            </BillBoxWrapper>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default FullScreenBill;
