import React, { useEffect, useState } from "react";
import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CardHeader,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import Controls from "../../../atoms/Controls";
import { Edit, MoreVert } from "@mui/icons-material";
import RxPage from "../../RxPage";
import { mobiliTheme } from "../../../themes/mobiliTheme";
import moment from "moment";
import { observer } from "mobx-react";
import FlatCard from "../../../atoms/FlatCard";
import { FlexListContainer, LabelItem, ValueItem } from "../../../molecules/FlexList";
import PrivateView from "./RxForms/Private/PrivateView";
import BG from "./RxForms/BG/BG";
import Verordnung13 from "./RxForms/Kasse/Verordnung13";
import Bundeswehr from "./RxForms/Bundeswehr/Bundeswehr";
import Zahnarzt from "./RxForms/Zahnarzt/Zahnarzt";
import { RxStoreContext } from "../../../stores/Rx/rx.provider";
import useStore from "../../../helpers/useStore";
import RxFormsData from "./RxForms/RxFormsData";
import { NoteDto, RxDtoProcessingStatusEnum } from "../../../api-client";
import Notes from "../../ScheduleOverview/Notes/Notes";
import { NotesContext } from "../../../stores/Notes/notes.provider";
import { UserContext } from "../../../stores/User/User.provider";
import Blankoverordnung from "./RxForms/Blankoverordnung/Blankoverordnung";
import RxStatusNotification from "./RxStatusNotification";
import notificationStore from "../../../stores/Notification/notificationStore";

interface RowDetailsProps {
  rowData: any;
  editRxMode?: boolean;
  setEditRxMode?: any;
  patientValues?: any;
  masterData?: any;
  isEditing?: boolean;
  setPatientData?: any;
  handleData?: any;
  handleMoreButtonClick: (event: React.MouseEvent<HTMLButtonElement>, rx: any) => void;
}
// const cellStyle = { minWidth: "50px", width: "20%" }; // Style for TableCell
const chipStyle = { width: "280px" }; // Style for Chip

const RxRowOverview: React.FC<RowDetailsProps> = observer(
  ({
    rowData,
    editRxMode,
    setEditRxMode,
    patientValues,
    masterData,
    isEditing,
    setPatientData,
    handleData,
    handleMoreButtonClick,
  }) => {
    const RxStore=useStore(RxStoreContext)
    const [openRxStatusNotfication,setOpenRxStatusNotification]=useState(false);
    const handleCloseRxStatusNotification=()=>{
      setOpenRxStatusNotification(false);
    }
    const {updateprocessingStatus}=RxStore;
    const renderBooleanProperty = (label: string, value: boolean) => {
      if (value === true) {
        return (
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>{label}</TableCell>
            {/* <TableCell>{value.toString()}</TableCell> */}
          </TableRow>
        );
      }
      return null;
    };

    const updateRxProcessingStatus=async(event:any)=>{
      try{
      console.log("azusuisisisooso",event.target.value)
      if(event.target.value===RxDtoProcessingStatusEnum.Abgebrochen){
      setOpenRxStatusNotification(!openRxStatusNotfication)
      }
      else if(Object.values(RxDtoProcessingStatusEnum).includes(event?.target.value)){
      const response=await updateprocessingStatus(rowData.id,event?.target.value)
    }}
    catch(error){
      notificationStore.showMessage("Error while updating Proceesing Status","error")
    }
    }
    const UserStore=useStore(UserContext);
    const {user}=UserStore;
    const NotesStore=useStore(NotesContext);
    const {getRxNotes,updateNoteById,postNotes,fetchNotes}=NotesStore;
    const [rxNotes, setRxNotes] = useState<NoteDto[]>([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          // if(fetchNotes){
          const response = await getRxNotes(rowData.id);
  
          setRxNotes(response);
          // }
        } catch (error) {
          console.log("error", error);
        }
      };
  
      fetchData();
    }, [rowData.id,fetchNotes]);

    const updateNote = async (index: number, updatedCard: NoteDto) => {
      updatedCard.rxId = rowData.id;
      if(updatedCard.id){
        await updateNoteById(updatedCard.id,user?.id as string,updatedCard)
     }
     else{
      await postNotes(user?.id as string, updatedCard);
     }
      const updatedNotes = [...rxNotes];
      updatedNotes[index] = updatedCard;
      setRxNotes(updatedNotes);
    };
  
    const renderRxComponent = () => {
      switch (rowData?.rxtype?.id) {
        case 'Privat/Selbstzahler':
          return <PrivateView selectedRow={rowData} patientData={patientValues} updateRxProcessingStatus={updateRxProcessingStatus} />;
        case 'Berufsgenossenschaft':
         
          return <BG selectedRow={rowData} patientValues={patientValues}  handleData={handleData} readOnlyView  newRx={false} updateRxProcessingStatus={updateRxProcessingStatus}/>
        case 'Kasse':
        case 'Entlassmanagement':
          return (
            <Verordnung13
            rxFormData={
              patientValues?.rxs?.filter(
                (rx: any) => rx.rxNumber === rowData?.rxNumber
              )[0]
            }
            patientValues={patientValues}
            handleData={handleData}
            masterTablesData={masterData}
            readOnlyView={true}
            newRx={false}
            updateRxProcessingStatus={updateRxProcessingStatus}
            // ref={saveGenericViewData}
          />
          );
          case 'Blankoverordnung':
              return (
                <Blankoverordnung
                rxFormData={
                  patientValues?.rxs?.filter(
                    (rx: any) => rx.rxNumber === rowData?.rxNumber
                  )[0]
                }
                patientValues={patientValues}
                handleData={handleData}
                masterTablesData={masterData}
                readOnlyView={true}
                newRx={false}
                updateRxProcessingStatus={updateRxProcessingStatus}
                // ref={saveGenericViewData}
              />
              );
          case 'Bundeswehr':
            return(
              <Bundeswehr
            rxFormData={
              patientValues?.rxs?.filter(
                (rx: any) => rx.rxNumber === rowData?.rxNumber
              )[0]
            }
            patientValues={patientValues}
            handleData={handleData}
            masterTablesData={masterData}
            readOnlyView={true}
            newRx={false}
            updateRxProcessingStatus={updateRxProcessingStatus}
            // ref={saveGenericViewData}
          />

            )
            case 'Zahnarzt':
              return (
                <Zahnarzt
                rxFormData={
                  patientValues?.rxs?.filter(
                    (rx: any) => rx.rxNumber === rowData?.rxNumber
                  )[0]
                }
                patientValues={patientValues}
                handleData={handleData}
                masterTablesData={masterData}
                readOnlyView={true}
                newRx={false}
                updateRxProcessingStatus={updateRxProcessingStatus}
                // ref={saveGenericViewData}
              />
              );
          
        // Add other cases for different rxtypes as needed
        default:
          return (
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} sx={{ border: "0px solid green" }}>
                <FlexListContainer>
                  <LabelItem label="Rx Datum" />
                  <ValueItem
                    value={
                      rowData.date
                        ? moment(rowData.date, "YYYY-MM-DD", true).isValid()
                          ? moment(rowData.date).format("DD.MM.YYYY")
                          : rowData.date
                        : "-"
                    }
                  />
                  <LabelItem label="Eingegeben am" />
                  <ValueItem value={rowData.creationDate || "-"} />
                  <LabelItem label="Behandlungsort" />
                  <ValueItem value={  rowData.isHomeVisit === true
                        ? "Haus/Heim"
                        : rowData.isHomeVisit === false
                        ? "Praxis"
                        : "-"} />
                  <LabelItem label="Mandant" />
                  <ValueItem value={rowData.account?.name || "-"} />
                  <LabelItem label="Rezeptverbleib" />
                  <ValueItem value={rowData?.status || "-"} />

                  <LabelItem label="Kostenträger" />
                  <ValueItem value={rowData?.payer?.name || "-"} />
                  <LabelItem label="IK Nummer" />
                  <ValueItem value={rowData?.payer?.ik || "-"} />
                  <LabelItem label="Tarif Group" />
                  <ValueItem value={rowData?.tarifGroupId || "-"} />
                  <LabelItem label="Versichertennummer" />
                  <ValueItem value={rowData.insureeNumber || patientValues.insuranceNo} />
                  <LabelItem label="Status" />
                  <ValueItem value={rowData?.insuranceStatus || "-"} />
                  <LabelItem label="Arzt" />
                  <ValueItem
                    value={
                      `${rowData?.doctor?.contactInfo?.firstName || " "} ${
                        rowData?.doctor?.contactInfo?.lastName || " "
                      }` || "-"
                    }
                  />
                  <LabelItem label="Rezept-Typ" />
                  <ValueItem value={rowData.rxtype?.id || "-"} />
                  <LabelItem label="LANR" />
                  <ValueItem value={rowData?.doctorNumber || "-"} />
                  <LabelItem label="BSNR" />
                  <ValueItem value={rowData?.practiceNumber || "-"} />
                  <LabelItem label="TextDiagnose" />
                  <ValueItem value={rowData?.diagnosisText || "-"} />
                  <LabelItem label="ICDCodes" />
                  <ValueItem
                    value={
                      (rowData || [])?.icdCodeEntries?.map((icd: any) => icd.userText).join(", ") ||
                      "-"
                    }
                  />
                  <LabelItem label="DiagnoseGruppe" />
                  <ValueItem value={rowData?.diagnosisGroup?.code || "-"} />

                  <LabelItem label="Optionen" />
                  <ValueItem
                    value={[
                      rowData?.isPerpetualApproved ? "Langfristgenehmigung" : "",
                      rowData?.isCopaymentExempted ? "Zuzahlungsfrei" : "",
                      rowData?.isAccident ? "Unfallfolgen" : "",
                      rowData?.isBVG ? "BVG" : "",
                      rowData?.problematic ? "auf Kontrolle" : "",
                      rowData?.changeRequested ? "auf zum Ändern" : "",
                      rowData?.therapyReport ? "Therapiebericht" : "",
                      rowData?.urgent ? "Dringlicher Behandlungsbedarf" : "",
                      rowData?.hygiene ? "Hygieneposition" : "",
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  />
                </FlexListContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <FlatCard title="Behandlungen">
                  <Table>
                    <TableRow>
                      <TableCell>Heilm.</TableCell>
                      <TableCell>Menge</TableCell>
                      <TableCell>Frequency</TableCell>
                    </TableRow>
                    {rowData.therapyRxs?.slice()
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((therapyRx: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell>{therapyRx?.therapy?.abbreviation}</TableCell>
                        <TableCell>{therapyRx?.amount}</TableCell>
                        <TableCell>{therapyRx?.frequency?.text}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </FlatCard>
                <Grid item xs={12}>
                  <Controls.Select
                    name="processingStatus"
                    label="Status"
                    onChange={updateRxProcessingStatus}
                    value={rowData?.processingStatus || RxDtoProcessingStatusEnum.InBehandlung}
                    options={Object.values(RxDtoProcessingStatusEnum).map(value => ({
                      value: value,
                      label: value,
                    }))}
                    noTriggerChange={true}
                    // style={{
                    //   width: "100%",
                    //   marginTop: "0px",
                    //   marginBottom: "0px",
                    //   fontSize: "10px",
                    // }}
                  />
                </Grid>
                <Grid item xs={12}>
                <FlatCard
            title="Notizen"
            sx={{
              paddingTop: "5px",
              marginBottom: "8px",
              textAlign: "left",
            }}
          >
            <Notes notes={rxNotes} setNotes={updateNote} maxVisibleNotes={3} />
          </FlatCard></Grid>
              </Grid>
            </Grid>
          );

      }
    };
    return (
      <Card sx={{ borderRadius: "10px" }}>
        <CardHeader
          title={`Rezept- ${rowData.rxNumber}`}
          sx={{ height: "40px" }}
          action={
            <>
              <IconButton onClick={() => setEditRxMode(!editRxMode)}>
                <Edit />
              </IconButton>
              <IconButton onClick={(event) => handleMoreButtonClick(event, rowData)}>
                <MoreVert />
              </IconButton>
            </>
          }
        />

        <CardContent>
          {renderRxComponent()}
          <RxStatusNotification
            open={openRxStatusNotfication} onClose={handleCloseRxStatusNotification } rxData={rowData} patientId={patientValues.id}          />
        </CardContent>
      </Card>
    );
  }
);

export default RxRowOverview;
