// PatientRechnungTable.tsx
import React, { FC, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Typography,
  Button,
  Chip,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { GetRechnungDetailsDto } from "../../../../api-client";
import { formatDateUI } from "../../../../helpers/dateFormat";
import PayRechnungDialog from "../dialogs/PayBankTransferDialog";
import { useDownload } from "../../../../hooks/useDownload";
import useStore from "../../../../helpers/useStore";
import { TransactionsContext } from "../../../../stores/Transactions/Transactions.provider";
import notificationStore from "../../../../stores/Notification/notificationStore";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteConfirmationDialog } from "../../../Transactions/Insurance/DirectBilling/common/exports";

interface PatientRechnungTableProps {
  rechnungen: GetRechnungDetailsDto[];
  userId?: string;
  // accountId?: string;
  fetchRechnungen: () => void;
  fetchPaidBills: () => void;
}

const PatientRechnungTable: FC<PatientRechnungTableProps> = ({
  rechnungen,
  userId,
  // accountId,
  fetchRechnungen,
  fetchPaidBills,
}) => {
  // State to keep track of which rows are expanded (using rechnungNumber as key)
  const [openRows, setOpenRows] = useState<Record<string, boolean>>({});
  const [selectedRechnung, setSelectedRechnung] = useState<GetRechnungDetailsDto | null>(null);
  const [payDialogOpen, setPayDialogOpen] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteRechnung, setDeleteRechnung] = useState<GetRechnungDetailsDto | null>(null);

  const transactionStore = useStore(TransactionsContext);
  const { softDeleteRechnung } = transactionStore;

  const { handleDownloadPdf } = useDownload();

  const handleOpenPayDialog = (rechnung: GetRechnungDetailsDto) => {
    setSelectedRechnung(rechnung);
    setPayDialogOpen(true);
  };

  const handlePaymentSuccess = () => {
    setPayDialogOpen(false);
    if (fetchRechnungen) {
      fetchRechnungen();
    }
  };

  const handleToggleRow = (rechnungNumber: string) => {
    setOpenRows((prev) => ({
      ...prev,
      [rechnungNumber]: !prev[rechnungNumber],
    }));
  };

  const handleOpenDeleteDialog = (rechnung: GetRechnungDetailsDto) => {
    setDeleteRechnung(rechnung);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteRechnung(null);
  };

  const handleDeleteConfirm = async () => {
    if (!deleteRechnung) return;
    try {
      await softDeleteRechnung(deleteRechnung.id);
      setDeleteDialogOpen(false);
      setDeleteRechnung(null);
      fetchRechnungen();
      fetchPaidBills();
    } catch (error) {
      console.error("Error deleting rechnung:", error);
      notificationStore.showMessage("Fehler beim Löschen der Rechnung", "error");
    }
  };

  // Define header labels for the main table
  const headers = [
    "", // for expand/collapse icon
    "Rechnungnummer",
    "Rezeptnummer",
    "Patient",
    "Motiv",
    // "Erstellt von",
    "Erstellt am",
    "Gesamtpreis (€)",
    "Bezahlt (€)",
    "Fällig (€)",
    "Dokumentieren",
    "Aktionen",
  ];

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, idx) => (
                <TableCell
                  key={idx}
                  sx={{ padding: "5px" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rechnungen.map((item) => {
              const isOpen = openRows[item.rechnungNumber] || false;
              return (
                <React.Fragment key={item.rechnungNumber}>
                  {/* Main row with invoice details */}
                  <TableRow>
                    {/* Expand/Collapse Icon Cell */}
                    <TableCell sx={{ padding: "5px" }}>
                      <IconButton
                        size="small"
                        onClick={() => handleToggleRow(item.rechnungNumber)}
                        aria-label="expand row"
                      >
                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>{item.rechnungNumber}</TableCell>
                    <TableCell sx={{ padding: "5px" }}>{item.rxNumber}</TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {`${item.patientLastName}, ${item.patientFirstName}`}
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>{item.text || ""}</TableCell>
                    {/* <TableCell sx={{ padding: "5px" }}>{item.userName || ""}</TableCell> */}
                    <TableCell sx={{ padding: "5px" }}>{formatDateUI(item.createdAt)}</TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {Number(item.amount).toFixed(2).replace(".", ",")} €
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {Number(item.totalPaid).toFixed(2).replace(".", ",")} €
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {Number(item.due).toFixed(2).replace(".", ",")} €
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {item.docDto?.id && (
                        <IconButton
                          className="fancy-button"
                          onClick={() => handleDownloadPdf(item.docDto!.id)}
                        >
                          <PictureAsPdfIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell sx={{ padding: "5px" }}>
                      {/* Payment button or text */}
                      {Number(item.due.toFixed(2)) > 0 ? (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            <Button
                              variant="contained"
                              size="medium"
                              color="secondary"
                              sx={{
                                fontSize: "0.7rem",
                                padding: "2px 5px",
                                minWidth: "auto",
                              }}
                              onClick={() => handleOpenPayDialog(item)}
                            >
                              Zahlen
                            </Button>
                            <IconButton
                              size="medium"
                              color="error"
                              onClick={() => handleOpenDeleteDialog(item)}
                              title="Rechnung löschen"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </>
                      ) : (
                        <Chip
                          label="Vollständig bezahlt"
                          color="success"
                          variant="outlined"
                        />
                      )}
                    </TableCell>
                  </TableRow>

                  {/* Collapsible row for allocations (bank transfers) */}
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={headers.length}
                    >
                      <Collapse
                        in={isOpen}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            component="div"
                          >
                            Banküberweisungen
                          </Typography>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Transaktionnummer</TableCell>
                                <TableCell>Bezahlter Betrag (€)</TableCell>
                                <TableCell>Bezahlt am</TableCell>
                                <TableCell>Erstellt von</TableCell>
                                <TableCell>Dokument</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.allocations && item.allocations.length > 0 ? (
                                item.allocations.map((alloc) => (
                                  <TableRow key={alloc.transactionNumber}>
                                    <TableCell>{alloc.transactionNumber}</TableCell>
                                    <TableCell>
                                      {Number(alloc.paidAmount).toFixed(2).replace(".", ",")} €
                                    </TableCell>
                                    <TableCell>{formatDateUI(alloc.createdAt)}</TableCell>
                                    <TableCell>
                                      {`${alloc.userLastName}, ${alloc.userFirstName}`}
                                    </TableCell>
                                    <TableCell>
                                      <IconButton onClick={() => {}}>
                                        <PictureAsPdfIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={5}>
                                    <Typography align="center">
                                      Keine Banküberweisungen vorhanden
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Payment Dialog */}
      <PayRechnungDialog
        open={payDialogOpen}
        onClose={() => setPayDialogOpen(false)}
        rechnung={selectedRechnung}
        accountId={selectedRechnung?.accountName || ""}
        userId={userId || ""}
        onPaymentSuccess={handlePaymentSuccess}
      />

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        title="Rechnung löschen?"
        content="Möchten Sie diese Rechnung wirklich löschen? Der Vorgang kann rückgängig gemacht werden."
      />
    </>
  );
};

export default PatientRechnungTable;
