import { ReactElement } from "react";
import {
  Business,
  Home,
  Store,
} from "@mui/icons-material";
import { mobiliTheme } from "../themes/mobiliTheme";

export const visitTypeMapping: { [key: string]: string } = {
  Heim: "HM",
  Haus: "HB",
  Praxis: "PX",
  // Add more mappings as needed
};

// Mapping of visitType to icons
export const visitTypeToIcon: Record<string, ReactElement> = {
  haus: <Home sx={{color:mobiliTheme.palette.info.dark}}/>,
  praxis: <Store sx={{color:mobiliTheme.palette.info.dark}}/>,
  heim: <Business sx={{color:mobiliTheme.palette.info.dark}}/>,
};
