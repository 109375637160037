import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const LoadingSpinner = () => (
  <Box sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1, display: 'flex', justifyContent: 'center',alignItems: 'center', width: '100%', height: '100%',}}>
    <CircularProgress
      size= '75px'
      sx={{
        color: 'rgba(40, 141, 223, 0.63)',
        position: "absolute",
        top: "35%",
        left: "45%",
        // transform: "translate(-50%, -50%)",
        pointerEvents: 'none',
        zIndex: 10
      }}
    />
  </Box>
);

export default LoadingSpinner;
