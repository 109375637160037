export function hexToRgba(hex: string, opacity = 1) {
    hex = hex.replace('#', '');

    // Convert hex to RGB
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Return the RGBA string
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}