import { Box, Button, Grid } from "@mui/material";
import { FC } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import IosShareIcon from "@mui/icons-material/IosShare";

interface HeaderButtonsProps {
  handleValidationFilter: () => void;
  handleSave: () => void;
  handleDrucken: () => void;
  handleExport: () => void;
}

export const HeaderButtons: FC<HeaderButtonsProps> = ({
  handleValidationFilter,
  handleDrucken,
  handleSave,
  handleExport,
}) => {
  return (
    <Box sx={{ padding: 1 }}>
      <Grid
        container
        alignItems="center"
        justifyContent={"space-evenly"}
        spacing={0.5}
      >
        <Grid
          item
          xs={0}
          className="header-button-container"
        >
          <Button
            className="button"
            endIcon={<VerifiedIcon />}
            onClick={handleValidationFilter}
          >
            nur Valide
          </Button>
        </Grid>
        <Grid
          item
          xs={0}
          className="header-button-container"
        >
          <Button
            className="button"
            endIcon={<SaveIcon />}
            onClick={handleSave}
          >
            Speichern
          </Button>
        </Grid>
        <Grid
          item
          xs={0}
          className="header-button-container"
        >
          <Button
            className="button"
            onClick={handleDrucken}
            endIcon={<PrintIcon />}
          >
            Ausdrucke erstellen
          </Button>
        </Grid>
        <Grid
          item
          xs={0}
          className="header-button-container"
        >
          <Button
            className="button"
            onClick={handleExport}
            endIcon={<IosShareIcon />}
          >
            Exportiert
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
