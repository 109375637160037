import React, { FC } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  Checkbox,
  IconButton,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableRow as MuiTableRow } from "@mui/material";
import { GetInsuranceTransactionDto } from "../../../../../api-client";
import { BillingStatus } from "../pages/InsurancePageLayout";
import { formatDateUI } from "../../../../../helpers/dateFormat";
import "../../DirectBilling/common/helpers/css/direct-bill-info.css";
import { useDownload } from "../../../../../hooks/useDownload";

import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";

interface InsuranceBillsTableProps {
  bills: GetInsuranceTransactionDto[];
  billStatus: BillingStatus;
  selectedBillIds: string[];
  onSelectAll: (checked: boolean) => void;
  onSelectOne: (id: string) => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export const InsuranceBillsTable: FC<InsuranceBillsTableProps> = ({
  bills,
  billStatus,
  selectedBillIds,
  onSelectAll,
  onSelectOne,
  onEdit,
  onDelete,
}) => {
  const sortedBills = bills.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
  const allChecked = sortedBills.length > 0 && selectedBillIds.length === sortedBills.length;
  const indeterminate = selectedBillIds.length > 0 && selectedBillIds.length < sortedBills.length;
  const { handleDownloadPdf } = useDownload();

  return (
    <TableContainer
      component={Paper}
      className="scrollable"
      sx={{ maxHeight: "550px" }}
    >
      <Table
        size="small"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell
              padding="checkbox"
              className="table-header-cell"
            >
              {billStatus === BillingStatus.START && (
                <Checkbox
                  indeterminate={indeterminate}
                  checked={allChecked}
                  onChange={(e) => onSelectAll(e.target.checked)}
                />
              )}
            </TableCell>
            <TableCell className="table-header-cell">Rechnung Nummer</TableCell>
            <TableCell className="table-header-cell">Payer</TableCell>
            <TableCell className="table-header-cell">Gesamtpreis (€)</TableCell>
            <TableCell className="table-header-cell">Erstellungsdatum</TableCell>
            <TableCell className="table-header-cell">Erstellt von</TableCell>
            <TableCell className="table-header-cell">Kontoname</TableCell>
            <TableCell className="table-header-cell">T-Konten</TableCell>
            {billStatus === BillingStatus.START && (
              <TableCell className="table-header-cell">Aktionen</TableCell>
            )}
            {billStatus === (BillingStatus.OPEN || BillingStatus.PREVIOUS) && (
              <TableCell className="table-header-cell">Rechnung</TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedBills.map((row) => {
            const isChecked = selectedBillIds.includes(row.id);
            console.log(" LETS SEE THE SORTED ROW", row);
            return (
              <MuiTableRow
                role="checkbox"
                aria-checked={isChecked}
                tabIndex={-1}
                key={row.id}
                selected={isChecked}
                className="fancy-table-row"
                sx={{
                  "&:not(:last-child)": { borderBottom: "1px solid #e0e0e0" },
                  height: "50px",
                }}
              >
                <TableCell padding="checkbox">
                  {billStatus === BillingStatus.START && (
                    <Checkbox
                      checked={isChecked}
                      onChange={() => onSelectOne(row.id)}
                    />
                  )}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                >
                  {row.transactionNumber}
                </TableCell>
                <TableCell align="left">{row.payer.name}</TableCell>
                <TableCell align="left">
                  {Number(row.amount).toFixed(2).replace(".", ",") + " €"}
                </TableCell>
                <TableCell align="left">{formatDateUI(row.createdAt)}</TableCell>
                <TableCell align="left">{row.user.firstName + " " + row.user.lastName}</TableCell>
                <TableCell align="left">{row.account.name}</TableCell>
                <TableCell align="left">{row.tAccounts?.name || "None"}</TableCell>
                {billStatus === BillingStatus.START && (
                  <TableCell align="left">
                    <IconButton
                      className="fancy-button"
                      onClick={() => onEdit(row.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => onDelete(row.id)}
                      aria-label="delete"
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
                {/* {(billStatus === BillingStatus.OPEN || billStatus === BillingStatus.PREVIOUS) && (
                  <TableCell align="left">
                    <Box
                      display="flex"
                      gap={1}
                    >
                      <IconButton
                        className="fancy-button"
                        onClick={() => handleDownloadPdf(row.paperCenterReceipt?.id || "")}
                      >
                        <HistoryEduIcon />
                      </IconButton>
                      <IconButton
                        className="fancy-button"
                        onClick={() => handleDownloadPdf(row.billPdf?.id || "")}
                      >
                        <RequestQuoteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                )} */}
                {(billStatus === BillingStatus.OPEN || billStatus === BillingStatus.PREVIOUS) &&
                  (row.billPdf?.id || row.paperCenterReceipt?.id || row.docDto?.id) && (
                    <TableCell align="left">
                      <Box
                        display="flex"
                        gap={1}
                      >
                        {row.paperCenterReceipt?.id && (
                          <IconButton
                            className="fancy-button"
                            onClick={() => handleDownloadPdf(row.paperCenterReceipt!.id)}
                          >
                            <HistoryEduIcon />
                          </IconButton>
                        )}
                        {row.billPdf?.id && (
                          <IconButton
                            className="fancy-button"
                            onClick={() => handleDownloadPdf(row.billPdf!.id)}
                          >
                            <RequestQuoteIcon />
                          </IconButton>
                        )}
                        {row.docDto?.id && (
                          <IconButton
                            className="fancy-button"
                            onClick={() => handleDownloadPdf(row.docDto!.id)}
                          >
                            <RequestQuoteIcon />
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  )}
              </MuiTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
