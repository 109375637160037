import { action, makeObservable, observable } from "mobx";
import AuthStore from "../Auth/authStore";
import {
  TransactionsApi,
  BaseTransactionDetailsDto,
  RxDto,
  CreatePatientTransactionDto,
  CreateInsuranceTransactionDto,
  PayerDto,
  UnpaidBillDto,
  GetInsuranceTransactionDto,
  GroupedDataDto,
  ExportBillingData,
  UpdateInsuranceTransactionDto,
  UpdateExportStatusDto,
  DocumentDto,
  PaginatedGetBankTransferDto,
  PaginatedBaseTransactionDetailsDto,
  PaginatedGetRechnungDto,
  CreateRechnungDto,
  GetRechnungDetailsDto,
  CreateBankTransferDto,
  RxBillDto,
  UpdateRechnungDto,
  ProcessedBGBillingDto,
  DebitPermissionDto,
  UploadDocumentDto,
} from "../../api-client";
import notificationStore from "../Notification/notificationStore";
import { transactionMethods } from "../../components/Transactions/Kasse/types/kasse.types";
import { BillingStatus } from "../../components/Transactions/Insurance/Base/pages/InsurancePageLayout";
import {
  PaginatedGetInsuranceTransaction,
  SearchTransaction,
} from "../../components/Transactions/Insurance/Base/types/insuranceBills.types";
import {
  TestType,
  TestTypeInterface,
} from "../../components/Transactions/Insurance/DirectBilling/Edit/types/TestTypes.interface";

export default class TransactionsStore {
  public apiClient: TransactionsApi;
  public authStore: AuthStore;

  public constructor(apiClient: TransactionsApi, authStore: AuthStore) {
    this.apiClient = apiClient;
    this.authStore = authStore;
    makeObservable(this);
  }

  @observable
  public lastUpdate = new Date();

  @action
  public createTransaction = async (
    creatDTO: CreatePatientTransactionDto | CreateInsuranceTransactionDto
  ): Promise<BaseTransactionDetailsDto> => {
    try {
      const { data } = await this.apiClient.createTransaction(creatDTO);
      this.lastUpdate = new Date();
      notificationStore.showMessage("Transaktion erfolgreich erstellt", "success");
      return data || [];
    } catch (error: any) {
      let errorMessage = error.response?.data?.message || error.message;
      notificationStore.showMessage(
        `Fehler beim Erstellen der Transaktion: ${errorMessage}`,
        "error",
        error
      );
      throw error;
    }
  };

  @action
  public noShowInvoiceTransaction = async (
    appointmentId: string,
    creatDTO: CreateRechnungDto
  ): Promise<GetRechnungDetailsDto> => {
    try {
      const { data } = await this.apiClient.noShowInvoiceTransaction(appointmentId, creatDTO);
      this.lastUpdate = new Date();
      notificationStore.showMessage("Transaktion erfolgreich erstellt", "success");
      return data || [];
    } catch (error) {
      console.error("Error creating transaction:", error);
      notificationStore.showMessage("Error creating transaction", "error", error);
      throw error;
    }
  };

  @action
  public getValueofAppointment = async (id: string): Promise<any> => {
    const { data } = (await this.apiClient.getValueofAppointment(id)) as any;
    return data || [];
  };

  @action
  public getTransactionByPatientId = async (id: number): Promise<BaseTransactionDetailsDto[]> => {
    const { data } = (await this.apiClient.getAllTransactionForPatient(id)) as any;
    return data || [];
  };

  @action
  public getPaidBillsByPatientId = async (id: number): Promise<RxBillDto> => {
    try {
      const { data } = (await this.apiClient.getPaidBillsByPatientId(id)) as any;
      return data || [];
    } catch (error) {
      console.error("Error fetching paid bills:", error);
      notificationStore.showMessage(
        `Error fetching paid bills for patient ID ${id}:`,
        "error",
        error
      );
      throw error;
    }
  };

  @action
  public getUnpaidBillsByPatientId = async (id: number): Promise<UnpaidBillDto> => {
    try {
      const { data } = (await this.apiClient.getUnpaidBillsByPatientId(id)) as any;
      return data || [];
    } catch (error) {
      console.error("Error fetching unpaid bills:", error);
      notificationStore.showMessage(
        `Error fetching unpaid bills for patient ID ${id}:`,
        "error",
        error
      );
      throw error;
    }
  };

  @action
  public getDuePaymentByRxNumber = async (
    id: string
  ): Promise<{
    dueAmount: number;
    matchAppointments: boolean;
    totalAmountPaid: number;
    isTimeSlotZero: boolean;
  }> => {
    try {
      const { data } = (await this.apiClient.getDuePaymentByRxNumberForPatient(id)) as any;
      return data || [];
    } catch (error) {
      console.error("Error fetching due payment:", error);
      notificationStore.showMessage(
        `Error fetching due payment for rx number ${id}:`,
        "error",
        error
      );
      throw error;
    }
  };

  @action
  public getBadgeUnpaidBillsByPatientId = async (id: number): Promise<boolean[]> => {
    try {
      const { data } = (await this.apiClient.getBadgeUnpaidBillsByPatientId(id)) as any;
      return data || [];
    } catch (error) {
      console.error("Error fetching reminder badge for unpaid bills:", error);
      notificationStore.showMessage(
        `Error fetching reminder badge for unpaid bills for patient ID ${id}:`,
        "error",
        error
      );
      throw error;
    }
  };

  @action
  public getBillingCentersFromPayer = async (
    payerIk: string,
    accountId: string
  ): Promise<PayerDto> => {
    try {
      const { data } = (await this.apiClient.getBillingCentersFromPayer(payerIk, accountId)) as any;
      return data || [];
    } catch (error) {
      console.error("Error fetching payers and billing centers:", error);
      notificationStore.showMessage(
        `Für Versicherer Nummer ${payerIk} wurde kein Zahler gefunden`,
        "error",
        error
      );
      throw error;
    }
  };

  @action
  public getRxsForIndirectBillsForInsurance = async (
    accountId: string
  ): Promise<{
    rxData: RxDto[];
    totalPrice: number;
  }> => {
    const { data } = (await this.apiClient.getRxsForIndirectBillsForInsurance(accountId)) as any;
    return data || [];
  };

  @action
  public getAllInsuranceBill = async (
    billStatus: BillingStatus,
    searchTransactionQuery: SearchTransaction
  ): Promise<PaginatedGetInsuranceTransaction> => {
    try {
      const { page = 1, limit = 15, startDate, endDate, searchStr } = searchTransactionQuery;
      const { data } = await this.apiClient.getAllInsuranceTransactions(
        billStatus,
        limit,
        page,
        startDate,
        endDate,
        searchStr
      );

      return data || [];
    } catch (error) {
      console.error("Error fetching insurance bills:", error);
      notificationStore.showMessage(`Error fetching insurance bills:`, "error", error);
      throw error;
    }
  };

  @action
  public getAllBGInsuranceBill = async (
    billStatus: BillingStatus,
    searchTransactionQuery: SearchTransaction
  ): Promise<PaginatedGetInsuranceTransaction> => {
    try {
      const { page = 1, limit = 15, startDate, endDate, searchStr } = searchTransactionQuery;
      const { data } = await this.apiClient.getAllBGInsuranceTransactions(
        billStatus,
        limit,
        page,
        startDate,
        endDate,
        searchStr
      );
      return data || [];
    } catch (error) {
      console.error("Error fetching insurance bills:", error);
      notificationStore.showMessage(`Error fetching post insurance bills:`, "error", error);
      throw error;
    }
  };

  @action
  public getSingleRxForIndirectBillsForInsurance = async (rxNumber: string): Promise<any> => {
    try {
      const { data } = (await this.apiClient.getSingleRxForIndirectBillsForInsurance(
        rxNumber
      )) as any;
      return data || [];
    } catch (error) {
      console.error("Error fetching insurance bills:", error);
      notificationStore.showMessage(`Error fetching insurance bills:`, "error", error);
      throw error;
    }
  };

  @action
  public directBillingAllInfo = async (accountId: string): Promise<GroupedDataDto[]> => {
    try {
      const { data } = (await this.apiClient.getDataPacketForDirectBilling(accountId)) as any;
      return data || [];
    } catch (error) {
      console.error("Error fetching direct billing data:", error);
      notificationStore.showMessage(`Error fetching direct billing data:`, "error", error);
      throw error;
    }
  };

  @action
  public BGBillingAllInfo = async (accountId: string): Promise<ProcessedBGBillingDto[]> => {
    try {
      const { data } = (await this.apiClient.getDataPacketForBGBilling(accountId)) as any;
      return data || [];
    } catch (error) {
      console.error("Error fetching direct billing data:", error);
      notificationStore.showMessage(`Error fetching direct billing data:`, "error", error);
      throw error;
    }
  };

  @action
  public getBillingDataForEditing = async (billId: string[]): Promise<ExportBillingData[]> => {
    try {
      const { data } = (await this.apiClient.getBillingDataForEditing(billId)) as any;
      return data || [];
    } catch (error) {
      console.error("Error fetching billing data for editing:", error);
      notificationStore.showMessage(`Error fetching billing data for editing:`, "error", error);
      throw error;
    }
  };

  @action
  public exportData = async (
    exportBillingData: ExportBillingData[],
    testTypeData: TestTypeInterface
  ): Promise<any> => {
    try {
      await this.apiClient.exportInsuranceBills({
        exportBillingData,
        testTypeData,
      });
      notificationStore.showMessage("Daten erfolgreich exportiert", "success");
    } catch (error) {
      console.error("Error exporting data:", error);
      notificationStore.showMessage("Error exporting data", "error", error);
      throw error;
    }
  };

  @action
  public updateExportAndBillingStatus = async (
    billId: string,
    data: UpdateExportStatusDto
  ): Promise<any> => {
    try {
      await this.apiClient.updateExportAndBillingStatus(billId, data);
    } catch (error) {
      console.error("Error updating export status:", error);
      notificationStore.showMessage("Error updating export status", "error", error);
      throw error;
    }
  };

  @action
  public updateExportBillingStatusAndRxStatus = async (
    billId: string,
    data: UpdateExportStatusDto,
    rxIds: string[]
  ): Promise<any> => {
    try {
      console.log("RXIDS", rxIds);
      await this.apiClient.updateExportBillingStatusAndRxStatus(billId, data, rxIds);
    } catch (error) {
      console.error("Error updating export status:", error);
      notificationStore.showMessage("Error updating export status", "error", error);
      throw error;
    }
  };

  @action
  public printBillingData = async (
    billId: string,
    incomingData: UpdateInsuranceTransactionDto,
    testType: TestType
  ): Promise<GetInsuranceTransactionDto> => {
    try {
      const { data } = await this.apiClient.printDirectBillingDocument(
        billId,
        incomingData,
        testType
      );
      notificationStore.showMessage("Erfolgreich gedruck", "success");
      return data || [];
    } catch (error) {
      console.error("Error submitting billing data for printing:", error);
      notificationStore.showMessage("Error submitting billing data for printing", "error", error);
      throw error;
    }
  };

  @action
  public submitEditedBillingData = async (
    billId: string,
    data: UpdateInsuranceTransactionDto
  ): Promise<UpdateInsuranceTransactionDto> => {
    try {
      await this.apiClient.updateInsuranceTransaction(billId, data);
      //notificationStore.showMessage("Daten erfolgreich gespeichert", "success");
      return data || [];
    } catch (error) {
      console.error("Error submitting edited billing data:", error);
      notificationStore.showMessage("Error submitting edited billing data", "error", error);
      throw error;
    }
  };

  @action
  public printBGBillingDocument = async (
    billId: string,
    incomingData: UpdateInsuranceTransactionDto
  ): Promise<GetInsuranceTransactionDto> => {
    try {
      const { data } = await this.apiClient.printBGtBillingDocument(billId, incomingData);
      notificationStore.showMessage("Erfolgreich gedruckt", "success");
      return data || [];
    } catch (error) {
      console.error("Error submitting billing data for printing:", error);
      notificationStore.showMessage("Error submitting billing data for printing", "error", error);
      throw error;
    }
  };

  @action
  public downloadPdf = async (documentId: string): Promise<DocumentDto> => {
    try {
      const { data } = await this.apiClient.downloadDirectBillingDocument(documentId);
      return data || {};
    } catch {
      notificationStore.showMessage("Fehler beim Herunterladen des PDFs", "error");
      throw new Error("Error downloading PDF");
    }
  };

  @action
  public softDeleteTransaction = async (transactionId: string): Promise<{ message: string }> => {
    try {
      const { message } = (await this.apiClient.softDeleteInsuranceTransaction(
        transactionId
      )) as any;
      notificationStore.showMessage("Transaktion erfolgreich gelöscht", "success");
      return message || [];
    } catch (error) {
      console.error(
        "For some reason the transaction is not deleted. This message is coming from transaction store",
        error
      );
      notificationStore.showMessage("Fehler beim Löschen der Transaktion", "error", error);
      throw error;
    }
  };

  @action
  public getPatientAndRxDetails = async (name: string): Promise<any> => {
    try {
      const { data } = (await this.apiClient.getPatientRx(name)) as any;
      console.log("PatientData: With RX for SPOTLIGHT Search FROM STORE", data);
      return data || [];
    } catch (error) {
      console.error("Error fetching patient and rx details:", error);
      notificationStore.showMessage(`Error fetching patient and rx details:`, "error", error);
      throw error;
    }
  };

  @action
  public getTransactionsByType = async (
    accountId: string,
    transactionType: transactionMethods,
    searchTransactionQuery: SearchTransaction
  ): Promise<PaginatedBaseTransactionDetailsDto> => {
    try {
      const { page = 1, limit = 1, startDate, endDate, searchStr } = searchTransactionQuery;
      const { data } = await this.apiClient.getAllTransactions(
        accountId,
        transactionType,
        limit,
        page,
        startDate,
        endDate,
        searchStr
      );
      return data || [];
    } catch (error) {
      console.error("Error fetching transactions:", error);
      notificationStore.showMessage(`Error fetching transactions:`, "error", error);
      throw error;
    }
  };

  @action
  public getAllBankTransfer = async (
    accountId: string,
    searchTransactionQuery: SearchTransaction
  ): Promise<PaginatedGetBankTransferDto> => {
    try {
      const { page = 1, limit = 1, startDate, endDate, searchStr } = searchTransactionQuery;
      const { data } = await this.apiClient.getAllBankTransfers(
        accountId,
        limit,
        page,
        startDate,
        endDate,
        searchStr
      );
      return data || [];
    } catch (error) {
      console.error("Error fetching bank transfers:", error);
      notificationStore.showMessage(`Error fetching bank transfers:`, "error", error);
      throw error;
    }
  };

  @action
  public getAllRechnung = async (
    accountId: string,
    searchTransactionQuery: SearchTransaction
  ): Promise<PaginatedGetRechnungDto> => {
    try {
      const { page = 1, limit = 1, startDate, endDate, searchStr } = searchTransactionQuery;
      const { data } = await this.apiClient.getAllRechnung(
        accountId,
        limit,
        page,
        startDate,
        endDate,
        searchStr
      );
      return data || [];
    } catch (error) {
      console.error("Error fetching rechnungen:", error);
      notificationStore.showMessage(`Error fetching rechnungen:`, "error", error);
      throw error;
    }
  };

  @action
  public getRechnungByPatientId = async (pid: number): Promise<GetRechnungDetailsDto[]> => {
    try {
      const { data } = (await this.apiClient.getRechnungByPatientId(pid)) as any;
      return data || [];
    } catch (error) {
      notificationStore.showMessage("error", (error as Error).message);
      throw error;
    }
  };

    @action
    public getAllRechnungWithBillReminder = async (accountId: string, searchTransactionQuery: SearchTransaction): Promise<PaginatedGetRechnungDto> => {
        try {
        const { page = 1, limit = 1, startDate, endDate, searchStr } = searchTransactionQuery;
          const { data } = await this.apiClient.getAllRechnungWithBillReminders(accountId,
                limit,
        page,
        startDate,
        endDate,
        searchStr
          );
        return data || [];
        } catch (error: any) {
            console.error('Problem Getting Mahnung:', error);
            let errorMessage = error.response?.data?.message || error.message;
            notificationStore.showMessage(`Problem Getting Mahnung: ${errorMessage}`, "error", error);
            throw error;
        }
    }


  // @action
  // public getRechnungByPatientId = async (pid: number): Promise<GetRechnungDetailsDto[]> => {
  //   try {
  //     const { data } = await this.apiClient.getRechnungByPatientId(pid) as any;
  //     return data || [];
  //   } catch (error) {
  //     notificationStore.showMessage("error", (error as Error).message);
  //     throw error;
  //   }
  // }
  @action
  public getDebitPermissionsByPatient = async (pid: number): Promise<GetRechnungDetailsDto[]> => {
    try {
      const { data } = (await this.apiClient.getDebitPermissionsForPatient(pid)) as any;
      return data || [];
    } catch (error) {
      notificationStore.showMessage("error", (error as Error).message);
      throw error;
    }
  };

  @action
  public createOrUpdateDebitPermission = async (patientId: number, dto: DebitPermissionDto) => {
    try {
      const { data } = (await this.apiClient.createOrUpdateDebitPermission(patientId, dto)) as any;
      notificationStore.showMessage("Abbuchungsberechtigung erfolgreich aktualisiert", "success");
      return data || [];
    } catch (error: any) {
      let errorMessage = error.response?.data?.message || error.message;
      notificationStore.showMessage(
        `Fehler beim Aktualisieren der Berechtigung: ${errorMessage}`,
        "error",
        error
      );
      throw error;
    }
  };

  @action
  public softDeleteDebitPermission = async (debitPermissionId: string) => {
    try {
      const { message } = (await this.apiClient.softDeleteDebitPermission(
        debitPermissionId
      )) as any;
      notificationStore.showMessage("Abbuchungserlaubnis erfolgreich gelöscht", "success");
      return message || "";
    } catch (error: any) {
      let errorMessage = error.response?.data?.message || error.message;
      notificationStore.showMessage(
        `Fehler beim Löschen der Berechtigung: ${errorMessage}`,
        "error",
        error
      );
    }
  };

  @action
  public getUniquePairsForPatient = async (patientId: number) => {
    try {
      const { data } = (await this.apiClient.getUniquePairsForPatient(patientId)) as any;
      console.log("ausuduuduuuf",data)
      return data || [];
    } catch (error: any) {
      let errorMessage = error.response?.data?.message || error.message;
      throw error;
    }
  };

  @action
  public generateDebitPermissionPdf = async (debitPermissionId: string) => {
    try {
      const { data } = (await this.apiClient.generateDebitPermissionPdf(debitPermissionId)) as any;
      notificationStore.showMessage("PDF erfolgreich erstellt!", "success");
      return data || [];
    } catch (error: any) {
      let errorMessage = error.response?.data?.message || error.message;
      notificationStore.showMessage(
        `Fehler beim Erstellen des PDF: ${errorMessage}`,
        "error",
        error
      );
      throw error;
    }
  };

  @action
  public uploadSignedDocument = async (debitPermissionId: string, body: UploadDocumentDto) => {
    try {
      const { data } = (await this.apiClient.uploadSignedDocument(
        debitPermissionId,
        body.docuType,
        body.ownerType,
        body.ownerId,
        body.file,
        body.makeAccessible,
        body.rxId
      )) as any;
      notificationStore.showMessage("Document uploaded successfully!", "success");
      return data || [];
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to upload Document";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action
  public createRechnung = async (creatDto: CreateRechnungDto): Promise<GetRechnungDetailsDto> => {
    try {
      const { data } = await this.apiClient.createRechnung(creatDto);
      this.lastUpdate = new Date();
      notificationStore.showMessage("Rechnung erfolgreich erstellt", "success");
      return data || [];
    } catch (error: any) {
      let errorMessage = error.response?.data?.message || error.message;
      notificationStore.showMessage(
        `Fehler beim Erstellen der Rechnungen: ${errorMessage}`,
        "error",
        error
      );
      throw error;
    }
  };

  @action
  public softDeleteRechnung = async (rechnungId: string): Promise<{ message: string }> => {
    try {
      const { data } = (await this.apiClient.softDeleteRechnung(rechnungId)) as any;
      notificationStore.showMessage("Rechnung erfolgreich gelöscht", "success");
      return data || [];
    } catch (error) {
      console.error(
        "For some reason the rechnung is not deleted. This message is coming from transaction store",
        error
      );
      notificationStore.showMessage("Fehler beim Löschen der Rechnung", "error", error);
      throw error;
    }
  };


    @action
    public createBillReminder = async (rechnungId: string): Promise<any> => {
        try {
            const { data } = await this.apiClient.createBillReminderForRechnung(rechnungId);
            notificationStore.showMessage("Mahnung erfolgreich erstellt", "success");
            return data || [];
        } catch (error: any) {
            let errorMessage = error.response?.data?.message || error.message;
            notificationStore.showMessage(
                `Fehler beim Erstellen der Mahnung: ${errorMessage}`,
                "error",
                error
            );
            throw error;
        }
    }


  // @action
  // public softDeleteRechnung = async (rechnungId: string): Promise<{ message: string }> => {
  //   try {
  //     const { data } = await this.apiClient.softDeleteRechnung(rechnungId) as any;
  //     notificationStore.showMessage("Rechnung erfolgreich gelöscht", "success");
  //     return data || [];
  //   }
  //   catch (error) {
  //     console.error('For some reason the rechnung is not deleted. This message is coming from transaction store', error);
  //     notificationStore.showMessage("Fehler beim Löschen der Rechnung", "error", error);
  //     throw error;
  //   }
  // }
  @action
  public createBankTransfer = async (
    creatDto: CreateBankTransferDto
  ): Promise<GetRechnungDetailsDto> => {
    try {
      const { data } = await this.apiClient.createBankTransferForRechnung(creatDto);
      this.lastUpdate = new Date();
      notificationStore.showMessage("Bank Transfer erfolgreich erstellt", "success");
      return data || [];
    } catch (error) {
      notificationStore.showMessage("error", (error as Error).message);
      throw error;
    }
  };

  @action
  public updateRechnung = async (
    id: string,
    updateData: UpdateRechnungDto
  ): Promise<GetRechnungDetailsDto> => {
    try {
      const { data } = await this.apiClient.updateRechnungById(id, updateData);
      this.lastUpdate = new Date();
      notificationStore.showMessage("Rechnung erfolgreich aktualisiert", "success");
      return data || [];
    } catch (error) {
      notificationStore.showMessage("error", (error as Error).message);
      throw error;
    }
  };

  @action
  public getRechnungForRx = async (rxId: string): Promise<any> => {
    try {
      const { data } = await this.apiClient.getRechnungByRxId(rxId);
      return data || null;
    } catch (error) {
      notificationStore.showMessage("error", (error as Error).message);
      throw error;
    }
  };
}
