import { FormControl, MenuItem, Select, SxProps } from '@mui/material';

interface PlotSelectorProps<T extends string> {
  plotType: T; 
  onChange: (value: T) => void;
  availableOptions: T[];
  optionLabels: Record<T, string>;
  sx?: SxProps;
}

const PlotSelector = <T extends string> ({ plotType, onChange, availableOptions, optionLabels, sx }: PlotSelectorProps<T>) => {
  return (
    <FormControl sx={{ margin: '0px', padding: '0px', ...sx }}>
        <Select value={plotType} onChange={(e) => onChange(e.target.value as T)} sx={{ fontSize: '14px', fontFamily: 'Inter', fontWeight: 700, mb: 0, position: 'relative', '.MuiOutlinedInput-notchedOutline': { border: 0 } }} MenuProps={{ disableScrollLock: true }} size="small" autoWidth disableUnderline >
          {availableOptions.map((option: T) => (
            <MenuItem key={option} value={option} sx={{ fontSize: '12px', fontFamily: 'Inter' }}>
              {optionLabels[option]}
            </MenuItem>
          ))}
        </Select>
    </FormControl>
  );
};

export default PlotSelector;