import { FC } from "react";
import { ExportBillingData } from "../../../../../../api-client";
import { Box } from "@mui/material";
import { PostBillCard } from "./sub-components/PostBillCard";

interface PostBillCardListProps {
  bills: ExportBillingData[];
  todayString: string;
  selectedRxId: string | null;
  notes: Record<string, string>;
  setNotes: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  onRowClick: (row: any) => void;
  setSelectedRxId: (id: string) => void;
  handleMenuOpen: (event: React.MouseEvent<HTMLButtonElement>, row: any) => void;
  handleTaxierenClick: (row: any) => void;
}
const PostBillCardList: FC<PostBillCardListProps> = ({
  bills,
  todayString,
  selectedRxId,
  notes,
  setNotes,
  onRowClick,
  setSelectedRxId,
  handleMenuOpen,
  handleTaxierenClick,
}) => {
  return (
    <>
      {bills.map((bill, billIndex) => (
        <Box
          className="bill-card"
          key={billIndex}
        >
          <PostBillCard
            bill={bill}
            todayString={todayString}
            selectedRxId={selectedRxId}
            notes={notes}
            setNotes={setNotes}
            onRowClick={onRowClick}
            setSelectedRxId={setSelectedRxId}
            handleMenuOpen={handleMenuOpen}
            handleTaxierenClick={handleTaxierenClick}
          />
        </Box>
      ))}
    </>
  );
};

export default PostBillCardList;
