// helper functions for schedule-based stats
import { startOfWeek, addWeeks, isEqual, isBefore } from 'date-fns';
import { ScheduleBasedStatsDto } from '../../../api-client';

interface GroupedData {
    [locationId: string]: ScheduleBasedStatsDto[]
}

interface TotalRxCountData {
    locationId: string, 
    locationName: string,
    date: Date, 
    totalCount: number
}

// function to calculate total number of Rxs by bin (week or day)
export const calculateScheduleBasedTotalRx = (scheduleData: ScheduleBasedStatsDto[], binType: 'week' | 'day'): TotalRxCountData[] => {
    // Array to store the results
    const totalCountData: TotalRxCountData[] = [];

    // Group by location
    const groupedData: GroupedData = Object.groupBy(scheduleData, ({ locationId }) => locationId) as GroupedData;

    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        
        const firstEntryDate = new Date(groupedData[locationId][0].date);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].date);

        let binStart: Date;
        let nextBinStart: Date;

        if (binType === 'week') {
            binStart = startOfWeek(firstEntryDate, { weekStartsOn: 1 });
            
            while(isBefore(binStart, lastEntryDate)) {
                nextBinStart = addWeeks(binStart, 1);
                        
                const total = groupedData[locationId].filter(entry => 
                    new Date(entry.date) >= binStart && new Date(entry.date) < nextBinStart
                ).reduce((total, entry) => total + entry.rxCount, 0);
            
                totalCountData.push({ 
                    locationId: locationId, 
                    locationName: groupedData[locationId][0].locationName,
                    date: binStart, 
                    totalCount: total 
                });

                binStart = nextBinStart;
            }
        } else { // bin = day
            groupedData[locationId].forEach((entry) => {
                totalCountData.push({ 
                    locationId: entry.locationId, 
                    locationName: entry.locationName,
                    date: new Date(entry.date), 
                    totalCount: entry.rxCount, 
                });
            });
        }
    });
    return totalCountData;
}


interface TotalApptValueData {
    locationId: string, 
    locationName: string,
    date: Date, 
    totalValue: number
}

// function to calculate total appointment value by bin (week or day)
export const calculateScheduleBasedTotalApptValue = (scheduleData: ScheduleBasedStatsDto[], binType: 'week' | 'day'): TotalApptValueData[] => {
    // Array to store the results
    const totalValueData: TotalApptValueData[] = [];

    // Group by locationId 
    const groupedData: GroupedData = Object.groupBy(scheduleData, ({ locationId }) => locationId) as GroupedData;

    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        const firstEntryDate = new Date(groupedData[locationId][0].date);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].date);

        let binStart: Date;
        let nextBinStart: Date;

        if (binType === 'week') {
            binStart = startOfWeek(firstEntryDate, {weekStartsOn: 1});
            
            while(isBefore(binStart, lastEntryDate)) {
                nextBinStart = addWeeks(binStart, 1);
            
                // calculate total value in current bin
                const total = groupedData[locationId].filter(entry => 
                    new Date(entry.date) >= binStart && new Date(entry.date) < nextBinStart
                ).reduce((total, entry) => total + entry.value, 0);

                totalValueData.push({
                    locationId: locationId,
                    locationName: groupedData[locationId][0].locationName,
                    date: binStart,
                    totalValue: total,
                });

                binStart = nextBinStart;
            }
        } else { // bin = day
            groupedData[locationId].forEach((entry) => {            
                totalValueData.push({ 
                    locationId: entry.locationId, 
                    locationName: entry.locationName,
                    date: new Date(entry.date), 
                    totalValue: entry.value 
                });
            });
        }
    });
    return totalValueData;
}


interface TotalGapsData {
    locationId: string, 
    locationName: string,
    date: Date, 
    totalGaps: number
}

// function to calculate total number of gaps by bin (week or day)
export const calculateScheduleBasedTotalGaps = (scheduleData: ScheduleBasedStatsDto[], binType: 'week' | 'day'): TotalGapsData[] => {
    // Array to store the results
    const totalGapsData: TotalGapsData[] = [];

    // console.log("sched data length: ", scheduleData.length);
    // console.log("unique dates: ", [...new Set(scheduleData.map((entry) => entry.date))].length);

    // Group by locationId 
    const groupedData: GroupedData = Object.groupBy(scheduleData, ({ locationId }) => locationId) as GroupedData;

    Object.keys(groupedData).forEach(locationId => {
        // Sort by date, ascending order
        groupedData[locationId].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        const firstEntryDate = new Date(groupedData[locationId][0].date);
        const lastEntryDate = new Date(groupedData[locationId][groupedData[locationId].length - 1].date);
        // console.log("sched data:", groupedData[locationId]);

        let binStart: Date;
        let nextBinStart: Date;

        if (binType === 'week') {
            binStart = startOfWeek(firstEntryDate, { weekStartsOn: 1 })
            
            while(isBefore(binStart, lastEntryDate)) {
                nextBinStart = addWeeks(binStart, 1);
            
                // calculate total gaps in current bin
                const total = groupedData[locationId].filter(entry => 
                    new Date(entry.date) >= binStart && new Date(entry.date) < nextBinStart
                ).reduce((total, entry) => total + entry.gaps, 0);

                totalGapsData.push({
                    locationId: locationId,
                    locationName: groupedData[locationId][0].locationName,
                    date: binStart,
                    totalGaps: total,
                });

                binStart = nextBinStart;
            }
        } else {
            groupedData[locationId].forEach((entry) => {
                totalGapsData.push({ 
                    locationId: entry.locationId, 
                    locationName: entry.locationName,
                    date: new Date(entry.date), 
                    totalGaps: entry.gaps
                });
            });
        }
    });
    return totalGapsData;
}