import React, { useState, useEffect, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  DialogActions,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import { DebitPermissionDto } from "../../../../../api-client";
import Controls from "../../../../../atoms/Controls";
import { v4 as uuidv4 } from "uuid";
import useStore from "../../../../../helpers/useStore";
import { TransactionsContext } from "../../../../../stores/Transactions/Transactions.provider";
import { UserContext } from "../../../../../stores/User/User.provider";
import { observer } from "mobx-react";
import notificationStore from "../../../../../stores/Notification/notificationStore";

interface DebitPermissionProps {
  open: boolean;
  handleTransactionClose: () => void;
  patientData: any;
  transactionMethod: string;
  selectedRow?: any;
  isSingleUse: boolean;
  setDataRefreshFlag: any;
  dataRefreshFlag: boolean;
  isEditing: boolean;
}

const DebitPermission: React.FC<DebitPermissionProps> = observer(
  ({
    open,
    handleTransactionClose,
    patientData,
    transactionMethod,
    selectedRow,
    isSingleUse,
    setDataRefreshFlag,
    dataRefreshFlag,
    isEditing,
  }) => {
    const TransactionsStore = useStore(TransactionsContext);
    const {
      getDuePaymentByRxNumber,
      createOrUpdateDebitPermission,
      generateDebitPermissionPdf,
      getUniquePairsForPatient,
    } = TransactionsStore;
    const [accessUrl, setAccessUrl] = useState<string>();
    const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>(undefined);
    const UserStore = useStore(UserContext);
    const { user } = UserStore;
    const [loading, setLoading] = useState(false);
    const [uniquePairs, setUniquePairs] = useState([]);
    const [isFormValid, setIsFormValid] = useState(true); // Track form validity



  

    const initialFormValues: DebitPermissionDto = {
      id: uuidv4(),
      firstName: patientData.firstName || "",
      lastName: patientData.lastName || "",
      iban: "",
      bic: "",
      amount: null,
      patient: patientData,
    };

    const [formValues, setFormValues] = useState<DebitPermissionDto>(initialFormValues);
    const validateForm = () => {
      // Fields that are required to be filled in
      const requiredFields: Array<keyof DebitPermissionDto> = [
        'firstName',
        'lastName',
        'id',
        'account', // Account is required
      ];
    
      // Check if each required field is filled
      const isValid = requiredFields.every((field) => {
        const value:any = formValues[field]; // Safe access with keyof
    
        return value && value !== '';
      });
    
      setIsFormValid(isValid); // Set form validity state
      return isValid;
    };
    

    useEffect(() => {
      const fetchData = async () => {
        let updatedValues = { ...initialFormValues };

        if (isEditing && selectedRow) {
          console.log("sjdjjfkfk",selectedRow)
          updatedValues = selectedRow;
          setSelectedAccountId(selectedRow?.account?.id);
        }

        if (isSingleUse && selectedRow) {
          try {
            const result = await getDuePaymentByRxNumber(selectedRow.rxNumber);
            updatedValues = {
              ...updatedValues,
              account: selectedRow.account || "",
              amount: Number(result.dueAmount) || null,
              isSingleUse: true,
            };
            setSelectedAccountId(selectedRow?.account?.id);
          } catch (error) {
            console.error("Error fetching payment data:", error);
          }
        }
        setFormValues(updatedValues);
      };

      fetchData();
    }, [isSingleUse, selectedRow]);


    useEffect(()=>{
      validateForm()
    },[formValues])
    const accounts = useMemo(() => user?.location?.accounts || [], [user]);
    const [showPairList, setShowPairList] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      if (name === "account") {
        setFormValues((prevValues: any) => ({
          ...prevValues,
          account: accounts.find((option) => option.id === value),
        }));
        setSelectedAccountId(value);
      } 
      if (name === "amount") {
        setFormValues((prevValues: any) => ({
          ...prevValues,
          amount: value || null,
        }));
      } else {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
    };

    //  const handleDocumentClick = (reportId: string) => {
    //     const fetchAccess = async () => {
    //       try {
    //         const response = await generateDebitPermissionPdf(reportId);
    //         setAccessUrl(response.accessUrl);
    //       } catch (err) {
    //         notificationStore.showMessage("Zugriff auf Dokument ist fehlgeschlagen", "error", err);
    //       }
    //     };

    //     fetchAccess();
    //   };

    const handleSubmit = async () => {
      try {
        if (!validateForm()) {
          notificationStore.showMessage("Bitte füllen Sie die erforderlichen Felder aus","warning")
          return;
        }
        setLoading(true);
        const response = await createOrUpdateDebitPermission(patientData.id, {...formValues,amount:formValues.amount?Number(formValues.amount):null});
        if (response && response.id) {
          const result = await generateDebitPermissionPdf(response.id);
          setAccessUrl(result.accessUrl);
        }
        setDataRefreshFlag(!dataRefreshFlag);

        handleTransactionClose();
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setLoading(false);
      }
    };
    console.log("DEIT DATE", formValues);
    useEffect(() => {
      if (accessUrl) {
        window.open(accessUrl, "_blank");
      }
    }, [accessUrl]);

    useEffect(() => {
      if (patientData.id) {
        fetchUniquePairs();
      }
    }, []);

    const fetchUniquePairs = async () => {
      try {
        const response = await getUniquePairsForPatient(patientData.id);
        setUniquePairs(response);
      } catch (error) {
        console.error("Error fetching unique pairs:", error);
      }
    };
    const [selectedPair, setSelectedPair] = useState<any>(null);
    const handlePairSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
      // Find the selected pair based on the value of the select input
      const selectedValue = event.target.value as string;

      // Find the full pair object that matches the selected value
      const pair: any = uniquePairs.find(
        (pair: any) => `${pair.firstName} ${pair.lastName} - IBAN: ${pair.iban}` === selectedValue
      );

      setSelectedPair(pair); // Update state with the selected pair
      if (pair)
        setFormValues({
          ...formValues,
          firstName: pair.firstName,
          lastName: pair.lastName,
          iban: pair.iban,
          bic: pair.bic,
        });
    };
    console.log("suusidiididiid", uniquePairs, selectedPair);

    return (
      <Dialog open={open} onClose={handleTransactionClose} fullWidth maxWidth="md">
        <DialogTitle>SEPA Lastschriftmandat ({transactionMethod})</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            {/* Left Side: Form Fields */}
            <Grid item xs={12} md={7}>
              <Grid container spacing={2}>
                {/* Mandat Reference */}
                <Grid item xs={12}>
                  <Controls.Input
                    label="Mandatsreferenz"
                    name="id"
                    value={formValues.id}
                    onChange={handleChange}
                    fullWidth
                    disabled
                    required
                  />
                </Grid>
                {/* Account Select */}
                <Grid item xs={12}>
                  <Controls.Select
                    name="account"
                    label="Account"
                    value={selectedAccountId || ""}
                    onChange={handleChange}
                    options={accounts.map((option: any) => ({
                      label: option?.name,
                      value: option?.id,
                    }))}
                    required
                    fullWidth
                  />
                </Grid>

                {/* Amount Field */}
                <Grid item xs={12}>
                  <Controls.Input
                    label="Amount"
                    name="amount"
                    value={formValues.amount?formValues.amount:null}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>

                {/* First Name, Last Name, IBAN, BIC */}
                <Grid item xs={12} md={6}>
                  <Controls.Input
                    name="firstName"
                    label="Vorname"
                    value={formValues.firstName}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controls.Input
                    name="lastName"
                    label="Nachname"
                    value={formValues.lastName}
                    onChange={handleChange}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controls.Input
                    label="IBAN"
                    name="iban"
                    value={formValues.iban}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Controls.Input
                    label="BIC"
                    name="bic"
                    value={formValues.bic}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Right Side: Card with Unique Pair Selector */}
            <Grid item xs={12} md={5} sx={{ marginTop: "44px" }}>
              <Card elevation={3} style={{ padding: "10px" }}>
                <CardContent>
                  <Controls.Select
                    name="uniquePair"
                    label="Name/IBAN"
                    value={
                      selectedPair
                        ? `${selectedPair.firstName} ${selectedPair.lastName} - IBAN: ${selectedPair.iban}`
                        : ""
                    }
                    onChange={handlePairSelect}
                    options={uniquePairs.map((pair: any) => ({
                      label: `${pair.firstName} ${pair.lastName} - IBAN: ${pair.iban}`,
                      value: `${pair.firstName} ${pair.lastName} - IBAN: ${pair.iban}`,
                    }))}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleTransactionClose} variant="text" disabled={loading}>
            Abbrechen
          </Button>
          <Button onClick={handleSubmit} variant="contained" disabled={!isFormValid}>
            {loading ? <CircularProgress size={24} color="inherit" /> : "Speichern"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default DebitPermission;
